import React, {useContext, useEffect, useState} from "react";
import {InputLabel, Paper, Select} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Input from "@material-ui/core/Input";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import UserSelectedContext from "../../../Contexts/UserSelectedContext";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import CardContent from "@material-ui/core/CardContent";
import CircularProgress from "@material-ui/core/CircularProgress";
import CardActions from "@material-ui/core/CardActions";
import { dateUTCToLocale } from "../../../Utils/Date";

const ProceedingInfoContainer = (props) => {

    const userSelected = useContext(UserSelectedContext)
    const alertContext = useContext(SnackBarContext)
    const [selectedFile,setSelectedFile] = useState()
    const [loading,setLoading] = useState(false)
    const [courses,setCourses] = useState([])
    const [course,setCourse] = useState()
    const [proceeding,setProceeding] = useState()

    const fetchCourses = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/courses/${userSelected}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return courses;
            });
        setCourses(response)
        setLoading(false);
    }

    const fetchProceeding = async (courseId) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/proceeding/${courseId}/${userSelected}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return courses;
            });
        setProceeding(response)
        setLoading(false);
    }

    const fetchProceedingFile = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        await fetch(`/student-record-api/proceeding/${course}/${userSelected}/download`,{ headers: headers })
            .then(r => r.blob())
            .then((blob)=>{
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `expediente.pdf`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return courses;
            });
        setLoading(false);
    }

    const handleChange = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const sendData = async () => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
        });
        const formData = new FormData()
        formData.append("file",selectedFile)
        const response = await fetch(`/proceeding/${course}/${userSelected}`,
            {
                headers: headers,
                method: "POST" ,
                body: formData
            })
            .then(r => r.json())
            .then(response => {
                alertContext.setMessage(`Expediente enviado`);
                alertContext.setOpen(true);
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return courses;
            });
        setLoading(false)
    }

    useEffect(() => {
        fetchCourses()
    }, [])

    return (
        <React.Fragment>
            <Paper elevation={3} style={{margin:"25px", padding:"15px"}}>
                <Grid container title={"Titulacion"} spacing={2} justify={"center"} alignItems={"center"}>
                    <Grid item>
                        <Typography variant={"h5"} color={"primary"}>Seleccione Titulacion</Typography>
                        { loading === true && <CircularProgress/>}
                    </Grid>
                    <Grid item>
                        <InputLabel id={"proceedingCourseSelect"}>
                            Titulacion
                        </InputLabel>
                        <Select labelId={"CourseSelectLabel"}
                                id={"SelectCourse"}
                                value={course} onChange={
                                    (event) => {
                                        setCourse(event.target.value)
                                        fetchProceeding(event.target.value)
                                    }
                                }>
                            { courses.map((value, index) => {
                                return <MenuItem key={index} value={value.externalId}>{value.name}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                    <Grid item md={12}>
                        { proceeding != null &&
                            <React.Fragment>
                                <Card>
                                    <CardHeader title={proceeding.externalCourseId} subheader={"Expediente"} />
                                    <CardContent>
                                        <Grid container spacing={2}>
                                            <Grid item>
                                                <Typography variant={"caption"} color={"primary"}>Fecha de creación</Typography>
                                                <Typography variant={"body1"} color={"primary"}>{dateUTCToLocale(proceeding.created)}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography variant={"caption"} color={"primary"}>Fecha ultima modificación</Typography>
                                                <Typography variant={"body1"} color={"primary"}>{dateUTCToLocale(proceeding.modified)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </CardContent>
                                    <CardActions>
                                        <Button variant={"contained"} color={"secondary"} onClick={() => {
                                            fetchProceedingFile()
                                        }}>Descargar Expediente</Button>
                                    </CardActions>
                                </Card>
                            </React.Fragment>
                        }
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={3} style={{margin:"25px",padding:"15px"}}>
                <Grid container title={"Expediente"} spacing={2} justify={"center"} alignItems={"center"}>
                    <Grid item md={12}>
                        <Typography variant={"h5"} color={"primary"}>Enviar Expediente</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant={"caption"} color={"primary"}>En caso de incidencia o duda con su expediente puede contactar directamente con Alicia de la Fuente. Su contacto está disponible en Mi institución / Mis organizaciones / Secretaría UCAV / Mensajería. Si lo desea también puede ponerse en contacto con ella en el teléfono 920251020 ext.110</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Divider/>
                    </Grid>
                    <Grid item>
                        <InputLabel id={"proceedingInput"}>
                            Expediente
                        </InputLabel>
                        <Input type={"file"} onChange={handleChange} />
                    </Grid>
                    <Grid item>
                        <Button color={"primary"} variant={"contained"} onClick={sendData}>Enviar</Button>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default ProceedingInfoContainer