import Button from "@material-ui/core/Button";
import React from "react";
import Grid from "@material-ui/core/Grid";
import {Link} from "react-router-dom";

const Service = (props) => {
    return(
        <Grid item>
            <Link to={props.link}>
                <Button variant={"outlined"} color="primary">
                    {props.name}
                </Button>
            </Link>
        </Grid>
    );

};

export default Service;