import React from 'react'
import { MenuItem, TextField } from '@material-ui/core'
import classNames from 'classnames'

const InputSelect = ({
  className, label, variant = 'filled', options, inputProps, value, onChange,
  labelKey = 'label', children, disabled, renderOption, ...props }) => {
    const InputClass = classNames('input', className);

    return (
      <TextField
        className={InputClass}
        onChange={onChange}
        value={value}
        label={label}
        variant={variant}
        disabled={disabled}
        fullWidth
        select
        {...inputProps}
      >
        {
          !!children
            ? children
            : options.map(option => {
              const { value, disabled, condition = true } = option;

              if (!condition) return null;
            
              return (
                <MenuItem
                  key={value}
                  value={value}
                  disabled={disabled}
                >
                  {
                    renderOption
                      ? renderOption(option)
                      : <span className="select-option">{option[labelKey]}</span>
                  }
                </MenuItem>
              )
            })
        }
      </TextField>
    )
}

export default InputSelect
