import React, {useContext, useEffect, useState} from "react";
import InteractionsList from "../../Interactions/InteractionsListPanel/InteractionsList";
import UserSelectedContext from "../../../Contexts/UserSelectedContext";
import {CircularProgress} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import StudentRecordNewInteractionPanel from "./StudentRecordNewInteractionPanel";

const StudentRecordInteractions = (props) => {

    const userSelected = useContext(UserSelectedContext)
    const [data, setData] = useState([])
    const [loading,setLoading] = useState(false)

    const fetchInteractions = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const interactions= await fetch(`/interactions-api/${userSelected}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        id: 1,
                        type: "auto",
                        created: "1/1/1",
                        issuer: "1234",
                        receiver: "4321",
                        dni: "0000000A",
                        titulation: null,
                        course: null,
                        interactionText: "asdf",
                        attatchments: [
                            "interaction.pdf",
                            "response.doc"
                        ]
                    },
                    {
                        id: 2,
                        type: "auto",
                        created: "1/1/1",
                        issuer: "1234",
                        receiver: "System",
                        dni: "0000000A",
                        titulation: null,
                        course: null,
                        interactionText: "asdf",
                        attatchments: [
                            "interaction.pdf",
                            "response.doc"
                        ]
                    }
                ];
            });
        setData(interactions)
        setLoading(false);
    }

    useEffect(() =>{
        fetchInteractions(data)
    },[]);

    return (
        <React.Fragment>
            { loading === true
                ? <CircularProgress />
                :
                <React.Fragment>
                    <StudentRecordNewInteractionPanel userSelected={userSelected} fetchInteractions={fetchInteractions}/>
                    <Grid container spacing={2} direction={"column"}>
                        <Grid item>
                            <InteractionsList user={userSelected} interactions={data}/>
                        </Grid>
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default StudentRecordInteractions