import dayjs from 'dayjs';

// Import locales
// import 'dayjs/locale/en-gb';
import 'dayjs/locale/es';

// Import dayjs extensions
import localizedFormat from 'dayjs/plugin/localizedFormat';
import utc from 'dayjs/plugin/utc';
import timezone from 'dayjs/plugin/timezone';
import relativeTime from 'dayjs/plugin/relativeTime';
import customParseFormat from 'dayjs/plugin/customParseFormat';

// Extend dayjs functionality
dayjs.extend(localizedFormat);
dayjs.extend(utc);
dayjs.extend(timezone);
dayjs.extend(relativeTime);
dayjs.extend(customParseFormat)

/**
 * Initialize locale and timezone for all date displaying methods
 */
const initDate = () => {
  // Set spanish as application locale
  dayjs.locale('es');
  // Retrieve current user timezone
  //const userTimezone = dayjs.tz.guess();
  const spainTimezone = "Europe/Madrid";
  // console.log('User timezone:', userTimezone);
  // Set application timezone
  dayjs.tz.setDefault(spainTimezone);
}

// Launch initialization on initial application load
initDate();

/**
 * Check if the provided param is a DayJS instance
 * @param input - Value to check
 */
export const isDate = input => input instanceof dayjs;

/**
 * Generate current date
 */
export const dateCurrent = () => dayjs().format();

/**
 * Generate yesterday's date (24 h difference)
 */
 export const dateYesterday = () => dayjs().utc().subtract(1, 'day').toISOString();

/**
 * Generate formatted date (example: 2021-06-14T14:36:02+00:00)
 * @param date? Date in UTC - If not provided, calculate from current date
 */
export const dateToString = date => dayjs(date).format();

/**
 * Generate localized date format (example: 14/05/2020)
 * @param date? Date in UTC - If not provided, calculate from current date
 */
export const dateToLocale = (date, format) => dayjs.utc(date).tz().format(format);

/**
 * Generate localized date format (example: 14/05/2020)
 * @param date? Date - If not provided, calculate from current date
 */
 export const dateToLocaleWithTime = date => dayjs(date).tz().format('LLL');

/**
  * Convert UTC date to user's timezone and format the output (result: 04/10/2021 11:30)
  * Add fallback to return '-' if no date is provided
  * @param date? Date - If not provided, calculate from current date
*/
export const dateUTCToLocale = date => {
  if (!date) return '-';

  const converted = dayjs.utc(date).tz().format('DD/MM/YYYY HH:mm');
  return converted;
}

export const dateInstanceToLocale = date => {
  if (!date) return '-';

  const converted = dayjs.utc(date).tz().format('DD/MM/YYYY HH:mm');
  return converted;
}

export const dateUTCToLocaleWithFormat = date => {
    if (!date) return '-';

    const converted = dayjs.utc(date, 'DD-MM-YYYY HH:mm').tz().format('DD/MM/YYYY HH:mm');
    return converted;
}

/**
 * Generate UTC date (example: 2021-06-14T12:36:00+00:00)
 * @param date? Date in UTC - If not provided, calculate from current date
 */
export const dateToUTC = (date, format) => dayjs(date, format).utc().format();

/**
 * Convert date format to date array with the following format [Y, M, D]
 * @param date - Date to convert
 */
export const dateToArray = date => [date.$y, date.$M+1, date.$D];

/**
 * Given two dates, returns if the second one is after the first
 * @param date1 - First date
 * @param date2 - Second date
 */
export const dateIsAfter = (date1, date2) => dayjs(date2).isAfter(date1);

/**
 * Given two dates, returns the difference in ms between them
 * @param date1 - First date
 * @param date2 - Second date
 */
 export const dateDifference = (date1, date2, unit) => dayjs(date2).diff(date1, unit);

 /**
  * Converts a date to the API expected format (Year-Month-Day)
  * @param date - Date to be converted
  * @returns string - 2021/08/22
  */
 export const dateToFilter = date => dayjs(date, 'DD/MM/YYYY').format('YYYY-MM-DD');

/** Converts a string into a dayjs date and returns only the day, month and year in the DD/MM/YYYY format */
export const dateToDayMonthYear = date => dayjs(date).format('DD/MM/YYYY');

/** Converts a string into a dayjs date and returns only the time, in HH:mm format */
export const dateToHourMinute = date => dayjs(date).format('HH:mm');