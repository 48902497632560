import React from 'react';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

const InputText = ({
  className, name, label, variant = 'filled', error,
  rules, disabled, onChange, value, ...props }) => {

  const InputClass = classNames('input', className);

  return (
    <TextField
      className={InputClass}
      name={name}
      id={name}
      onChange={onChange}
      value={value}
      label={label}
      variant={variant}
      error={!!error}
      helperText={error}
      disabled={disabled}
      fullWidth
      {...props}
    />
  )
}

export default InputText
