import React, {useContext, useState} from "react";
import Step from "@material-ui/core/Step";
import {Paper, StepLabel} from "@material-ui/core";
import Stepper from "@material-ui/core/Stepper";
import NewRegistryRequiredParamsContainer from "./NewRegistryRequiredParamsContainer";
import NewRegistryOptionalParamsContainer from "./NewRegistryOptionalParamsContainer";
import NewRegistryUserParamsContainer from "./NewRegistryUserParamsContainer";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import {useNotification} from "../../../Utils/hooks";
import {createRegistry} from "../../../services/Registry/RegistryService";

const NewRegistryPanelContainer = (props) => {

    const alertContext = useContext(SnackBarContext)

    const steps=["Datos requeridos","Busqueda de usuario","Datos opcionales"]
    const [activeStep, setActiveStep] = React.useState(0);
    //Entry variables
    const [bookType,setBookType] = useState("general")
    const [code,setCode] = useState("")
    const [type,setType] = useState("ENTRY")
    const [created,setCreated] = useState(new Date())
    const [modified,setModified] = useState(new Date())
    const [file,setFile] = useState("")
    const [text,setText] = useState(null)
    const [term,setTerm] = useState(null)
    const [userExternalId,setUserExternalId] = useState(null)
    const [receiver,setReceiver] = useState(null)
    const [documentType,setDocumentType] = useState(null)
    const notification = useNotification()

    const NextStep = () => {
        if(activeStep<2)setActiveStep(activeStep+1)
    }

    const PreviousStep = () => {
        if(activeStep>0) setActiveStep(activeStep-1)
    }

    const sendNewEntry = async () => {
        let newRegistry = {
            bookType: bookType,
            code: code,
            type: type,
            created: created,
            modified: modified,
            file: file,
            text: text,
            term: term,
            userExternalId: userExternalId,
            receiver: receiver,
            documentType: documentType
        }
        if(await createRegistry(newRegistry,notification)){
                setActiveStep(0)
                setBookType("general")
                setCode("")
                setType("ENTRY")
                setCreated(new Date())
                setModified(new Date())
                setFile("")
                setText(null)
                setTerm(null)
                setUserExternalId(null)
                setReceiver(null)
                setDocumentType(null)
        }
    }

    return (
        <React.Fragment>
            <Stepper activeStep={activeStep}>
                {steps.map((value, index) => {
                    return (
                        <Step key={value}>
                            <StepLabel>{value}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <Paper elevation={3} style={{margin:"25px"}}>
                { activeStep === 0 &&
                    <NewRegistryRequiredParamsContainer
                        NextStep={NextStep}
                        bookType={bookType} setBookType={setBookType}
                        code={code} setCode={setCode}
                        type={type} setType={setType}
                        created={created} setCreated={setCreated}/>
                }
                { activeStep === 1 &&
                    <NewRegistryUserParamsContainer
                        NextStep={NextStep}
                        PreviousStep={PreviousStep}
                        userExternalId={userExternalId} setUserExternalId={setUserExternalId} />
                }
                { activeStep === 2 &&
                    <NewRegistryOptionalParamsContainer
                        PreviousStep={PreviousStep}
                        sendEntry={sendNewEntry}
                        receiver={receiver} setReceiver={setReceiver}
                        documentType={documentType} setDocumentType={setDocumentType}
                        file={file} setFile={setFile}
                        term={term} setTerm={setTerm}
                        text={text} setText={setText}/>
                }
            </Paper>
        </React.Fragment>
    )
}

export default NewRegistryPanelContainer
