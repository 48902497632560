import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    CircularProgress,
    Divider, FormControlLabel, InputAdornment,
    InputBase,
    Paper, Switch, TextField,
    Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import SnackBarContext from "../../Contexts/SnackBarContext";
import FilterListIcon from '@material-ui/icons/FilterList';
import { dateUTCToLocale } from "../../Utils/Date";

const DocumentalCoord = () => {

    const alertContext = useContext(SnackBarContext)

    const [loading,setLoading] = useState(false)
    const [documents,setDocuments] = useState([])
    const [search,setSearch] = useState("")

    const [filteredDocuments,setFilteredDocuments] = useState([])

    const [reason,setReason] = useState()
    const [onlyPending,setOnlyPending] = useState(false)

    const fetchDocuments = async () => {
        console.log("FETCHING")
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        let response = await fetch(`/documents-api`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.error(reason);
                return [
                    {
                        id: 0,
                        studentId: "alumno",
                        documentType: "Test",
                        documentFile: "182394k393kd39",
                        documentFilename: "test.txt",
                        status: "APPROVED",
                        reason: "",
                        created: "01-01-1995",
                        modified: "01-01-1995"
                    },
                    {
                        id: 1,
                        studentId: "alumno",
                        documentType: "Test",
                        documentFile: "182394k393kd39",
                        documentFilename: "test.txt",
                        status: "PENDING",
                        reason: "",
                        created: "01-01-1995",
                        modified: "01-01-1995"
                    },
                    {
                        id: 2,
                        studentId: "alumno",
                        documentType: "Test",
                        documentFile: "182394k393kd39",
                        documentFilename: "test.txt",
                        status: "REJECTED",
                        reason: "No cumple los requisitos",
                        created: "01-01-1995",
                        modified: "01-01-1995"
                    }
                ];
            });
        setDocuments(response)
        setFilteredDocuments(response)
        setLoading(false)
    }

    useEffect(() =>{
        fetchDocuments()
    },[])

    useEffect(() => {
        filterDocuments()
    },[search,onlyPending])

    const filterDocuments = async () => {
        if(documents.length>0){
            console.log("Filtering")
            let documentsFiltered
            if(search!==""){
                documentsFiltered=documents.filter(value => value.studentId.includes(search));
            }else {
                //if the filtering is void just print all courses
                documentsFiltered=documents
            }
            //apply filter if only pending is checked
            if(onlyPending){
                documentsFiltered=documentsFiltered.filter(value => value.status === "PENDING")
            }
            setFilteredDocuments(documentsFiltered)
        }
    }

    const fetchFile = async (id,filename) => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        await fetch(`/documents-api/${id}/file`,{ headers: headers })
            .then(r => r.blob())
            .then((blob)=>{
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
                console.log(reason);
            });
        setLoading(false)
    }

    const fetchApproveDocument = async(id) =>{
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        await fetch(`/documents-api/${id}/approve`,{ headers: headers, method: "POST" })
            .then(r => {
                if(r.ok){
                    alertContext.setMessage("Documento aprobado")
                    alertContext.setOpen(true)
                }
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
                console.log(reason);
            })
        setLoading(false)
    }

    const fetchRejectDocument = async(id) =>{
        if(reason!=null || reason===""){
            let rejection={
                id: id,
                reason: reason
            }
            setLoading(true)
            let sessionId=sessionStorage.getItem("sessionId")
            let headers= new Headers({
                'Authorization':'Bearer '+sessionId,
                'Content-Type':'application/json'
            })
            await fetch(`/documents-api/${id}/reject`,
                {
                    headers: headers,
                    method: "POST",
                    body: JSON.stringify(rejection)
                })
                .then(r => {
                    if(r.ok){
                        setReason("")
                        alertContext.setMessage("Documento rechazado")
                        alertContext.setOpen(true)
                    }
                })
                .catch(reason => {
                    alertContext.setMessage(`Ocurrio un error: ${reason}`);
                    alertContext.setOpen(true)
                    console.log(reason);
                })
            setLoading(false)
        }else {
            alertContext.setMessage("Se necesita un motivo para el rechazo de un documento")
            alertContext.setOpen(true)
        }

    }

    const handleChange = (event) => {
        setOnlyPending(event.target.checked);
    };

    function renderStatus(status,reason){
        switch (status){
            case "APPROVED":
                return <Button variant={"contained"} style={{background:"#1de9b6"}}>Aprobado</Button>
            case "REJECTED":
                return <Button variant={"contained"} style={{background:"#ff4569"}}>Rechazado por: {reason}</Button>
            case "PENDING":
                return <Button variant={"contained"} style={{background:"#ffc400"}}>Pendiente</Button>
        }
    }

    return (
        <Paper style={{margin:25,padding:25}}>
            { loading === true
                ? <CircularProgress/>
                : <Grid container spacing={2}>
                    <Grid item md={10}>
                        <TextField variant={"outlined"}
                                   placeholder={"Id del alumno"}
                                   label={"Filtro"}
                                   fullWidth
                                   autoFocus
                                   onChange={event => { setSearch(event.target.value) }}
                                   InputProps={{startAdornment:(<InputAdornment position={"start"}><FilterListIcon/></InputAdornment>)}} />
                    </Grid>
                    <Grid item md={2}>
                        <FormControlLabel
                            control={
                                <Switch
                                    checked={onlyPending}
                                    onChange={handleChange}
                                    name="checkedB"
                                    color="primary"
                                />
                            }
                            label="Mostrar solo Pendientes"
                        />
                    </Grid>
                    {filteredDocuments.map((value,index)=>{
                        return(
                            <Grid item md={6}>
                                <Card raised>
                                    <CardHeader title={"Documento "+value.id} subheader={value.documentType}/>
                                    <CardContent>
                                        <Typography variant={"body1"}>Usuario: {value.studentId}</Typography>
                                        {renderStatus(value.status,value.reason)}
                                        <Divider/>
                                        <Typography variant={"caption"}>Creado el {dateUTCToLocale(value.created)}</Typography>
                                        <Divider/>
                                        <Typography variant={"caption"}>Modificado el {dateUTCToLocale(value.modified)}</Typography>
                                    </CardContent>
                                    <CardActions>
                                        <Button
                                            variant={"contained"}
                                            color={"primary"}
                                            onClick={()=>fetchFile(value.id,value.documentFilename)}>
                                            Descargar
                                        </Button>
                                        { value.status !== "APPROVED"
                                            &&
                                            <Button variant={"contained"}
                                                      style={{background:"#6ae66e"}}
                                                      onClick={()=>fetchApproveDocument(value.id)}>
                                                Aprobar
                                            </Button>
                                        }
                                        <Paper variant={"outlined"} style={{padding:2}}>
                                            <InputBase placeholder={"Razon del rechazo"} onChange={(e) => setReason(e.target.value)}/>
                                            <Button variant={"contained"} style={{background:"#e66a6a"}}
                                                    onClick={()=>fetchRejectDocument(value.id)}>
                                                Rechazar
                                            </Button>
                                        </Paper>
                                    </CardActions>
                                </Card>
                            </Grid>
                        )
                    })}
                </Grid>
            }
        </Paper>
    )
}

export default DocumentalCoord