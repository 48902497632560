import React, {useContext, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Input, InputLabel, TextField} from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import SnackBarContext from "../../../Contexts/SnackBarContext";

const SendInteractionPanel = ({setActiveStep,titulation,course,user,handleTabChange}) => {
    const alertContext=useContext(SnackBarContext)
    const [comment,setComment]=useState("")
    const [loading,setLoading] = useState(false)

    function sendNewInteraction(){
        let interaction = {
            "issuer" : sessionStorage.getItem("sessionId"),
            "receiver" : user,
            "dni" : user,
            "titulation" : titulation,
            "course" : course,
            "interactionText" : comment
        }
        console.log(interaction);
        fetchPostInteraction(JSON.stringify(interaction))
    }

    const fetchPostInteraction = async (interaction) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-type': 'application/json'
        });
        await fetch("/interactions-api",{
            headers: headers,
            method: "POST",
            body: interaction })
            .then(r => r.json())
            .then(response =>{
                alertContext.setMessage(`Interaccion ${response.id} creada`);
                alertContext.setOpen(true);
                handleTabChange(0,0);
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
            });
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Grid container
                  spacing={3}
                  alignItems={"center"}
                  justify={"space-around"}
                  style={{padding:"10px"}}>
                <Grid item lg>
                    <FormControl fullWidth>
                        <InputLabel id={"TitulationSelectLabel"}>
                            Titulacion
                        </InputLabel>
                        <Input labelId={"TitulationSelectLabel"}
                               disabled
                               value={titulation}/>
                    </FormControl>
                </Grid>
                <Grid item lg>
                    <FormControl fullWidth>
                        <InputLabel id={"CourseSelectLabel"}>
                            Asignatura
                        </InputLabel>
                        <Input labelId={"CourseSelectLabel"}
                               disabled
                               value={course}/>
                    </FormControl>
                </Grid>
                <Grid item lg>
                    <FormControl fullWidth>
                        <InputLabel id={"UserSelectLabel"}>
                            Usuario
                        </InputLabel>
                        <Input labelId={"UserSelectLabel"}
                               disabled
                               value={user}/>
                    </FormControl>
                </Grid>
                <Grid item lg={12}>
                    <Divider/>
                </Grid>
                <Grid item lg={6}>
                    <FormControl fullWidth>
                        <TextField label={"Comentario"}
                                   onChange={(e) => setComment(e.target.value)}
                                   variant={"filled"}
                                   multiline
                                   placeholder={"Comentario de la interaccion"}/>
                    </FormControl>
                </Grid>
                <Grid item lg={6}>
                    <FormControl fullWidth>
                        <InputLabel id={"FileLabel"}>
                            Adjunto
                        </InputLabel>
                        <Input labelId={"FileLabel"}
                               type={"file"}/>
                    </FormControl>
                </Grid>
                <Grid item lg={12}>
                    <Divider/>
                </Grid>
                <Grid item lg={6}>
                    <Button variant={"contained"}
                            color={"default"}
                            fullWidth
                            onClick={()=>{setActiveStep(2)}}>
                        Atras
                    </Button>
                </Grid>
                <Grid item lg={6}>
                    <Button variant={"contained"}
                            color={"primary"}
                            fullWidth onClick={() => { sendNewInteraction() }}>
                        Enviar
                    </Button>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default SendInteractionPanel