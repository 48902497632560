import React from 'react';
import classNames from 'classnames';
import './Divider.scss';

const Divider = ({ className, title, actions }) => {
  const DividerClass = classNames('divider', className);

  return (
    <div className={DividerClass}>
      <h2 className="divider--title">{title}</h2>
      { !!actions && <div className="divider--actions">{actions}</div> }
    </div>
  )
}

export default Divider;
