export const MUIDatatableLabels = {
  body: {
    noMatch: "Lo sentimos, no hay resultados para la búsqueda actual",
    toolTip: "Ordenar",
  },
  pagination: {
    next: "Siguiente página",
    previous: "Página anterior",
    rowsPerPage: "Filas por página:",
    displayRows: "de"
  },
  toolbar: {
    search: "Buscar",
    downloadCsv: "Descargar CSV",
    print: "Imprimir",
    viewColumns: "Mostrar columnas",
    filterTable: "Filtrar tabla"
  },
  filter: {
    all: "Todo",
    title: "FILTROS",
    reset: "REINICIAR"
  },
  viewColumns: {
    title: "Mostrar columnas",
    titleAria: "Mostrar/esconder columnas"
  },
  selectedRows: {
    text: "fila(s) seleccionadas",
    delete: "Borrar",
    deleteAria: "Borrar filas seleccionadas"
  }
};