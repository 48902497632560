import React, {useContext, useEffect, useState} from "react";
import {
    InputLabel,
    makeStyles,
    MenuItem, Paper,
    Select,
    Step,
    StepContent,
    StepLabel,
    Stepper, TextField,
} from "@material-ui/core";
import StudentRecordUserSelectionContainer
    from "../../StudentRecord/CoordUserSelection/StudentRecordUserSelectionContainer";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import dayjs from "dayjs";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

const ExamsCoordExtraordinaryExamsView = () => {
    const classes = useStyles();
    const [step, setStep] = useState(0)
    const [courses, setCourses] = useState([{
        "courseId": "1",
        "courseName": "Curso 1"
    }])
    const alertContext = useContext(SnackBarContext)
    const [course, setCourse] = useState("")
    const [userSelected, setUserSelected] = useState("")
    const [examOption, setExamOption] = useState("")
    const [examDate, setExamDate] = useState(dayjs().tz().format().substring(0, 16))
    const [exams, setExams] = useState([])
    const [examSelected, setExamSelected] = useState("")

    useEffect(()=>{
        fetchEnrollments()
    }, [userSelected])

    useEffect(()=>{
        fetchExams()
    },[course])

    useEffect(() => {
        console.log(examSelected)
    },[examSelected])

    const fetchEnrollments = async () => {
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const courses = await fetch(`/exams-api/courses/users/${userSelected}/enrollments`, {headers: headers})
            .then(r => {
                if (r.ok) {
                    return r.json()
                } else {
                    throw new Error("Error recieving session...")
                }
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return [
                    {
                        "courseId": "1",
                        "courseName": "Curso 1"
                    },
                    {
                        "courseId": "2",
                        "courseName": "Curso 2"
                    }
                ]
            })
        setCourses(courses)
    }

    const fetchExams = async () =>{
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const exams = await fetch(`/exams-api/exams/courses/${course}`, {headers: headers})
            .then(r => {
                if (r.ok) {
                    return r.json()
                } else {
                    throw new Error("Error recieving session...")
                }
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return [
                    {
                        "id": "1",
                        "option": "Examen 1"
                    },
                    {
                        "id": "2",
                        "option": "Examen 2"
                    }
                ]
            })
        setExams(exams)
    }


    const createExam = async () => {

        let currExam = exams.filter(exam => exam.id === examSelected)[0]

        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-type': 'application/json'
        });

        let data=JSON.stringify({
            examId: currExam.id,
            option: examOption,
            date: examDate
        })

        await fetch(`/exams-api/exams/${currExam.id}/extraordinary`,{
            headers: headers,
            method: "POST",
            body: data
        })
            .then(r => r.json())
            .then(response =>{
                alertContext.setMessage(`Examen extraordinario ${response.id} creado`)
                alertContext.setOpen(true)
                return response
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`)
                console.log(reason)
            })
            .then(response => {
                createChoice(response.id)
            })
    }

    const createChoice = async (examId) =>{

        let currExam = exams.filter(exam => exam.id === examSelected)[0]

        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-type': 'application/json'
        });

        let data=JSON.stringify({
            userId: userSelected,
            examId: examId,
            headquarterId: currExam.defaultHeadquarter
        })

        await fetch(`/exams-api/requests/${userSelected}`,{
            headers: headers,
            method: "POST",
            body: data
        })
            .then(r => r.json())
            .then(response =>{
                alertContext.setMessage(`Examen extraordinario ${response.id} creado`)
                alertContext.setOpen(true)
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`)
                console.log(reason)
            });
    }

    const nextStep = () =>{
        setStep(step+1)
    }

    const prevStep = () =>{
        setStep(step-1)
    }

    return (
        <React.Fragment>
            <Stepper activeStep={step} orientation={"vertical"}>
                <Step key={0}>
                    <StepLabel>Buscar usuario</StepLabel>
                    {userSelected === "" ?
                        (
                            <StepContent>
                                <StudentRecordUserSelectionContainer userSelected={userSelected}
                                                                     setUserSelected={setUserSelected}/>
                            </StepContent>
                        ) :
                        (
                            <StepContent>
                                <StudentRecordUserSelectionContainer userSelected={userSelected}
                                                                     setUserSentry-loginelected={setUserSelected}/>
                                <Grid container spacing={2} style={{margin:"20px"}}>
                                    <Grid item xs>
                                        <Button variant="contained" color="primary" disabled={true} onClick={prevStep} style={{marginRight:"5px"}}>Anterior</Button>
                                        <Button variant="contained" color="primary" onClick={nextStep}>Siguiente</Button>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        )}
                </Step>
                <Step key={1}>
                    <StepLabel>Elegir curso</StepLabel>
                    <StepContent>
                        <FormControl>
                            <InputLabel id="demo-simple-select-label">Curso</InputLabel>
                            <Select
                                className={classes.formControl}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={course}
                                onChange={(e) => setCourse(e.target.value)}
                            >
                                {courses.map((c) => (<MenuItem key={"courseSelector" + c.courseId} value={c.courseId}>{c.courseName}</MenuItem>))}
                            </Select>
                            <Grid container spacing={2} style={{margin:"20px"}}>
                                <Grid item xs>
                                    <Button variant="contained" color="primary"  onClick={prevStep} style={{marginRight:"5px"}}>Anterior</Button>
                                    <Button variant="contained" color="primary" disabled={course===""} onClick={nextStep}>Siguiente</Button>
                                </Grid>
                            </Grid>
                        </FormControl>
                    </StepContent>
                </Step>
                <Step key={2}>
                    <StepLabel>Crear examen extraordinario</StepLabel>
                    <StepContent>
                        <Grid container>
                            <Grid item>
                                <InputLabel id="selectExamLabel">Examen</InputLabel>
                                <Select
                                    className={classes.formControl}
                                    labelId="selectExamLabel"
                                    id="selectExam"
                                    value={examSelected}
                                    onChange={(e) => {setExamSelected(e.target.value)}}
                                >
                                    {exams.map((e) => (<MenuItem key={ e.id} value={e.id}>{e.option}</MenuItem>))}
                                </Select>
                                <TextField
                                    id="examOption"
                                    label="Nombre del examen"
                                    onChange={(e) =>(setExamOption(e.target.value))}/>
                                <TextField
                                    id="datetimeExam"
                                    label="Fecha del examen"
                                    type="datetime-local"
                                    defaultValue={examDate}
                                    className={classes.textField}
                                    onChange={e=>(setExamDate(e.target.value))}
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={2} style={{margin:"20px"}}>
                            <Grid item xs>
                                <Button variant="contained" color="primary"  onClick={prevStep} style={{marginRight:"5px"}}>Anterior</Button>
                                <Button variant="contained" color="primary"  onClick={createExam} disabled={examOption==="" || examDate < new Date() || examSelected.date < new Date()}>Enviar solicitud</Button>
                            </Grid>
                        </Grid>
                    </StepContent>
                </Step>
            </Stepper>
        </React.Fragment>
    )
}

export default ExamsCoordExtraordinaryExamsView
