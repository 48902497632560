import React, {useContext, useState} from "react";
import {Button, Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EnrollmentFormUserData from "./EnrollmentFormUserData";
import EnrollmentFormTimeOptionData from "./EnrollmentFormTimeOptionData";
import EnrollmentFormCourseTable from "./EnrollmentFormCourseTable";
import EnrollmentFormPaymentOptionData from "./EnrollmentFormPaymentOptionData";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import {getExtendedRequestFiles} from "../../../services/ExtendedEnrollments/ExtendedRequestsService";
import ExtendedEnrollmentsFormDownloadPDF from "./ExtendedEnrollmentsFormDownloadPDF";
import EnrollmentFormImageAuthorizationOptionData from "./EnrollmentFormImageAuthorizationOptionData";

const ExtendedEnrollmentFormReadOnly = ({request, admin = false, newService }) => {

    const alertContext = useContext(SnackBarContext)

    const [loading,setLoading] = useState(false)

    const fetchFiles = async () => {
        setLoading(true)
        let error=await getExtendedRequestFiles(request.id)
        if(error){
            alertContext.setMessage("No se ha podido descargar el fichero")
            alertContext.setOpen(true)
        }
        setLoading(false)
    }

    return (
        <Container style={{padding:15}}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    { admin === true
                        ? <EnrollmentFormUserData  externalUserId={request.userExternalId}/>
                        : <EnrollmentFormUserData />
                    }
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormTimeOptionData selected={request.timeOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormCourseTable
                        gradeSelected={request.grade}
                        termSelected={request.termId}
                        coursesSelected={request.courses}
                    />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormPaymentOptionData selected={request.paymentOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormImageAuthorizationOptionData selected={request.imageAuthorization.toString()} />
                </Grid>
                <Grid item md={12}>
                    <Button variant={"contained"} fullWidth onClick={()=>fetchFiles()}>
                        Descargar Ficheros
                    </Button>
                </Grid>
                <Grid item md={12}>
                    <ExtendedEnrollmentsFormDownloadPDF requestId={request.id} newService={newService} />
                </Grid>
            </Grid>
        </Container>
    )
}

export default ExtendedEnrollmentFormReadOnly