import React, {useContext, useEffect, useState} from "react";
import { Button, Container, Grid } from "@material-ui/core";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import EnrollmentFormUserData from "./EnrollmentFormUserData";
import EnrollmentFormTimeOptionData from "./EnrollmentFormTimeOptionData";
import EnrollmentFormCourseTable from "./EnrollmentFormCourseTable";
import EnrollmentFormGeneralDiscountOptionData from "./EnrollmentFormGeneralDiscountOptionData";
import EnrollmentFormInsuranceOptionData from "./EnrollmentFormInsuranceOptionData";
import EnrollmentFormPaymentOptionData from "./EnrollmentFormPaymentOptionData";
import EnrollmentFormDebtData from "./EnrollmentFormDebtData";
import {postFiles, postRequest} from "../../../services/Enrollments/RequestsService";
import EnrollmentFormImageAuthorizationOptionData from "./EnrollmentFormImageAuthorizationOptionData";
import EnrollmentFormSEPIOptionData from "./EnrollmentFormSEPIOptionData";
import { setApplicationLoading } from '../../ApplicationLoading'

const EnrollmentForm = ({ returnToIndex, enrollmentTerm }) => {

    const alertContext = useContext(SnackBarContext)

    const [timeOption,setTimeOption] = useState("FULL_TIME")
    const [gradeSelected,setGradeSelected] = useState("")
    const [termSelected,setTermSelected] = useState("")
    const [coursesSelected,setCoursesSelected] = useState([])
    const [generalDiscountOption,setGeneralDiscountOption] = useState("NO_DISCOUNT")
    const [minusDiscountOption,setMinusDiscountOption] = useState("NO_MINUS")
    const [synchronousDiscountOption,setSynchronousDiscountOption] = useState("NO_SYNCHRONOUS")
    const [discountMHOption,setDiscountMHOption] = useState(false)
    const [discountUcavOption,setDiscountUcavOption] = useState(false)
    const [discountConvOption,setDiscountConvOption] = useState(false)
    const [discountConvText,setDiscountConvText] = useState("")
    const [discountOtherOption,setDiscountOtherOption] = useState(false)
    const [discountOtherText,setDiscountOtherText] = useState("")
    const [insuranceOption,setInsuranceOption] = useState("NO_INSURANCE")
    const [paymentOption,setPaymentOption] = useState("")
    const [debtDNI,setDebtDNI] = useState("")
    const [debtOption,setDebtOption] = useState("INDIVIDUAL")
    const [debtName,setDebtName] = useState("")
    const [debtAddress,setDebtAddress] = useState("")
    const [debtCity,setDebtCity] = useState("")
    const [debtZipCode,setDebtZipCode] = useState("")
    const [debtState,setDebtState] = useState("")
    const [debtCountry,setDebtCountry] = useState("")
    const [debtIBAN,setDebtIBAN] = useState("")
    const [debtBIC,setDebtBIC] = useState("")
    const [imageAuthorizationOption,setImageAuthorizationOption] = useState(true)
    const [dataSEPIOption,setDataSEPIOption] = useState(false)

    //const [creditsValidation,setCreditsValidations] = useState(false)
    const [debtValidations,setDebtValidations] = useState(false)

    const [files,setFiles] = useState()

    useEffect(() => {
        console.log(debtValidations)
    },[debtValidations])

    const handleFileChange = (event) => {
        setFiles(event.target.files)
    }

    const postRequestAction = async () => {
        if(coursesSelected.length === 0){
            alertContext.setMessage("No se ha seleccionado ninguna asignatura")
            alertContext.setOpen(true)
            return
        }

        if(!paymentOption){
            alertContext.setMessage("Por favor, selecciona un método de pago")
            alertContext.setOpen(true)
            return
        }

        if(paymentOption !== "FULL" && !debtValidations){
            alertContext.setMessage("El formulario no esta correcto")
            alertContext.setOpen(true)
            return
        }

       /* if(creditsValidation === false){
            console.log(creditsValidation)
            alertContext.setMessage("Se ha excedido el numero de creditos")
            alertContext.setOpen(true)
            return
        }*/

        setApplicationLoading(true);

        //Build the RequestDTO
        let request={
            enrollmentTermId: enrollmentTerm,
            enrollmentType: "R",
            timeOption: timeOption,
            grade: gradeSelected,
            termId: termSelected,
            generalDiscountOption: generalDiscountOption,
            minusDiscountOption: minusDiscountOption,
            synchronousDiscountOption: synchronousDiscountOption,
            discountMH: discountMHOption,
            discountUcav: discountUcavOption,
            discountConv: discountConvOption,
            discountConvText: discountConvText,
            discountOther: discountOtherOption,
            discountOtherText: discountOtherText,
            insuranceOption: insuranceOption,
            paymentOption: paymentOption,
            debtDNI: debtDNI,
            debtOption: debtOption,
            debtName: debtName,
            debtAddress: debtAddress,
            debtState: debtState,
            debtCity: debtCity,
            debtZipCode: debtZipCode,
            debtCountry: debtCountry,
            debtIBAN: debtIBAN,
            debtBIC: debtBIC,
            imageAuthorization: imageAuthorizationOption,
            dataSAPI: dataSEPIOption,
            courses: coursesSelected
        }

        let response = await postRequest(request)
        if (typeof response === 'string' || response instanceof String){
            alertContext.setMessage(response)
            alertContext.setOpen(true)
        } else {
            alertContext.setMessage("Peticion registrada")
            alertContext.setOpen(true)
        }

        if(files){
            await postFilesAction(response.id,files)
        }

        setApplicationLoading(false);
        
        if(returnToIndex) returnToIndex()
    }

    const postFilesAction = async (requestId,files) => {
        if(files===null){
            alertContext.setMessage(`No se ha seleccionado ningun archivo`);
            return
        }
        await postFiles(requestId,files)
    }

    return (
        <Container style={{padding:15}}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <EnrollmentFormUserData/>
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormTimeOptionData selected={timeOption} setSelected={setTimeOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormCourseTable
                        gradeSelected={gradeSelected}
                        setGradeSelected={setGradeSelected}
                        termSelected={termSelected}
                        setTermSelected={setTermSelected}
                        coursesSelected={coursesSelected}
                        setCoursesSelected={setCoursesSelected}
                        //setCreditsValidation={setCreditsValidations}
                    />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormGeneralDiscountOptionData
                        generalDiscountOption={generalDiscountOption}
                        setGeneralDiscountOption={setGeneralDiscountOption}

                        minusDiscountOption={minusDiscountOption}
                        setMinusDiscountOption={setMinusDiscountOption}

                        synchronousDiscountOption={synchronousDiscountOption}
                        setSynchronousDiscountOption={setSynchronousDiscountOption}

                        discountMHOption={discountMHOption}
                        setDiscountMHOption={setDiscountMHOption}

                        discountUcavOption={discountUcavOption}
                        setDiscountUcavOption={setDiscountUcavOption}

                        discountConvOption={discountConvOption}
                        setDiscountConvOption={setDiscountConvOption}

                        discountConvText={discountConvText}
                        setDiscountConvText={setDiscountConvText}

                        discountOtherOption={discountOtherOption}
                        setDiscountOtherOption={setDiscountOtherOption}

                        discountOtherText={discountOtherText}
                        setDiscountOtherText={setDiscountOtherText}

                    />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormInsuranceOptionData selected={insuranceOption}
                                                       setSelected={setInsuranceOption}/>
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormImageAuthorizationOptionData selected={imageAuthorizationOption}
                                                                setSelected={setImageAuthorizationOption}/>
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormSEPIOptionData selected={dataSEPIOption} setSelected={setDataSEPIOption}/>
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormPaymentOptionData selected={paymentOption}
                                                     setSelected={setPaymentOption} />
                </Grid>
                <Grid item md={12}>
                    {
                        paymentOption && paymentOption !== "FULL"
                        &&  <EnrollmentFormDebtData
                            debtDNI={debtDNI}
                            setDebtDNI={setDebtDNI}

                            debtOption={debtOption}
                            setDebtOption={setDebtOption}

                            debtName={debtName}
                            setDebtName={setDebtName}

                            debtAddress={debtAddress}
                            setDebtAddress={setDebtAddress}

                            debtCity={debtCity}
                            setDebtCity={setDebtCity}

                            debtZipCode={debtZipCode}
                            setDebtZipCode={setDebtZipCode}

                            debtState={debtState}
                            setDebtState={setDebtState}

                            debtCountry={debtCountry}
                            setDebtCountry={setDebtCountry}

                            debtIBAN={debtIBAN}
                            setDebtIBAN={setDebtIBAN}

                            debtBIC={debtBIC}
                            setDebtBIC={setDebtBIC}

                            setDebtValidations={setDebtValidations}
                        />
                    }
                </Grid>
                <Grid item md={12}>
                    { files
                        ?
                        <Button
                            style={{background: '#52b788'}}
                            variant="contained"
                            fullWidth
                            component="label">
                            Adjuntar archivos
                            <input type="file"
                                   multiple
                                   hidden
                                   onChange={handleFileChange}/>
                        </Button>
                        :
                        <Button
                            variant="contained"
                            fullWidth
                            component="label">
                            Adjuntar archivos
                            <input type="file"
                                   multiple
                                   hidden
                                   onChange={handleFileChange}/>
                        </Button>
                    }
                </Grid>
                <Grid item md={12}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => postRequestAction()}
                        fullWidth>
                        Enviar
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default EnrollmentForm
