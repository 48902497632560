import Grid from "@material-ui/core/Grid";
import {
    LinearProgress
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ExamsCourseCard from "./ExamsCourseCard";
import {postChoice} from "../../../services/Exams/ChoiceService";
import {useNotification} from "../../../Utils/hooks";

const ExamsStudentSelector = ({courseId,organizations}) => {

    const [loading, setLoading] = useState(false)
    const [exams, setExams] = useState([])
    const [choice, setChoice] = useState({})
    const notification = useNotification()

    const fetchExams = async () => {
        setLoading(true);
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const exams = await fetch(`/exams-api/exams/courses/${courseId}`, {headers: headers})
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setExams(exams)
        setLoading(false);
    }

    const fetchChoice = async () => {
        setLoading(true)
        let sessionId = sessionStorage.getItem("sessionId")
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const choice = await fetch(`/exams-api/requests/courses/${courseId}`, {headers: headers})
            .then(r => {
                if(r.ok) return r.json()
                else if (r.status===404) return {}
                else{
                    throw new Error("Error retrieving choice")
                }
            })
            .catch(reason => {
                console.log(reason);
                return {
                    "id":5,
                    "userId":"alumnoucav",
                    "examId":0,
                    "courseId":"Curso001",
                    "headquarterId":"cntr_exam_99",
                    "created":"2021-03-29T12:50:50"
                }
            })
        setChoice(choice)
        setLoading(false)
    }

    const postChoiceAction = async (examId, headquarterId) => {
        setLoading(true)
        let choice = await postChoice(examId,headquarterId)
        if(choice.status === 409) {
            notification("error","Ya ha elegido un examen para esta fecha")
        }else if(choice.status === 406) {
            notification("error","La petición de examen que intenta realizar está fuera de plazo. Consulte con su orientadora académica.")
        }else if(choice.status === 423) {
            notification("error","Se ha alcanzado el aforo máximo para la sede seleccionada, por favor selecciona otra o contacta con su orientadora académica.", { autoHideDuration: 15000 })
        }else {
            setChoice(choice)
        }
        setLoading(false)
    }

    const deleteChoice = async (choiceId) => {
        setLoading(true);
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId,
            'Content-Type': 'application/json'
        });
        await fetch(`/exams-api/requests/${choiceId}`,
            {
                headers: headers,
                method: "DELETE"
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setChoice({})
        setLoading(false);
    }

    useEffect(() => {
        fetchExams()
    }, [])

    useEffect(() => {
        fetchChoice()
    }, [exams])

    return (
        <Grid container spacing={3} justify={"center"}>
            {loading === true
                ? <LinearProgress/>
                : exams.map((value, index) => {
                    return (
                        <Grid item key={index} md={3}>
                            <ExamsCourseCard
                                exam={value}
                                organizations={organizations}
                                choice={choice}
                                deleteChoice={deleteChoice}
                                postChoice={postChoiceAction}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default ExamsStudentSelector
