import React, {useState} from "react";
import {Button, Card, CardContent, CardHeader, CircularProgress, Paper, Typography} from "@material-ui/core";
import {postCoursesCSV} from "../../services/Enrollments/CourseService";

const EnrollmentsCourseCSVForm = ({uploadCallback}) => {

    const [loading, setLoading] = useState(false)

    const postCSVFile = async (selectedFile) => {
        setLoading(true)
        let courses = await postCoursesCSV(selectedFile)
        setLoading(false)
    }

    const handleFileChange = (event) => {
        postCSVFile(event.target.files[0])
        if(uploadCallback) uploadCallback()
    }

    return (
        <React.Fragment>
            <Card>
                <CardHeader
                    title={"Subir asignaturas por lotes"}
                    subheader={"Cargue un archivo csv con la cabecera courseId,credits,available,enrollments,maxEnrollments"}
                />
                <CardContent>
                    { loading
                        ? <CircularProgress />
                        : <Button variant="contained" component="label">
                            Upload File
                            <input type="file" hidden onChange={handleFileChange}/>
                        </Button>
                    }
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default EnrollmentsCourseCSVForm