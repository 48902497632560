import React, { useState, useCallback, useEffect, useReducer, useRef } from 'react'
import MUIDataTable from 'mui-datatables';
import { MUIDatatableLabels } from '../../../Utils/Table.utils';
import { dateInstanceToLocale } from '../../../Utils/Date';
import { renderTFGStatus } from '../TFG.utils';
import { Button, CircularProgress } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { useNotification } from '../../../Utils/hooks';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import TFGDefenseAcceptDialog from './TFGDefenseAcceptDialog/TFGDefenseAcceptDialog';
import TFGDefenseRejectDialog from './TFGDefenseRejectDialog/TFGDefenseRejectDialog';
import TFGDefenseDialog from './TFGDefenseDialog/TFGDefenseDialog';
import {
  reducer, initialState, updatePaginationTableAction, searchInProgressTableAction, searchErrorTableAction,
  searchSuccessTableAction, changeOrderingTableAction, updateElementTableAction, addElementTableAction,
} from '../../../reducers/tableReducer';
import { cancelTFGDefenseRequest, downloadTFGDefenseFile, getTFGDefenseRequests } from '../../../services/TFG/TFGService';
import { generateTFGDefenseTableActions, renderTFGDefenseComments } from './TFGDefense.utils';
import { downloadFromRawData } from '../../../Utils/utils';
import TFGDefenseFilters from './TFGDefenseFilters';
import './TFGDefense.scss';

const TFGDefense = ({ role }) => {
  const [tableReducer, dispatch] = useReducer(reducer, initialState);
  const [editing, setEditing] = useState(null);
  const [accept, setAccept] = useState(null);
  const [reject, setReject] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [showAcceptDialog, setShowAcceptDialog] = useState(false);
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const notification = useNotification();
  // const mountedRef = useRef(false);
  const confirm = useConfirm();

  const retrieveRequests = useCallback(async () => {
    try {
      // setApplicationLoading(true);
      dispatch(searchInProgressTableAction());
      let params = null;
      if (role === "COORDINATOR") {
        params = { filter: 'GESTION' };
      } else if (role === "INSTRUCTOR") {
        params = { filter: 'DIRECTOR' };
      }
      const result = await getTFGDefenseRequests(params, tableReducer.pagination, tableReducer.sortOrder, tableReducer.filters);
      if (!result) throw new Error();
      dispatch(searchSuccessTableAction({ data: result.content, total: result.totalElements }))
    } catch (err) {
      notification('error', 'Ha habido un error al obtener las solicitudes de defensa');
      dispatch(searchErrorTableAction('Ha habido un error al obtener las solicitudes de defensa'))
    } finally {
      // setApplicationLoading(false);
    }
  }, [notification, role, tableReducer.pagination, tableReducer.sortOrder, tableReducer.filters]);

  useEffect(() => {
    // if (mountedRef.current || role === "STUDENT") retrieveRequests();
    retrieveRequests();
  }, [retrieveRequests]);

  // useEffect(() => {
  //   mountedRef.current = true;
  //   return () => mountedRef.current = false;
  // }, []);
  
  const onCancel = (requestId, defenseId) => {
    confirm({
      title: 'Cancelar solicitud',
      description: 'Atención, esta acción cancelará la solicitud seleccionada, ¿estás seguro de que deseas realizar esta acción?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          const result = await cancelTFGDefenseRequest(requestId, defenseId);
          dispatch(updateElementTableAction(defenseId, result));
        } catch {
          notification('error', 'Ha habido un error al cancelar la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onDownload = async (requestId, defenseId) => {
    try {
      setApplicationLoading(true);
      const { file, name } = await downloadTFGDefenseFile(requestId, defenseId);
      downloadFromRawData(file, name);
    } catch {
      notification('error', 'Ha habido un error al descargar el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onAcceptClick = (requestId, id) => {
    setAccept({ requestId, id });
    setTimeout(() => setShowAcceptDialog(true), 250);
  }

  const onRejectClick = (requestId, id) => {
    setReject({ requestId, id });
    setTimeout(() => setShowRejectDialog(true), 250);
  }

  const onHideAccept = () => {
    setShowAcceptDialog(false);
    setTimeout(() => setAccept(null), 250);
  }

  const onHideReject = () => {
    setShowRejectDialog(false);
    setTimeout(() => setReject(null), 250);
  }

  const onAcceptSuccess = async result => {
    dispatch(updateElementTableAction(result.id, result));
    onHideAccept();
  }

  const onRejectSuccess = async result => {
    dispatch(updateElementTableAction(result.id, result));
    onHideReject();
  }

  const onCreateClick = () => {
    setShowDialog(true);
  }

  const onEditClick = request => {
    setEditing(request);
    setShowDialog(true);
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setEditing(null), 250);
  }

  const onSuccess = (isEdit, result) => {
    if (isEdit) dispatch(updateElementTableAction(result.id, result));
    else dispatch(addElementTableAction(result))
    onHideDialog();
  }

  const onTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        dispatch(updatePaginationTableAction({ page: tableState.page }))
        break;

      case 'changeRowsPerPage':
        dispatch(updatePaginationTableAction({ size: tableState.rowsPerPage, page: 0 }))
        break;

      case 'sort':
        dispatch(changeOrderingTableAction(tableState.sortOrder))
        break;

      default:
        break;
    }
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    textLabels: {
      ...MUIDatatableLabels,
      body: {
        ...MUIDatatableLabels.body,
        noMatch: 'Todavía no se han realizado solicitudes de defensa'
      }
    },
    print: false,
    download: true,
    onTableChange,
    rowsPerPage: tableReducer.pagination.size,
    rowsPerPageOptions: [10, 25],
    serverSide: true,
    page: tableReducer.pagination.page,
    count: tableReducer.total,
    sortOrder: tableReducer.sortOrder,
    sort: false,
    filter: false
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
        sort: false,
      }
    },
    ...(["INSTRUCTOR", "SECR", "COORDINATOR"].includes(role)) ? [
      {
        name: 'userName',
        label: 'Alumno',
        options: {
          sort: false,
        }
      },
      {
        name: 'dni',
        label: 'DNI',
        options: {
          sort: false,
        }
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          display: false,
          sort: false,
        }
      },
      // TODO: Remove comment once implemented
      // {
      //   name: 'studentPhone',
      //   label: 'Teléfono',
      //   options: {
      //     display: false,
      //   }
      // },
    ] : [],
    {
      name: 'degreeId', // degreeName?
      label: 'Titulación',
    },
    // {
    //   name: 'instructorName',
    //   label: 'Profesor',
    // },
    {
      name: 'provisionalTitle',
      label: 'Título provisional',
      options: {
        sort: false,
      }
    },
    {
      name: 'created',
      label: 'Fecha de solicitud',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'start',
      label: 'Fecha',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    // TODO: Remove comment once implemented
    // {
    //   name: 'instructorName',
    //   label: 'Director',
    // },
    ...["INSTRUCTOR", "SECR", "COORDINATOR"].includes(role) ? [
      {
        name: 'id',
        label: 'Tribunal',
        options: {
          sort: false,
          customBodyRenderLite: (index) => {
            const request = tableReducer.data[index];

            return (
              <ul className="court-members">
                <li>{`Presidente: ${request.chairmanName}`}</li>
                <li>{`Secretario: ${request.secretaryName}`}</li>
                <li>{`Vocal: ${request.vocalName}`}</li>
              </ul>
            )
          },
        }
      },
    ] : [],
    {
      name: 'defenseRequestStatus',
      label: 'Estado',
      options: {
        customBodyRender: status => renderTFGStatus(status)
      }
    },
    {
      name: 'id',
      label: 'Observaciones',
      options: {
        sort: false,
        customBodyRenderLite: index => {
          const request = tableReducer.data[index];
          return renderTFGDefenseComments(request);
        }
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        sort: false,
        download: false,
        customBodyRenderLite: index => {
          const request = tableReducer.data[index];
          return generateTFGDefenseTableActions(role, request, onDownload, onAcceptClick, onRejectClick, onCancel, onEditClick);
        },
      }
    }
  ]

  return (
    <div className="defense-requests">
      <TFGDefenseDialog
        visible={showDialog}
        editing={editing}
        onHide={onHideDialog}
        onSuccess={onSuccess}
      />
      <TFGDefenseAcceptDialog
        accept={accept}
        visible={showAcceptDialog}
        onHide={onHideAccept}
        onAcceptSuccess={onAcceptSuccess}
      />
      <TFGDefenseRejectDialog
        reject={reject}
        visible={showRejectDialog}
        onHide={onHideReject}
        onRejectSuccess={onRejectSuccess}
      />
      { role === "STUDENT" && (
        <div className="defense-requests__actions">
          <Button
            onClick={onCreateClick}
            variant="contained"
            color="primary"
          >
            Crear solicitud de defensa
          </Button>
        </div>
      ) }
      { role !== "STUDENT" && <TFGDefenseFilters dispatch={dispatch} /> }
      {
        tableReducer.loading
          ? (
            <div className="table-progress-container">
              <CircularProgress size={24} />
            </div>
          ) : (
            <MUIDataTable
            className="tfg-defense-requests-table"
            title="Solicitudes de defensa"
              columns={columns}
              options={options}
              data={tableReducer.data}
            />
          )
      }
    </div>
  )
}

export default TFGDefense