import React, { useState, useEffect, useCallback } from 'react';
import MUIDataTable from 'mui-datatables';
import { CircularProgress, Icon, IconButton, Button } from '@material-ui/core';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import InstancesTypesDialog from './InstancesTypesDialog/InstancesTypesDialog';
import { useNotification } from '../../../Utils/hooks';
import { MUIDatatableLabels } from '../../../Utils/Table.utils';
import { createTemplateType, getTemplateTypes, updateTemplateType, deleteTemplateType } from '../../../services/Instances/InstancesService';
import '../Instances.scss';

const InstancesTypes = ({ secr }) => {
  const [types, setTypes] = useState([]);
  const [edit, setEdit] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const notification = useNotification();

  const getTypesFn = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getTemplateTypes();
      if (!result) throw new Error();
      setTypes(result);
    } catch {
      notification('error', 'Ha habido un error al cargar las plantillas');
    } finally {
      setLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    getTypesFn();
  }, [getTypesFn]);

  const onEdit = id => {
    const type = types.find(type => type.id === id);
  
    if (type) {
      setEdit(type);
      setShowDialog(true);
    }
  }

  const onDelete = async id => {
    try {
      setApplicationLoading(true);
      await deleteTemplateType(id);
      setTypes(prev => prev.filter(obj => obj.id !== id))
    } catch {
      notification('error', 'Ha habido un error al eliminar la plantilla');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onEditDialogClose = () => {
    setShowDialog(false);
    setTimeout(() => setEdit(null), 250);
  }

  const onSubmit = async (data) => {
    try {
      setApplicationLoading(true);
      const result = await (edit
        ? updateTemplateType(data.id, data.name)
        : createTemplateType(data.name)
      );
      if (!result) throw new Error();
      if (edit) {
        setTypes(prevTypes => {
          const newTypes = [...prevTypes];
          const index = newTypes.findIndex(type => type.id === data.id);
          if (~index) newTypes[index] = result;
          return newTypes
        })
      } else {
        setTypes(prevTypes => [...prevTypes, result])
      }
      onEditDialogClose();
    } catch {
      notification('error', `Ha habido un error al ${edit ? 'editar' : 'crear'} la instancia`)
    } finally {
      setApplicationLoading(false);
    }
  }

  const onCreateClick = () => {
    setShowDialog(true);
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    textLabels: MUIDatatableLabels,
    print: false,
    download: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRender: (id) => {
          return (
            <div className="actions">
              <IconButton aria-label='edit' onClick={() => onEdit(id)}>
                <Icon>edit</Icon>
              </IconButton>
              <IconButton aria-label='delete' onClick={() => onDelete(id)}>
                <Icon>delete</Icon>
              </IconButton>
            </div>
          )
        },
        sort: false,
        print: false,
        download: false,
      }
    }
  ];

  return (
    <div className="instances">
      <InstancesTypesDialog
        visible={showDialog}
        type={edit}
        onHide={onEditDialogClose}
        onSubmit={onSubmit}
      />
      <div className="template-actions">
        <Button
          onClick={onCreateClick}
          variant="contained"
          color="primary"
        >
          Crear nueva instancia
        </Button>
      </div>
      {
        loading
          ? (
            <div className="spinner-container">
              <CircularProgress size={32} />
            </div>
          ) : (
            <div className="instances-table-container">
              <MUIDataTable
                className="instances-table"
                title='Listado de instancias'
                columns={columns}
                options={options}
                data={types}
              />
            </div>
          )
      }
    </div>
  )
}

export default InstancesTypes;
