import React from 'react';
import { Controller } from 'react-hook-form';
import { TextField } from '@material-ui/core';
import classNames from 'classnames';

const InputText = ({
  className, control, name, label, variant = 'filled',
  inputProps, rules, disabled, onChange, helperText, ...props }) => {
  const InputClass = classNames('input', className);

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue=""
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <TextField
          className={InputClass}
          name={name}
          id={name}
          onChange={onChange}
          onBlur={onBlur}
          value={value}
          inputRef={ref}
          label={label}
          variant={variant}
          error={!!error}
          helperText={error?.message ?? helperText}
          disabled={disabled}
          fullWidth
          {...inputProps}
        />
      )}
      {...props}
    />
  )
}

export default InputText
