import React, {useEffect, useState} from "react";
import {
    Card,
    CardContent,
    CardHeader,
    Checkbox, FormControlLabel,
    Grid,
    List,
    ListItem,
    makeStyles, Modal, Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";

const EnrollmentFormImageAuthorizationOptionData = ({selected,setSelected = null}) => {

    const [modal,setModal] = useState(false)

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles()

    const handleChange = (event) => {
        if(setSelected){
            setSelected(event.target.value);
        }else {
            console.warn("Read-only Component")
        }
    };

    const handleCloseModal = (refresh = false) => {
        setModal(false)
    }

    const handleOpenModal = () => {
        setModal(true)
    }

    useEffect(() => {
        console.log(`Image Authorization: ${selected}`)
    },[selected])

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"CESIÓN DE DERECHOS"}
            />
            <CardContent>
                <RadioGroup name={"imageAuthorizationOption"} value={selected} onChange={handleChange}>
                <Grid container direction={"column"}>
                    <Grid item>
                        <Typography variant={"h5"} align={"center"}>
                            El/la estudiante, en su condición de alumno/a de la Universidad Católica
                            Santa Teresa de Jesús de Ávila (en adelante UCAV),
                            MANIFIESTA:
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"caption"} align={"left"}>
                            Que sabe y conoce que:
                        </Typography>
                        <List>
                            <ListItem>
                                <Typography variant={"caption"}>
                                    a) Las clases en las que va a participar se realizarán en streaming y que serán grabadas y editadas.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant={"caption"}>
                                    b) Los exámenes o pruebas académicas se podrán realizar telemáticamente, procediendo a su grabación y archivo.
                                </Typography>
                            </ListItem>
                            <ListItem>
                                <Typography variant={"caption"}>
                                    c) La UCAV, dentro de la actividad educativa de formación a distancia y on-line que desarrolla, utilizará las grabaciones de las clases en
                                    las que participa el/la estudiante para la elaboración de materiales y productos docentes y las grabaciones de los exámenes o pruebas
                                    realizados telemáticamente con fines académicos.
                                </Typography>
                            </ListItem>
                        </List>
                    </Grid>
                    <Grid item>
                        <Typography variant={"caption"} align={"center"}>
                            Por tales motivos, el/la alumno/a firmante, (marque la opción que elige)
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid item>
                                    <FormControlLabel value={"true"} control={<Radio />} label={"AUTORIZA"}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value={"false"} control={<Radio onClick={handleOpenModal} />} label={"NO AUTORIZA"}/>
                                </Grid>
                            </Grid>
                    </Grid>
                    <Grid item>
                        <Typography variant={"caption"}>
                            A la UNIVERSIDAD CATÓLICA SANTA TERESA DE JESÚS DE ÁVILA, con CIF nº R-0500336-C y domicilio social en la calle Canteros
                            s/n, de Ávila, 05005, en exclusiva y con facultad de cesión a terceros, a grabar, reproducir y comunicar públicamente su imagen y voz y
                            hacer uso de la mismas según sean captadas y/o fijadas para la elaboración de materiales y productos docentes para que los mismos
                            puedan ser puestos a disposición del personal docente y de los/as alumnos/as matriculados/as en los estudios de la UCAV durante el
                            presente curso académico y para fines académicos, en cualquier medio o soporte
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"caption"}>
                            A tal fin, el/la AUTORIZANTE cede a la UCAV y con facultad de cesión a terceros, sin limitación territorial ni temporal, los derechos de
                            explotación necesarios, relativos y derivados de su imagen exclusivamente para la puesta a disposición, por la UCAV, al personal
                            docente y a los/as alumnos/as matriculados/as en los estudios de la UCAV, de los materiales y productos académicos y docentes que
                            elabore, en concreto, los derechos de fijación, reproducción directa o indirecta en cualquier medio, distribución, doblaje y subtitulado y
                            comunicación pública, en los términos y con las limitaciones que constan en el presente documento.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"caption"}>
                            Esta cesión se refiere a los usos que pueden darse a las imágenes, o partes de las mismas, grabadas en las clases en las que el/la
                            alumno/a participe bajo la modalidad streaming o durante la realización de exámenes o pruebas académicas, utilizando los medios
                            técnicos conocidos en la actualidad y para las aplicaciones que permitan una adecuada puesta a disposición de las mismas a los
                            alumnos de la UCAV.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant={"caption"}>
                            Todo ello con la única salvedad y limitación de aquellas utilizaciones o aplicaciones que pudieran atentar al derecho al honor en los
                            términos previstos en la Ley Orgánica 1/85, de 5 de mayo, de Protección Civil al Derecho al Honor, la Intimidad Personal y familiar y la
                            Propia Imagen.
                        </Typography>
                    </Grid>
                    <Grid item>
                        <Alert severity={"error"}>
                            <Typography variant={"caption"} color={"error"}>
                                Se advierte en todo caso a los/as estudiante que no otorguen la presente autorización que no podrán asistir a las clases que se
                                graben y que los exámenes o pruebas que realice la UCAV tendrán que ser siempre presenciales y nunca telemáticos.
                            </Typography>
                        </Alert>
                    </Grid>
                </Grid>
                </RadioGroup>
            </CardContent>
            <Modal open={modal}
                   onClose={handleCloseModal}
                   closeAfterTransition
                   style={{margin: 25, display: "flex", alignItems: "center", justifyContent: "center"}}>
                <Alert severity={"error"}>
                    <Typography variant={"caption"} color={"error"}>
                        Se advierte en todo caso a los/as estudiante que no otorguen la presente autorización que no podrán asistir a las clases que se
                        graben y que los exámenes o pruebas que realice la UCAV tendrán que ser siempre presenciales y nunca telemáticos.
                    </Typography>
                </Alert>
            </Modal>
        </Card>
    )
}

export default EnrollmentFormImageAuthorizationOptionData