import React, { useState, useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { useNotification } from "../../../Utils/hooks";
import { setApplicationLoading } from "../../../components/ApplicationLoading";
import { Dialog, DialogTitle, DialogContent, DialogActions, Grid, Button, CircularProgress } from "@material-ui/core";
import { getTFGDegreeAreas, getTFGTerms, getTFGTitulations, updateTFGRequest } from "../../../services/TFG/TFGService";
import InputAutoCompleteValue from "../../../components/Inputs/RHK/InputAutoCompleteValue";
import InputText from "../../../components/Inputs/RHK/InputText";
import BaseInputText from "../../../components/Inputs/InputText";
import { required } from "../../../Utils/utils";

const TFGEditRequest = ({ visible, editing, onHide, onSuccess }) => {
  const { control, handleSubmit, reset, watch, setValue } = useForm();
  const [titulations, setTitulations] = useState([]);
  const [terms, setTerms] = useState([]);
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState({ titulations: true, terms: true, areas: true, instructors: true });
  const [initialized, setInitialized] = useState(false);
  const notification = useNotification();

  const { userId, degreeId, areaId, termId, /* instructorId, provisionalTitle */ } = watch();

  const instructors = useMemo(() => {
    if (!areaId || !areas?.length) return [];

    const managers = areas.find((area => area.id === areaId))?.assignedManagers;
    // setLoading(prev => ({ ...prev, instructors: false }));
  
    if (!managers || (managers && !managers.length)) {
      notification('error', 'No hay directores disponibles para este área temática');
      return [];
    };

    return managers.map(ins => ({ label: ins.name, value: ins.externalId }));
  }, [areaId, areas, notification]);

  const academicYear = useMemo(() => {
    if (!termId) return null;

    const term = terms.find(term => term.value === termId);

    // if (!term) {
    //   notification('error', 'No se ha encontrado la convocatoria asignada, por favor contacta con un administrador');
    //   return null;
    // }
  
    return term?.academicYear?.id;
  }, [termId, terms]);

  useEffect(() => {
    const { titulations, terms, areas, instructors } = loading;
    const isLoading = titulations || terms || areas || instructors;

    if (!isLoading) {
      setInitialized(true);
    }
  }, [editing, loading])

  useEffect(() => {
    if (!visible) {
      setInitialized(false)
      setLoading({ titulations: true, terms: true, areas: true, instructors: true });
      setTitulations([]); setTerms([]); setAreas([]); reset();
    }
  }, [reset, visible]);

  useEffect(() => {
    if (visible) setApplicationLoading(!initialized);
  }, [visible, initialized]);

  useEffect(() => {
    if (visible && editing) {
      reset(editing)
    }
  }, [editing, reset, visible])

  useEffect(() => {
    if (initialized) reset(editing)
  }, [editing, initialized, reset])

  useEffect(() => {
    async function retrieveTitulations(studentId) {
      try {
        setLoading(prev => ({ ...prev, titulations: true }));
        // TODO: Add termId?
        const result = await getTFGTitulations(undefined, undefined, studentId).then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
        );
        if (!result) throw new Error();
        if (!result.length) {
          notification('error', 'No se han encontrado titulaciones con áreas temáticas disponibles');
        }
        setTitulations(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las titulaciones');
      } finally {
        setLoading(prev => ({ ...prev, titulations: false }));
      }
    }

    if (visible && userId) retrieveTitulations(userId);
  }, [visible, notification, userId]);

  useEffect(() => {
    async function retrieveTerms(id) {
      try {
        setLoading(prev => ({ ...prev, terms: true }));
        const result = await getTFGTerms('AREA', id).then(
          res => res.map(item => ({ ...item, value: item.id, label: item.name }))
        );
        if (!result) throw new Error();
        setTerms(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las convocatorias');
      } finally {
        setLoading(prev => ({ ...prev, terms: false }));
      }
    }

    visible && degreeId && retrieveTerms(degreeId);
  }, [visible, notification, degreeId]);

  useEffect(() => {
    async function retrieveAreas(degreeId, academicYear) {
      try {
        setLoading(prev => ({ ...prev, areas: true, instructors: true }));
        const result = await getTFGDegreeAreas(degreeId, academicYear).then(
          res => res.map(item => ({ ...item, value: item.id, label: item.name }))
        );
        if (!result) throw new Error();
        setAreas(result);
      } catch {
        notification('error', 'Ha habido un error al obtener las áreas temáticas disponibles para la titulación seleccionada');
      } finally {
        setLoading(prev => ({ ...prev, areas: false, instructors: false }));
      }
    }

    visible && degreeId && academicYear && retrieveAreas(degreeId, academicYear);
  }, [visible, notification, degreeId, academicYear]);
  
  // Reset any possible selections
  useEffect(() => {
    if (initialized) {
      setValue('areaId', null);
      setValue('termId', null);
    }
  // Not using initialized here to avoid resetting the form when it is initially loaded, might have to be revisited in the future
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValue, degreeId]);

  useEffect(() => {
    setValue('areaId', null);
  }, [setValue, termId]);

  useEffect(() => {
    setValue('instructorId', null);
  }, [setValue, areaId]);

  useEffect(() => {
    if (editing) {
      if (instructors.some(ins => ins.value === editing.instructorId)) {
        setValue('instructorId', editing.instructorId)
      } else {
        setValue('instructorId', null);
      }
    }
  }, [setValue, instructors, editing])

  const onSubmit = async data => {
    try {
      setApplicationLoading(true);

      const body = {
        id: data.id,
        degreeId: data.degreeId,
        termId: data.termId,
        areaId: data.areaId,
        instructorId: data.instructorId,
        provisionalTitle: data.provisionalTitle,
      };

      const result = await updateTFGRequest(body);
      if (!result) throw new Error();
      onSuccess(result);
    } catch {
      notification('error', `Ha habido un error al actualizar la instancia`);
    } finally {
      setApplicationLoading(false);
    }
  }

  if (!editing || !initialized) return null;

  return (
    <Dialog
      className="tfg-term-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
    >
      <DialogTitle>Editar instancia</DialogTitle>
      <DialogContent>
        <form className="tfg-edit-request" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <BaseInputText
                key="userLabel"
                name="userLabel"
                label="Alumno"
                value={`${editing.userName} (${editing.userId})`}
                onChange={() => {}}
                readOnly
                disabled
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                key="degreeId"
                name="degreeId"
                control={control}
                label="Titulación"
                options={titulations}
                loading={loading.titulations}
                rules={required}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                key="termId"
                name="termId"
                control={control}
                label="Convocatoria"
                options={terms}
                loading={loading.terms}
                rules={required}
                // renderOption={renderInstanceOption}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                key="areaId"
                name="areaId"
                control={control}
                label="Área temática"
                options={areas}
                loading={loading.areas}
                rules={required}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                name="instructorId"
                control={control}
                label="Director"
                options={instructors}
                loading={loading.instructors}
                rules={required}
              />
            </Grid>
            <Grid item xs={12}>
              <InputText
                key="provisionalTitle"
                name="provisionalTitle"
                label="Título"
                control={control}
                inputProps={{ placeholder: "Título orientativo" }}
                // helperText="Título orientativo"
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TFGEditRequest;