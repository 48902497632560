import React, {useEffect, useState} from "react";
import {Button, CircularProgress, Paper} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import {getGrades} from "../../services/Grades/GradesService";
import {useNotification} from "../../Utils/hooks";

const GradesList = () => {

    const [loading,setLoading] = useState(false)
    const [grades,setGrades] = useState([])
    const notification = useNotification();

    const columns =[
        {
            label: 'Id de curso',
            name: "courseId"
        },
        {
            label: "Curso",
            name: "courseName"
        },
        {
            label: "Nota",
            name: "grade"
        },
        {
            label: "Nota calificativa",
            name: "gradeText"
        },
        {
            label: "Ciclo Lectivo",
            name: "term"
        },
        {
            label: "Encuesta Pendiente",
            name: "surveyId",
            options: {
                display: false
            }
        },
        {
            label: "Enlace a la encuesta",
            name: "url",
            options: {
                display: false
            }
        }
    ]
    const options={
        selectableRows: "none",
        print: false,
        customRowRender: (data,dataIndex,rowIndex) => {
            if(data[6]==null){
                // console.log(data)
                return (
                    <tr className={"MuiTableRow-root "} key={rowIndex}>
                        {data.map(info => {
                            return (
                                <td className={"MuiTableCell-body MuiTableCell-root"}>
                                    <div className={"MuiTableCell-body"}>{info}</div>
                                </td>
                            )
                        })}
                    </tr>
                )
            }else {
                console.log(data)
                return (
                    <tr className={"MuiTableRow-root "} key={rowIndex}>
                        <td className={"MuiTableCell-body MuiTableCell-root"}>
                            <div className={"MuiTableCell-body"}>{data[0]}</div>
                        </td>
                        <td className={"MuiTableCell-body MuiTableCell-root"}>
                            <div className={"MuiTableCell-body"}>{data[1]}</div>
                        </td>
                        <td className={"MuiTableCell-body MuiTableCell-root"}>
                            <Button variant={"contained"} color={"secondary"} href={data[6]}>Tienes encuestas pendientes </Button>
                        </td>
                    </tr>
                )
            }

        }
    }

    const fetchGrades = async () => {
        try{
            setLoading(true);
            const result = await getGrades()
            setGrades(result)
        } catch {
            notification('error', 'Ha habido un error al cargar las notas');
        } finally {
            setLoading(false)
        }
    }

    useEffect(()=> {
        fetchGrades()
    }, [])

    return(
        <Paper elevation={3} style={{margin:"25px"}}>
            { loading === false
                ? <MUIDataTable
                    title={`Notas`}
                    columns={columns}
                    options={options}
                    data={grades}/>
                : <CircularProgress/>
            }
        </Paper>
    )
}

export default GradesList