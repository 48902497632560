export const getUsersFiltered = async (searchType="id", search) => {
    let sessionId=sessionStorage.getItem("sessionId");
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId
    });
    const users= await fetch(`/registry-api/users/${searchType}/${search}`,{ headers: headers })
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
            return [
                {
                    dni: "0000000A",
                    name: "Alumno",
                    surnames: "A"
                },
                {
                    dni: "1111111B",
                    name: "Alumno",
                    surnames: "B"
                }
            ];
        });
    return users
}
