import React, { useContext, useEffect, useState } from "react";
import MUIDataTable from "mui-datatables";
import { deleteRequests } from "../../../services/Enrollments/RequestsService";
import { Icon, IconButton, LinearProgress, Tooltip } from "@material-ui/core";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import { dateUTCToLocale } from "../../../Utils/Date";
import { renderEnrollmentStatus } from "../NewEnrollments.utils";
import { getNewEnrollments } from "../../../services/Enrollments/NewEnrollmentsService";
import { useNotification } from "../../../Utils/hooks";
import NewEnrollmentsFormReadOnly from "../../../components/NewEnrollments/NewEnrollmentsFormReadOnly/NewEnrollmentsFormReadOnly";

const NewEnrollmentsList = () => {
  const alertContext = useContext(SnackBarContext);

  const [loading, setLoading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [requests, setRequests] = useState([]);
  const [selectedRequest, setSelectedRequest] = useState();
  const notification = useNotification();

  useEffect(() => {
    fetchRequests();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      let result = await getNewEnrollments();
      if (!result) throw new Error();
      setRequests(result);
    } catch {
      notification('error', 'Ha habido un error al obtener las matriculaciones')
    } finally {
      setLoading(false);
    }
  };

  const deleteRequestAction = async (requestId) => {
    let request = requests.find((element) => element.id === requestId);
    if (request.isProcessed) {
      let message = "No se puede eliminar una peticion ya procesada";
      alertContext.setMessage(`Mensaje: ${message}`);
      alertContext.setOpen(true);
    } else {
      setLoading(true);
      let message = await deleteRequests(requestId);
      setLoading(false);
      if (message !== "Peticion no eliminada") {
        let indexOf = requests.findIndex((element) => element.id === requestId);
        requests.splice(indexOf, 1);
        setRequests(requests);
      }
      alertContext.setMessage(`Mensaje: ${message}`);
      alertContext.setOpen(true);
    }
  };

  const columns = [
    {
      label: "ID",
      name: "id",
    },
    {
      label: "Tipo de matrícula",
      name: "enrollmentType",
      options: {
        customBodyRender: type =>
          type === "A" ? 'Extensión de matrícula' : 'Matrícula'
      }
    },
    {
      label: "Convocatoria",
      name: "enrollmentTerm",
      options: {
        customBodyRender: enrollmentTerm =>
          enrollmentTerm?.name || '-'
      }
    },
    {
      label: "Fecha de Creacion",
      name: "created",
      options: {
        customBodyRender: date => {
          return dateUTCToLocale(date);
        },
      },
    },
    {
      label: "Usuario",
      name: "userExternalId",
    },
    {
      label: "Procesado",
      name: "isProcessed",
      options: {
        customBodyRender: value => renderEnrollmentStatus(value),
      },
    },
    {
      label: "Acciones",
      name: "id",
      options: {
        customBodyRender: id => {
          return (
            <div className="enrollment-actions">
              <Tooltip title="Ver" aria-label="view">
                <IconButton aria-label="view" onClick={() => handleOpen(id)}>
                  <Icon className="view-icon">
                    search
                  </Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" aria-label="delete">
                <IconButton aria-label="delete" onClick={() => deleteRequestAction(id)}>
                  <Icon className="delete-icon">
                    delete
                  </Icon>
                </IconButton>
              </Tooltip>
            </div>
          );
        },
      },
    },
  ];

  const options = {
    selectableRows: "none",
    print: false,
  };

  const handleOpen = requestId => {
    let selectedRequest = requests.find((element) => element.id === requestId);
    setSelectedRequest(selectedRequest);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setTimeout(() => setSelectedRequest(null), 500);
  };

  if (loading) return <LinearProgress />

  return (
    <React.Fragment>
      <MUIDataTable
        className="enrollments-table"
        title="Solicitudes realizadas"
        columns={columns}
        options={options}
        data={requests}
      />
      <NewEnrollmentsFormReadOnly
        open={open}
        onHide={handleClose}
        request={selectedRequest}
      />
    </React.Fragment>
  );
};

export default NewEnrollmentsList;
