import React from 'react'
import { MenuItem, TextField } from '@material-ui/core'
import classNames from 'classnames'
import { Controller } from 'react-hook-form';

const InputSelect = ({
  className, control, name, label, variant = 'filled', options, inputProps,
  defaultValue = '', labelKey = 'label', rules, children, disabled, renderOption, ...props }) => {

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => {
        const InputClass = classNames('input', { error }, className);

        return (
          <TextField
            className={InputClass}
            name={name}
            id={name}
            onChange={onChange}
            onBlur={onBlur}
            value={value}
            inputRef={ref}
            label={label}
            variant={variant}
            error={!!error}
            helperText={error?.message}
            disabled={disabled}
            fullWidth
            select
            {...inputProps}
          >
            {
              !!children
                ? children
                : options.map(option => {
                  const { value, disabled, condition = true } = option;

                  if (!condition) return null;
                
                  return (
                    <MenuItem
                      key={value}
                      value={value}
                      disabled={disabled}
                    >
                      {
                        renderOption
                          ? renderOption(option)
                          : <span className="select-option">{option[labelKey]}</span>
                      }
                    </MenuItem>
                  )
                })
            }
          </TextField>
        )
      }}
      {...props}
    />
  )
}

export default InputSelect
