import React from 'react';
import classNames from 'classnames';
import './Badges.scss';

const Badges = ({ data }) => {
  if (!data || (data && !data.length)) return null;

  return (
    <div className="badges">
      {data.map((badge, index) => {
        const { label, className } = badge;
        const BadgeClass = classNames('badge', className);

        return (
          <div className={BadgeClass} key={`badge${index}`}>
            {label}
          </div>
        );
      })}
    </div>
  );
};

export default Badges;
