import React from 'react'
import { ListItemText, Toolbar } from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Grid from "@material-ui/core/Grid";
import AppBar from "@material-ui/core/AppBar";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from "@material-ui/core/Drawer";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Button from "@material-ui/core/Button";
import { Link } from "react-router-dom";
import {ArrowBack} from "@material-ui/icons";
import './Header.css';

const Header = ({services}) => {

    const [isDrawerOpened, setDrawerOpened] = React.useState(false);

    const onLinkClick = () => {
        setDrawerOpened(false)
    }

    return(
        <React.Fragment>
            <AppBar variant={"outlined"} position={"static"} color={"default"}>
                <Toolbar>
                    <Grid container
                          spacing={2}
                          justify={"space-between"}
                          alignItems={"center"}>
                        <Grid item>
                            <IconButton edge="start" color="inherit" aria-label="menu" onClick={() =>{setDrawerOpened(true)}}>
                                <MenuIcon />
                            </IconButton>
                        </Grid>
                        <Grid item>
                            <Typography color={"primary"} variant="h5" align={"justify"}>
                                Portal de servicios
                            </Typography>
                        </Grid>
                        <Grid item>
                            <Typography>
                                <img src={process.env.PUBLIC_URL + "/logo_ucav.png"} style={{maxHeight:100}} alt={"Logo Ucav"}/>
                            </Typography>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            <Drawer variant="persistent" anchor="left" open={isDrawerOpened}>
                <List className="nav-menu">
                    <ListItem button onClick={() => {setDrawerOpened(false)}}>
                        <ListItemText>
                            <ArrowBack />
                        </ListItemText>
                    </ListItem>
                    {services.map((value,index)=>{
                        return (
                            <ListItem key={index}>
                                <ListItemText>
                                    <Link to={value.link}>
                                        <Button variant={"outlined"} color="primary" onClick={onLinkClick}>
                                            {value.name}
                                        </Button>
                                    </Link>
                                </ListItemText>
                            </ListItem>
                        )
                    })}
                </List>
            </Drawer>
        </React.Fragment>
    )
};

export default Header;
