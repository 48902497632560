import React from 'react'
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import classNames from 'classnames'
import { Autocomplete } from '@material-ui/lab';

const InputAutoComplete = ({
  className, value, onChange, name, label, variant = 'filled', options, multiple,
  inputProps, defaultValue = multiple ? [] : null, valueKey = 'value', labelKey = 'label',
  rules, getOptionLabel, children, disabled, loading, filterOptions, ...props }) => {
  const InputClass = classNames('input', className);

  const getOptionSelected = (option, value) => {
    return option[valueKey] === value
  }

  const renderOptionLabel = option => {
    if (getOptionLabel) return getOptionLabel(option);

    if (option && option[labelKey]) return option[labelKey];
    if (option) {
      const fullOption = options.find((x) => x[valueKey] === option);
      if (fullOption) return fullOption[labelKey];
    };

    return '';
  }

  const onChangeFn = (_, values, onChange) => {
    if (!Array.isArray(values))
      return onChange(((values && values[valueKey]) ? values[valueKey] : values))
    return onChange(values.map((v) => {
      if (v && v[valueKey]) return v[valueKey];
      return v;
    }))
  }

  return (
    <Autocomplete
      id={`${name}_autocomplete`}
      options={options}
      getOptionLabel={renderOptionLabel}
      getOptionSelected={getOptionSelected}
      onChange={(_, values) => onChangeFn(_, values, onChange)}
      value={value}
      disabled={disabled || loading}
      multiple={multiple}
      filterOptions={filterOptions}
      renderInput={params =>
        <TextField
          className={InputClass}
          name={name}
          id={name}
          label={label}
          variant={variant}
          fullWidth
          inputProps={inputProps}
          {...params}
          InputProps={{
            ...params.InputProps,
            ...loading && { startAdornment:
              <InputAdornment position="start" className="loading-adornment">
                <CircularProgress size={16} className="input-spinner"/>
              </InputAdornment>
            } 
          }}
        >
        </TextField>
      }
      {...props}
    />
  )
}

export default InputAutoComplete
