import React, {useContext} from "react";
import Grid from "@material-ui/core/Grid";
import {InputLabel, Select, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import DateFnsUtils from  "@date-io/date-fns";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import SnackBarContext from "../../../Contexts/SnackBarContext";

const NewRegistryRequiredParamsContainer = (props) => {

    const alertContext= useContext(SnackBarContext)

    return (
        <React.Fragment>
            <Grid container spacing={2} justify={"flex-end"} style={{padding:"15px"}}>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <InputLabel id={"bookTypeLabel"}>
                            Tipo de Libro
                        </InputLabel>
                        <Select labelId={"bookTypeLabel"}
                                value={props.bookType}
                                onChange={event => {props.setBookType(event.target.value)}}>
                            <MenuItem value={"general"}>Libro registro alumnos</MenuItem>
                            <MenuItem value={"alumonline"}>Libro registro alumnos online</MenuItem>
                            <MenuItem value={"alumpresen"}>Libro registro alumnos presencial</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <TextField label={"Código de registro"} value={props.code} onChange={event => props.setCode(event.target.value)}/>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <InputLabel id={"registryTypeLabel"}>
                            Tipo de Registro
                        </InputLabel>
                        <Select labelId={"registryTypeLabel"}
                                value={props.type}
                                onChange={event => {props.setType(event.target.value)}}>
                            <MenuItem value={"ENTRY"}>Entrada</MenuItem>
                            <MenuItem value={"EXIT"}>Salida</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                        <KeyboardDatePicker
                            label={"Fecha de creacion"}
                            format={"dd/MM/yyyy"}
                            value={props.created}
                            onChange={(date) => props.setCreated(date)}/>
                        </MuiPickersUtilsProvider>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <Button color={"primary"}
                                variant={"contained"}
                                onClick={ event => {
                                    if(props.code !== ""){
                                        props.NextStep()
                                    }else {
                                        alertContext.setMessage(`El codigo: [${props.code}] no es valido`);
                                        alertContext.setOpen(true);
                                    }
                                }}>
                            Siguiente
                        </Button>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NewRegistryRequiredParamsContainer
