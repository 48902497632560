import React, { useEffect, useState } from "react";
import Grid from "@material-ui/core/Grid";
import { getCourses } from "../../../services/Enrollments/CourseService";
import EnrollmentsCourseList from "../../../components/Enrollments/EnrollmentsLists/EnrollmentsCourseList";
import EnrollmentsCourseCSVForm from "../../../components/Enrollments/EnrollmentsCourseCSVForm";

const NewEnrollmentsAdminAdministrationView = () => {
  const [loading, setLoading] = useState(false);
  const [courses, setCourses] = useState([]);

  const fetchCourses = async () => {
    setLoading(true);
    let courses = await getCourses();
    setCourses(courses);
    setLoading(false);
  };

  useEffect(() => {
    fetchCourses();
  }, []);

  return (
    <Grid container spacing={2}>
      <Grid item md={12}>
        <EnrollmentsCourseList courses={courses} loading={loading} />
      </Grid>
      <Grid item md={12}>
        <EnrollmentsCourseCSVForm uploadCallback={fetchCourses} />
      </Grid>
    </Grid>
  );
};

export default NewEnrollmentsAdminAdministrationView;
