import React from "react";
import Grid from "@material-ui/core/Grid";
import {Typography} from "@material-ui/core";
import { dateUTCToLocale } from "../../../Utils/Date";

const ExamsInstructorExamDetails = ({exam, fetchExams}) =>{
    return (
        <Grid container>
            <Grid item md={12}>
                <Typography variant={"h6"} color={"primary"}>Detalles del examen</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"body1"}>Convocatoria:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"subtitle1"} color={"primary"}>{exam.termId}</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"body1"}>Visibilidad:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"subtitle1"} color={"primary"}>{exam.visible?"Visible":"Oculto"}</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"body1"}>Online:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"subtitle1"} color={"primary"}>{exam.online?"Examen Online":"Examen Presencial"}</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"body1"}>Fecha de inicio de inscripción:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"subtitle1"} color={"primary"}>{dateUTCToLocale(exam.startInscriptionDate)}</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"body1"}>Fecha de fin de inscripción:</Typography>
            </Grid>
            <Grid item md={6}>
                <Typography variant={"subtitle1"} color={"primary"}>{dateUTCToLocale(exam.endInscriptionDate)}</Typography>
            </Grid>
        </Grid>
    )
}

export default ExamsInstructorExamDetails
