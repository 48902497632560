import React from 'react';
import { Icon, IconButton, Tooltip } from '@material-ui/core';

export const generateTFGDefenseTableActions = (role, request, onDownload, onAccept, onReject, onCancel, onEditClick) => {
  const { id, requestId, defenseRequestStatus: status } = request;
  let actions = [];

  const DownloadButton = (
    <Tooltip title="Descargar" aria-label="download">
      <IconButton aria-label="download-icon" onClick={() => onDownload(requestId, id)}>
        <Icon className="download-icon">
          download
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const AcceptButton = (
    <Tooltip title="Aceptar" aria-label="accept">
      <IconButton aria-label="accept" onClick={() => onAccept(requestId, id)}>
        <Icon className="accept-icon">
          done
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const RejectButton = (
    <Tooltip title="Rechazar" aria-label="reject">
      <IconButton aria-label="reject" onClick={() => onReject(requestId, id)}>
        <Icon className="reject-icon">
          close
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const CancelButton = (
    <Tooltip title="Cancelar" aria-label="cancel">
      <IconButton aria-label="cancel" onClick={() => onCancel(requestId, id)}>
        <Icon className="cancel-icon">
          close
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const EditButton = (
    <Tooltip title="Editar" aria-label="download">
      <IconButton aria-label="edit-icon" onClick={() => onEditClick(request)}>
        <Icon className="edit-icon">
          edit
        </Icon>
      </IconButton>
    </Tooltip>
  )

  if (status === 'ACCEPTED') actions.push(DownloadButton);

  if (role === "STUDENT" && status === 'OPEN') {
    // actions.push(EditButton);
    actions.push(CancelButton);
  };
  
  if (onEditClick && role === "COORDINATOR" && ['OPEN', 'ACCEPTED'].includes(status)) {
    actions.push(EditButton);
  }

  if (role === "COORDINATOR" && status === 'OPEN') {
    actions.push(AcceptButton);
    actions.push(RejectButton);
  }

  return <div className="actions">
    {actions}
  </div>
}

export const renderTFGDefenseComments = request => {
  const { defenseRequestStatus: status, location, rejectReason } = request;

  if (status === 'OPEN') return 'Pendiente de revisión';
  if (status === 'CANCELED') return 'Cancelada por el alumno';
  if (status === 'ACCEPTED') return `Aceptada, lugar de defensa: ${location}`;
  if (status === 'REJECTED') return rejectReason ? `Rechazada, razón: ${rejectReason}` : 'Rechazada';
  return '-';
}