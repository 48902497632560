import Http from '../../Utils/Http';
import { localDevCatch } from '../../Utils/utils';
import { mockedEnrollmentTerms, mockedNewEnrollments } from './NewEnrollmentsService.mock';

export const getAvailableEnrollments = async () => {
  const result = await Http.get(`/enrollments-api/enrollment-terms`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedEnrollmentTerms));
  return result;
};

export const getEnrollmentTerms = async () => {
  const options = { params: { all: true } };
  const result = await Http.get(`/enrollments-api/enrollment-terms`, options)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedEnrollmentTerms));
  return result;
}

export const createEnrollmentTerm = async (term) => {
  const result = await Http.post(`/enrollments-api/enrollment-terms`, term)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: '666', ...term }));
  return result;
}

export const updateEnrollmentTerm = async (term) => {
  const result = await Http.patch(`/enrollments-api/enrollment-terms/${term.id}`, term)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, term));
  return result;
}

export const getNewEnrollments = async admin => {
  let url = "/enrollments-api/requests";
  if (admin) url += "/all";

  const result = await Http.get(url)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedNewEnrollments));
  return result; 
}