import React, {useContext, useEffect, useState} from "react";
import {
    Avatar, Card,
    CircularProgress, IconButton, InputBase,
    List,
    ListItem,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText, ListSubheader,
    Paper, Typography
} from "@material-ui/core";
import Divider from "@material-ui/core/Divider";
import DeleteIcon from '@material-ui/icons/Delete';
import DescriptionIcon from '@material-ui/icons/Description';
import Grid from "@material-ui/core/Grid";
import AddBoxIcon from '@material-ui/icons/AddBox';
import SnackBarContext from "../../Contexts/SnackBarContext";

const DocumentalAdmin = () => {

    const alertContext = useContext(SnackBarContext)

    const [loading,setLoading] = useState(false)
    const [documentTypes,setDocumentTypes] = useState([])

    const [newType,setNewType] = useState()

    const fetchDocumentTypes = async () => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        let response = await fetch(`/documents-api/types`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.error(reason);
                return [
                    {
                        id: 0,
                        name: "DNI"
                    },
                    {
                        id: 1,
                        name: "Foto"
                    },
                    {
                        id: 2,
                        name: "Test"
                    },
                ];
            });
        setDocumentTypes(response)
        setLoading(false)
    }

    useEffect(() =>{
        fetchDocumentTypes()
    },[])

    const fetchNewType = async () => {
        setLoading(true)
        let documentType={
            name: newType
        }
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        })
        let response = await fetch(`/documents-api/types`,
            {
                headers: headers,
                method: "POST",
                body: JSON.stringify(documentType)
            })
            .then(response => {
                if(response.ok){
                    return response.json()
                }else {
                    throw new Error("Error recieving session...")
                }
            })
            .catch(reason => {
                console.error(reason);
                return {
                    id: 10,
                    name: "Nuevo"
                }
            });
        documentTypes.push(response)
        setDocumentTypes(documentTypes)
        setLoading(false)
    }

    const fetchDeleteDocumentType = async (documentTypeId) => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        let response = await fetch(`/documents-api/types/${documentTypeId}`,
            {
                headers: headers,
                method: "DELETE"
            })
            .then(r => {
                if(r.ok){
                    alertContext.setMessage("Tipo de documento eliminado")
                    alertContext.setOpen(true)
                }
            })
            .catch(reason => {
                console.error(reason);
                return {
                    id: 10,
                    name: "Nuevo"
                }
            });
        documentTypes.forEach(value => {
            if(value.id===documentTypeId){
                documentTypes.splice(documentTypes.indexOf(value),1)
            }
        })
        setDocumentTypes(documentTypes)
        setLoading(false)
    }

    return (
        <Paper style={{margin:25,padding:25}}>
            <Grid container spacing={2} direction={"column"}>
                <Grid item>
                    <Card raised style={{padding:25}}>
                        <Typography paragraph variant={"h5"}>Nuevo tipo de documento</Typography>
                        <Paper square>
                            <InputBase placeholder={"Nuevo tipo"}
                                       fullWidth
                                       style={{padding:5}}
                                       onChange={(e) => setNewType(e.target.value)}
                                       endAdornment={
                                           <IconButton size={"medium"} color={"primary"} onClick={()=>fetchNewType()}>
                                               <AddBoxIcon/>
                                           </IconButton>
                                       }/>
                        </Paper>
                    </Card>
                </Grid>
                <Grid item>
                    {
                        loading === true
                            ? <CircularProgress/>
                            :
                            <Card raised>
                                <List component={"nav"}
                                      subheader={<ListSubheader>Tipos de documentos</ListSubheader>}>
                                    {documentTypes.map((value, index) => {
                                        return (
                                            <React.Fragment>
                                                <Divider/>
                                                <ListItem alignItems={"center"}>
                                                    <ListItemAvatar>
                                                        <Avatar>
                                                            <DescriptionIcon/>
                                                        </Avatar>
                                                    </ListItemAvatar>
                                                    <ListItemText primary={value.name} secondary={value.id}/>
                                                    <ListItemSecondaryAction>
                                                        <IconButton edge={"end"} aria-label={"Eliminar"}>
                                                            <DeleteIcon onClick={()=>fetchDeleteDocumentType(value.id)}/>
                                                        </IconButton>
                                                    </ListItemSecondaryAction>
                                                </ListItem>
                                            </React.Fragment>
                                        )
                                    })}
                                </List>
                            </Card>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default DocumentalAdmin
