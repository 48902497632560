import { useCallback } from 'react';
import { useSnackbar } from 'notistack';

/**
 * Notification dispatcher wrapper
 * @param type - Notification type [success, error, warning, info]
 * @param message - Message to be displayed
 * @param type - (Optional) Snackbar options
 * @returns Notification dispatcher
 */
export const useNotification = () => {
  const { enqueueSnackbar } = useSnackbar();

  const dispatcher = useCallback((type, message, options) => {
    enqueueSnackbar(message, { 
      variant: type,
      ...options
    });
  }, [enqueueSnackbar]);
  
  return dispatcher;
};
