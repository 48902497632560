import React, {useContext, useState} from "react";
import {Input, LinearProgress, Paper, TextField} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import Divider from "@material-ui/core/Divider";
import Button from "@material-ui/core/Button";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import UserSelectedContext from "../../../Contexts/UserSelectedContext";

const SendSMSContainer=(props) => {

    const alertContext=useContext(SnackBarContext)
    const userSelected = useContext(UserSelectedContext)
    const [loading,setLoading] = useState(false)
    const [smsText,setSMSText] = useState("")

    const handleChange = (event) => {
        setSMSText(event.target.value)
    };

    const sendSMS = async () => {
        if(smsText !== ""){
            setLoading(true);
            const smsBody= JSON.stringify({user: userSelected, text: smsText})
            let sessionId=sessionStorage.getItem("sessionId");
            let headers= new Headers({
                'Authorization':'Bearer '+sessionId,
                'Content-type': 'application/json'
            });
            await fetch("/student-record-api/sms",{
                headers: headers,
                method: "POST",
                body: smsBody
            }).then(r => r.json())
                .then(response =>{
                    alertContext.setMessage(`SMS Enviado`);
                    alertContext.setOpen(true);
                })
                .catch(reason => {
                    alertContext.setMessage(`Ocurrio un error: ${reason}`);
                    console.log(reason);
                });
            setLoading(false);
        } else {
            alertContext.setMessage("El texto esta vacio, mensaje no enviado");
            alertContext.setOpen(true);
        }
    }

    return(
        <React.Fragment>
            <Paper elevation={3} style={{margin:"25px",padding:"15px"}}>
                <Grid container title={"Expediente"} spacing={2} justify={"center"} alignItems={"center"}>
                    <Grid item xs={12}>
                        <Typography variant={"h5"} color={"primary"}>Envio de SMS</Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant={"caption"} color={"primary"} align={"justify"}>
                            Los mensajes de texto tienen un tamaño máximo de 160 caracteres. Superado ese tamaño se enviarán en varios sms.
                        </Typography>
                    </Grid>
                    <Grid item md={12}>
                        <Divider/>
                        { loading === true && <LinearProgress/> }
                    </Grid>
                    <Grid item md={12}>
                        <TextField
                            label={`Cuerpo del mensaje (${smsText.length})`}
                            variant={"outlined"}
                            color={"primary"}
                            multiline
                            onChange={handleChange}
                            inputMode={"text"}
                            InputProps={{
                                endAdornment: (
                                    <Button variant={"contained"} color={"primary"} onClick={sendSMS}>
                                        Enviar
                                    </Button>
                                )
                            }}
                            fullWidth/>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default SendSMSContainer