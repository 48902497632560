import MUIDataTable from "mui-datatables";
import React from "react";
import {LinearProgress} from "@material-ui/core";

const EnrollmentsCourseList = ({courses,loading}) => {

    const columns =[
        {
            label: "Codigo asignatura",
            name: "courseId"
        },
        {
            label: "Nombre asignatura",
            name: "courseName"
        },
        {
            label: "Creditos",
            name: "credits"
        },
        {
            label: "Matriculaciones",
            name: "enrollments"
        },
        {
            label: "Limite Matriculaciones",
            name: "maxEnrollments"
        }
    ]
    const options={
        selectableRows: "none",
        print: false
    }

    return (
        <React.Fragment>
            { loading === true
                ? <LinearProgress/>
                : <MUIDataTable
                    title={`Cursos registrados para matriculas`}
                    columns={columns}
                    options={options}
                    data={courses}/>
            }
        </React.Fragment>
    )
}

export default EnrollmentsCourseList