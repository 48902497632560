import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Divider } from '@material-ui/core';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import { postInstanceRequest, uploadStudentInstanceFile } from '../../../../services/Instances/InstancesService';
import { useNotification } from '../../../../Utils/hooks';
import StudentInformation from '../../InstanceRequestDialog/StudentInformation';
import InputText from '../../../../components/Inputs/InputText';

const initialUserValues = {
  givenName: '',
  surname1: '',
  userExternalId: '',
  dni: '',
  state: '',
  city: '',
  address: '',
  email: '',
  mobilePhone: '',
  homePhone: '',
}

const InstanceRequestDialog = ({ visible, user, instance, onHide, onSuccess, userSelected, external }) => {
  const [file, setFile] = useState(null);
  const [userInfo, setUserInfo] = useState(initialUserValues);
  const [userText, setUserText] = useState('');
  const notification = useNotification();
  const inputRef = useRef(null);

  useEffect(() => {
    if (!visible) {
      setUserText('')
      setFile(null)
      setUserInfo(initialUserValues)
    };
  }, [visible]);

  const uploadFile = async (id, file) => {
    try {
      setApplicationLoading(true);
      await uploadStudentInstanceFile(id, file);
    } catch {
      notification('error', 'Ha habido un error al subir el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onSubmit = async () => {
    if (!instance || !file) return;
    if (!external && !userSelected) return;
  
    if (external) {
      if (!userInfo.dni || !userInfo.givenName || !userInfo.surname1 || !userInfo.email) {
        notification('error', 'Por favor, rellena los datos del alumno externo');
        return;
      };
    }

    try {
      setApplicationLoading(true);
      const request = { templateId: instance.id, userText };
      const userData = external ? { ...userInfo, external: true } : { userId: userSelected };
      const result = await postInstanceRequest(instance.id, userText, userData);
      if (file) await uploadFile(result.id, file);
      onSuccess && onSuccess(request);
      notification('success', 'La instancia ha sido creada correctamente');
    } catch {
      notification('error', 'Ha habido un error al crear la instacia');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) setFile(file);
    }
  }

  return (
    <Dialog
      className="instance-request-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{instance?.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="upload-file">
                <p>
                  <b>Fichero actual:</b><br />
                  {file?.name ? file.name :  'No hay ningún fichero adjunto'}
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                >
                  {`${file?.name ? 'Reemplazar': 'Subir'} fichero`}
                  <input
                    onChange={onFilesChange}
                    ref={inputRef}
                    type="file"
                    hidden
                  />
                </Button>
              </div>
            </Grid>
            <StudentInformation
              user={user}
              external={external}
              userInfo={userInfo}
              setUserInfo={setUserInfo}
            />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <InputText
                name="userText"
                value={userText}
                onChange={e => setUserText(e.target.value)}
                label="Comentario adicional"
                placeholder="Opcional"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 200 }}
                rows={4}
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="actions">
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!file} /* type="submit" */ >
            Crear solicitud
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InstanceRequestDialog
