import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import {FormControlLabel, InputLabel, LinearProgress, Radio, RadioGroup, Select, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import {useNotification} from "../../../Utils/hooks";
import {getUsersFiltered} from "../../../services/Registry/UsersRegistryService";

const NewRegistryUserParamsContainer = (props) => {

    const [search,setSearch] = useState("")
    const [searchType,setSearchType] = useState("id")
    const [users,setUsers] = useState([])
    const [loading,setLoading] = useState(false)
    const notification = useNotification()

    const fetchUsers = async () => {
        if(!search) {
            notification("error", "Debe añadir un parámetro de búsqueda")
            return;
        }
        setLoading(true);
        setUsers(await getUsersFiltered(searchType,search));
        setLoading(false);
    }

    return (
        <React.Fragment>
            <Grid container spacing={2} justify={"flex-end"} style={{padding:"15px"}}>
                <Grid item md={6}>
                    <FormControl fullWidth component={"fieldset"}>
                        <TextField label={"Buscar"}
                                   name={"searchBy"}
                                   onChange={event => { setSearch(event.target.value) }}
                                   InputProps={{
                                       endAdornment:
                                           <Button variant={"contained"}
                                                   color={"primary"} onClick={fetchUsers}>
                                               Buscar
                                           </Button>
                                   }}/>
                        <RadioGroup row
                                    value={searchType}
                                    onChange={event => setSearchType(event.target.value)}>
                            <FormControlLabel value={"id"} control={<Radio color={"primary"}/> } label={"Por Id"}/>
                            <FormControlLabel value={"family"} control={<Radio color={"primary"}/> } label={"Por Apellidos"}/>
                        </RadioGroup>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    { loading === true &&
                        <LinearProgress />
                    }
                    <FormControl fullWidth>
                        <InputLabel id={"userLabel"}>
                            Usuario
                        </InputLabel>
                        <Select labelId={"userLabel"}
                                value={props.userExternalId}
                                onChange={event => {props.setUserExternalId(event.target.value)}}>
                            { users.map((value, index) => {
                                return <MenuItem key={index} value={value.dni}>{value.name} {value.surnames}</MenuItem>
                            })}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <Button variant={"contained"} onClick={props.PreviousStep }>Atras</Button>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <Button color={"primary"} variant={"contained"} onClick={props.NextStep }>Siguiente</Button>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NewRegistryUserParamsContainer
