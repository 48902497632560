import React from "react";

const ErrorPage = ({message}) => {
    return (
        <React.Fragment>
            { message !== ""
                ? message
                : "Esta pagina no esta disponible"
            }
        </React.Fragment>
    )
}

export default ErrorPage