import React, {useContext, useEffect, useState} from "react";
import {Button, CircularProgress, Paper} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import SnackBarContext from "../../Contexts/SnackBarContext";
import { dateUTCToLocale } from "../../Utils/Date";

const ProceedingsList = () => {

    const alertContext = useContext(SnackBarContext)
    const [loading,setLoading] = useState(false)
    const [proceedings,setProceedings] = useState([])

    const columns =[
        {
            label: 'Id de curso',
            name: "externalCourseId"
        },
        {
            label: "Fecha de Creacion",
            name: "created",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Fecha de Modificacion",
            name: "modified",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Documento",
            name: "externalCourseId",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={()=>fetchFile(value)}>
                        Descargar
                    </Button>
                }
            }
        }
    ]
    const options={
        selectableRows: "none",
        print: false
    }

    const fetchProceedings = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const proceedings = await fetch(`/grades-api/proceedings`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        externalCourseId: "Curso001",
                        externalUserId: "7.3",
                        created: "01/12/20",
                        modified: "02/12/20"
                    }
                ];
            });
        setProceedings(proceedings)
        setLoading(false);
    }

    useEffect(()=> {
        fetchProceedings()
    },[])

    const fetchFile = async (courseId) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        await fetch(`/grades-api/proceedings/${courseId}/download`,{ headers: headers })
            .then(r => r.blob())
            .then((blob)=>{
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', `expediente.pdf`);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
            });
        setLoading(false);
    }

    return(
        <Paper elevation={3} style={{margin:"25px"}}>
            { loading === false
                ? <MUIDataTable
                    title={`Expedientes`}
                    columns={columns}
                    options={options}
                    data={proceedings}/>
                : <CircularProgress/>
            }
        </Paper>
    )
}

export default ProceedingsList