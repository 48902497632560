import React from 'react'
import classNames from 'classnames';
import { Controller } from 'react-hook-form';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';
import DayJSUtils from  "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import ErrorText from 'components/Inputs/utils/ErrorText';

const InputDate = ({
  className, control, name, label, inputProps, defaultValue = null,
  rules, disabled, helperText, ...props }) => {
  // const LabelClass = classNames('input-label', { required: rules?.required});

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange: RHKOnChange, onBlur, value, ref },
        fieldState: { error }
      }) => {
        const InputClass = classNames('input', 'date', { error: !!error }, className);

        const onChange = date => {
          Date.parse(date) ? RHKOnChange(date.toISOString()) : RHKOnChange(null);
        };

        return (
          <FormControl fullWidth>
            <MuiPickersUtilsProvider utils={DayJSUtils}>
              <KeyboardDateTimePicker
                className={InputClass}
                id={name}
                onChange={onChange}
                onBlur={onBlur}
                value={value}
                inputRef={ref}
                label={label}
                inputVariant="filled"
                format="DD/MM/YYYY HH:mm"
                error={!!error}
                helperText={error?.message ?? helperText}
                KeyboardButtonProps={{ 'aria-label': 'change date' }}
                disabled={disabled}
                ampm={false}
                fullWidth
                {...inputProps}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        )
      }}
      {...props}
    />
  )
}

export default InputDate;
