import React, {useEffect, useState} from "react";
import {
    CircularProgress,
    Paper
} from "@material-ui/core";
import { dateUTCToLocale } from "../../../Utils/Date";
import MUIDataTable from "mui-datatables";

const PetitionsInstructorView = () => {
    const [loading,setLoading] = useState(false)
    const [data, setData] = useState([])

    const columns =[
        {
            label: 'Id',
            name: "id"
        },
        {
            label: "Examen",
            name: "examOption"
        },
        {
            label: "Curso",
            name: "courseName"
        },
        {
            label: "Sede",
            name: "headquarterName"
        },
        {
            label: "Fecha de peticion",
            name: "created",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        }
    ]
    const options={
        selectableRows: "none",
        print: false,
        download:false,
        elevation: 0
    }

    const fetchChoices = async () => {
        setLoading(true);
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const choices = await fetch(`/exams-api/requests`, {headers: headers})
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        courseId: "UCAVILA",
                        courseName: "Ucavila",
                        created: "2021-03-31T14:52:16",
                        examId: 2,
                        examOption: "A",
                        headquarterId: "cntr_exam_99",
                        headquarterName: "EXAMEN ONLINE",
                        id: 62,
                        name: "Alumno UCAV",
                        userId: "alumnoucav"
                    },
                    {
                        courseId: "UCAVILA",
                        courseName: "Ucavila",
                        created: "2021-03-31T14:52:16",
                        examId: 2,
                        examOption: "A",
                        headquarterId: "cntr_exam_99",
                        headquarterName: "EXAMEN ONLINE",
                        id: 62,
                        name: "Alumno UCAV",
                        userId: "alumnoucav"
                    },
                ];
            });
        setData(choices)
        setLoading(false);
    }

    useEffect( ()=>{
        fetchChoices()
    },[])

    return (
        <Paper elevation={3} style={{ padding:"25px",margin:"25px" }}>
            { loading === true
                ? <CircularProgress/>
                : <MUIDataTable
                    title={"Peticiones de tus exámenes"}
                    columns={columns}
                    options={options}
                    data={data}/>
            }
        </Paper>
    )
}

export default PetitionsInstructorView
