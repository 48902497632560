import React, { useEffect } from "react";
import { useParams, useHistory, Link } from 'react-router-dom';
import {Paper} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import Service from "../../components/Header/ServiceLink";
import { setHttpHeader } from "../../Utils/Http";

const Dashboard = (props) => {
    const { sessionId } = useParams();
    const history = useHistory();
    let { services } = props;

    useEffect(() => {
        sessionStorage.setItem("sessionId",sessionId)
        setHttpHeader('Authorization', `Bearer ${sessionId}`)
    }, [sessionId]);


    return(
        <React.Fragment>
            <Paper elevation={3} style={{ padding:"25px",margin:"25px" }}>
                <Grid
                    container
                    spacing={1}
                    direction={"row"}
                    justifyContent={"center"}
                    alignItems={"center"}
                >
                    {
                        services.map((value,index)=>{
                            return <Service key={index} name={value.name} link={value.link}  />
                        })
                    }
                </Grid>
            </Paper>
        </React.Fragment>
    )
};

export default Dashboard;