export const getCourses = async () => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/courses`
    return await fetch(endpoint, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving enrollments")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const postCoursesCSV = async (selectedFile) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let formData=new FormData();
    formData.append('file',selectedFile)
    let endpoint = `/enrollments-api/courses/csv`
    return await fetch(endpoint,
        {
            headers: headers,
            method: "POST",
            body: formData
        })
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving enrollments")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}