import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, ButtonGroup, CircularProgress, InputLabel, styled, TextField, Typography} from "@material-ui/core";
import MUIDataTable, {debounceSearchRender} from "mui-datatables";
import { dateUTCToLocale } from '../../../Utils/Date';
import {getExamsAdmin} from "../../../services/Exams/ExamsService";

const ExamsAdminTab = (props) =>{

    const [loading,setLoading] = useState(false)
    const [selectedFile,setSelectedFile] = useState()
    const [exams,setExams] = useState([])

    const columns =[
        {
            label: 'Id',
            name: "id"
        },
        {
            label: "Convocatoria",
            name: "termId"
        },
        {
            label: "Asignatura",
            name: "courseId"
        },
        {
            label: "Fecha",
            name: "date",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Opcion",
            name: "option",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}>
                        {value}
                    </Button>
                }
            }
        },
        {
            label: "Estado",
            name: "state",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {

                    switch (value){
                        case "NOT_UPLOADED":
                            return <SoftRedButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                No Subido
                            </SoftRedButton>
                        case "UPLOADED":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Subido
                            </WarningButton>
                        case "UPLOAD_INCORRECT":
                            return <SoftRedButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Subida incorrecta
                            </SoftRedButton>
                        case "UPLOAD_CORRECT":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Subida correcta
                            </WarningButton>
                        case "REVISION_PENDING":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Revision pendiente
                            </WarningButton>
                        case "REVISED_INCORRECT":
                            return <SoftRedButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Revision incorrecta
                            </SoftRedButton>
                        case "REVISED_CORRECT":
                            return <SoftGreenButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Revision correcta
                            </SoftGreenButton>
                        default:
                            return <Button
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                {value}
                            </Button>
                    }
                }
            }
        },
        {
            label: "Visible",
            name: "visible",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}>
                        { value===true ? "Si" : "No"}
                    </Button>
                }
            }
        },
        {
            label: "Online",
            name: "online",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}>
                        { value===true ? "Si" : "No"}
                    </Button>
                }
            }
        },
        {
            label: "Sede por defecto",
            name: "defaultHeadquarter",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Typography variant={"caption"}>{value!=null ? value : "Sin sede por defecto"}</Typography>
                }
            }
        },
        {
            label: "Duracion",
            name: "duration"
        },
        {
            label: "Inicio Fecha Inscripcion",
            name: "startInscriptionDate",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Fin Fecha Inscripcion",
            name: "endInscriptionDate",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Generar examen online",
            name: "id",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"contained"}
                        color={"primary"} onClick={() => fetchGenerateExam(value)}>
                        Generar Examen
                    </Button>
                }
            }
        }
    ]
    const options={
        selectableRows: "none",
        print: false,
        customSearchRender: debounceSearchRender(700),
    }

    const SoftGreenButton = styled(Button)({
        background: '#52b788',
        color: 'white',
    });

    const WarningButton = styled(Button)({
        background: '#f6bd60',
        color: 'white',
    });

    const SoftRedButton = styled(Button)({
        background: '#f28482',
        color: 'white',
    });

    const handleChange = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const fetchExams = async () => {
        setLoading(true)
        const response = await getExamsAdmin()
        setExams(response)
        setLoading(false)
    }

    const fetchGenerateExam = async (id) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response = await fetch(`/exams-api/exams/${id}/generate`,
            {
                headers: headers,
                method: "POST",
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason)
            });
        setLoading(false)
    }

    const postCSV = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        let formData=new FormData();
        formData.append('file',selectedFile)
        const response = await fetch(`/exams-api/exams/csv`,
            {
                headers: headers,
                method: "POST",
                body: formData
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason)
            });
        setExams(response)
        setLoading(false);
    }

    useEffect(()=>{
        fetchExams()
    },[])

    return (
        <React.Fragment>
            { loading
                ? <CircularProgress/>
                :
                <div style={{padding:'20px'}}>
                <Grid container
                        spacing={2}
                        justify={"center"}
                        direction={"column"}
                        style={{padding:"25px"}}>
                    <Grid item>
                        <InputLabel id={"examsCsvInput"}>CSV</InputLabel>
                        <TextField fullWidth type={"file"} variant={"filled"} onChange={handleChange}/>
                    </Grid>
                    <Grid item>
                        <ButtonGroup fullWidth variant={"contained"} color={"secondary"}>
                            <Button>Descargar</Button>
                            <Button onClick={()=>postCSV()}>Subir</Button>
                        </ButtonGroup>
                    </Grid>
                    <Grid item>
                        <MUIDataTable
                            columns={columns}
                            options={options}
                            data={exams}
                            title={"Examenes"}
                        />
                    </Grid>
                </Grid>
                </div>
            }
        </React.Fragment>
    )
}

export default ExamsAdminTab
