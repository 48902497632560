import React from 'react'
import classNames from 'classnames';
import { KeyboardDateTimePicker } from '@material-ui/pickers';
import { FormControl } from '@material-ui/core';
import DayJSUtils from  "@date-io/dayjs";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
// import ErrorText from 'components/Inputs/utils/ErrorText';

const InputDate = ({
  className, name, label, inputProps, defaultValue = null,
  rules, disabled, helperText, value, onChange, error, ...props }) => {
  // const LabelClass = classNames('input-label', { required: rules?.required});
  const InputClass = classNames('input', 'date', { error: !!error }, className);

  const _onChange = date => {
    Date.parse(date) ? onChange(date.toISOString()) : onChange(null);
  };

  return (
    <FormControl fullWidth>
      <MuiPickersUtilsProvider utils={DayJSUtils}>
        <KeyboardDateTimePicker
          className={InputClass}
          id={name}
          onChange={_onChange}
          value={value}
          label={label}
          inputVariant="outlined"
          format="DD/MM/YYYY HH:mm"
          error={!!error}
          helperText={error?.message ?? helperText}
          KeyboardButtonProps={{ 'aria-label': 'change date' }}
          disabled={disabled}
          ampm={false}
          fullWidth
          {...props}
        />
      </MuiPickersUtilsProvider>
    </FormControl>
  )
}

export default InputDate;
