import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TFGTerms from "./TFGTerms";
import TFGRequestsTable from "./TFGRequests/TFGRequestsTable";
import TFGRequest from "./TFGRequest/TFGRequest";
import TFGDefense from "./TFGDefense/TFGDefense";
import TFGHome from "./TFGHome/TFGHome";

const TFGSecr = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (_e, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          <Tab label="Principal" style={{ display: 'none' }} />
          <Tab label="Solicitudes área temática" />
          <Tab label="Solicitudes de defensa" />
          <Tab label="Convocatorias" />
          <Tab label="Solicitud extraordinaria" />
        </Tabs>
      </AppBar>
      <div className="tfg">
        { tab === 0 && <TFGHome secr handleTabChange={handleTabChange} /> }
        { tab === 1 && <TFGRequestsTable secr /> }
        { tab === 2 && <TFGDefense role="SECR" /> }
        { tab === 3 && <TFGTerms /> }
        { tab === 4 && <TFGRequest extraordinary onSuccess={() => setTab(1)} /> }
      </div>
    </>
  )
}

export default TFGSecr
