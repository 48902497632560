import React from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { useForm } from 'react-hook-form'
import InputText from '../../../../components/Inputs/RHK/InputText';
import { required } from '../../../../Utils/utils';
import { Button } from '@material-ui/core';
import { useEffect } from 'react';

const InstancesTypesDialog = ({ visible, onHide, type, onSubmit }) => {
  const { control, handleSubmit, reset } = useForm();

  useEffect(() => {
    reset(type || { name: '' })
  }, [reset, type])

  return (
    <Dialog
      className="instance-form-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{type ? 'Editar instancia' : 'Crear instancia'}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputText
                name="name"
                control={control}
                label="Nombre"
                rules={required}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InstancesTypesDialog