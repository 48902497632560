import React, { useContext } from "react";
import RoleContext from "../../Contexts/RoleContext";
import ErrorPage from "../Error/ErrorPage";
import NewEnrollmentsStudentMain from "./Student/NewEnrollmentsStudentMain";
import NewEnrollmentsAdminMain from "./Admin/NewEnrollmentsAdminMain";
import NewEnrollmentsSecrMain from "./Secr/NewEnrollmentsSecrMain";
import './NewEnrollments.scss';

const NewEnrollmentsMain = () => {
  const roleContext = useContext(RoleContext);

  function getRoleView() {
    switch (roleContext.role) {
      case "ROLE_STUDENT":
        return <NewEnrollmentsStudentMain />;
      case "ROLE_ADMIN":
        return <NewEnrollmentsAdminMain />;
      case "ROLE_SECR":
        return <NewEnrollmentsSecrMain />;
      default:
        return (
          <ErrorPage message={"No tienes permisos para ver esta pagina"} />
        );
    }
  }

  return <React.Fragment>{getRoleView()}</React.Fragment>;
};

export default NewEnrollmentsMain;
