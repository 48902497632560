import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TFGRequest from "./TFGRequest/TFGRequest";
import TFGRequestsTable from "./TFGRequests/TFGRequestsTable";
import TFGDefense from "./TFGDefense/TFGDefense";

const TFGCoord = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (_e, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          <Tab label="Solicitudes" />
          <Tab label="Solicitudes de defensa" />
          <Tab label="Crear solicitud extraordinaria" />
        </Tabs>
      </AppBar>
      <div className="tfg">
        { tab === 0 && <TFGRequestsTable secr /> }
        { tab === 1 && <TFGDefense role="SECR" /> }
        { tab === 2 && <TFGRequest extraordinary onSuccess={() => setTab(1)} /> }
      </div>
    </>
  )
}

export default TFGCoord