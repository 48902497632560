import React from 'react';
import classNames from 'classnames';
import MUIDataTable from "mui-datatables";
import { MUIDatatableLabels } from '../../../../Utils/Table.utils';
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@material-ui/core';

const TFGCourtTable = ({ data, onRowClick, onEditClick, onDeleteClick }) => {
  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    onRowClick,
    rowHover: !!onRowClick,
    textLabels: {
      ...MUIDatatableLabels,
      body: {
        ...MUIDatatableLabels.body,
        noMatch: 'No se han encontrado tribunales de defensa'
      }
    },
    print: false,
    download: false,
    filter: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'name',
      label: 'Nombre del tribunal',
    },
    {
      name: 'id',
      label: 'Tribunal',
      options: {
        customBodyRenderLite: (dataIndex) => {
          const court = data[dataIndex];
          return (
            <ul className="court-members">
              <li>{`Presidente: ${court.chairmanName}`}</li>
              <li>{`Secretario: ${court.secretaryName}`}</li>
              <li>{`Vocal: ${court.vocalName}`}</li>
            </ul>
          )
        }
      }
    },
    {
      name: 'degreeId',
      label: 'Titulación asociada',
      options: {
        customBodyRender: degreeId => degreeId ?? '-'
      }
    },
    {
      name: 'timeSpans',
      label: 'Franjas horarias',
      options: {
        customBodyRender: timespans => `${timespans.length} franjas horarias configuradas`
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRender: id => (
          <div className="actions">
            {/* <Tooltip title="Editar" aria-label="edit">
              <IconButton aria-label="edit" onClick={() => onEditClick(id)}>
                <Icon className="edit-icon">
                  edit
                </Icon>
              </IconButton>
            </Tooltip> */}
            <Tooltip title="Configurar intervalos" aria-label="edit">
              <IconButton aria-label="edit" onClick={() => onEditClick(id)}>
                <Icon className="edit-icon">
                  edit
                </Icon>
              </IconButton>
            </Tooltip>
            <Tooltip title="Eliminar" aria-label="delete">
              <IconButton aria-label="delete" onClick={() => onDeleteClick(id)}>
                <Icon className="delete-icon">
                  delete
                </Icon>
              </IconButton>
            </Tooltip>
          </div>
        )
      }
    }
  ]

  const TableClass = classNames('tfg-court-table', { clickable: !!onRowClick });

  return (
    <div className="tfg-court-table-container">
      <MUIDataTable
        className={TableClass}
        title="Tribunales de defensa"
        columns={columns}
        options={options}
        data={data}
      />
    </div>
  )
}

export default TFGCourtTable
