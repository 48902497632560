import React, { useState, useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import InputAutoCompleteValue from '../../../../components/Inputs/RHK/InputAutoCompleteValue';
import InputSelect from '../../../../components/Inputs/RHK/InputSelect';
import InputText from '../../../../components/Inputs/RHK/InputText';
import InputDate from '../../../../components/Inputs/RHK/InputDate';
import { createTFGTerm, getTFGTitulations, updateTFGTerm } from '../../../../services/TFG/TFGService';
import { useNotification } from '../../../../Utils/hooks';
import { required } from '../../../../Utils/utils';

const initialValues = { academicYear: '', name: '', type: '', assignedDegreeIds: [], start: null, end: null };

const mapDegreeIds = degrees => degrees.map(degree => degree.externalId);

const TFGTermDialog = ({ visible, initial, editing, years, selectedYear, onHide, onSuccess }) => {
  const { control, handleSubmit, reset, watch } = useForm({ defaultValues: initialValues });
  const [loadingTitulations, setLoadingTitulations] = useState(false);
  const [titulations, setTitulations] = useState([]);
  const notification = useNotification();

  const currentYear = watch('academicYear');

  useEffect(() => {
    if (visible) {
      if (editing) {
        const { assignedDegrees, academicYear, ...rest } = editing;
        reset({ ...rest, assignedDegreeIds: mapDegreeIds(assignedDegrees), academicYear: academicYear.id });
      } else if (initial) {
        const { assignedDegrees, academicYear, ...rest } = initial;
        reset({ ...rest, assignedDegreeIds: mapDegreeIds(assignedDegrees), academicYear: academicYear.id });
      } else reset({ ...initialValues, academicYear: selectedYear });
    }
  }, [visible, editing, initial, reset, selectedYear])

  useEffect(() => {
    async function retrieveTitulations(currentYear) {
      try {
        setLoadingTitulations(true);
        const instructors = await getTFGTitulations(currentYear, true).then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
        );
        if (!instructors) throw new Error();
        setTitulations(instructors);
      } catch {
        // Notification
      } finally {
        setLoadingTitulations(false);
      }
    }

    if (currentYear) retrieveTitulations(currentYear);
    else setTitulations([]);
  }, [currentYear]);

  const onSubmit = async data => {
    try {
      setApplicationLoading(true);
      const service = editing ? updateTFGTerm : createTFGTerm;
      const result = await service(data);
      if (!result) throw new Error();
      onSuccess(!!editing, result);
      reset(initialValues);
    } catch {
      notification('error', `Ha habido un error al ${editing ? 'actualizar' : 'crear'} la convocatoria`);
    } finally {
      setApplicationLoading(false);
    }
  }

  return (
    <Dialog
      className="tfg-term-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
    >
      <DialogTitle>Convocatoria</DialogTitle>
      <DialogContent>
        <form className="tfg-term-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputSelect
                name="academicYear"
                label="Año académico"
                control={control}
                options={years}
                rules={required}
              />
            </Grid>
            <Grid item xs={12}>
              <InputSelect
                name="type"
                label="Tipo de convocatoria"
                control={control}
                rules={required}
                options={[
                  { label: 'Solicitud de área temática', value: 'AREA' },
                  { label: 'Solicitud de defensa', value: 'DEFENSE' },
                ]}
              />
            </Grid>
            {/* TODO: Add titulation selector (multiple) */}
            <Grid item xs={12}>
              <InputText
                name="name"
                label="Nombre de convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                className="multiple"
                name="assignedDegreeIds"
                control={control}
                options={titulations}
                disabled={!selectedYear}
                label="Titulaciones"
                loading={loadingTitulations}
                rules={required}
                multiple
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputDate
                name="start"
                // label="Fecha inicio inscripciones"
                label="Fecha de inicio"
                // helperText="Fecha a partir de la que los alumnos pueden solicitar instancias de TFG para esta convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputDate
                name="end"
                // label="Fecha fin inscripciones"
                label="Fecha de fin"
                // helperText="Fecha hasta la que los alumnos pueden solicitar instancias de TFG para esta convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
            {/* <Grid item md={6} xs={12}>
              <InputDate
                name="evaluationStart"
                label="Fecha inicio evaluaciones"
                // helperText="Fecha a partir de la que los alumnos pueden solicitar defensas de TFG para esta convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputDate
                name="evaluationEnd"
                label="Fecha fin evaluaciones"
                // helperText="Fecha hasta la que los alumnos pueden solicitar defensas de TFG para esta convocatoria"
                control={control}
                rules={required}
              />
            </Grid> */}
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TFGTermDialog