import React from 'react'
import ElementCard from "../../../components/ElementCard";
import './TFGHome.scss'
import { Icon } from '@material-ui/core';

const TFGHome = ({ handleTabChange }) => {
  const routes = [
    { label: "Solicitudes área temática", onClick: (e) => handleTabChange(e, 1), icon: <Icon className="element-card--image">list_alt</Icon> },
    { label: "Solicitudes de defensa", onClick: (e) => handleTabChange(e, 2), icon: <Icon className="element-card--image">list_alt</Icon> },
    { label: "Convocatorias", onClick: (e) => handleTabChange(e, 3), icon: <Icon className="element-card--image">date_range</Icon> },
    // { label: "Asignación docente", onClick: (e) => handleTabChange(e, 4), icon: <Icon className="element-card--image">people</Icon> },
    { label: "Solicitud extraordinaria", onClick: (e) => handleTabChange(e, 4), icon: <Icon className="element-card--image">add_circle_outline</Icon> },
  ]

  return (
    <div className="tfg-home">
      { routes.map((route) => (
        <ElementCard
          icon={route.icon}
          title={route.label}
          onClick={route.onClick}
        />
      )) }
    </div>
  );
}

export default TFGHome