import React, {useContext} from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import UserInfoContainer from "./UserInfoPanel/UserInfoContainer";
import CourseInfoContainer from "./CourseInfoPanel/CourseInfoContainer";
import GradesInfoContainer from "./GradesInfoPanel/GradesInfoContainer";
import ProceedingInfoContainer from "./ProceedingsInfoPanel/ProceedingsInfoContainer";
import SendSMSContainer from "./SMSPanel/SendSMSContainer";
import StudentRecordInteractions from "./StudentRecordInteractions/StudentRecordInteractions";
import RoleContext from "../../Contexts/RoleContext";
import StudentsList from "./StudentsList/StudentsList";

const StudentRecordTabbedPanel = (props) => {

    const roleContext=useContext(RoleContext)
    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        console.log(`Going to tab ${newValue}`);
        setValue(newValue);
    };

    function TabPanel(props) {
        return(
            <div role="tabpanel"
                 hidden={value !== props.index}
                 id={`tabpanel-${props.index}`}
                 aria-labelledby={`full-width-tab-${props.index}`}>
                { value === props.index && (
                    <Box>
                        {props.component}
                    </Box>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <AppBar position={"static"}>
                <Tabs variant={"fullWidth"} value={value} onChange={handleTabChange}>
                    <Tab label={"Informacion Personal"} />
                    <Tab label={"Asignaturas disponibles"} />
                    <Tab label={"Notas del Alumno"} />
                    <Tab label={"Expedientes del Alumno"} />
                    <Tab label={"Interacciones del Alumno"} />
                    <Tab label={"Enviar SMS"} />
                    { roleContext.role === "ROLE_INSTRUCTOR" ? <Tab label={"Tus Alumnos"} /> : ""}
                </Tabs>
            </AppBar>
            <TabPanel index={0} component={<UserInfoContainer goToTab={setValue}/>}/>
            <TabPanel index={1} component={CourseInfoContainer}/>
            <TabPanel index={2} component={GradesInfoContainer}/>
            <TabPanel index={3} component={ProceedingInfoContainer}/>
            <TabPanel index={4} component={StudentRecordInteractions}/>
            <TabPanel index={5} component={SendSMSContainer}/>
            { roleContext.role === "ROLE_INSTRUCTOR" ? <TabPanel index={6} component={StudentsList}/> : "" }
        </React.Fragment>
    )
}

export default StudentRecordTabbedPanel