import TimelineItem from "@material-ui/lab/TimelineItem";
import {Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator} from "@material-ui/lab";
import NotInterestedIcon from "@material-ui/icons/NotInterested";
import {Paper, Typography} from "@material-ui/core";
import CloudDoneIcon from "@material-ui/icons/CloudDone";
import WarningIcon from "@material-ui/icons/Warning";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import React from "react";

const ExamsInstructorFileDetails = ({exam, fetchExams}) => {

    const backgroundPaperColor = "#f8f8f8"

    function representExamState(state){
        switch (state){
            case "NOT_UPLOADED":
                return (
                    <Timeline align={"alternate"}>
                        { getNotUploadedInfo() }
                        { getPendingRevisionInactive() }
                        { getRevisionInactive() }
                    </Timeline>
                )
            case "UPLOAD_WITHOUT_FILES":
            case "UPLOADED":
                return (
                    <Timeline align={"alternate"}>
                        { getUploadedInfo() }
                        { getPendingRevisionInactive() }
                        { getRevisionInactive() }
                    </Timeline>
                )
            case "UPLOAD_INCORRECT":
                return (
                    <Timeline align={"alternate"}>
                        { getIncorrectUploadedInfo() }
                        { getPendingRevisionInactive() }
                        { getRevisionInactive() }
                    </Timeline>
                )
            case "UPLOAD_CORRECT":
                return (
                    <Timeline align={"alternate"}>
                        { getCorrectUploadedInfo() }
                        { getPendingRevisionInactive() }
                        { getRevisionInactive() }
                    </Timeline>
                )
            case "REVISION_PENDING":
                return (
                    <Timeline align={"alternate"}>
                        { getCorrectUploadedInfo() }
                        { getPendingRevisionActive() }
                        { getRevisionInactive() }
                    </Timeline>
                )
            case "REVISED_INCORRECT":
                return (
                    <Timeline align={"alternate"}>
                        { getCorrectUploadedInfo() }
                        { getPendingRevisionActive() }
                        { getRevisionIncorrect() }
                    </Timeline>
                )
            case "REVISED_CORRECT":
                return (
                    <Timeline align={"alternate"}>
                        { getCorrectUploadedInfo() }
                        { getPendingRevisionActive() }
                        { getRevisionCorrect() }
                    </Timeline>
                )
            default:
                return (
                    <Typography color={"error"}>Ha ocurrido un error leyendo el estado del examen</Typography>
                )
        }
    }

    function getNotUploadedInfo(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color={"primary"}>
                        <NotInterestedIcon/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"primary"}>No subido</Typography>
                        <Typography>Es necesario subir el archivo del examen</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getUploadedInfo(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot>
                        <CloudDoneIcon color={"primary"}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"primary"}>Subido</Typography>
                        <Typography>El archivo se ha subido a la plataforma</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getIncorrectUploadedInfo(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color={"secondary"}>
                        <WarningIcon color={"error"}/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"error"}>Subido Incorrecto</Typography>
                        <Typography>{`Error: ${exam.reason}`}</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getCorrectUploadedInfo(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color={"primary"}>
                        <CloudDoneIcon/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"primary"}>Subido Correcto</Typography>
                        <Typography>El examen esta pendiente de ser procesado por soporte</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getPendingRevisionInactive(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot>
                        <WarningIcon/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"}>Pendiente Revision</Typography>
                        <Typography>El examen esta siendo procesado por soporte</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getPendingRevisionActive(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color={"primary"}>
                        <WarningIcon/>
                    </TimelineDot>
                    <TimelineConnector />
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"primary"}>Pendiente Revision</Typography>
                        <Typography>El archivo se ha subido correctamente</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getRevisionInactive(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot>
                        <CheckCircleIcon/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"}>Revisado</Typography>
                        <Typography>El examen ha sido aprobado</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getRevisionCorrect(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color={"primary"}>
                        <CheckCircleIcon/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"primary"}>Revisado</Typography>
                        <Typography>El examen ha sido aprobado</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    function getRevisionIncorrect(){
        return (
            <TimelineItem>
                <TimelineSeparator>
                    <TimelineDot color={"secondary"}>
                        <WarningIcon color={"error"}/>
                    </TimelineDot>
                </TimelineSeparator>
                <TimelineContent>
                    <Paper elevation={3}
                           style={{padding:5,background:backgroundPaperColor}}>
                        <Typography variant={"h6"} color={"error"}>Revisado Incorrecto</Typography>
                        <Typography>{`El examen no ha sido aprobado: ${exam.reason}`}</Typography>
                    </Paper>
                </TimelineContent>
            </TimelineItem>
        )
    }

    return (
        representExamState(exam.state)
    )
}

export default ExamsInstructorFileDetails
