import React from "react";
import StudentRecordTabbedPanel from "./StudentRecordTabbedPanel";
import StudentRecordUserSelectionContainer from "./CoordUserSelection/StudentRecordUserSelectionContainer";
import AppBar from "@material-ui/core/AppBar";
import Breadcrumbs from "@material-ui/core/Breadcrumbs";
import Chip from "@material-ui/core/Chip";
import UserSelectedContext from "../../Contexts/UserSelectedContext";

const StudentRecordMain = (props) => {

    const [userSelected,setUserSelected] = React.useState("")

    return (
        <React.Fragment>
            <AppBar position={"static"} color={"paper"} style={{padding:"15px"}}>
                <Breadcrumbs aria-label="seleccion de usuario" separator={">>"}>
                    <Chip color={"primary"} label={"Ficha de Alumno"} onClick={() => setUserSelected("")} />
                    { userSelected !== "" && <Chip label={userSelected} /> }
                </Breadcrumbs>
            </AppBar>
            { userSelected === "" && <StudentRecordUserSelectionContainer userSelected={userSelected} setUserSelected={setUserSelected} /> }
            <UserSelectedContext.Provider value={userSelected}>
                { userSelected !== "" &&
                    <StudentRecordTabbedPanel />
                }
            </UserSelectedContext.Provider>
        </React.Fragment>

    )
}

export default StudentRecordMain