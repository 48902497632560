import React, {useContext, useEffect, useState} from "react";
import {
    makeStyles,
    Step,
    StepContent,
    StepLabel,
    Stepper,
} from "@material-ui/core";
import StudentRecordUserSelectionContainer
    from "../../StudentRecord/CoordUserSelection/StudentRecordUserSelectionContainer";
import Grid from "@material-ui/core/Grid";
import Button from "@material-ui/core/Button";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import CourseExamsDisplayer from "../../../components/CourseExamsDisplayer/CourseExamsDisplayer";

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2),
        minWidth: 120,
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
        width: 200,
    }
}));

const ExamsCoordExtraordinaryPetitionsView = () => {
    const [step, setStep] = useState(0)
    const [courses, setCourses] = useState([{
        "courseId": "1",
        "courseName": "Curso 1"
    }])
    const alertContext = useContext(SnackBarContext)
    const [course, setCourse] = useState("")
    const [userSelected, setUserSelected] = useState("")
    const [exams, setExams] = useState([])
    const [examSelected, setExamSelected] = useState("")

    useEffect(()=>{
        fetchEnrollments()
    }, [userSelected])

    useEffect(()=>{
        fetchExams()
    },[course])

    useEffect(() => {
        console.log(examSelected)
    },[examSelected])

    const fetchEnrollments = async () => {
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const courses = await fetch(`/exams-api/courses/users/${userSelected}/enrollments`, {headers: headers})
            .then(r => {
                if (r.ok) {
                    return r.json()
                } else {
                    throw new Error("Error recieving session...")
                }
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return [
                    {
                        "courseId": "1",
                        "courseName": "Curso 1"
                    },
                    {
                        "courseId": "2",
                        "courseName": "Curso 2"
                    }
                ]
            })
        setCourses(courses)
    }

    const fetchExams = async () =>{
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const exams = await fetch(`/exams-api/exams/courses/${course}`, {headers: headers})
            .then(r => {
                if (r.ok) {
                    return r.json()
                } else {
                    throw new Error("Error recieving session...")
                }
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
                return [
                    {
                        "id": "1",
                        "option": "Examen 1"
                    },
                    {
                        "id": "2",
                        "option": "Examen 2"
                    }
                ]
            })
        setExams(exams)
    }

    const nextStep = () =>{
        setStep(step+1)
    }

    const prevStep = () =>{
        setStep(step-1)
    }

    return (
        <React.Fragment>
            <Stepper activeStep={step} orientation={"vertical"}>
                <Step key={0}>
                    <StepLabel>Buscar usuario</StepLabel>
                    { userSelected === "" ?
                        (
                            <StepContent>
                                <StudentRecordUserSelectionContainer userSelected={userSelected}
                                                                     setUserSelected={setUserSelected}/>
                            </StepContent>
                        ) :
                        (
                            <StepContent>
                                <StudentRecordUserSelectionContainer userSelected={userSelected}
                                                                     setUserSentry-loginelected={setUserSelected}/>
                                <Grid container spacing={2} style={{margin:"20px"}}>
                                    <Grid item xs>
                                        <Button variant="contained" color="primary" disabled={true} onClick={prevStep} style={{marginRight:"5px"}}>Anterior</Button>
                                        <Button variant="contained" color="primary" onClick={nextStep}>Siguiente</Button>
                                    </Grid>
                                </Grid>
                            </StepContent>
                        )
                    }
                </Step>
                <Step key={1}>
                    <StepLabel>Hacer peticion</StepLabel>
                    <StepContent>
                        <CourseExamsDisplayer coordinator={true} userId={userSelected} />
                    </StepContent>
                </Step>
            </Stepper>
        </React.Fragment>
    )
}

export default ExamsCoordExtraordinaryPetitionsView
