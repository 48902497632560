import React, {useState} from "react";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import {Typography} from "@material-ui/core";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const TemplateComponent = ({template}) => {

    const [loading,setLoading] = useState(false)

    const [showSnackbar,setShowSnackbar] = useState(false)
    const [snackbarMessage,setSnackbarMessage] = useState("")

    const [reference,setReference] = useState(template.reference)
    const [title,setTitle] = useState(template.title)
    const [text,setText] = useState(template.text)


    function editingOrCreatingTextInfo(){
        if(template.id===""){
            return "Creando nueva plantilla"
        }else {
            return `Editando la plantilla ${template.id}`
        }
    }

    const updateTemplate = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        });
        await fetch(`/notifications-api/templates/${template.id}`,
            {
                headers: headers,
                method:"PATCH",
                body: JSON.stringify({
                    id: template.id,
                    reference: reference,
                    title: title,
                    text: text
                })
            })
            .then(r => r.json())
            .then(json => {
                template = json
            })
            .catch(reason => {
                console.log(reason);
            })
            .then(() => setSnackbarMessage("Plantilla Actualizada"))
            .then(() => setShowSnackbar(true))
        setLoading(false);
    }

    const createTemplate = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type': 'application/json'
        });
        let data = JSON.stringify({reference: reference,title: title,text: text})
        console.log(data)
        await fetch(`/notifications-api/templates`,{
            headers: headers,
            method:"POST",
            body: data
        })
            .then(r => r.json())
            .then(json => {
                template = json
            })
            .catch(reason => {
                console.log(reason);
            })
            .then(() => setSnackbarMessage("Plantilla Creada"))
            .then(() => setShowSnackbar(true))
        setLoading(false);
    }

    return (
        <Paper variant={"outlined"} style={{padding:"25px",margin:"25px"}}>
            <Snackbar
                open={showSnackbar}
                autoHideDuration={6000}
                onClose={() => setShowSnackbar(false) }
                anchorOrigin={{vertical:"bottom",horizontal:"right"}}>
                <Alert severity={"success"}>
                    {snackbarMessage}
                </Alert>
            </Snackbar>
            <Grid container direction={"column"} spacing={2} alignItems={"stretch"}>
                <Grid item>
                    <Typography>{editingOrCreatingTextInfo()}</Typography>
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        label={"Nombre de referencia"}
                        variant={"outlined"}
                        onChange={(event) => {setReference(event.target.value)}}
                        value={reference} />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        label={"Asunto"}
                        variant={"outlined"}
                        onChange={(event) => {setTitle(event.target.value)}}
                        value={title} />
                </Grid>
                <Grid item>
                    <TextField
                        fullWidth
                        label={"Cuerpo"}
                        multiline
                        variant={"outlined"}
                        onChange={(event) => {setText(event.target.value)}}
                        value={text} />
                </Grid>
                <Grid item>
                    { loading === true && <CircularProgress/>}
                    { template.id === "" && loading === false
                        ?
                        <Button variant={"contained"} color={"primary"} onClick={() => createTemplate()}>
                            Crear
                        </Button>
                        :
                        <Button variant={"contained"} color={"secondary"} onClick={() => updateTemplate()}>
                            Actualizar
                        </Button>
                    }
                </Grid>
            </Grid>
        </Paper>
    )
}

export default TemplateComponent