
export const getChoice = async (courseId,userId = "") => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/exams-api/requests/courses/${courseId}`
    if(userId){
        endpoint = `/exams-api/requests/courses/${courseId}/users/${userId}`
    }
    return await fetch(endpoint, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return {}
            else{
                throw new Error("Error retrieving choice")
            }
        })
        .catch(reason => {
            console.log(reason);
            return {
                "id":5,
                "userId":"alumnoucav",
                "examId":0,
                "courseId":"Curso001",
                "headquarterId":"cntr_exam_99",
                "created":"2021-03-29T12:50:50"
            }
        })
}

export const postChoice = async (examId, headquarterId,userId = "") => {
    let sessionId = sessionStorage.getItem("sessionId");
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId,
        'Content-Type': 'application/json'
    });
    let endpoint = `/exams-api/requests`
    let body = JSON.stringify({
        examId: examId,
        headquarterId: headquarterId
    })
    if(userId){
        endpoint = `/exams-api/requests/${userId}`
        body = JSON.stringify({
            examId: examId,
            headquarterId: headquarterId,
            userId: userId
        })
    }
    return await fetch(endpoint,
        {
            headers: headers,
            method: "POST",
            body: body
        })
        .catch(reason => {
            console.error(reason);
        })
        .then(r => r.json())
}

export const deleteChoice = async (choiceId) => {
    let sessionId = sessionStorage.getItem("sessionId");
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId,
        'Content-Type': 'application/json'
    });
    return await fetch(`/exams-api/requests/${choiceId}`,
        {
            headers: headers,
            method: "DELETE"
        })
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
        })
}