import React, {useState} from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import ExamsStudentView from "./ExamsStudentView";
import PetitionsStudentView from "./PetitionsStudentView";

const ExamsStudentMain = () => {

    const [tabValue, setTabValue] = useState(0);

    const handleTabChange = (event, newValue) => {
        console.log(`Going to tab ${newValue}`);
        setTabValue(newValue);
    };

    function TabPanel(props) {
        return(
            <div role="tabpanel"
                 hidden={tabValue !== props.index}
                 id={`tabpanel-${props.index}`}
                 aria-labelledby={`full-width-tab-${props.index}`}>
                { tabValue === props.index && (
                    <Box>
                        {props.component}
                    </Box>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <AppBar position={"static"}>
                <Tabs variant={"fullWidth"} value={tabValue} onChange={handleTabChange}>
                    <Tab label={"Examenes"} />
                    <Tab label={"Peticiones Realizadas"} />
                </Tabs>
            </AppBar>
            <TabPanel index={0} component={ExamsStudentView}/>
            <TabPanel index={1} component={PetitionsStudentView}/>
        </React.Fragment>
    )
}

export default ExamsStudentMain
