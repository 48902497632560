import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, ButtonGroup, IconButton, InputLabel, TextField, Typography} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import DeleteIcon from '@material-ui/icons/Delete';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';

const TermsAdminTab = (props) =>{

    const [terms,setTerms] = useState([])
    const [loading,setLoading] = useState(false)
    const [newTermName,setNewTermName]=useState("")

    const columns =[
        {
            label: 'Id',
            name: "id"
        },
        {
            label: "Nombre",
            name: "name"
        },
        {
            label: "Disponible",
            name: "enabled",
            options:
                {
                    customBodyRender: (value,tableMeta,updateValue) => {
                        return (value === true) ? <CheckCircleIcon color={"primary"}/> : <CancelIcon color={"error"}/>
                    }
                }
        },
        {
            label: "Deshabilitar",
            name: "id",
            options:
                {
                    customBodyRender: (value,tableMeta,updateValue) => {
                        return <IconButton onClick={()=>deleteTerm(value)}  component={DeleteIcon} />
                    }
                }
        }
    ]
    const options={
        selectableRows: "none",
        print: false
    }

    const fetchTerms = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/exams-api/terms`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setTerms(response)
        setLoading(false);
    }

    const postNewTerm = async (termName) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        });
        const term = await fetch(`/exams-api/terms`,
            {
                headers: headers,
                method: "POST",
                body: JSON.stringify({
                    name:termName,
                })
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return {
                    id: 0,
                    name: termName
                };
            });
        terms.push(term)
        setTerms(terms)
        setLoading(false);
    }

    const deleteTerm = async (termId) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        });
        await fetch(`/exams-api/terms/${termId}`,
            {
                headers: headers,
                method: "DELETE"
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setLoading(false);
        fetchTerms()
    }

    useEffect(()=>{
        fetchTerms()
    },[])

    return (
        <React.Fragment>
            <Grid container
                  spacing={2}
                  justify={"space-evenly"}
                  style={{padding:"25px"}}>
                <Grid item>
                    <MUIDataTable
                        columns={columns}
                        options={options}
                        data={terms}
                        title={"Convocatorias"}
                    />
                </Grid>
                <Grid item>
                    <Grid container
                          spacing={2}
                          justify={"center"}
                          direction={"column"}>
                        <Grid item>
                            <Typography variant={"h5"}>Crear una nueva convocatoria</Typography>
                        </Grid>
                        <Grid item>
                            <TextField fullWidth
                                       variant={"filled"}
                                       label={"Nombre"}
                                       placeholder={"Febrero 2022"}
                                       onChange={(e) => setNewTermName(e.target.value)} />
                        </Grid>
                        <Grid item>
                            <Button fullWidth
                                    variant={"contained"}
                                    color={"primary"} onClick={()=>postNewTerm(newTermName)}>
                                Crear
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default TermsAdminTab