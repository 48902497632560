import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'

const TFGConfigResultDialog = ({ visible, onHide, results }) => {
  return (
    <Dialog
      className="update-instructors-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Resultados de la actualización</DialogTitle>
      <DialogContent>
        <div className="success">
          {
            !results.success.length
              ? <p>No se han actualizado instructores</p>
              : `Se han actualizado los siguientes instructores: ${results.success.join(', ')}`
          }
        </div>
        <div className="error">
          {
            !results.failed.length 
              ? <p>No se han detectado errores</p>
              : `Ha habido un error al actualizar los siguientes instructores: ${results.failed.join(', ')}`
          }
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={onHide} color="primary">Cerrar</Button>
      </DialogActions>
    </Dialog>
  )
}

export default TFGConfigResultDialog