import React, {useContext, useEffect, useState} from "react";
import {Button, Divider, Input, Paper, Typography} from "@material-ui/core";
import {ToggleButton, ToggleButtonGroup} from "@material-ui/lab";
import SnackBarContext from "../../Contexts/SnackBarContext";

const DocumentalUploadDocument = () => {

    const alertContext = useContext(SnackBarContext)

    const [loading,setLoading] = useState(false)
    const [documentTypes,setDocumentTypes] = useState([])

    const [typeSelected,setTypeSelected] = useState()
    const [selectedFile,setSelectedFile] = useState()

    const handleDocumentTypeChange = (event, newType) => {
        setTypeSelected(newType);
    };

    const handleFileChange = (event) => {
        setSelectedFile(event.target.files[0])
    }

    const fetchDocumentTypes = async () => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        let response = await fetch(`/documents-api/types`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.error(reason);
                return [
                    {
                        id: 0,
                        name: "DNI"
                    },
                    {
                        id: 1,
                        name: "Foto"
                    },
                    {
                        id: 2,
                        name: "Test"
                    },
                ];
            });
        setDocumentTypes(response)
        setLoading(false)
    }

    //convert multipartfile to base64
    const toBase64 = file => new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });

    const sendFile = async () =>{
        if(typeSelected!=null && selectedFile!=null){
            setLoading(true)
            let studentDocument={
                documentType: typeSelected,
                documentFilename: selectedFile.name,
                documentFileBytes: await toBase64(selectedFile)
            }
            let sessionId=sessionStorage.getItem("sessionId")
            let headers= new Headers({
                'Authorization':'Bearer '+sessionId,
                'Content-Type':'application/json'
            })
            await fetch("/documents-api",{
                headers: headers,
                method: "POST",
                body: JSON.stringify(studentDocument)
            })
                .then(r => r.json())
                .then(response =>{
                    alertContext.setMessage(`Documento ${response.id} enviado`)
                    alertContext.setOpen(true)
                })
                .catch(reason => {
                    alertContext.setMessage(`Ocurrio un error: ${reason}`)
                    alertContext.setOpen(true)
                    console.log(reason)
                });
            setLoading(false)
        }else {
            alertContext.setMessage("No se ha seleccionado tipo de archivo o el archivo es invalido")
            alertContext.setOpen(true)
        }
    }

    useEffect(() =>{
        fetchDocumentTypes()
    },[])

    return (
        <Paper variant={"elevation"} style={{margin:25,padding:25}}>
            <Typography>Tipo de archivo</Typography>
            <Divider/>
            <ToggleButtonGroup
                value={typeSelected}
                onChange={handleDocumentTypeChange}
                exclusive>
                {documentTypes.map((value, index) => {
                    return <ToggleButton value={value.name}>{value.name}</ToggleButton>
                })}
            </ToggleButtonGroup>
            <Divider/>
            <Input labelId={"FileLabel"}
                   type={"file"}
                   onChange={handleFileChange}/>
            <Button variant={"contained"}
                    color={"primary"} onClick={sendFile}>
                Enviar
            </Button>
        </Paper>
    )
}

export default DocumentalUploadDocument