import Http from '../../Utils/Http';
import { localDevCatch } from '../../Utils/utils';

const mockedUsers = [
  {
    dni: 'alumnoucav',
    name: 'TEST',
    surnames: 'UCAV'
  },
  {
    dni: "0000000A",
    name: "Alumno",
    surnames: "A"
  },
  {
    dni: "00061882",
    name: "Nuevo test",
    surnames: "B"
  },
]

export const getGeneralUsers = async (searchType, search) => {
  const result = await Http.get(`/student-record-api/users/${searchType}/${search}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedUsers));
  return result;
};