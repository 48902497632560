import React from 'react'
import { Grid } from '@material-ui/core';
import InputText from '../../../../components/Inputs/InputText';

const noop = () => {};

const StudentInformation = ({ user, external, userInfo, setUserInfo }) => {
  if (!user && !external) return null;

  const externalOnChange = (e, name) => {
    const value = e.target.value;
    setUserInfo(prev => ({
      ...prev,
      [name]: value
    }))
  }

  const renderField = (name, label, required) => {
    return <InputText
      name={name}
      label={label}
      value={external ? userInfo[name] : user[name] ?? '-'}
      onChange={e => external ? externalOnChange(e, name) : noop}
      InputProps={{ readOnly: !external, disabled: !external }}
      required={external ? required : undefined}
    />
  }

  return (
    <>
      <Grid item md={6} xs={12}>
        { renderField('givenName', 'Nombre', true) }
      </Grid>
      <Grid item md={6} xs={12}>
        { renderField('surname1', 'Apellidos', true) }
      </Grid>
      <Grid item md={6} xs={12}>
        <InputText
          name="userExternalId"
          label={`Usuario${external ? ' (no aplicable)' : ''}`}
          value={external ? userInfo["userExternalId"] : user["userExternalId"] ?? '-'}
          onChange={e => external ? externalOnChange(e, "userExternalId") : noop}
          InputProps={{ readOnly: true, disabled: true }}
        />
      </Grid>
      <Grid item md={6} xs={12}>
        { renderField('dni', 'DNI', true) }
      </Grid>
      <Grid item md={6} xs={12}>
        { renderField('state', 'Estado') }
      </Grid>
      <Grid item md={6} xs={12}>
        { renderField('city', 'Ciudad') }
      </Grid>
      <Grid item xs={12}>
        { renderField('address', 'Dirección') }
      </Grid>
      <Grid item xs={12}>
        { renderField('email', 'Email', true) }
      </Grid>
      <Grid item md={6} xs={12}>
        { renderField('mobilePhone', 'Teléfono') }
      </Grid>
      <Grid item md={6} xs={12}>
        { renderField('homePhone', 'Teléfono casa') }
      </Grid>
    </>
  )
}

export default StudentInformation