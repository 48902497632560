import React, {useEffect, useState} from "react";
import {CircularProgress, Icon, IconButton, Paper, Tooltip} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import { dateUTCToLocale } from "../../../Utils/Date";
import { getRegistries } from "../../../services/Registry/RegistryService";
import RegistryEditModal from "./RegistryEditModal";
import { getAllTerms } from "../../../services/Registry/TermsRegistryService";

const RegistryListContainer = (props) => {

    const [loading,setLoading] = useState(false)
    const [data,setData] = useState([])
    const [registrySelected, setRegistrySelected] = useState()
    const [modal,setModal] = useState(false)
    const [terms, setTerms] = useState([]);
    const columns =[
        {
            label: 'Id',
            name: "id"
        },
        {
            label: "Tipo de libro",
            name: "bookType"
        },
        {
            label: "Codigo de registro",
            name: "code"
        },
        {
            label: "Tipo de Registro",
            name: "type"
        },
        {
            label: "Fecha de creacion",
            name: "created",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Fecha de modificacion",
            name: "modified",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Alumno",
            name: "userExternalId"
        },
        {
            label: "Destino",
            name: "receiver"
        },
        {
            label: "Tipo de documento",
            name: "documentType"
        },
        {
            label: "Documento",
            name: "file"
        },
        {
            label: "Motivo",
            name: "text"
        },
        {
            label: "Año academico",
            name: "term",
            options: {
                customBodyRender: (term, row) => normalizeTerms(term, row),
                sort: true,
                print: true,
                download: false,
            }
        },
        {
            name: 'id',
            label: 'Acciones',
            options: {
                customBodyRender: (id, row) => tableCustomActions(id, row),
                sort: false,
                print: false,
                download: false,
            }
        }
    ]
    const normalizeTerms = (term, row) =>{
        const objTerm = terms.find( t => t.id === term)
        console.log(terms)
        console.log(term)
        if(objTerm)
            return <label>{objTerm.name}</label>
        else
            return ""
    }
    const tableCustomActions = (id, row) =>{
        return <Tooltip title="Editar" aria-label="edit">
                <IconButton aria-label="edit">
                    <Icon className="edit-icon"  onClick={() => onEdit(id)}>
                        edit
                    </Icon>
                </IconButton>
            </Tooltip>
    }
    const handleCloseModal = () => {
        setModal(false)
        setRegistrySelected(0)
        fetchData()
    }
    const onEdit = (id) =>{
        console.log(id)
       setRegistrySelected(id)
        setModal(true)
    }
    const options={
        selectableRows: "none",
        print: false
    }

    const fetchData = async () => {
        setLoading(true)
        setTerms(await getAllTerms())
        setData(await getRegistries());
        setLoading(false);
    }

    useEffect(()=> {
        fetchData()
    },[])
    useEffect(()=>{
        if(!modal) fetchData()
    },[modal])
    return (
        <React.Fragment>
            <Paper elevation={3} style={{ padding:"25px",margin:"25px" }}>
                { loading === true
                    ? <CircularProgress/>
                    : <MUIDataTable
                        title={"Registros"}
                        columns={columns}
                        options={options}
                        data={data}/>
                }
            </Paper>
            <RegistryEditModal  modal={modal} setModal={setModal} handleClose={handleCloseModal} idRegistry={registrySelected} />
        </React.Fragment>
    )
}

export default RegistryListContainer
