import React, {useContext} from "react";
import RoleContext from "../../Contexts/RoleContext";
import DocumentalStudent from "./DocumentalStudent";
import DocumentalCoord from "./DocumentalCoord";
import DocumentalAdmin from "./DocumentalAdmin";

const DocumentalMain = ()=>{

    const roleContext=useContext(RoleContext)

    function renderViewByRole(){
        switch (roleContext.role){
            case "ROLE_STUDENT":
                console.log("Rendering Student View")
                return <DocumentalStudent/>
            case "ROLE_COORD":
                console.log("Rendering Coordinator View")
                return <DocumentalCoord/>
            case "ROLE_ADMIN":
                console.log("Rendering Admin View")
                return <DocumentalAdmin/>
        }
    }

    return (
        <React.Fragment>
            {renderViewByRole()}
        </React.Fragment>
    )
}

export default DocumentalMain