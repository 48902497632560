import React from 'react'
import { FormControlLabel, Checkbox as MUICheckbox } from '@material-ui/core'
import './Checkbox.scss';

const Checkbox = ({ name, checked, onChange, label, description, color = 'primary', ...props }) => {
  return (
    <div className="checkbox">
      <FormControlLabel
        control={
          <MUICheckbox
            checked={checked}
            onChange={e => onChange(e.target.checked, name)}
            name={name}
            color={color}
            {...props}
          />
        }
        label={label}
      />
      { !!description && (
        <p className="checkbox--description">
          {description}
        </p>
      )}
    </div>
  )
}

export default Checkbox
