import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Icon, IconButton } from '@material-ui/core';
import InputText from '../../../components/Inputs/InputText';
import StudentInformation from '../InstanceRequestDialog/StudentInformation';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { useNotification } from '../../../Utils/hooks';
import { downloadStudentInstanceFile, replyInstance, uploadInstanceFile } from '../../../services/Instances/InstancesService';
import { dateInstanceToLocale } from '../../../Utils/Date';
import { downloadFromRawData } from '../../../Utils/utils';

const InstanceResponseDialog = ({ visible, user, instance, onHide, onSuccess, onSuccessfulFileUpload }) => {
  const [text, setText] = useState('');
  const notification = useNotification();
  const inputRef = useRef(null);
  const finished = ['CLOSED', 'CANCELED'].includes(instance?.status);
  const [step, setStep] = useState(1);

  const onSubmit = async () => {
    if (!instance) return;

    try {
      setApplicationLoading(true);
      const result = await replyInstance(instance.id, text);
      onSuccess && onSuccess(result);
      // Local test
      // onSuccess && onSuccess({ ...instance, instructorText: text });
    } catch {
      notification('error', 'Ha habido un error al actualizar la instancia');
    } finally {
      setApplicationLoading(false);
    }
  }

  useEffect(() => {
    setText(instance?.instructorText ?? '');
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance?.instructorText])

  useEffect(() => {
    if (!visible) {
      setTimeout(() => {
        setText('');
        setStep(1);
      }, 250);
    }
  }, [visible]);

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);

      if (file) {
        try {
          setApplicationLoading(true);
          await uploadInstanceFile(instance.id, file);
          notification('success', 'El fichero se ha subido correctamente');
          onSuccessfulFileUpload && onSuccessfulFileUpload(instance.id, file.name);
        } catch {
          notification('error', 'Ha habido un error al subir el fichero');
        } finally {
          setApplicationLoading(false);
          inputRef.current.value = null;
        }
      }
    }
  }

  const onDownloadStudentFile = async () => {
    try {
      if (!instance) return;
      setApplicationLoading(true);
      const { file, name } = await downloadStudentInstanceFile(instance.id);
      downloadFromRawData(file, name);
    } catch {
      notification('error', 'Ha habido un error al descargar el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  return (
    <Dialog
      className="instance-response-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{instance?.templateName}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            {
              step === 1 && (<>
                { finished && <Grid item xs={12}>
                  <p className="disclaimer">Esta instancia ha sido cerrada</p>
                </Grid> }
                <Grid item xs={12}>
                  <div className="student-file">
                    <span>
                      <b>Fichero de petición:</b><br />
                      { instance?.studentFileName ?? 'El estudiante no ha adjuntado ningún fichero' }
                    </span>
                    <IconButton aria-label="download" disabled={!instance?.studentFileName} onClick={onDownloadStudentFile}>
                      <Icon>download</Icon>
                    </IconButton>
                  </div>
                </Grid>
                <StudentInformation user={user} />
                <Grid item xs={12}>
                  <InputText
                    name='userText'
                    label='Comentario del estudiante'
                    value={instance?.userText || '-'}
                    onChange={() => {}}
                    InputProps={{ readOnly: true, disabled: true }}
                  />
                </Grid>
                { finished && (
                  <Grid item xs={12}>
                    <InputText
                      name='instructorText'
                      label='Comentario de respuesta'
                      value={instance?.instructorText || '-'}
                      onChange={() => {}}
                      InputProps={{ readOnly: true, disabled: true }}
                    />
                  </Grid>
                )}
                { finished && (
                  <Grid item xs={12}>
                    <div className="request-outcome">
                      <p>{`Fecha de creación: ${instance.created ? dateInstanceToLocale(instance.created) : '-'}`}</p>
                      <p>{`Fecha de cierre: ${instance.closed ? dateInstanceToLocale(instance.closed) : '-'}`}</p>
                      <p>{`Cerrado por: ${instance.closedBy || '-'}`}</p>
                    </div>
                  </Grid>
                )}
              </>)
            }
            {
              step === 2 && (<>
                <Grid item xs={12}>
                  <div className="upload-file">
                    <p>
                      <b>Fichero de respuesta:</b><br />
                      {instance?.requestFileName ? instance.requestFileName :  'No hay ningún fichero adjunto'}
                    </p>
                    <Button
                      variant="contained"
                      color="primary"
                      component="label"
                      // disabled={finished}
                    >
                      {`${instance?.requestFileName ? 'Reemplazar': 'Subir'} fichero`}
                      <input
                        onChange={onFilesChange}
                        ref={inputRef}
                        type="file"
                        hidden
                      />
                    </Button>
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <InputText
                    name="instructorText"
                    value={text}
                    onChange={e => setText(e.target.value)}
                    label="Comentario del instructor"
                    placeholder="Opcional"
                    InputLabelProps={{ shrink: true }}
                    // disabled={finished}
                    rows={4}
                    multiline
                  />
                </Grid>
              </>)
            }
          </Grid>
        </DialogContent>
        <DialogActions className="actions">
          {
            step === 1
              ? (
                <Button variant="contained" color="primary" onClick={() => setStep(2)} >
                  {finished ? 'Modificar instancia' : 'Proceder a resolver'}
                </Button>
              )
              : (
                <>
                  <Button variant="contained" color="secondary" onClick={() => setStep(1)} >
                    Volver
                  </Button>
                  <Button variant="contained" color="primary" onClick={onSubmit} >
                    {finished ? 'Modificar' : 'Resolver'}
                  </Button>
                </>
              )
          }
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InstanceResponseDialog
