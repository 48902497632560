import Grid from "@material-ui/core/Grid";
import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    LinearProgress
} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import ExamsInstructorExamDetails from "./ExamsInstructorExamDetails";
import ExamsInstructorFileDetails from "./ExamsInstructorFileDetails";
import { dateUTCToLocale } from "../../../Utils/Date";

const ExamsInstructorFileManager = ({courseId,openModal}) =>{

    const [loading,setLoading] = useState(false)
    const [exams,setExams] = useState([])

    const fetchExams = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const exams = await fetch(`/exams-api/exams/courses/${courseId}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                /*return [
                    {
                        id: 0,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "A",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "NOT_UPLOADED"
                    },
                    {
                        id: 1,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "B",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "UPLOADED"
                    },
                    {
                        id: 2,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "C",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "UPLOADED_INCORRECT"
                    },
                    {
                        id: 3,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "D",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "UPLOADED_CORRECT"
                    },
                    {
                        id: 4,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "E",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "REVISION_PENDING"
                    },
                    {
                        id: 5,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "F",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "REVISION_INCORRECT"
                    },
                    {
                        id: 6,
                        courseId: "Curso001",
                        date: "2021-10-04T12:50:16",
                        duration: "",
                        startInscriptionDate: "2021-10-04T12:50:16",
                        endInscriptionDate: "2021-10-16T20:07:44",
                        option: "G",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "REVISION_CORRECT"
                    },
                ];*/
            });
        setExams(exams)
        setLoading(false);
    }

    useEffect(()=> {
        fetchExams()
    },[])

    return (
        <Grid container
              spacing={3}
              justify={"center"}>
            { loading === true
                ? <LinearProgress/>
                : exams.map((value, index) => {
                    return (
                        <Grid item key={index} md={6}>
                            <Card raised>
                                <CardHeader
                                    avatar={<Avatar variant={"rounded"}>{value.option}</Avatar>}
                                    title={`Examen ${value.option}`}
                                    subheader={dateUTCToLocale(value.date)}
                                />
                                <CardContent>
                                    <Grid container>
                                        <Grid item>
                                            <ExamsInstructorExamDetails exam={value} fetchExams={fetchExams}/>
                                        </Grid>
                                        <Grid item>
                                            <ExamsInstructorFileDetails exam={value}/>
                                        </Grid>
                                    </Grid>
                                </CardContent>
                                <CardActions>
                                    <Button onClick={() => {openModal(value)}} fullWidth color={"primary"} variant={"contained"}>Gestionar examen</Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default ExamsInstructorFileManager
