import React, {useContext, useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import Card from "@material-ui/core/Card";
import CardHeader from "@material-ui/core/CardHeader";
import {CardActions, CardContent, CircularProgress, Paper, TextField} from "@material-ui/core";
import Typography from "@material-ui/core/Typography";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import Avatar from "@material-ui/core/Avatar";
import UserSelectedContext from "../../../Contexts/UserSelectedContext";

const CourseInfoContainer = (props) =>{

    const userSelected = useContext(UserSelectedContext)
    const [loading,setLoading] = useState(false)
    const [search,setSearch] = useState()
    const [courses,setCourses] = useState([
        {
            externalId: "Course",
            uuid: "2739k3tujs",
            titulation: "Titulacion",
            name: "Asignatura",
            description: "Descripcion de la asignatura",
            term: "20/21",
            created: "19/10/20",
            externalLink: ""
        }])
    const [coursesFiltered,setCoursesFiltered] = useState([])

    const fetchCourses = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/courses/${userSelected}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return courses;
            });
        setCourses(response)
        setCoursesFiltered(response)
        setLoading(false);
    }

    const filterCourses = async () => {
        if(search!==""){
            let coursesFiltered=courses.filter(value =>
                value.name.includes(search) || value.externalId.includes(search) || value.created.includes(search)
            );
            setCoursesFiltered(coursesFiltered)
        }else {
            //if the filtering is void just print all courses
            setCoursesFiltered(courses)
        }

    }

    useEffect(() => {
        fetchCourses()
    }, [])

    useEffect(() => {
        filterCourses()
    },[search])

    return (
        <React.Fragment>
            { loading === true && <CircularProgress />}
            { loading === false &&
                <React.Fragment>
                    <Grid container spacing={2} justify={"space-evenly"} style={{padding:"25px"}}>
                        <Grid item md={12}>
                            <TextField label={"Filtrar Asignaturas"}
                                       variant={"filled"}
                                       fullWidth
                                       onChange={event => { setSearch(event.target.value) }}
                                       />
                        </Grid>
                        { coursesFiltered.map((value, index) => {
                            return (
                                <Grid item md={3} key={index}>
                                    <Card variant={"outlined"}>
                                        <CardHeader
                                            avatar={<Avatar>A</Avatar>}
                                            title={value.name}
                                            subheader={value.externalId}/>
                                        <CardContent>
                                            <Typography variant={"body1"}>{value.description}</Typography>
                                            <Divider/>
                                            <Typography variant={"caption"}>Año academico: {value.term}</Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button color={"primary"} variant={"contained"} href={value.externalLink}>Acceder</Button>
                                        </CardActions>
                                    </Card>
                                </Grid>
                            )
                        })}
                    </Grid>
                </React.Fragment>
            }
        </React.Fragment>
    )
}

export default CourseInfoContainer