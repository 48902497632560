import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import React from "react";

const EnrollmentFormPaymentOptionData = ({selected, setSelected = null}) => {

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles()

    const handleChange = (event) => {
        if(setSelected){
            setSelected(event.target.value);
        }else {
            console.warn("Read-only Component")
        }
    };

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"SOLICITUD DE CONDICIONES PARA EL PAGO DE MATRÍCULA"}
            />
            <CardContent>
                <RadioGroup name={"paymentOption"} value={selected} onChange={handleChange}>
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Alert severity={"warning"}>
                                Para más información póngase en contacto con el Departamento de Administración de la Universidad, administracion@ucavila.es
                            </Alert>
                        </Grid>
                        <Grid item md={12}>
                            <Typography
                                variant={"caption"}
                                color={"primary"}>
                                Por favor, marque la forma de pago que desea:
                            </Typography>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Typography
                                        variant={"caption"}
                                        color={"textSecondary"}>
                                        FORMA DE PAGO MÁSTER
                                    </Typography>
                                </Grid>
                                {/* <Grid item>
                                    <FormControlLabel value={"FULL"} control={<Radio />} label={"Pago al Contado"}/>
                                </Grid> */}
                                <Grid item>
                                    <FormControlLabel value={"FULL_DOM"} control={<Radio />} label={"Domiciliado Pago Único"}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value={"SPLIT"} control={<Radio />} label={"Domiciliado Pago Fraccionado en 4 plazos (sin coste asociado)"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container direction={"column"}>
                                <Grid item>
                                    <Typography
                                        variant={"caption"}
                                        color={"textSecondary"}>
                                        FORMA DE PAGO GRADO
                                    </Typography>
                                </Grid>
                                {/* <Grid item>
                                    <FormControlLabel value={"FULL"} control={<Radio />} label={"Pago al Contado"}/>
                                </Grid> */}
                                <Grid item>
                                    <FormControlLabel value={"FULL_DOM_DIST"} control={<Radio />} label={"Domiciliado Pago Único"}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value={"FOUR_TERMS"} control={<Radio />} label={"Domiciliado Pago Fraccionado en 4 plazos (sin coste asociado)"}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value={"SIX_TERMS"} control={<Radio />} label={"Domiciliado Pago Fraccionado en 6 plazos (con coste asociado)"}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value={"NINE_TERMS"} control={<Radio />} label={"Domiciliado Pago Fraccionado en 9 plazos (con coste asociado)"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormPaymentOptionData