import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";
import DocumentalMyDocumentsList from "./DocumentalMyDocumentsList";

const DocumentalMyDocuments = () => {

    const [loading,setLoading] = useState(false)
    const [documents,setDocuments] = useState([])

    const fetchDocuments = async () => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        let response = await fetch(`/documents-api`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.error(reason);
                return [
                    {
                        id: 0,
                        studentId: "alumno",
                        documentType: "Test",
                        documentFile: "182394k393kd39",
                        documentFilename: "test.txt",
                        status: "APPROVED",
                        reason: "",
                        created: "01-01-1995",
                        modified: "01-01-1995"
                    },
                    {
                        id: 1,
                        studentId: "alumno",
                        documentType: "Test",
                        documentFile: "182394k393kd39",
                        documentFilename: "test.txt",
                        status: "PENDING",
                        reason: "",
                        created: "01-01-1995",
                        modified: "01-01-1995"
                    },
                    {
                        id: 2,
                        studentId: "alumno",
                        documentType: "Test",
                        documentFile: "182394k393kd39",
                        documentFilename: "test.txt",
                        status: "REJECTED",
                        reason: "No cumple los requisitos",
                        created: "01-01-1995",
                        modified: "01-01-1995"
                    }
                ];
            });
        setDocuments(response)
        setLoading(false)
    }

    useEffect(() =>{
        fetchDocuments()
    },[])

    return (
        <React.Fragment>
            { loading === true
                ? <CircularProgress/>
                : <DocumentalMyDocumentsList list={documents} />
            }
        </React.Fragment>
    )
}

export default DocumentalMyDocuments