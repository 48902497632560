import React, {useContext, useState} from "react";
import {Button, Container} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import EnrollmentFormUserData from "./EnrollmentFormUserData";
import EnrollmentFormTimeOptionData from "./EnrollmentFormTimeOptionData";
import EnrollmentFormCourseTable from "./EnrollmentFormCourseTable";
import EnrollmentFormGeneralDiscountOptionData from "./EnrollmentFormGeneralDiscountOptionData";
import EnrollmentFormInsuranceOptionData from "./EnrollmentFormInsuranceOptionData";
import EnrollmentFormPaymentOptionData from "./EnrollmentFormPaymentOptionData";
import EnrollmentFormDebtData from "./EnrollmentFormDebtData";
import EnrollmentsFormDownloadPDF from "./EnrollmentsFormDownloadPDF";
import {getRequestFiles} from "../../../services/Enrollments/RequestsService";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import EnrollmentFormImageAuthorizationOptionData from "./EnrollmentFormImageAuthorizationOptionData";
import EnrollmentFormSEPIOptionData from "./EnrollmentFormSEPIOptionData";

const EnrollmentFormReadOnly = ({request, admin = false}) => {

    const alertContext = useContext(SnackBarContext)

    const [loading,setLoading] = useState(false)

    const fetchFiles = async () => {
        setLoading(true)
        let error=await getRequestFiles(request.id)
        if(error){
            alertContext.setMessage("No se ha podido descargar el fichero")
            alertContext.setOpen(true)
        }
        setLoading(false)
    }

    return (
        <Container style={{padding:15}}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    { admin === true
                        ? <EnrollmentFormUserData  externalUserId={request.userExternalId}/>
                        : <EnrollmentFormUserData />
                    }
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormTimeOptionData selected={request.timeOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormCourseTable
                        gradeSelected={request.grade}
                        termSelected={request.termId}
                        coursesSelected={request.courses} 
                    />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormGeneralDiscountOptionData
                        generalDiscountOption={request.generalDiscountOption}
                        minusDiscountOption={request.minusDiscountOption}
                        discountMHOption={request.discountMH}
                        discountUcavOption={request.discountUcav}
                        discountConvOption={request.discountConv}
                        discountConvText={request.discountConvText}
                        discountOtherOption={request.discountOther}
                        discountOtherText={request.discountOtherText}
                    />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormInsuranceOptionData selected={request.insuranceOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormPaymentOptionData selected={request.paymentOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormImageAuthorizationOptionData selected={request.imageAuthorization.toString()} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormSEPIOptionData selected={request.dataSAPI.toString()} />
                </Grid>
                <Grid item md={12}>
                    {
                        request.paymentOption !== "FULL"
                        && <EnrollmentFormDebtData
                            debtDNI={request.debtDNI}
                            debtOption={request.debtOption}
                            debtName={request.debtName}
                            debtAddress={request.debtAddress}
                            debtCity={request.debtCity}
                            debtZipCode={request.debtZipCode}
                            debtState={request.debtState}
                            debtCountry={request.debtCountry}
                            debtIBAN={request.debtIBAN}
                            debtBIC={request.debtBIC}
                            readOnly
                        />
                    }
                </Grid>
                <Grid item md={12}>
                    <Button variant={"contained"} fullWidth onClick={()=>fetchFiles()}>
                        Descargar Ficheros
                    </Button>
                </Grid>
                <Grid item md={12}>
                    <EnrollmentsFormDownloadPDF requestId={request.id}/>
                </Grid>
            </Grid>
        </Container>
    )
}

export default EnrollmentFormReadOnly