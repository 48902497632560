import React, {useState} from "react";
import Paper from "@material-ui/core/Paper";
import {Button, FormControl, Typography} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import InputLabel from "@material-ui/core/InputLabel";
import TemplateComponent from "./TemplateComponent";
import Grid from "@material-ui/core/Grid";

const TemplatesMain = ({templates}) => {

    const [selectedTemplate,setSelectedTemplate] = useState()
    const [editing,setEditing] = useState(false)

    const handleOnChange = (event) => {
        setSelectedTemplate(event.target.value)
    }

    function templatesOptions(){
        return (
            templates.map((value, index) => {
                return <MenuItem value={value.id} key={index}>{value.reference}</MenuItem>
            })
        )
    }

    function templateSelected(){
        let templateToEdit=templates.filter(template => template.id === selectedTemplate)[0]
        return (templateToEdit!=null)?templateToEdit:{id:"",reference:"",title:"",text:""}
    }

    return (
        <React.Fragment>
            <Paper variant={"outlined"} style={{padding:"25px",margin: "25px"}}>
                <Typography variant={"h5"} color={"primary"}>Bienvenido a la edicion de plantillas de correo electronico</Typography>
                <Typography variant={"body1"}>Es posible editar las plantillas de correo electronico como documentos HTML
                    e incluir estas variables dependiendo del rol al que van asignadas cada plantilla</Typography>
                <Typography variant={"body1"}>Para alumnos:</Typography>
                <ul>
                    <li>{"{{name}} - Nombre del alumno"}</li>
                    <li>{"{{password}} - La nueva contrasenya asignada al alumno"}</li>
                    <li>{"{{username}} - Nombre de usuario del alumno"}</li>
                </ul>
                <Typography variant={"body1"}>Para profesores y coordinadores:</Typography>
                <ul>
                    <li>{"{{list}} - Lista de nuevas inscripciones del curso"}</li>
                </ul>
                <Grid container justify={"center"} alignItems={"center"} spacing={2}>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <InputLabel id="template-selection-label">Plantilla a editar</InputLabel>
                            <Select value={selectedTemplate}
                                    onChange={handleOnChange}
                                    labelId={"template-selection-label"}
                                    variant={"filled"}
                                    autoWidth>
                                {templatesOptions()}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant={"contained"} fullWidth color={"primary"} onClick={() => {
                            templateSelected()
                            setEditing(true)
                        }}>
                            Editar
                        </Button>
                    </Grid>
                    <Grid item xs={2}>
                        <Typography align={"center"}>O</Typography>
                    </Grid>
                    <Grid item xs={5}>
                        <Button variant={"contained"} fullWidth color={"secondary"} onClick={() => {
                            setSelectedTemplate(undefined)
                            setEditing(true)
                        }}>
                            Crear Nueva Plantilla
                        </Button>
                    </Grid>
                </Grid>
            </Paper>
            { editing &&
                <TemplateComponent template={templateSelected()}/>
            }
        </React.Fragment>
    )
}

export default TemplatesMain