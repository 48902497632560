
export const getRequests = async () => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests`
    return await fetch(endpoint, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving enrollments")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const getRequestPDF = async (requestId) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/${requestId}/pdf`
    return await fetch(endpoint, {headers: headers})
        .then(r => r.blob())
        .then((blob)=>{
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `peticion_automatricula_${requestId}.pdf`)
            // 3. Append to html page
            document.body.appendChild(link)
            // 4. Force download
            link.click()
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link)
        })
        .catch(reason => {
            console.log(reason)
        })
}

export const getAllRequests = async () => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/all`
    return await fetch(endpoint, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving enrollments")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const postRequest = async (request) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId,
        'Content-Type': 'application/json'
    });
    let endpoint = `/enrollments-api/requests/`
    return await fetch(
        endpoint,
        {
            headers: headers,
            method: "POST",
            body: JSON.stringify(request)
        })
        .then(r => {
            if(r.ok) {
                return r.json()
            }else {
                return "Peticion no registrada"
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const deleteRequests = async (requestId) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/${requestId}`
    return await fetch(
        endpoint,
        {
            headers: headers,
            method: "DELETE"
        })
        .then(r => {
            if(r.ok) {
                return r.text()
            }else {
                return "Peticion no eliminada"
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const processRequest = async (requestId) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/${requestId}/process`
    return await fetch(
        endpoint,
        {
            headers: headers,
            method: "POST"
        })
        .then(r => {
            if(r.ok) {
                return r.json()
            }else {
                return "Peticion no procesada"
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const unprocessRequest = async (requestId) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/${requestId}/unprocess`
    return await fetch(
        endpoint,
        {
            headers: headers,
            method: "POST"
        })
        .then(r => {
            if(r.ok) {
                return r.json()
            }else {
                return "Peticion no desprocesada"
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const postFiles = async (requestId,files) => {
    let formData=new FormData()
    for(let file of files){
        formData.append("files",file)
    }
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/${requestId}/files`
    return await fetch(
        endpoint,
        {
            headers: headers,
            method: "POST",
            body: formData
        })
        .then(r => {
            if(r.ok) {
                return r.text()
            }else {
                return "Archivos no adjuntados"
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const getRequestFiles = async (requestId) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/${requestId}/files`
    return await fetch(endpoint, {headers: headers})
        .then(r => {
            if(r.ok){
                r.blob()
                    .then((blob)=>{
                        // 1. filename
                        const filename = r.headers
                            .get("content-disposition")
                            .split('"')[1];
                        // 2. Create blob link to download
                        const url = window.URL.createObjectURL(new Blob([blob]))
                        const link = document.createElement('a')
                        link.href = url
                        link.setAttribute('download', filename)
                        // 3. Append to html page
                        document.body.appendChild(link)
                        // 4. Force download
                        link.click()
                        // 5. Clean up and remove the link
                        link.parentNode.removeChild(link)
                    })
            }else {
                throw Error("Can't download file")
            }
        })
        .catch(reason => {
            console.error(reason)
            return reason
        })
}

export const fetchCsvFile = async () =>{
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/requests/csv`
    return await fetch(endpoint, {headers: headers})
        .then(r => r.blob())
        .then((blob)=>{
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `selfextendedenrollments.csv`)
            // 3. Append to html page
            document.body.appendChild(link)
            // 4. Force download
            link.click()
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link)
        })
        .catch(reason => {
            console.log(reason)
        })
}
