import React from 'react'
import { CircularProgress, InputAdornment, TextField } from '@material-ui/core'
import classNames from 'classnames'
import { Controller } from 'react-hook-form';
import { Autocomplete } from '@material-ui/lab';

const InputAutoComplete = ({
  className, control, name, label, variant = 'filled', options, multiple,
  inputProps, defaultValue = multiple ? [] : null, valueKey = 'value', labelKey = 'label',
  rules, getOptionLabel, renderOption, children, disabled, loading, filterOptions, ...props }) => {
  const InputClass = classNames('input', className);

  const getOptionSelected = (option, value) => {
    return option[valueKey] === value
  }

  const renderOptionLabel = option => {
    if (getOptionLabel) return getOptionLabel(option);

    if (option && option[labelKey]) return option[labelKey];
    if (option) {
      const fullOption = options.find((x) => x[valueKey] === option);
      if (fullOption) return fullOption[labelKey];
    };

    return '';
  }

  const onChangeFn = (_, values, onChange) => {
    if (!Array.isArray(values))
      return onChange(((values && values[valueKey]) ? values[valueKey] : values))
    return onChange(values.map((v) => {
      if (v && v[valueKey]) return v[valueKey];
      return v;
    }))
  }

  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={defaultValue}
      render={({
        field: { onChange, onBlur, value, ref },
        fieldState: { error },
      }) => (
        <Autocomplete
          id={`${name}_autocomplete`}
          options={options}
          getOptionLabel={renderOptionLabel}
          getOptionSelected={getOptionSelected}
          onChange={(_, values) => onChangeFn(_, values, onChange)}
          onBlur={onBlur}
          value={value}
          ref={ref}
          disabled={disabled || loading}
          multiple={multiple}
          filterOptions={filterOptions}
          renderOption={renderOption}
          renderInput={params =>
            <TextField
              className={InputClass}
              name={name}
              id={name}
              label={label}
              variant={variant}
              error={!!error}
              helperText={error?.message}
              fullWidth
              inputProps={inputProps}
              {...params}
              InputProps={{
                ...params.InputProps,
                ...loading && { startAdornment:
                  <InputAdornment position="start" className="loading-adornment">
                    <CircularProgress size={16} className="input-spinner"/>
                  </InputAdornment>
                } 
              }}
            >
            </TextField>
          }
        />
      )}
      {...props}
    />
  )
}

export default InputAutoComplete
