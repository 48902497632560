import React, {useState} from "react";

const SnackBarContext = React.createContext({});

const AlertProvider = (props) => {
    const [message,setMessage] = useState("Mensaje")
    const [open,setOpen] = useState(false)

    return (
        <SnackBarContext.Provider value={{message,setMessage,open,setOpen}}>
            {props.children}
        </SnackBarContext.Provider>
    )
}

export default SnackBarContext

export { AlertProvider }

export class SnackBarProvider {
}