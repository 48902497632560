import {
    Avatar,
    Button, Card,
    CircularProgress, FormControlLabel, IconButton,
    Input, InputBase,
    InputLabel, LinearProgress, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, ListSubheader,
    Modal,
    Paper, Radio, RadioGroup,
    Select,
    TextField,
    Typography
} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, {useContext, useEffect, useState} from "react";
import FormControl from "@material-ui/core/FormControl";
import MenuItem from "@material-ui/core/MenuItem";
import {KeyboardDatePicker, MuiPickersUtilsProvider} from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";
import {useNotification} from "../../../Utils/hooks";
import AddBoxIcon from "@material-ui/icons/AddBox";
import Divider from "@material-ui/core/Divider";
import DescriptionIcon from "@material-ui/icons/Description";
import DeleteIcon from "@material-ui/icons/Delete";
import {getUsersFiltered} from "../../../services/Registry/UsersRegistryService";
import {createRegistry, getRegistry, updateRegistry} from "../../../services/Registry/RegistryService";
import {getAllTerms} from "../../../services/Registry/TermsRegistryService";

const RegistryEditModal = ({modal,setModal, handleClose, handleOpen, idRegistry}) => {
    const [search,setSearch] = useState("")
    const [registry, setRegistry] = useState({})
    const [loading,setLoading] = useState(false)
    const [searchType,setSearchType] = useState("id")
    const [users,setUsers] = useState([])
    const [userLabel,setUserLabel] = useState("")
    const [terms, setTerms] = useState([]);
    const notification = useNotification()

    const fetchUsers = async () => {
        if(!search) {
            notification("error", "Debe añadir un parámetro de búsqueda")
            return;
        }
        setLoading(true);
        setUsers(await getUsersFiltered(searchType,search));
        setLoading(false);
    }
    const fetchData = async ()=>{
        setLoading(true)
        let registry = await getRegistry(idRegistry)
        setRegistry(registry)
        setUserLabel(registry.userExternalId)
        setUsers(await getUsersFiltered("id",registry.userExternalId))
        setTerms(await getAllTerms())
        setLoading(false)
    }
    const saveRegistry = async ()=>{
        debugger;
        let completed = await updateRegistry(registry,notification)
        if (completed) setModal(false)

    }
    useEffect(() => {
        if(modal) fetchData()
    }, [modal]);


    return (
        <Modal open={modal}
               onClose={handleClose}
               closeAfterTransition
               style={{margin: 25, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Paper variant={"outlined"}>
                <Grid container spacing={2} justify={"flex-end"} style={{padding:"15px"}}>
                    <Grid item md={12}>
                        <Typography id="modal-modal-title" variant="h6" component="h2" align={"center"}>
                            Modificando registro: <strong>{registry.code}</strong>
                        </Typography>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <InputLabel id={"bookTypeLabel"}>
                                Tipo de Libro
                            </InputLabel>
                            <Select labelId={"bookTypeLabel"} value={registry.bookType || ""}  onChange={event => {setRegistry({
                                ...registry,
                                bookType: event.target.value
                            })}}>
                                <MenuItem value={"general"}>Libro registro alumnos</MenuItem>
                                <MenuItem value={"alumonline"}>Libro registro alumnos online</MenuItem>
                                <MenuItem value={"alumpresen"}>Libro registro alumnos presencial</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField label={"Código de registro"} value={registry.code || "" } disabled/>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <InputLabel id={"registryTypeLabel"}>
                                Tipo de Registro
                            </InputLabel>
                            <Select labelId={"registryTypeLabel"} value={registry.type || ""} onChange={event => {setRegistry({
                                ...registry,
                                type: event.target.value
                            })}}>
                                <MenuItem value={"ENTRY"}>Entrada</MenuItem>
                                <MenuItem value={"EXIT"}>Salida</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                    label={"Fecha de creacion"}
                                    format={"dd/MM/yyyy"}
                                    value={registry.created}
                                    disabled/>
                            </MuiPickersUtilsProvider>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth component={"fieldset"}>
                            <TextField label={"Buscar"}
                                       name={"searchBy"}
                                       value={search}
                                       onChange={event => { setSearch(event.target.value) }}
                                       InputProps={{
                                           endAdornment:
                                               <Button variant={"contained"}
                                                       color={"primary"} onClick={fetchUsers}>
                                                   Buscar
                                               </Button>
                                       }}/>
                            <RadioGroup row
                                        value={searchType}
                                        onChange={event => setSearchType(event.target.value)}>
                                <FormControlLabel value={"id"} control={<Radio color={"primary"}/> } label={"Por Id"}/>
                                <FormControlLabel value={"family"} control={<Radio color={"primary"}/> } label={"Por Apellidos"}/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        { loading === true &&
                        <LinearProgress />
                        }
                        <FormControl fullWidth>
                            <InputLabel id={"userLabel"}>
                                Usuario
                            </InputLabel>
                            <Select labelId={"userLabel"}
                                    value={registry.userExternalId || ""}>
                                { users.map((value, index) => {
                                    return <MenuItem key={index} value={value.dni}>{value.name} {value.surnames}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField label={"Destino"} value={registry.receiver || ""} onChange={
                                event => {setRegistry({...registry, receiver: event.target.value })}} />
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField label={"Tipo de documento"} value={registry.documentType || ""} onChange={event => {setRegistry({
                                ...registry,
                                documentType: event.target.value
                            })}}/>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <TextField type={"file"} label={"Documento"}/>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <InputLabel id={"termLabel"}>
                                Año académico
                            </InputLabel>
                            <Select labelId={"termLabel"}
                                    value={registry.term ?? ""}
                                    onChange={event => setRegistry({...registry, term:event.target.value})}>
                                {terms.map( term => {
                                    return <MenuItem value={term.id}>{term.name}</MenuItem>
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <FormControl fullWidth>
                            <TextField label={"Motivo"} multiline value={registry.text || ""}
                                       onChange={event => setRegistry({...registry, text:event.target.value})}/>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <Button variant={"contained"} onClick={()=>{setModal(false)}}>Cancelar</Button>
                        </FormControl>
                    </Grid>
                    <Grid item md={6}>
                        <FormControl fullWidth>
                            <Button color={"primary"} variant={"contained"} onClick={()=>saveRegistry()}>Modificar</Button>
                        </FormControl>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default RegistryEditModal
