import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {CircularProgress, InputLabel, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";

const CourseSelectionPanel = ( {course, setCourse, setActiveStep}) => {

    const [courses,setCourses] = useState([])
    const [loading,setLoading] = useState(false)

    const fetchCourses = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const courses= await fetch("/interactions-api/courses",{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        id: "CourseId1",
                        name: "Asignatura1"
                    },
                    {
                        id: "CourseId2",
                        name: "Asignatura2"
                    }
                ];
            });
        setCourses(courses);
        setLoading(false);
    }

    useEffect(() =>{
        fetchCourses(courses)
    },[])

    return (
        <React.Fragment>
            <Grid container
                  spacing={3}
                  alignItems={"center"}
                  justify={"space-around"}
                  style={{padding:"10px"}}>
                { loading === true &&
                    <React.Fragment>
                        <CircularProgress />
                    </React.Fragment>
                }
                { loading === false &&
                    <React.Fragment>
                        <Grid item lg={12}>
                            <FormControl variant={"standard"} fullWidth>
                                <InputLabel id={"CourseSelectLabel"}>
                                    Asignatura
                                </InputLabel>
                                <Select labelId={"CourseSelectLabel"}
                                        id={"CourseSelect"}
                                        value={course} onChange={(event => {setCourse(event.target.value)})}>
                                    { courses.map(value => {
                                        return (
                                            <MenuItem key={value.courseId} value={value.courseId}>{value.name}</MenuItem>
                                        )
                                    })}
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item lg>
                            <Button variant={"contained"}
                                    color={"default"}
                                    fullWidth
                                    onClick={()=>{setActiveStep(0)}}>
                                Atras
                            </Button>
                        </Grid>
                        <Grid item lg>
                            <Button variant={"contained"}
                                    color={"primary"}
                                    fullWidth
                                    onClick={()=>{setActiveStep(2)}}>
                                Seleccionar
                            </Button>
                        </Grid>
                    </React.Fragment>
                }
            </Grid>
        </React.Fragment>
    )
}

export default CourseSelectionPanel