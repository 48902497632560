import Http from '../../Utils/Http';

export const getRegistries = async () => {
    let sessionId=sessionStorage.getItem("sessionId");
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId
    });
    const entries= await fetch(`/registry-api`,{ headers: headers })
        .then(r => r.json())
        .catch(reason => {
            console.error(reason);
        });
    return entries
}

export const getRegistry = async (idRegistry) =>{
    let sessionId=sessionStorage.getItem("sessionId");
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId
    });
    const registry= await fetch(`/registry-api/${idRegistry}`,{ headers: headers })
        .then(r => r.json())
        .catch(reason => {
            console.error(reason);
        });
    return registry
}

export const createRegistry = async (registry, notification) =>{
    let sessionId=sessionStorage.getItem("sessionId");
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId,
        'Content-type': 'application/json'
    });

    return await fetch("/registry-api",{
        headers: headers,
        method: "POST",
        body: JSON.stringify(registry) })
        .then(r => r.json())
        .then(response =>{
            notification("success", "Se ha guardado correctamente el Registro")
            return true
        })
        .catch(reason => {
            notification("error",`Ocurrio un error: ${reason}`)
            console.log(reason);
            return false
        });
}

export const updateRegistry = async (registry, notification)=>{
    let sessionId=sessionStorage.getItem("sessionId");
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId,
        'Content-type': 'application/json'
    });

    console.log(registry)
    let id = registry.id
    delete registry.id
    return await fetch("/registry-api/" + id,{
        headers: headers,
        method: "PATCH",
        body: JSON.stringify(registry) })
        .then(r => r.json())
        .then(response =>{
            notification("success", "Se ha actualizado correctamente el Registro")
            return true;
        })
        .catch(reason => {
            notification("error",`Ocurrio un error: ${reason}`)
            console.log(reason);
            return false;
        });
}
