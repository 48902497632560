import axios from 'axios';
import {initializeInstance} from "./HttpUtils";

const instance = axios.create();

export const setHttpHeader = (key, value) => {
  instance.defaults.headers.common[key] = value;
}

export const setBaseURL = baseURL => {
  instance.defaults.baseURL = baseURL;
}

export const setHttpInterceptor = (onSuccess, onError) => {
  return instance.interceptors.response.use(onSuccess, onError)
};

export const ejectHttpInterceptor = interceptor => {
  instance.interceptors.response.eject(interceptor)
};

// Immediately launch token retrieval
initializeInstance(setHttpHeader);

export default instance;