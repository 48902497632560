
export const getExams = async (courseId) => {
    let sessionId = sessionStorage.getItem("sessionId");
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    return await fetch(`/exams-api/exams/courses/${courseId}`, {headers: headers})
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
            return [
                {
                    id: 0,
                    courseId: "Curso001",
                    date: "2021-04-04T12:00:00",
                    duration: "",
                    startInscriptionDate: "12/12/12",
                    endInscriptionDate: "21/21/21",
                    option: "A",
                    termId: 0,
                    visible: true,
                    online: false,
                    defaultHeadquarter: null,
                    state: "Not Uploaded"
                },
                {
                    id: 1,
                    courseId: "Curso001",
                    date: "2021-04-04T12:00:00",
                    duration: "",
                    startInscriptionDate: "12/12/12",
                    endInscriptionDate: "21/21/21",
                    option: "B",
                    termId: 0,
                    visible: true,
                    online: false,
                    defaultHeadquarter: "cntr_exam_1",
                    state: "Not Uploaded"
                },
            ];
        });
}

export const getExamsAdmin = async () => {
    let sessionId=sessionStorage.getItem("sessionId")
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId
    })
    return await fetch(`/exams-api/exams?fullExamList=false`,{ headers: headers })
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
        });
}

export const fetchExamsCsvFile = async () =>{
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/exams-api/requests/courses/download`
    return await fetch(endpoint, {headers: headers})
        .then(r => r.blob())
        .then((blob)=>{
            // 2. Create blob link to download
            const url = window.URL.createObjectURL(new Blob([blob]))
            const link = document.createElement('a')
            link.href = url
            link.setAttribute('download', `choices.xlsx`)
            // 3. Append to html page
            document.body.appendChild(link)
            // 4. Force download
            link.click()
            // 5. Clean up and remove the link
            link.parentNode.removeChild(link)
        })
        .catch(reason => {
            console.log(reason)
        })
}

export const getExamsInstructionsFile = async (id, filename) =>{
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/exams-api/exams/${id}/instructions`
    return await fetch(endpoint, {headers: headers})
        .then(r => r.blob())
        .then((blob)=>{
            generateLinkAndDownload(blob, filename)
        })
}

export const getExamsQuestionsFile = async (id, filename) =>{
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/exams-api/exams/${id}/questions`
    return await fetch(endpoint, {headers: headers})
        .then(r => r.blob())
        .then((blob)=>{
            generateLinkAndDownload(blob, filename)
        })
}

const generateLinkAndDownload = (blob, filename) =>{
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename+".zip");
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
}
