import React from 'react';
import classNames from 'classnames';
import MUIDataTable from "mui-datatables";
import { IconButton } from '@material-ui/core';
import { Tooltip } from '@material-ui/core';
import { Icon } from '@material-ui/core';
import { MUIDatatableLabels } from '../../../../Utils/Table.utils';
import Badges from '../../../../components/Badges/Badges';

const TFGSubjectsTable = ({ subjects, onRowClick, onEditClick, onDeleteClick, onToggleVisibilityClick }) => {
  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    onRowClick,
    rowHover: !!onRowClick,
    textLabels: {
      ...MUIDatatableLabels,
      body: {
        ...MUIDatatableLabels.body,
        noMatch: 'No se han encontrado áreas temáticas'
      }
    },
    print: false,
    download: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'name',
      label: 'Nombre',
    },
    {
      name: 'assignedDegrees',
      label: 'Titulaciones',
      options: {
        customBodyRender: titulations => titulations.map(titul => titul.name).join(', ')
      }
    },
    {
      name: 'assignedManagers',
      label: 'Profesores',
      options: {
        customBodyRender: instructors => instructors.map(ins => ins.name).join(', ')
      }
    },
    {
      name: 'active',
      label: 'Visibilidad',
      options: {
        customBodyRender: active => {
          const data = active
            ? [{ label: 'Visible / Activa', className: 'green' }]
            : [{ label: 'Oculta / Inactiva', className: 'clear' }];

          return <Badges data={data} />
        }
      }
    },
    ...onEditClick && [
      {
        name: 'id',
        label: 'Acciones',
        options: {
          customBodyRenderLite: index => {
            const subject = subjects[index];
            if (!subject) return;

            const { id, active } = subject;
            return (
              <div className="actions">
                <Tooltip title="Editar" aria-label="edit">
                  <IconButton aria-label="edit" onClick={() => onEditClick(id)}>
                    <Icon className="edit-icon">
                      edit
                    </Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title={!active ? 'Mostrar / Activar' : 'Ocultar / Desactivar'} aria-label="hide">
                  <IconButton aria-label="hide" onClick={() => onToggleVisibilityClick(id)}>
                    <Icon className="hide-icon">
                      { !active ? 'visibility_off' : 'visibility' }
                    </Icon>
                  </IconButton>
                </Tooltip>
                <Tooltip title="Eliminar" aria-label="delete">
                  <IconButton aria-label="delete" onClick={() => onDeleteClick(id)}>
                    <Icon className="delete-icon">
                      delete
                    </Icon>
                  </IconButton>
                </Tooltip>
              </div>
            )
        }
        }
      }
    ]
  ]

  const TableClass = classNames('tfg-requests-table', { clickable: !!onRowClick });

  return (
    <div className="tfg-requests-table-container">
      <MUIDataTable
        className={TableClass}
        title="Áreas temáticas"
        columns={columns}
        options={options}
        data={subjects}
      />
    </div>
  )
}

export default TFGSubjectsTable
