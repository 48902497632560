import React from 'react';
import classNames from 'classnames';
import MUIDataTable from "mui-datatables";
import { MUIDatatableLabels } from '../../../Utils/Table.utils';

const InstancesTable = ({ title, columns, data, onRowClick, options }) => {
  const _options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    onRowClick,
    rowHover: !!onRowClick,
    textLabels: MUIDatatableLabels,
    print: false,
    download: false,
    filterType: 'textField',
    ...options
  };

  const TableClass = classNames('instances-table', { clickable: !!onRowClick });

  return (
    <div className="instances-table-container">
      <MUIDataTable
        className={TableClass}
        title={title ?? 'Listado de instancias'}
        columns={columns}
        options={_options}
        data={data}
      />
    </div>
  )
}

export default InstancesTable
