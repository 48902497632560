import React from 'react'
import { Modal, makeStyles } from '@material-ui/core'
import EnrollmentFormReadOnly from '../../Enrollments/EnrollmentsForms/EnrollmentFormReadOnly';
import ExtendedEnrollmentFormReadOnly from '../../Enrollments/EnrollmentsForms/ExtendedEnrollmentFormReadOnly';

const NewEnrollmentsFormReadOnly = ({ open, onHide, request }) => {
  const useStyles = makeStyles(() => ({
    modalScroll: {
      position: "absolute",
      overflow: "scroll",
      display: "flow",
      alignItems: "center",
      justifyContent: "center",
    },
  }));

  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={onHide}
      closeAfterTransition
      className={classes.modalScroll}
    >
      <>
        { request?.enrollmentType === 'R' && <EnrollmentFormReadOnly request={request} /> }
        { request?.enrollmentType === 'A' && <ExtendedEnrollmentFormReadOnly request={request} newService /> }
      </>
    </Modal>
  )
}

export default NewEnrollmentsFormReadOnly