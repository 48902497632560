import React, {useContext, useEffect, useState} from "react";
import {CircularProgress, InputLabel, Paper, Select} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import UserSelectedContext from "../../../Contexts/UserSelectedContext";
import Grid from "@material-ui/core/Grid";
import Typography from "@material-ui/core/Typography";
import MenuItem from "@material-ui/core/MenuItem";

const GradesInfoContainer = (props) =>{

    const userSelected = useContext(UserSelectedContext)
    const [loading,setLoading] = useState(false)
    const [convocatories,setConvocatories] = useState([])
    const [selectedConvocatory,setSelectedConvocatory] = useState("1")
    const [grades,setGrades] = useState([])
    const columns =[
        {
            label: 'Id de asignatura',
            name: "courseId"
        },
        {
            label: "Asignatura",
            name: "courseName"
        },
        {
            label: "Año Academico",
            name: "term"
        },
        {
            label: "Convocatoria",
            name: "convocatory"
        },
        {
            label: "Nota",
            name: "grade"
        },
        {
            label: "Nota cualitativa",
            name: "gradeText"
        }
    ]
    const options={
        selectableRows: "none",
        print: false
    }

    const fetchConvocatories = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/convocatories`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return ["1","2","3"];
            });
        setConvocatories(response)
        setLoading(false);
    }

    const fetchGrades = async (convocatory) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/grades/${userSelected}/convocatory/${convocatory}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return grades;
            });
        setGrades(response)
        setLoading(false);
    }

    useEffect(() => {
        fetchConvocatories()
    }, [])

    return (
        <React.Fragment>
            <Paper elevation={3} style={{margin:"25px"}}>
                <Grid container spacing={2}>
                    <Grid item>
                        <Typography variant={"h4"}>Selecciona la convocatoria</Typography>
                    </Grid>
                    <Grid item>
                        <InputLabel id={"convocatorySelect"}>
                            Convocatoria
                        </InputLabel>
                        <Select labelId={"convocatorySelect"}
                                id={"SelectCourse"}
                                value={selectedConvocatory} onChange={
                                    (event) => {
                                        setSelectedConvocatory(event.target.value)
                                        fetchGrades(event.target.value)
                                    }
                                }>
                            { convocatories.map((value, index) => {
                                return <MenuItem key={index} value={value}>{value}</MenuItem>
                            })}
                        </Select>
                    </Grid>
                </Grid>
            </Paper>
            <Paper elevation={3} style={{margin:"25px"}}>
                { loading === true && <CircularProgress/>}
                { loading === false &&
                    <MUIDataTable
                        columns={columns}
                        options={options}
                        data={grades}
                        title={"Notas"}
                    />
                }
            </Paper>
        </React.Fragment>
    )
}

export default GradesInfoContainer