import {Card, CardContent, CardHeader, Grid, LinearProgress, makeStyles, TextField} from "@material-ui/core";
import React, {useEffect, useState} from "react";
import {getUserData} from "../../../services/Enrollments/UserDataService";

const EnrollmentFormUserData = ({externalUserId = null}) => {

    const [loading, setLoading] = useState(false)
    const [userData, setUserData] = useState({})

    const fetchUserData = async () => {
        setLoading(true)
        let userData = await getUserData(externalUserId)
        setUserData(userData)
        setLoading(false)
    }

    useEffect(()=>{
        fetchUserData()
    },[])

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles();

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"DATOS PERSONALES DEL ESTUDIANTE"}
                subheader={"Por favor, indique sus datos en mayúsculas, con todos los signos ortográficos, acentos, guiones, diéresis, etc."}
            />
            <CardContent>
                { loading
                    ? <LinearProgress/>
                    : <Grid container spacing={2}>
                        <Grid item md={6}>
                            <TextField
                                label={"NOMBRE"}
                                value={userData.givenName}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled/>
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label={"APELLIDOS"}
                                value={userData.familyName}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled/>
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label={"TIPO DOCUMENTO IDENTIFICACIÓN PERSONAL"}
                                value={userData.idType}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label={"Nº IDENTIFICACIÓN"}
                                value={userData.externalId}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label={"DOMICILIO DURANTE EL CURSO"}
                                value={userData.address}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                label={"LOCALIDAD"}
                                value={userData.city}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                label={"PROVINCIA"}
                                value={userData.state}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={4}>
                            <TextField
                                label={"CP"}
                                value={userData.zipCode}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label={"TELÉFONO MÓVIL"}
                                value={userData.mobilePhone}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={6}>
                            <TextField
                                label={"TELÉFONO FIJO"}
                                value={userData.homePhone}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                        <Grid item md={12}>
                            <TextField
                                label={"CORREO ELECTRÓNICO"}
                                value={userData.email}
                                variant={"outlined"}
                                fullWidth
                                required
                                disabled />
                        </Grid>
                    </Grid>
                }
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormUserData