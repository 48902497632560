import React, { useEffect, useState, useRef } from 'react';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid, Divider } from '@material-ui/core';
import InputText from '../../../components/Inputs/InputText';
import StudentInformation from './StudentInformation';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { useNotification } from '../../../Utils/hooks';
import { uploadStudentInstanceFile, postInstanceRequest } from '../../../services/Instances/InstancesService';

const InstanceRequestDialog = ({ visible, user, instance, onHide, onSuccess }) => {
  const [file, setFile] = useState(null);
  const [userText, setUserText] = useState('');
  const notification = useNotification();
  const inputRef = useRef(null);

  useEffect(() => {
    if (!visible) {
      setUserText('')
      setFile(null)
    };
  }, [visible]);

  const uploadFile = async (id, file) => {
    try {
      setApplicationLoading(true);
      await uploadStudentInstanceFile(id, file);
    } catch {
      notification('error', 'Ha habido un error al subir el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onSubmit = async () => {
    if (!instance || !file) return;

    try {
      setApplicationLoading(true);
      const request = { templateId: instance.id, userText };
      const result = await postInstanceRequest(instance.id, userText);
      if (file) await uploadFile(result.id, file);
      onSuccess && onSuccess(request);
      notification('success', 'La instancia ha sido creada correctamente');
    } catch {
      notification('error', 'Ha habido un error al crear la instacia');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) setFile(file);
    }
  }

  return (
    <Dialog
      className="instance-request-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={onSubmit}>
        <DialogTitle>{instance?.name}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div className="upload-file">
                <p>
                  <b>Fichero actual:</b><br />
                  {file?.name ? file.name :  'No hay ningún fichero adjunto'}
                </p>
                <Button
                  variant="contained"
                  color="primary"
                  component="label"
                >
                  {`${file?.name ? 'Reemplazar': 'Subir'} fichero`}
                  <input
                    onChange={onFilesChange}
                    ref={inputRef}
                    type="file"
                    hidden
                  />
                </Button>
              </div>
            </Grid>
            <StudentInformation user={user} />
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <InputText
                name="userText"
                value={userText}
                onChange={e => setUserText(e.target.value)}
                label="Comentario adicional"
                placeholder="Opcional"
                InputLabelProps={{ shrink: true }}
                inputProps={{ maxLength: 200 }}
                rows={4}
                multiline
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions className="actions">
          <Button variant="contained" color="primary" onClick={onSubmit} disabled={!file} /* type="submit" */ >
            Crear solicitud
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InstanceRequestDialog
