import React from "react";
import Grid from "@material-ui/core/Grid";
import AddAssignationComponent from "./AddAssignationComponent";
import AssignationComponent from "./AssignationComponent";
import Box from "@material-ui/core/Box";
import MenuItem from "@material-ui/core/MenuItem";

const AssignationsList = ({assignationType,assignationText,templates,assignations}) => {

    function templatesOptions(){
        return (
            templates.map((value, index) => {
                return <MenuItem value={value.id} key={index}>{value.reference}</MenuItem>
            })
        )
    }

    return (
        <Box style={{padding: "25px"}}>
            <Grid container
                  spacing={2}
                  direction={"row"}
                  alignItems={"stretch"}>
                <Grid item sm={12}>
                    <AddAssignationComponent assignationType={assignationType} assignationText={assignationText} templatesList={templatesOptions()} />
                </Grid>
            </Grid>
            <Grid container
                  spacing={2}
                  direction={"column"}
                  alignItems={"stretch"}>
                { assignations.map((value, index) => {
                    return (
                        <React.Fragment>
                            <Grid item key={index}>
                                <AssignationComponent assignation={value} templatesList={templatesOptions()}/>
                            </Grid>
                        </React.Fragment>
                    )
                })}
            </Grid>
        </Box>
    )
}

export default AssignationsList