import React, {useContext, useEffect, useState} from "react";
import {Button, CardActionArea, CardContent, CardHeader, CircularProgress, Paper} from "@material-ui/core";
import Card from "@material-ui/core/Card";
import Avatar from "@material-ui/core/Avatar";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import UserSelectedContext from "../../../Contexts/UserSelectedContext";
import CardActions from "@material-ui/core/CardActions";

const UserInfoContainer = ({goToTab}) => {

    const userSelected = useContext(UserSelectedContext)
    const [userInfo,setUserInfo] = useState({
        dni: "0000000A",
        name: "Alumno",
        surnames: "A",
        email: "alumno@ucavila.es",
        mobilePhone: "884792993"
    })
    const [loading,setLoading] = useState(false)

    const fetchInfo = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/user/${userSelected}`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return userInfo;
            });
        setUserInfo(response)
        setLoading(false);
    }

    useEffect(() => {
        fetchInfo()
    }, [])

    return (
        <React.Fragment>
            <Paper elevation={3} style={{margin:"25px"}}>
                { loading === true && <CircularProgress />}
                { loading === false &&
                    <Card>
                        <CardHeader
                            avatar={
                                <Avatar>
                                    {userInfo.name.charAt(0)}
                                </Avatar>
                            }
                            title={userInfo.name}
                            subheader={userInfo.dni}
                        />
                        <CardContent>
                            <Grid container alignItems={"center"} spacing={2}>
                                <Grid item>
                                    <TextField id={"name-text-field"} label={"Nombre"} variant="outlined" disabled value={userInfo.name}/>
                                </Grid>
                                <Grid item>
                                    <TextField id={"surname-text-field"} label="Apellidos" variant="outlined" disabled value={userInfo.surnames}/>
                                </Grid>
                                <Grid item>
                                    <TextField id={"id-text-field"} label="DNI" variant="outlined" disabled value={userInfo.dni}/>
                                </Grid>
                                <Grid item>
                                    <TextField id={"id-text-field"} label="Email" variant="outlined" disabled value={userInfo.email}/>
                                </Grid>
                                <Grid item>
                                    <TextField id={"id-text-field"} label="Telefono" variant="outlined" disabled value={userInfo.mobilePhone}/>
                                </Grid>
                            </Grid>
                        </CardContent>
                        <CardActions>
                            <Button variant={"contained"} color={"primary"} onClick={() => goToTab(4)}>Consultar Interacciones</Button>
                        </CardActions>
                    </Card>
                }
            </Paper>
        </React.Fragment>
    )
}

export default UserInfoContainer