import React, { useState } from "react";
import { Container, Grid } from "@material-ui/core";
import NewEnrollmentFormTerm from "../NewEnrollmentsForms/NewEnrollmentFormTerm";
import EnrollmentForm from "../../Enrollments/EnrollmentsForms/EnrollmentForm";
import ExtendedEnrollmentForm from "../../Enrollments/EnrollmentsForms/ExtendedEnrollmentForm";
import "./NewEnrollmentSwitch.scss";

const NewEnrollmentSwitch = ({ onHide }) => {
  const [enrollmentTerm, setEnrollmentTerm] = useState(null);
  const [enrollmentType, setEnrollmentType] = useState(null);

  return (
    <div className="enrollment-type-selector">
      <Container className="enrollment-term-switch">
        <Grid container spacing={2}>
          <Grid item md={12}>
            <NewEnrollmentFormTerm
              enrollmentTerm={enrollmentTerm}
              setEnrollmentTerm={setEnrollmentTerm}
              setEnrollmentType={setEnrollmentType}
            />
          </Grid>
        </Grid>
      </Container>
      {enrollmentType === "R" && (
        <EnrollmentForm
          enrollmentTerm={enrollmentTerm}
          returnToIndex={onHide}
        />
      )}
      {enrollmentType === "A" && (
        <ExtendedEnrollmentForm
          enrollmentTerm={enrollmentTerm}
          returnToIndex={onHide}
        />
      )}
    </div>
  );
};

export default NewEnrollmentSwitch;
