export const confirmOptions = {
  confirmationText: 'Confirmar',
  cancellationText: 'Cancelar',
  confirmationButtonProps: { variant: 'contained', color: 'primary' },
  cancellationButtonProps: { variant: 'contained', color: 'secondary' },
  dialogProps: { className: 'confirmation-dialog' }
};

const notificationsAlign = {
  horizontal: 'right',
  vertical: 'top'
};

export const snackbarOptions = {
  maxSnack: 4,
  anchorOrigin: notificationsAlign,
  classes: {
    variantSuccess: 'notification success',
    variantError: 'notification error',
    variantWarning: 'notification warning',
    variantInfo: 'notification info',
  }
}