import React from 'react';
import { Icon, IconButton, Tooltip } from '@material-ui/core';

export const generateRequestsTableActions = (student, instructor, coordinator, secr, request, onDownload, onAccept, onReject, onCancel, onEditClick) => {
  const { id, status } = request;
  let actions = [];

  const AcceptButton = (
    <Tooltip title="Aceptar" aria-label="accept">
      <IconButton aria-label="accept" onClick={() => onAccept(id)}>
        <Icon className="accept-icon">
          done
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const RejectButton = (
    <Tooltip title="Rechazar" aria-label="reject">
      <IconButton aria-label="reject" onClick={() => onReject(id)}>
        <Icon className="reject-icon">
          close
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const CancelButton = (
    <Tooltip title="Cancelar" aria-label="cancel">
      <IconButton aria-label="cancel" onClick={() => onCancel(id)}>
        <Icon className="cancel-icon">
          close
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const DownloadButton = (
    <Tooltip title="Descargar" aria-label="download">
      <IconButton aria-label="download-icon" onClick={() => onDownload(id)}>
        <Icon className="download-icon">
          download
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const EditButton = (
    <Tooltip title="Editar" aria-label="download">
      <IconButton aria-label="edit-icon" onClick={() => onEditClick(request)}>
        <Icon className="edit-icon">
          edit
        </Icon>
      </IconButton>
    </Tooltip>
  )

  if (status === 'ACCEPTED') actions.push(DownloadButton);
  
  if (student && status === 'OPEN') {
    actions.push(CancelButton);
  };
  
  // 28/04/2023 - Activate edit even if the request is accepted
  if (onEditClick && coordinator && ['OPEN', 'ACCEPTED'].includes(status)) {
    actions.push(EditButton);
  }

  // 18/04/2023 - Removed instructor actions, moved to coordinators
  if (coordinator && status === 'OPEN') {
    actions.push(AcceptButton);
    actions.push(RejectButton);
  }

  return <div className="actions">
    {actions}
  </div>
}