import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import {Button, CircularProgress, Container, styled, Typography} from "@material-ui/core";
import MUIDataTable, {debounceSearchRender} from "mui-datatables";
import {Alert} from "@material-ui/lab";
import ExamsManagerExamManagement from "./ExamsManagerExamManagement";
import ExamManagerModal from "./ExamManagerModal";
import { dateUTCToLocale } from '../../../Utils/Date';

const ExamsManagerView = () => {

    const [loading,setLoading] = useState(false)
    const [exams,setExams] = useState([])
    const [examManaged,setExamManaged] = useState()
    const [modal,setModal] = useState(false)

    const columns =[
        {
            label: 'Id',
            name: "id"
        },
        {
            label: "Convocatoria",
            name: "termId"
        },
        {
            label: "Asignatura",
            name: "courseId"
        },
        {
            label: "Fecha",
            name: "date",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Opcion",
            name: "option",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}>
                        {value}
                    </Button>
                }
            }
        },
        {
            label: "Estado",
            name: "state",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {

                    switch (value){
                        case "NOT_UPLOADED":
                            return <SoftRedButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                No Subido
                            </SoftRedButton>
                        case "UPLOADED":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Subido
                            </WarningButton>
                        case "UPLOAD_WITHOUT_FILES":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Entregado sin ficheros
                            </WarningButton>
                        case "UPLOAD_INCORRECT":
                            return <SoftRedButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Subida incorrecta
                            </SoftRedButton>
                        case "UPLOAD_CORRECT":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Subida correcta
                            </WarningButton>
                        case "REVISION_PENDING":
                            return <WarningButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Revision pendiente
                            </WarningButton>
                        case "REVISED_INCORRECT":
                            return <SoftRedButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Revision incorrecta
                            </SoftRedButton>
                        case "REVISED_CORRECT":
                            return <SoftGreenButton
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                Revision correcta
                            </SoftGreenButton>
                        default:
                            return <Button
                                disableTouchRipple
                                size={"small"}
                                variant={"outlined"}
                                color={"primary"}>
                                {value}
                            </Button>
                    }
                }
            }
        },
        {
            label: "Visible",
            name: "visible",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}>
                        { value===true ? "Si" : "No"}
                    </Button>
                }
            }
        },
        {
            label: "Online",
            name: "online",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        disableTouchRipple
                        size={"small"}
                        variant={"outlined"}
                        color={"primary"}>
                        { value===true ? "Si" : "No"}
                    </Button>
                }
            }
        },
        {
            label: "Sede por defecto",
            name: "defaultHeadquarter",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Typography variant={"caption"}>{value!=null ? value : "Sin sede por defecto"}</Typography>
                },
                display: false
            }
        },
        {
            label: "Duracion",
            name: "duration",
            options: {
                display: false
            }
        },
        {
            label: "Inicio Fecha Inscripcion",
            name: "startInscriptionDate",
            options: {
                display: false,
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "Fin Fecha Inscripcion",
            name: "endInscriptionDate",
            options: {
                display: false,
                customBodyRender: date => dateUTCToLocale(date)
            }
        }
    ]
    const options={
        selectableRows: "none",
        responsive: "stacked",
        print: false,
        onRowClick: (rowData,rowMeta) => {
            let examSelected=exams.find(value => value.id===rowData[0])
            setExamManaged(examSelected)
        },
        customSearchRender: debounceSearchRender(700),
    }

    const SoftGreenButton = styled(Button)({
        background: '#52b788',
        color: 'white',
    });

    const WarningButton = styled(Button)({
        background: '#f6bd60',
        color: 'white',
    });

    const SoftRedButton = styled(Button)({
        background: '#f28482',
        color: 'white',
    });

    const handleOpenModal = () => {
        setModal(true)
    }

    const handleCloseModal = (refresh = false) => {
        setModal(false)
        if(refresh){
            setExamManaged(null)
            fetchExams()
        }
    }

    const fetchExams = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/exams-api/exams`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        id: 0,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "A",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "NOT_UPLOADED"
                    },
                    {
                        id: 1,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "B",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "UPLOADED"
                    },
                    {
                        id: 2,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "C",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "UPLOAD_INCORRECT"
                    },
                    {
                        id: 3,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "D",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "UPLOAD_CORRECT"
                    },
                    {
                        id: 4,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "E",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "REVISION_PENDING"
                    },
                    {
                        id: 5,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "F",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "REVISED_INCORRECT"
                    },
                    {
                        id: 6,
                        courseId: "Curso001",
                        date: "12/12/12",
                        duration: "",
                        startInscriptionDate: "12/12/12",
                        endInscriptionDate: "21/21/21",
                        option: "G",
                        termId: 0,
                        visible: true,
                        online: false,
                        defaultHeadquarter: null,
                        state: "REVISED_CORRECT"
                    },
                ];
            });
        setExams(response)
        setLoading(false);
    }

    useEffect(()=> {
        fetchExams()
    },[])

    return (
        <Container maxWidth={false}>
            <Grid container
                  direction={"column"}
                  spacing={3}
                  style={{padding:"25px"}}>
                <Grid item>
                    { loading
                        ? <CircularProgress/>
                        : <MUIDataTable
                            columns={columns}
                            options={options}
                            data={exams}
                            title={"Examenes"}/>
                    }
                </Grid>
                <Grid item>
                    { examManaged != null
                        ? <ExamsManagerExamManagement fetchExams={fetchExams} exam={examManaged} setExam={setExamManaged} openModal={handleOpenModal} />
                        : <Alert severity={"info"}>Selecciona un examen para gestionarlo</Alert>
                    }
                </Grid>
                <ExamManagerModal  fetchExams={fetchExams}  modal={modal} handleClose={handleCloseModal} examSelected={examManaged} />
            </Grid>
        </Container>
    )
}

export default ExamsManagerView
