import {
    Card,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    Grid, makeStyles,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import React from "react";

const EnrollmentFormGeneralDiscountOptionData =
    ({

         generalDiscountOption,
         setGeneralDiscountOption,

         minusDiscountOption,
         setMinusDiscountOption,

        synchronousDiscountOption,
        setSynchronousDiscountOption,

         discountMHOption,
         setDiscountMHOption,

         discountUcavOption,
         setDiscountUcavOption,

         discountConvOption,
         setDiscountConvOption,

         discountConvText,
         setDiscountConvText,

         discountOtherOption,
         setDiscountOtherOption,

         discountOtherText,
         setDiscountOtherText
    }) => {

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles();

    const handleChangeGeneralDiscount = (event) => {
        if(setGeneralDiscountOption) setGeneralDiscountOption(event.target.value)
        else console.warn("Read-Only Component")
    }

    const handleChangeMinusDiscount = (event) => {
        if(setMinusDiscountOption) setMinusDiscountOption(event.target.value)
        else console.warn("Read-Only Component")
    }

    const handleChangeSynchronousDiscount = (event) => {
        if(setSynchronousDiscountOption) setSynchronousDiscountOption(event.target.value)
        else console.warn("Read-Only Component")
    }

    const handleChangeMHDiscount = (event) => {
        if(setDiscountMHOption) setDiscountMHOption(event.target.checked)
        else console.warn("Read-Only Component")
    }

    const handleChangeUcavDiscount = (event) => {
        if(setDiscountUcavOption) setDiscountUcavOption(event.target.checked)
        else console.warn("Read-Only Component")
    }

    const handleChangeConvDiscount = (event) => {
        if(setDiscountConvOption) setDiscountConvOption(event.target.checked)
        else console.warn("Read-Only Component")
    }

    const handleChangeConvText = (event) => {
        if(setDiscountConvText) setDiscountConvText(event.target.value)
        else console.warn("Read-Only Component")
    }

    const handleChangeOtherDiscount = (event) => {
        if(setDiscountOtherOption) setDiscountOtherOption(event.target.checked)
        else console.warn("Read-Only Component")
    }

    const handleChangeOtherText = (event) => {
        if(setDiscountOtherText) setDiscountOtherText(event.target.value)
        else console.warn("Read-Only Component")
    }

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"SOLICITUD DE DESCUENTOS"}
                subheader={"Cualquier tipo de descuento regulado en normativa académica o en virtud de convenio y aplicable a la matrícula debe ser acreditado documentalmente y aportarse con la matrícula. Los descuentos se aplicarán de forma individualizada sobre el importe de los créditos."}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={4}>
                        <RadioGroup name={"generalDiscountOption"} value={generalDiscountOption} onChange={handleChangeGeneralDiscount}>
                            <FormControlLabel value={"NO_DISCOUNT"} control={<Radio />} label={"Sin Familia Numerosa"}/>
                            <FormControlLabel value={"DISCOUNT_FNCG"} control={<Radio />} label={"Familia Numerosa. Categoría General"}/>
                            <FormControlLabel value={"DISCOUNT_FNCE"} control={<Radio />} label={"Familia Numerosa. Categoría Especial"}/>
                        </RadioGroup>
                    </Grid>
                    <Grid item md={4}>
                        <RadioGroup name={"minusDiscountOption"} value={minusDiscountOption} onChange={handleChangeMinusDiscount}>
                            <FormControlLabel value={"NO_MINUS"} control={<Radio />} label={"Sin Minusvalía o inferior al 33%"}/>
                            <FormControlLabel value={"MINUS_33"} control={<Radio />} label={"Minusvalía entre el 33% y el 66%"}/>
                            <FormControlLabel value={"MINUS_66"} control={<Radio />} label={"Minusvalía mayor del 66%"}/>
                        </RadioGroup>
                    </Grid>
                    <Grid item md={4}>
                        <RadioGroup name={"otherDiscounts"}>
                            <FormControlLabel
                                value={"discountMH"}
                                control={
                                    <Checkbox
                                        checked={discountMHOption}
                                        onChange={handleChangeMHDiscount}
                                    />
                                }
                                label={"Descuento por Matrícula de Honor"}
                            />
                            <FormControlLabel
                                value={"discountUcav"}
                                control={
                                    <Checkbox
                                        checked={discountUcavOption}
                                        onChange={handleChangeUcavDiscount}
                                    />
                                }
                                label={"Más de un miembro de la unidad familiar estudiando en la UCAV"}
                            />
                            <FormControlLabel
                                value={"discountConv"}
                                control={
                                    <Checkbox
                                        checked={discountConvOption}
                                        onChange={handleChangeConvDiscount}
                                    />
                                }
                                label={"Convenios"}
                            />
                            <TextField
                                label={"Convenio"}
                                variant={"filled"}
                                fullWidth
                                error={!discountConvText && discountConvOption}
                                value={discountConvText}
                                helperText={"Indique convenio aplicable"}
                                onChange={handleChangeConvText}
                            />
                            <FormControlLabel
                                value={"discountOther"}
                                control={
                                    <Checkbox
                                        checked={discountOtherOption}
                                        onChange={handleChangeOtherDiscount}
                                    />
                                }
                                label={"Otros Descuentos"}
                            />
                            <TextField
                                label={"Descuento"}
                                variant={"filled"}
                                fullWidth
                                error={!discountOtherText && discountOtherOption}
                                value={discountOtherText}
                                helperText={"Indique descuento aplicable"}
                                onChange={handleChangeOtherText}
                            />
                        </RadioGroup>
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormGeneralDiscountOptionData