import React from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { sleep } from '../../Utils/utils';
import './ApplicationLoading.scss';

let loadingRef = null;

const TRANSITION_DELAY = 650;

const setLoadingComponent = comp => loadingRef = comp;

export const setApplicationLoading = async state => {
  if (loadingRef) {
    if (state) {
      loadingRef.style.visibility = 'visible';
      loadingRef.style.opacity = '1';
    } else {
      loadingRef.style.opacity = '0';
      await sleep(TRANSITION_DELAY);
      loadingRef.style.visibility = 'hidden';
    }
  }
}

const ApplicationLoading = () => {
  return <Backdrop
    className='application-loading'
    style={{ transition: `${TRANSITION_DELAY}ms ease opacity` }}
    ref={ref => setLoadingComponent(ref)}
    open
  >
    <div className="spinner-container">
      <CircularProgress
        className="base-spinner"
        variant="determinate"
        size="3.125rem"
        thickness={2}
        value={100}
      />
      <CircularProgress
        className="main-spinner"
        variant="indeterminate"
        disableShrink
        size="3.125rem"
        thickness={2}
      />
    </div>
  </Backdrop>
}

export default ApplicationLoading;
