import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import InputText from '../../../../components/Inputs/InputText';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import { useNotification } from '../../../../Utils/hooks';
import { rejectTFGDefenseRequest } from '../../../../services/TFG/TFGService';

const TFGDefenseRejectDialog = ({ reject, visible, onHide, onRejectSuccess }) => {
  const [rejectReason, setRejectReason] = useState('');
  const notification = useNotification();

  useEffect(() => {
    if (!visible) setRejectReason('');
  }, [visible]);

  const onReject = async () => {
    try {
      setApplicationLoading(true);
      const result = await rejectTFGDefenseRequest(reject.requestId, reject.id, rejectReason);
      onRejectSuccess(result);
    } catch {
      notification('error', 'Ha habido un error al rechazar la solicitud');
    } finally {
      setApplicationLoading(false);
    }
  }

  return (
    <Dialog
      className="reject-request-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Rechazar solicitud</DialogTitle>
      <DialogContent>
        <div className="reject-dialog">
          <p>Atención, esta acción rechazará la solicitud de defensa seleccionada, puedes adjuntar una razón para el alumno en el campo inferior</p>
          <InputText
            name="reject-reason"
            label="Razón"
            value={rejectReason}
            onChange={e => setRejectReason(e.target.value)}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={onReject} >
          Rechazar solicitud
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TFGDefenseRejectDialog