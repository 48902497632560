import {useEffect, useState} from "react";
import {deleteChoice, getChoice, postChoice} from "../../services/Exams/ChoiceService";
import {getExams} from "../../services/Exams/ExamsService";

const ExamSelectorLogic = (courseId,userSelected = "") => {

    const [loading, setLoading] = useState(false)
    const [exams, setExams] = useState([])
    const [choice, setChoice] = useState({})

    const fetchExams = async () => {
        setLoading(true);
        let exams = await getExams(courseId)
        setExams(exams)
        setLoading(false);
    }

    const fetchChoice = async () => {
        setLoading(true)
        let choice = await getChoice(courseId,userSelected)
        setChoice(choice)
        setLoading(false)
    }

    const postChoiceAction = async (examId, headquarterId, userId = "") => {
        setLoading(true);
        let choice = await postChoice(examId,headquarterId,userId)
        setChoice(choice)
        setLoading(false);
    }

    const deleteChoiceAction = async (choiceId) => {
        setLoading(true);
        await deleteChoice(choiceId)
        setChoice({})
        setLoading(false);
    }

    useEffect(() => {
        fetchExams()
    }, [])

    useEffect(() => {
        fetchChoice()
    }, [exams])

    return {exams,loading,choice,postChoiceAction,deleteChoiceAction}

}

export default ExamSelectorLogic