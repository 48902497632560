import React, {useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {CircularProgress} from "@material-ui/core";

const StudentsList = (props) => {

    const [loading,setLoading] = useState(false)
    const [list,setList] = useState([])

    const columns =[
        {
            label: 'Id',
            name: "userId"
        },
        {
            label: "Nombre",
            name: "name"
        },
        {
            label: "Apellidos",
            name: "surname"
        },
        {
            label: "Id de curso",
            name: "courseId"
        },
        {
            label: "Curso",
            name: "courseName"
        }
    ]
    const options={
        selectableRows: "none",
        print: false
    }

    const fetchList = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/student-record-api/users/list`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return ["1","2","3"];
            });
        setList(response)
        setLoading(false);
    }

    useEffect(()=> {
        fetchList()
    },[])

    return (
        <React.Fragment>
            { loading === true
                ? <CircularProgress />
                :
                <MUIDataTable
                    columns={columns}
                    options={options}
                    data={list}
                    title={"Alumnos"}
                />
            }
        </React.Fragment>
    )
}

export default StudentsList
