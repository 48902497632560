import {Button, CircularProgress} from "@material-ui/core";
import React, {useState} from "react";
import {getRequestPDF} from "../../../services/Enrollments/RequestsService";

const EnrollmentsFormDownloadPDF = ({requestId}) =>{

    const [loading,setLoading] = useState(false)

    const fetchPDFFile = async (requestId) => {
        setLoading(true)
        await getRequestPDF(requestId)
        setLoading(false)
    }

    return (
        <React.Fragment>
            { loading
                ? <CircularProgress/>
                : <Button variant={"contained"} color={"primary"} fullWidth onClick={() => fetchPDFFile(requestId)}>
                    Descargar PDF
                </Button>
            }
        </React.Fragment>
    )
}

export default EnrollmentsFormDownloadPDF