import React, { useState, useCallback } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TFGRequestsTable from "./TFGRequests/TFGRequestsTable";
import TFGRequest from "./TFGRequest/TFGRequest";
import TFGDefense from "./TFGDefense/TFGDefense";

const TFGStudent = () => {
  const [tab, setTab] = useState(0);
  const [hasTFGs, setHasTFGs] = useState(false);

  const onRequestsChange = useCallback(requests => {
    setHasTFGs(requests.some(req => req.status === "ACCEPTED"));
  }, []);

  const handleTabChange = (_e, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          <Tab label="Mis solicitudes" />
          <Tab label="Crear solicitud de área temática" />
          { hasTFGs && <Tab label="Crear solicitud de defensa" /> }
        </Tabs>
      </AppBar>
      <div className="tfg">
        { tab === 0 && <TFGRequestsTable student onRequestsChange={onRequestsChange} /> }
        { tab === 1 && <TFGRequest onSuccess={() => setTab(0)} /> }
        { tab === 2 && hasTFGs && <TFGDefense role="STUDENT" /> }
      </div>
    </>
  );
};

export default TFGStudent;
