import React, {useContext} from "react";
import RoleContext from "../../Contexts/RoleContext";
import ErrorPage from "../Error/ErrorPage";
import InstancesTypes from "./InstancesTypes";
import InstancesStudent from "./InstancesStudent";
import InstancesCoord from "./InstancesCoord";
import InstancesSecr from "./InstancesSecr";

const EnrollmentsMain = () => {
  const roleContext = useContext(RoleContext);

  function getRoleView() {
    switch (roleContext.role){
      case "ROLE_ADMIN":
        return <InstancesTypes />
      case "ROLE_STUDENT":
        return <InstancesStudent />
      case "ROLE_COORD":
        return <InstancesCoord />
      case "ROLE_SECR":
        return <InstancesSecr />
      default:
        return <ErrorPage message="No tienes permisos para ver esta pagina" />
    }
  };

  return (
    <React.Fragment>
      { getRoleView() }
    </React.Fragment>
  );
}

export default EnrollmentsMain;