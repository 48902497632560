import React, { useState } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import InstancesOnBehalf from "./InstancesOnBehalf";
import InstancesInstructor from "./InstancesInstructor";

const InstancesCoord = () => {
  const [tab, setTab] = useState(0);

  const handleTabChange = (_e, newValue) => {
    setTab(newValue);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          <Tab label="Instancias" />
          <Tab label="Crear solicitud" />
        </Tabs>
      </AppBar>
      <div className="tfg">
        { tab === 0 && <InstancesInstructor /> }
        { tab === 1 && <InstancesOnBehalf /> }
      </div>
    </>
  );
};

export default InstancesCoord;
