import React, {useEffect, useState} from "react";
import Grid from "@material-ui/core/Grid";
import { InputLabel, Select, TextField } from "@material-ui/core";
import FormControl from "@material-ui/core/FormControl";
import Button from "@material-ui/core/Button";
import MenuItem from "@material-ui/core/MenuItem";
import {getAllTerms} from "../../../services/Registry/TermsRegistryService";

const NewRegistryOptionalParamsContainer = (props) => {
    const [terms, setTerms] = useState([]);
    const  fetchAllTerms= async ()=>{
        setTerms(await getAllTerms())
    }
    useEffect(()=>{
        fetchAllTerms()
    },[])
    return (
        <React.Fragment>
            <Grid container spacing={2} justify={"flex-end"} style={{padding:"15px"}}>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <TextField label={"Destino"} value={props.receiver} onChange={event => {props.setReceiver(event.target.value)}} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <TextField label={"Tipo de documento"} value={props.documentType} onChange={event => {props.setDocumentType(event.target.value)}}/>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <TextField type={"file"} label={"Documento"}/>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <InputLabel id={"termLabel"}>
                            Año académico
                        </InputLabel>
                        <Select labelId={"termLabel"}
                                value={props.term}
                                onChange={event => props.setTerm(event.target.value)}>
                            {terms.map( term => {
                                    return <MenuItem value={term.id}>{term.name}</MenuItem>
                                }
                            )}
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item md={12}>
                    <FormControl fullWidth>
                        <TextField label={"Motivo"} multiline value={props.text} onChange={event => props.setText(event.target.value)} />
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <Button variant={"contained"} onClick={props.PreviousStep }>Atras</Button>
                    </FormControl>
                </Grid>
                <Grid item md={6}>
                    <FormControl fullWidth>
                        <Button color={"primary"} variant={"contained"} onClick={props.sendEntry }>Crear</Button>
                    </FormControl>
                </Grid>
            </Grid>
        </React.Fragment>
    )
}

export default NewRegistryOptionalParamsContainer
