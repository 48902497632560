import React, { useEffect, useState } from "react";
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  LinearProgress,
  makeStyles,
} from "@material-ui/core";
import InputAutoComplete from '../../../components/Inputs/InputAutoComplete';
import { getAvailableEnrollments } from "../../../services/Enrollments/NewEnrollmentsService";
import { required } from "../../../Utils/utils";
import { useNotification } from "../../../Utils/hooks";

const NewEnrollmentFormTerm = ({ enrollmentTerm, setEnrollmentTerm, setEnrollmentType }) => {
  const [loading, setLoading] = useState(false);
  const [terms, setTerms] = useState([]);
  const notification = useNotification();

  const fetchTerms = async () => {
    try {
      setLoading(true);
      let result = await getAvailableEnrollments().then(
        res => res.map(term => ({ ...term, label: term.name, value: term.id }))
      );
      if (!result?.length) throw new Error();
      setTerms(result);
    } catch {
      notification('error', 'No se han encontrado convocatorias de matrícula abiertas');
    } finally {
      setLoading(false);
    }
  };

  // Retrieve terms and grades options
  useEffect(() => {
    fetchTerms();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (enrollmentTerm) {
      const term = terms.find(term => term.id === enrollmentTerm);
      if (term) setEnrollmentType(term.type);
    } else setEnrollmentType(null);
  }, [terms, enrollmentTerm, setEnrollmentType]);

  const useStyles = makeStyles(() => ({
    header: {
      backgroundColor: "#003F62",
      color: "#FFF",
    },
    subheader: {
      color: "#FFF",
    },
  }));

  const classes = useStyles();

  return (
    <Card>
      <CardHeader
        classes={{
          root: classes.header,
          subheader: classes.subheader,
        }}
        title="CONVOCATORIA"
        subheader={
          "Por favor, seleccione la convocatoria para la matrícula"
        }
      />
      <CardContent>
        <Grid container spacing={2}>
          <Grid item md={12}>
            {loading ? (
              <LinearProgress />
            ) : (
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <InputAutoComplete
                    name="enrollment-term"
                    value={enrollmentTerm}
                    onChange={setEnrollmentTerm}
                    options={terms}
                    label="Convocatoria"
                    loading={loading}
                    rules={required}
                  />
                </Grid>
              </Grid>
            )}
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default NewEnrollmentFormTerm;
