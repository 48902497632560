import {
    Avatar,
    Button,
    Card,
    CardActions,
    CardContent,
    CardHeader,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    MenuItem,
    Select, styled
} from "@material-ui/core";
import React, { useState, useMemo, useEffect } from "react";
import { getDefaultExamOrganization } from './ExamsCourseCard.utils'
import {dateUTCToLocale} from "../../../Utils/Date";

const ExamsCourseCard = ({exam, organizations, choice,userId = "", deleteChoice, postChoice}) => {
    const [organization, setOrganization] = useState();
    const filteredOrganizations = useMemo(
        () => organizations.filter(org => org.courseId === exam.defaultHeadquarter)
    , [exam.defaultHeadquarter, organizations]);

    useEffect(() => {
        const defaultOption = getDefaultExamOrganization(organizations, exam.defaultHeadquarter);
        if (defaultOption) setOrganization(exam.defaultHeadquarter);
    }, [organizations, exam.defaultHeadquarter]);

    let examDateString = dateUTCToLocale(exam.date)

    const handleHeadquarterChange = (ev) => {
        setOrganization(ev.target.value);
    }

    const SelectedIndicator = styled(Button)({
        background: '#52b788',
        color: 'white',
    });

    function renderSelects(){
        if(choice!==null && choice.examId === exam.id){
            return <Select labelId={"selectorLabel"} value={choice.headquarterId ?? ""} disabled>
                { organizations.filter(org => org.courseId === choice.headquarterId)
                    .map(org => <MenuItem key={org.courseId} value={org.courseId} children={org.courseName}/>)
                }
            </Select>
        }
        if(exam.defaultHeadquarter === null || exam.defaultHeadquarter === ""){
            return <Select labelId={"selectorLabel"} value={organization ?? ""} onChange={handleHeadquarterChange}>
                { organizations.filter(org => org.courseId !== "cntr_exam_99")
                    .map(org => (<MenuItem key={org.courseId} value={org.courseId}>{org.courseName}</MenuItem>)) }
            </Select>
        }
        if (!!filteredOrganizations.length) {
            return <Select labelId={"selectorLabel"} value={organization ?? ""} onChange={handleHeadquarterChange}>
                { filteredOrganizations.map(org => <MenuItem key={org.courseId} value={org.courseId} children={org.courseName}/>) }
            </Select>
        } else {
            return <Select labelId={"selectorLabel"} value={organization ?? ""} onChange={handleHeadquarterChange}>
                { organizations.map(org => <MenuItem key={org.courseId} value={org.courseId} children={org.courseName}/>) }
            </Select>
        }
    }

    return (
        <Card raised variant={"outlined"}>
            <CardHeader
                avatar={<Avatar variant={"rounded"}>{exam.option}</Avatar>}
                title={`Examen ${exam.option}`}
                subheader={examDateString}
                action={choice.examId === exam.id ? <SelectedIndicator disabled>Seleccionada</SelectedIndicator> : ""}
            />
            <CardContent>
                <Grid container>
                    <Grid item>
                        <FormControl fullWidth>
                            <InputLabel id="selectorLabel">Sede</InputLabel>
                            { renderSelects() }
                            <FormHelperText>Elige una sede</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

            </CardContent>
            <CardActions>
                {choice.examId === exam.id
                    ? <Button variant={"outlined"} color={"primary"} onClick={() => deleteChoice(choice.id)}>Eliminar Peticion</Button>
                    : <Button variant={"contained"} color={"primary"} onClick={() => postChoice(exam.id, organization,userId)}>Crear Peticion</Button>
                }
            </CardActions>
        </Card>
    )
}
export default ExamsCourseCard;
