import React, { useRef, useMemo } from 'react'
import { Button, Dialog, DialogTitle, DialogContent, Grid, Tooltip, IconButton, Icon } from '@material-ui/core';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { useNotification } from '../../../Utils/hooks';
import { downloadInstanceFile, downloadStudentInstanceFile, uploadInstanceFile, uploadStudentInstanceFile } from '../../../services/Instances/InstancesService';
import { downloadFromRawData } from '../../../Utils/utils';

const InstanceFiles = ({ visible, instance, onHide, onSuccess, canUploadStudentFile, canUploadInstructor }) => {
  const notification = useNotification();
  const inputRef = useRef(null);
  const isLocked = useMemo(() => ['CLOSED', 'CANCELED'].includes(instance?.status), [instance]);

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);

      if (file) {
        try {
          setApplicationLoading(true);
          await uploadInstanceFile(instance.id, file);
          notification('success', 'El fichero se ha subido correctamente');
          onSuccess && onSuccess(instance.id, file.name);
        } catch {
          notification('error', 'Ha habido un error al subir el fichero');
        } finally {
          setApplicationLoading(false);
          inputRef.current.value = null;
        }
      }
    }
  }

  const onStudentFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);

      if (file) {
        try {
          setApplicationLoading(true);
          await uploadStudentInstanceFile(instance.id, file);
          notification('success', 'El fichero se ha subido correctamente');
          onSuccess && onSuccess(instance.id, file.name);
        } catch {
          notification('error', 'Ha habido un error al subir el fichero');
        } finally {
          setApplicationLoading(false);
          inputRef.current.value = null;
        }
      }
    }
  }

  const onDownloadFile = async () => {
    try {
      if (!instance) return;
      setApplicationLoading(true);
      const { file, name } = await downloadInstanceFile(instance.id);
      downloadFromRawData(file, name);
    } catch {
      notification('error', 'Ha habido un error al descargar el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onDownloadStudentFile = async () => {
    try {
      if (!instance) return;
      setApplicationLoading(true);
      const { file, name } = await downloadStudentInstanceFile(instance.id);
      downloadFromRawData(file, name);
    } catch {
      notification('error', 'Ha habido un error al descargar el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  const StudentFile = (canUploadStudentFile && !isLocked)
    ? (
      <div className="student-file-actions">
        <Tooltip title="Descargar" aria-label="delete">
          <IconButton
            aria-label="download"
            onClick={onDownloadStudentFile}
            disabled={!instance?.studentFileName}
          >
            <Icon className="download-icon">download</Icon>
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          component="label"
        >
          {`${instance?.studentFileName ? 'Reemplazar': 'Subir'} fichero`}
          <input
            onChange={onStudentFilesChange}
            ref={inputRef}
            type="file"
            hidden
          />
        </Button>
      </div>
    ) : (
      <Button
        variant="contained"
        color="primary"
        component="label"
        onClick={onDownloadStudentFile}
        disabled={!instance?.studentFileName}
      >
        Descargar fichero
      </Button>
    );

  const InstructorFile = (canUploadInstructor && !isLocked)
    ? (
      <div className="instructor-file-actions">
        <Tooltip title="Descargar" aria-label="delete">
          <IconButton
            aria-label="download"
            onClick={onDownloadFile}
            disabled={!instance?.requestFileName}
          >
            <Icon className="download-icon">download</Icon>
          </IconButton>
        </Tooltip>
        <Button
          variant="contained"
          color="primary"
          component="label"
        >
          {`${instance?.requestFileName ? 'Reemplazar': 'Subir'} fichero`}
          <input
            onChange={onFilesChange}
            ref={inputRef}
            type="file"
            hidden
          />
        </Button>
      </div>
    ) : (
      <Button
        variant="contained"
        color="primary"
        component="label"
        onClick={onDownloadFile}
        disabled={!instance?.requestFileName}
      >
        Descargar fichero
      </Button>
    );

  return (
    <Dialog
      className="instance-files-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle>Ficheros de la solicitud</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <div className="upload-file">
              <p>
                <b>Fichero de la petición:</b><br />
                {instance?.studentFileName ? instance.studentFileName :  'No hay ningún fichero adjunto'}
              </p>
              {StudentFile}
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="upload-file">
              <p>
                <b>Fichero de la respuesta:</b><br />
                {instance?.requestFileName ? instance.requestFileName :  'No hay ningún fichero adjunto'}
              </p>
              {InstructorFile}
            </div>
          </Grid>
        </Grid>
      </DialogContent>
    </Dialog>
  )
}

export default InstanceFiles