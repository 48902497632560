import React, {useContext, useEffect, useState} from "react";
import MUIDataTable from "mui-datatables";
import {Button, Card, CardActions, CardContent, Modal, Paper, TextField} from "@material-ui/core";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import { dateUTCToLocale } from "../../../Utils/Date";

const InteractionsList = ({user,interactions}) => {

    const [modal,setModal] = useState(false)
    const [loading,setLoading] = useState(false)
    const [editComment,setEditComment] = useState()
    const [editingInteraction,setEditingInteraction] = useState({})
    const alertContext = useContext(SnackBarContext)

    const columns =[
        {
            label: 'Id',
            name: "id"
        },
        {
            label: "Tipo",
            name: "type"
        },
        {
            label: "Fecha de Creacion",
            name: "created",
            options: {
                customBodyRender: date => dateUTCToLocale(date)
            }
        },
        {
            label: "DNI",
            name: "dni"
        },
        {
            label: "Titulacion",
            name: "titulation"
        },
        {
            label: "Curso",
            name: "course"
        },
        {
            label: "Interaccion",
            name: "interactionText"
        },
        {
            label: "Archivos",
            name: "attatchments"
        },
        {
            label: "Editar",
            name: "id",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        variant={"contained"}
                        color={"secondary"}
                        onClick={()=>editForm(value,tableMeta)}>
                        Editar
                    </Button>
                }
            }
        },
        {
            label: "Eliminar",
            name: "id",
            options: {
                customBodyRender: (value,tableMeta,updateValue) => {
                    return <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={()=>deleteInteraction(value)}>
                        Eliminar
                    </Button>
                }
            }
        }
    ]

    const options={
        selectableRows: "none",
        print: false
    }

    const deleteInteraction = async (interactionId) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        await fetch(`/interactions-api/${interactionId}`,{ headers: headers, method: "DELETE" })
            .then(r => r.json())
            .then(json => {
                //Delete the interaction from the list
                interactions.forEach(element => {
                    if(element.id === interactionId){
                        interactions.splice(interactions.indexOf(element),1)
                    }
                })
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
            });
        setLoading(false);
    }

    const editForm = (value) => {
        setEditingInteraction(interactions.find(interaction => interaction.id === value))
        console.log(value)
        console.table(editingInteraction)
        setEditComment(editingInteraction.interactionText)
        setModal(true)
    }

    const sendPatchInteraction = async () => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        await fetch(`/interactions-api/${editingInteraction.id}`,
            {
                headers: headers,
                method: "PATCH",
                body: JSON.stringify(editingInteraction)
            })
            .then(r => r.json())
            .then(json => {
                //Refresh Interactions
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`)
                console.log(reason)
            });
        setLoading(false)
        setModal(false)
    }

    useEffect(()=> {
        console.log("updating interactions...")
    },interactions)

    return(
        <Paper elevation={3} style={{margin:"25px"}}>
            <MUIDataTable
                title={`Interacciones de ${user}`}
                columns={columns}
                options={options}
                data={interactions}/>
            <Modal open={modal} style={{padding:"20vh"}}>
                <Card>
                    <CardContent>
                        <TextField label={"Comentario"}
                                   required
                                   value={editComment}
                                   onChange={(e) => setEditComment(e.target.value)}
                                   variant={"filled"}
                                   multiline
                                   placeholder={"Comentario de la interaccion"}/>
                    </CardContent>
                    <CardActions>
                        <Button variant={"contained"} color={"primary"} onClick={()=>{
                            editingInteraction.interactionText=editComment
                            sendPatchInteraction()
                        }}>Aceptar</Button>
                        <Button variant={"contained"} color={"secondary"} onClick={()=>setModal(false)}>Cancelar</Button>
                    </CardActions>
                </Card>
            </Modal>
        </Paper>
    )
}

export default InteractionsList;

