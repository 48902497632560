import React from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import NewEnrollmentsList from "./NewEnrollmentsList";
import NewEnrollmentSwitch from "../../../components/NewEnrollments/NewEnrollmentSwitch/NewEnrollmentSwitch";

const NewEnrollmentsStudentMain = () => {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (_event, newValue) => {
    console.log(`Going to tab ${newValue}`);
    setValue(newValue);
  };

  function TabPanel(props) {
    return (
      <div
        role="tabpanel"
        hidden={value !== props.index}
        id={`tabpanel-${props.index}`}
        aria-labelledby={`full-width-tab-${props.index}`}
      >
        {value === props.index && <Box>{props.component}</Box>}
      </div>
    );
  }

  return (
    <React.Fragment>
      <AppBar position={"static"}>
        <Tabs variant={"fullWidth"} value={value} onChange={handleTabChange}>
          <Tab label={"Solicitudes"} />
          <Tab label={"Nueva solicitud"} />
        </Tabs>
      </AppBar>
      <TabPanel index={0} component={NewEnrollmentsList} />
      <TabPanel
        index={1}
        component={
          <NewEnrollmentSwitch onHide={() => handleTabChange(null, 0)} />
        }
      />
    </React.Fragment>
  );
};

export default NewEnrollmentsStudentMain;
