import React, {useContext, useEffect, useState} from "react";
import {
    Button,
    Container,
    Grid, makeStyles, styled
} from "@material-ui/core";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import EnrollmentFormUserData from "./EnrollmentFormUserData";
import EnrollmentFormTimeOptionData from "./EnrollmentFormTimeOptionData";
import EnrollmentFormCourseTable from "./EnrollmentFormCourseTable";
import EnrollmentFormGeneralDiscountOptionData from "./EnrollmentFormGeneralDiscountOptionData";
import EnrollmentFormInsuranceOptionData from "./EnrollmentFormInsuranceOptionData";
import EnrollmentFormPaymentOptionData from "./EnrollmentFormPaymentOptionData";
import EnrollmentFormDebtData from "./EnrollmentFormDebtData";
import {postFiles, postRequest} from "../../../services/Enrollments/RequestsService";
import {postExtendedFiles, postExtendedRequest} from "../../../services/ExtendedEnrollments/ExtendedRequestsService";
import EnrollmentFormImageAuthorizationOptionData from "./EnrollmentFormImageAuthorizationOptionData";

const ExtendedEnrollmentForm = ({ returnToIndex, enrollmentTerm }) => {

    const alertContext = useContext(SnackBarContext)

    const [loading,setLoading] = useState(false)

    const [timeOption,setTimeOption] = useState("FULL_TIME")
    const [gradeSelected,setGradeSelected] = useState("")
    const [termSelected,setTermSelected] = useState("")
    const [coursesSelected,setCoursesSelected] = useState([])
    const [paymentOption,setPaymentOption] = useState("FULL")
    const [imageAuthorizationOption,setImageAuthorizationOption] = useState(true)

    const [files,setFiles] = useState()

    const handleFileChange = (event) => {
        setFiles(event.target.files)
    }

    const postRequestAction = async () => {
        if(coursesSelected.length === 0){
            alertContext.setMessage("No se ha seleccionado ninguna asignatura")
            alertContext.setOpen(true)
            return
        }
        setLoading(true)
        //Build the RequestDTO
        let request={
            enrollmentTermId: enrollmentTerm,
            enrollmentType: "A",
            timeOption: timeOption,
            grade: gradeSelected,
            termId: termSelected,
            paymentOption: paymentOption,
            imageAuthorization: imageAuthorizationOption,
            courses: coursesSelected
        }
        let response = await postExtendedRequest(request)
        if (typeof response === 'string' || response instanceof String){
            alertContext.setMessage(response)
            alertContext.setOpen(true)
        }else {
            alertContext.setMessage("Peticion registrada")
            alertContext.setOpen(true)
        }
        if(files){
            await postFilesAction(response.id,files)
        }
        setLoading(false)
        if(returnToIndex) returnToIndex()
    }

    const postFilesAction = async (requestId,files) => {
        if(files===null){
            alertContext.setMessage(`No se ha seleccionado ningun archivo`);
            return
        }
        await postExtendedFiles(requestId,files);
    }

    return (
        <Container style={{padding:15}}>
            <Grid container spacing={2}>
                <Grid item md={12}>
                    <EnrollmentFormUserData/>
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormTimeOptionData selected={timeOption} setSelected={setTimeOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormCourseTable
                        gradeSelected={gradeSelected}
                        setGradeSelected={setGradeSelected}
                        termSelected={termSelected}
                        setTermSelected={setTermSelected}
                        coursesSelected={coursesSelected}
                        setCoursesSelected={setCoursesSelected}
                    />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormPaymentOptionData selected={paymentOption} setSelected={setPaymentOption} />
                </Grid>
                <Grid item md={12}>
                    <EnrollmentFormImageAuthorizationOptionData selected={imageAuthorizationOption}
                                                                setSelected={setImageAuthorizationOption} />
                </Grid>
                <Grid item md={12}>
                    { files
                        ?
                        <Button
                            style={{background: '#52b788'}}
                            variant="contained"
                            fullWidth
                            component="label">
                            Adjuntar archivos
                            <input type="file"
                                   multiple
                                   hidden
                                   onChange={handleFileChange}/>
                        </Button>
                        :
                        <Button
                            variant="contained"
                            fullWidth
                            component="label">
                            Adjuntar archivos
                            <input type="file"
                                   multiple
                                   hidden
                                   onChange={handleFileChange}/>
                        </Button>
                    }
                </Grid>
                <Grid item md={12}>
                    <Button
                        variant={"contained"}
                        color={"primary"}
                        onClick={() => postRequestAction()}
                        fullWidth>
                        Enviar
                    </Button>
                </Grid>
            </Grid>
        </Container>
    )
}

export default ExtendedEnrollmentForm
