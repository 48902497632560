import Grid from "@material-ui/core/Grid";
import {Accordion, AccordionDetails, AccordionSummary, LinearProgress, Typography} from "@material-ui/core";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React, {useState} from "react";
import CourseExamsDisplayerLogic from "./CourseExamsDisplayerLogic";
import ExamSelector from "../ExamSelector/ExamSelector";

const CourseExamsDisplayer = ({coordinator,userId}) => {

    const {courses,organizations,loading} = CourseExamsDisplayerLogic(coordinator,userId)

    const [expanded, setExpanded] = useState("")

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    return (
        <Grid container
              elevation={3}
              direction={"column"}
              spacing={3}>
            <Grid item>
                <Typography variant={"h4"} color={"primary"}>Tus Cursos:</Typography>
            </Grid>
            {loading === true
                ? <Grid item><LinearProgress/></Grid>
                : courses.map((value, index) => {
                    return (
                        <Grid item key={index}>
                            <Accordion expanded={expanded === `${index}`} onChange={handleChange(`${index}`)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Grid container spacing={3}>
                                        <Typography variant={"body1"}>{value.courseName}</Typography>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ExamSelector courseId={value.courseId} organizations={organizations} userSelected={userId} />
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default CourseExamsDisplayer