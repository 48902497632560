import React from 'react'
import classNames from "classnames";
import Badges from '../../components/Badges/Badges';

const ENROLLMENT_STATUS = {
  PENDING: 'Pendiente',
  PROCESSED: 'Procesado',
}

export const renderEnrollmentStatus = isProcessed => {
  const status = isProcessed ? 'PROCESSED' : 'PENDING';
  const label = ENROLLMENT_STATUS[status] || 'Indefinido';

  const className = classNames(
    { green: status === "PROCESSED" },
  );

  return <Badges data={[{ label, className }]} />
}