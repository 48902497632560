import React, {useContext, useState} from "react";
import {Avatar, Button, Card, CardContent, CardHeader, Grid, TextField, Typography} from "@material-ui/core";
import {Alert} from "@material-ui/lab";
import SnackBarContext from "../../../Contexts/SnackBarContext";

const ExamsManagerExamManagement = ({exam,fetchExams, setExam,openModal}) => {

    const alertContext = useContext(SnackBarContext)
    const [examIncorrectReason, setExamIncorrectReason] = useState("")

    const generateLinkAndDownload = (blob, filename) =>{
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', filename+".zip");
        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
    }

    const fetchInstructions = async (id,filename) => {
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        await fetch(`/exams-api/exams/${id}/instructions`,{ headers: headers })
            .then(r => r.blob())
            .then((blob)=>{
                generateLinkAndDownload(blob, filename)
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
                console.log(reason);
            });
    }

    const fetchQuestions = async (id,filename) => {
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        await fetch(`/exams-api/exams/${id}/questions`,{ headers: headers })
            .then(r => r.blob())
            .then((blob)=>{
                generateLinkAndDownload(blob, filename)
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
                console.log(reason);
            });
    }

    const setExamCorrect = async (id) =>{
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        })
        await fetch(`/exams-api/exams/${id}/status`,{
            headers: headers,
            method: "POST",
            body: JSON.stringify({
                id:id,
                status:'UPLOAD_CORRECT'
            })
        })
            .then(r => r.json())
            .then(response =>{
                fetchExams()
                setExam(null)
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
            });
    }

    const setExamIncorrect = async (id) =>{
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        })
        await fetch(`/exams-api/exams/${id}/status`,{
            headers: headers,
            method: "POST",
            body: JSON.stringify({
                id:id,
                status:'UPLOAD_INCORRECT',
                reason:examIncorrectReason
            })
        })
            .then(r => r.json())
            .then(response =>{
                fetchExams()
                setExam(null)
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
            });
    }

    const setExamPendingForReview = async (id) =>{
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        })
        await fetch(`/exams-api/exams/${id}/status`,{
            headers: headers,
            method: "POST",
            body: JSON.stringify({
                id:id,
                status:'REVISION_PENDING'
            })
        })
            .then(r => r.json())
            .then(response =>{
                fetchExams()
                setExam(null)
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
            });
    }

    const onChangeExamIncorrectReason = (e) =>{
        setExamIncorrectReason(e.target.value)
    }

    function examForm(state){
        switch (state){
            case "NOT_UPLOADED":
                return (
                    <Grid container spacing={2}>
                        <Grid item md={8}>
                            <Alert severity={"info"}>El examen aun no tiene ficheros de instrucciones ni preguntas</Alert>
                        </Grid>
                        <Grid item md={4}>
                            <Button spacing={2} style={{height:'100%'}} variant={"contained"} onClick={()=>{openModal(true)}}  fullWidth>Marcar cómo subido</Button>
                        </Grid>
                    </Grid>
                )
            case "UPLOAD_WITHOUT_FILES":
            case "UPLOADED":
                return (
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Button variant={"contained"} onClick={() => fetchInstructions(exam.id, "Instruccionens_" + exam.option)} fullWidth>Descargar Instrucciones</Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} onClick={()=>fetchQuestions(exam.id, "Preguntas_" + exam.option)} fullWidth>Descargar Preguntas</Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setExamCorrect(exam.id)} fullWidth>Marcar como Correcto</Button>
                        </Grid>
                        <Grid item md={6}>
                            <Grid container spacing={2}>
                                <Grid item md={6}>
                                    <TextField value={examIncorrectReason} onChange={onChangeExamIncorrectReason} placeholder={"El archivo esta corrupto"} fullWidth/>
                                </Grid>
                                <Grid item md={6}>
                                    <Button variant={"contained"} color={"secondary"} onClick={() => setExamIncorrect(exam.id)} fullWidth>Marcar como Incorrecto</Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                )
            case "UPLOAD_INCORRECT":
                return (
                    <Grid container spacing={2}>
                        <Grid item md={8}>
                            <Alert severity={"info"}>El examen ha sido marcado como incorrecto, esperando a que el profesor suba los archivos de nuevo</Alert>
                        </Grid>
                        <Grid item md={4}>
                            <Button spacing={2} style={{height:'100%'}} variant={"contained"} onClick={()=>{openModal(true)}}  fullWidth>Marcar cómo subido</Button>
                        </Grid>
                    </Grid>
                )
            case "UPLOAD_CORRECT":
                return (
                    <Grid container spacing={2}>
                        <Grid item md={6}>
                            <Button variant={"contained"} onClick={() => fetchInstructions(exam.id, "Instruccionens_" + exam.option)} fullWidth>Descargar Instrucciones</Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} onClick={()=>fetchQuestions(exam.id, "Preguntas_" + exam.option)} fullWidth>Descargar Preguntas</Button>
                        </Grid>
                        <Grid item md={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setExamPendingForReview(exam.id)} fullWidth>Marcar como pendiente de revision</Button>
                        </Grid>
                    </Grid>
                )
            case "REVISION_PENDING":
                return (
                    <Alert severity={"info"}>El examen esta pendiente de revision por el profesor</Alert>
                )
            case "REVISED_INCORRECT":
                return (
                    <Grid container spacing={2}>
                        <Grid item md={12}>
                            <Alert severity={"error"}>{`El examen ha sido marcado como Revisado Incorrecto por este motivo: ${exam.reason}`}</Alert>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} onClick={() => fetchInstructions(exam.id, "Instruccionens_" + exam.option)} fullWidth>Descargar Instrucciones</Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} onClick={()=>fetchQuestions(exam.id, "Preguntas_" + exam.option)} fullWidth>Descargar Preguntas</Button>
                        </Grid>
                        <Grid item md={12}>
                            <Button variant={"contained"} color={"primary"} onClick={()=>setExamPendingForReview(exam.id)} fullWidth>Marcar como pendiente de revision</Button>
                        </Grid>
                    </Grid>
                )
            case "REVISED_CORRECT":
                return (
                    <Alert severity={"success"}>El Examen ha sido configurado y revisado</Alert>
                )
            default:
                return (
                    <Typography color={"error"}>Ha ocurrido un error leyendo el estado del examen</Typography>
                )
        }
    }

    return (
        <Card>
            <CardHeader
                title={`Examen: ${exam.id}`}
                subheader={exam.state}
                avatar={<Avatar>{exam.option}</Avatar>}
            />
            <CardContent>
                { (exam) && examForm(exam.state)}
            </CardContent>
        </Card>
    )
}

export default ExamsManagerExamManagement
