import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import NewEnrollmentsListAdmin from "../Admin/NewEnrollmentsListAdmin";
import NewEnrollmentsTerms from "./NewEnrollmentsTerms";

const NewEnrollmentsSecrMain = () => {
  const [value, setValue] = React.useState(0);

  const handleTabChange = (_event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    return (
      <div
        role="tabpanel"
        hidden={value !== props.index}
        id={`tabpanel-${props.index}`}
        aria-labelledby={`full-width-tab-${props.index}`}
      >
        {value === props.index && <Box>{props.component}</Box>}
      </div>
    );
  }

  return (
    <React.Fragment>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={value} onChange={handleTabChange}>
          <Tab label="Solicitudes" />
          <Tab label="Convocatorias" />
        </Tabs>
      </AppBar>
      <TabPanel index={0} component={NewEnrollmentsListAdmin} />
      <TabPanel index={1} component={NewEnrollmentsTerms} />
    </React.Fragment>
  );
};

export default NewEnrollmentsSecrMain;
