import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import AssignationsList from "./AssignationsList";

const AssignationsMain = ({templates,assignations}) => {

    const [tabValue, setTabValue] = useState(0);
    const [credentialsAssignations,setCredentialsAssignations] = useState([])
    const [instructorAssignations,setInstructorAssignations] = useState([])
    const [coordinatorAssignations,setCoordinatorAssignations] = useState([])

    const handleTabChange = (event, newValue) => {
        console.log(`Going to tab ${newValue}`);
        setTabValue(newValue);
    };

    function TabPanel(props) {
        return(
            <div role="tabpanel"
                 hidden={tabValue !== props.index}
                 id={`tabpanel-${props.index}`}
                 aria-labelledby={`full-width-tab-${props.index}`}>
                { tabValue === props.index && (
                    <Box>
                        {props.component}
                    </Box>
                )}
            </div>
        )
    }

    useEffect(()=>{
        let passwordTemplatesCurrent=[]
        let instructorsTemplatesCurrent=[]
        let coordinatorsTemplatesCurrent=[]
        assignations.forEach(assignation => {
            switch (assignation.type){
                case 0:
                    passwordTemplatesCurrent.push(assignation)
                    break
                case 1:
                    instructorsTemplatesCurrent.push(assignation)
                    break
                case 2:
                    coordinatorsTemplatesCurrent.push(assignation)
                    break
            }
        })
        setCredentialsAssignations(passwordTemplatesCurrent)
        setInstructorAssignations(instructorsTemplatesCurrent)
        setCoordinatorAssignations(coordinatorsTemplatesCurrent)
    },[assignations])

    return (
        <React.Fragment>
            <AppBar position={"static"} color={"secondary"}>
                <Tabs variant={"fullWidth"} value={tabValue} onChange={handleTabChange}>
                    <Tab label={"Credenciales"} />
                    <Tab label={"Instructores"} />
                    <Tab label="Coordinadores" />
                </Tabs>
            </AppBar>
            <TabPanel index={0} component={<AssignationsList assignationType={0} assignationText={"Credenciales"} templates={templates} assignations={credentialsAssignations} />}/>
            <TabPanel index={1} component={<AssignationsList assignationType={1} assignationText={"Instructor"} templates={templates} assignations={instructorAssignations} />}/>
            <TabPanel index={2} component={<AssignationsList assignationType={2} assignationText={"Coordinador"} templates={templates} assignations={coordinatorAssignations} />}/>
        </React.Fragment>

    )
}

export default AssignationsMain