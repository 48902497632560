export const getAllTerms = async () => {
    let sessionId=sessionStorage.getItem("sessionId");
    let headers= new Headers({
        'Authorization':'Bearer '+sessionId
    });
    const terms= await fetch(`/registry-api/terms`,{ headers: headers })
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
            return [
                {
                    id:"1",
                    text:"Term 1"
                },
                {
                    id:"2",
                    text:"Term 2"
                },
                {
                    id:"3",
                    text:"Term 3"
                },
                {
                    id:"4",
                    text:"Term 4"
                },
            ];
        });
    return terms
}
