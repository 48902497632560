import React, { useContext, useEffect, useState } from "react";
import Header from "../../components/Header/Header";
import AlertMessage from "../../Contexts/AlertMessage";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { setApplicationLoading } from "../../components/ApplicationLoading";
import InteractionsMain from "../Interactions/InteractionsMain";
import StudentRecordMain from "../StudentRecord/StudentRecordMain";
import RegistryMain from "../Registry/RegistryMain";
import Dashboard from "./Dashboard";
import RoleContext from "../../Contexts/RoleContext";
import NotificationsMain from "../Notifications/NotificationsMain";
import GradesMain from "../Grades/GradesMain";
import DocumentalMain from "../Documental/DocumentalMain";
import ExamsMain from "../Exams/ExamsMain";
import NewEnrollmentsMain from "../NewEnrollments/NewEnrollmentsMain";
import InstancesMain from "../Instances/InstancesMain";
import TFGMain from "../TFG/TFGMain";

const DashBoardContainer = () => {

    const roleContext=useContext(RoleContext)

    const [services,setServices] = useState([])
    const [loading,setLoading] = useState(true)

    const fetchRole = async () => {
        setLoading(true)

        const services = {
            // "AUTOMATRICULA": { name: "Automatricula", link: "/enrollments" }, // Módulo obsoleto - 23/06/2023
            "EXAMENES": { name: "Examenes", link: "/exams" },
            "DOCUMENTOS": { name: "Documentos", link: "/documental" },
            "INTERACCIONES": { name: "Interaciones", link: "/interactions" },
            "FICHA_ALUMNO": { name: "Ficha de Alumno", link: "/student-record" },
            "LIBRO_REGISTRO": { name: "Libro de registro", link: "/registry" },
            "NOTIFICACIONES": { name: "Notificaciones", link: "/notifications" },
            "NOTAS": { name: "Notas", link: "/grades" },
            "GESTOR_DOCUMENTAL": { name: "Gestor Documental", link: "/documental" },
            // "AMPLIACION_MATRICULA": { name: "Ampliacion de matricula", link: "/extended-enrollments" }, // Módulo obsoleto - 23/06/2023
            "INSTANCIAS": { name: "Instancias", link: "/instances" },
            "TFM": { name: "TFG/TFM", link: "/tfg" },
            "MATRICULAS": { name: "Matrículas", link: "/enrollments" }
        };

        let showServices = [];

        switch (roleContext.role) {
            case "ROLE_STUDENT":
                showServices = [
                    services["EXAMENES"],
                    services["NOTAS"],
                    services["GESTOR_DOCUMENTAL"],
                    services["INSTANCIAS"],
                    services["TFM"], // Acceso de alumnos provisionalmente eliminado 25/09/2023
                    services["MATRICULAS"],
                ]
                break;

            case "ROLE_INSTRUCTOR":
                showServices = [
                    services["EXAMENES"],
                    services["FICHA_ALUMNO"],
                    services["TFM"],
                ]
                break;

            case "ROLE_SECR":
                showServices = [
                    services["FICHA_ALUMNO"],
                    services["LIBRO_REGISTRO"],
                    services["INSTANCIAS"],
                    services["TFM"],
                    services["MATRICULAS"],
                ]
                break;

            case "ROLE_ADMIN":
                showServices = [
                    services["EXAMENES"],
                    services["FICHA_ALUMNO"],
                    services["LIBRO_REGISTRO"],
                    services["NOTIFICACIONES"],
                    services["GESTOR_DOCUMENTAL"],
                    services["INSTANCIAS"],
                    services["TFM"],
                    services["MATRICULAS"],
                ]
                break;
    
            case "ROLE_COORD":
                showServices = [
                    services["EXAMENES"],
                    services["FICHA_ALUMNO"],
                    services["GESTOR_DOCUMENTAL"],
                    services["INSTANCIAS"],
                    services["TFM"],
                ]
                break;
    
            case "ROLE_GES_EXAM":
                showServices = [
                    services["EXAMENES"],
                ]
                break;

            default:
                break;
        }

        setServices((services) => [...services,...showServices])
        setLoading(false);
    }

    useEffect(() =>{
        if (roleContext?.role) fetchRole()
    }, [roleContext])

    useEffect(() => {
        setApplicationLoading(loading)
    }, [loading]);

    // if (loading) return (
    //     <BrowserRouter>
    //         <Route path={"/:sessionId"} component={ (props) => <Dashboard {...props} services={services} /> }/>
    //     </BrowserRouter>
    // )

    return (
        <BrowserRouter>
            <Header services={services}/>
            <AlertMessage />
            <Switch>
                {/* Replaced by NewEnrollments module - 23/06/2023 */}
                {/* <Route exact={true} path={"/enrollments"} component={EnrollmentsMain} /> */}
                <Route exact={true} path={"/exams"} component={ExamsMain}/>
                <Route exact={true} path={"/interactions"} component={InteractionsMain}/>
                <Route exact={true} path={"/student-record"} component={StudentRecordMain}/>
                <Route exact={true} path={"/registry"} component={RegistryMain}/>
                <Route exact={true} path={"/notifications"} component={NotificationsMain}/>
                <Route exact={true} path={"/grades"} component={GradesMain}/>
                <Route exact={true} path={"/documental"} component={DocumentalMain}/>
                {/* Replaced by NewEnrollments module - 23/06/2023 */}
                {/* <Route exact={true} path={"/extended-enrollments"} component={ExtendedEnrollmentsMain} /> */}
                <Route exact={true} path={"/enrollments"} component={NewEnrollmentsMain} />
                <Route exact path={"/instances"} component={InstancesMain}/>
                <Route exact path={"/tfg"} component={TFGMain}/>
                {/* Dashboard route has to be the last to make the others routes match first */}
                <Route path={"/:sessionId"} component={ (props) => <Dashboard {...props} services={services} /> }/>
            </Switch>
        </BrowserRouter>
    )
}

export default DashBoardContainer
