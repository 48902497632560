import React from "react";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import RegistryListContainer from "./RegistryListPanel/RegistryListContainer";
import NewRegistryPanelContainer from "./RegistryNewEntryPanel/NewRegistryPanelContainer";

const RegistryMain = (props) => {

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        console.log(`Going to tab ${newValue}`);
        setValue(newValue);
    };

    function TabPanel(props) {
        return(
            <div role="tabpanel"
                 hidden={value !== props.index}
                 id={`tabpanel-${props.index}`}
                 aria-labelledby={`tab-${props.index}`}>
                { value === props.index && (
                    <Box>
                        {props.component}
                    </Box>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <AppBar position={"static"}>
                <Tabs variant={"fullWidth"} value={value} onChange={handleTabChange}>
                    <Tab label="Libro de Registros" />
                    <Tab label="Nuevo Registro" />
                </Tabs>
            </AppBar>
            <TabPanel index={0} component={ RegistryListContainer }/>
            <TabPanel index={1} component={ <NewRegistryPanelContainer handleTabChange={handleTabChange} /> }/>
        </React.Fragment>
    )
}

export default RegistryMain
