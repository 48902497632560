import {Card, CardContent, CardHeader, FormControlLabel, makeStyles, Radio, RadioGroup} from "@material-ui/core";
import React from "react";

const EnrollmentFormInsuranceOptionData = (
    {
        selected,
        setSelected = null
    }) => {

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles()

    const handleChange = (event) => {
        if(setSelected){
            setSelected(event.target.value);
        }else {
            console.warn("Read-only Component")
        }
    };

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"SEGURO ESCOLAR"}
            />
            <CardContent>
                <RadioGroup name={"insuranceOption"} value={selected} onChange={handleChange}>
                    <FormControlLabel value={"NO_INSURANCE"} control={<Radio />} label={"Sin Seguro Escolar"}/>
                    <FormControlLabel value={"INSURANCE_LESS_28"} control={<Radio />} label={"Seguro Escolar menores de 28 años"}/>
                    <FormControlLabel value={"INSURANCE_MORE_28"} control={<Radio />} label={"Seguro Escolar mayores de 28 años"}/>
                </RadioGroup>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormInsuranceOptionData