import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { useConfirm } from 'material-ui-confirm';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { deleteTFGTerm, getTFGTerms, uploadTFGTermsCSV } from '../../../services/TFG/TFGService';
import { dateInstanceToLocale } from '../../../Utils/Date';
import { useNotification } from '../../../Utils/hooks';
import { copyToClipboard } from '../../../Utils/utils';
import TFGTermDialog from './TFGTermDialog/TFGTermDialog';
import TFGAcademicYearSelector from '../TFGAcademicYearSelector/TFGAcademicYearSelector';

const CSVKeys = ['academicYear', 'type', 'name', 'assignedDegreeIds', 'start', 'end'];

const TFGTerms = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [editing, setEditing] = useState(null);
  const [initial, setInitial] = useState(null);
  const [terms, setTerms] = useState([]);
  const [selectedTerm, setSelectedTerm] = useState('');
  const [academicYears, setAcademicYears] = useState({ loading: false, data: [] });
  const fileUploadRef = useRef(null);
  const notification = useNotification();
  const confirm = useConfirm();

  const getTermsFn = useCallback(async (selectedTerm) => {
    try {
      setApplicationLoading(true);
      const result = await getTFGTerms(undefined, undefined, selectedTerm);
      if (!result) throw new Error();
      setTerms(result);
    } catch {
      notification('error', 'Ha habido un error al obtener las convocatorias');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);


  useEffect(() => {
    if (selectedTerm) getTermsFn(selectedTerm);
    else setTerms([]);
  }, [getTermsFn, selectedTerm])

  const onCopyClick = () => {
    copyToClipboard(CSVKeys.join('|'));
    notification('info', 'Las cabeceras se han copiado al portapapeles');
  }

  const onUploadClick = event => {
    event.preventDefault();

    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) {
        try {
          setApplicationLoading(true);
          await uploadTFGTermsCSV(file);
          notification('success', 'Las convocatorias se han creado correctamente');
          getTermsFn();
        } catch {
          notification('error', 'Ha habido un error al subir el CSV');
        } finally {
          setApplicationLoading(false);
        }
      }
    }
  }

  const onEditClick = term => {
    setEditing(term);
    setShowDialog(true);
  }

  const onDuplicateClick = term => {
    const { id, start, end, ...others } = term;
    setInitial(others);
    setShowDialog(true);
  }

  const onDeleteClick = term => {
    confirm({
      title: 'Eliminar convocatoria',
      description: `Atención, esta acción eliminará la convocatoria ${term.name} si todavía no ha sido utilizada para ninguna solicitud, ¿estás seguro de que deseas realizar esta acción?`,
    })
      .then(async () => {
        try {
          if (!term) return;
          setApplicationLoading(true);
          await deleteTFGTerm(term.id);
          setTerms(prev => prev.filter(trm => trm.id !== term.id))
        } catch {
          notification('error', 'Ha habido un error al eliminar la convocatoria, es posible que la convocatoria ya esté en uso');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setEditing(null), 250);
    setTimeout(() => setInitial(null), 250);
  }

  const onDialogSuccess = (isEditing, term) => {
    setTerms(prev => {
      const newTerms = [...prev];
      if (!isEditing) newTerms.push(term);
      else {
        const index = newTerms.findIndex(trm => trm.id === term.id);
        if (~index) newTerms[index] = term;
      }
      return newTerms;
    })
    onHideDialog();
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    print: false,
    download: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'type',
      label: 'Tipo de convocatoria',
      options: {
        customBodyRender: type => type === 'AREA' ? 'Solicitud de área temática' : 'Solicitud de defensa'
      }
    },
    // {
    //   name: 'academicYear',
    //   label: 'Año académico',
    //   options: {
    //     customBodyRender: academicYear => academicYear?.name || '-',
    //   }
    // },
    {
      name: 'name',
      label: 'Nombre de la convocatoria',
    },
    {
      name: 'assignedDegrees',
      label: 'Titulaciones',
      options: {
        customBodyRender: titulations => titulations.map(titul => titul.name).join(', ')
      }
    },
    {
      name: 'start',
      label: 'Fecha inicio inscripciones',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'end',
      label: 'Fecha fin inscripciones',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    // {
    //   name: 'evaluationStart',
    //   label: 'Fecha inicio evaluaciones',
    //   options: {
    //     customBodyRender: date => dateInstanceToLocale(date)
    //   }
    // },
    // {
    //   name: 'evaluationEnd',
    //   label: 'Fecha fin evaluaciones',
    //   options: {
    //     customBodyRender: date => dateInstanceToLocale(date)
    //   }
    // },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        filter: false,
        print: false,
        customBodyRenderLite: index => {
          const term = terms[index];
          
          return (
            <div className="actions">
            <Tooltip title="Copiar" aria-label="copy">
              <IconButton aria-label="copy" onClick={() => onDuplicateClick(term)}>
                <Icon className="copy-icon">
                  content_copy
                </Icon>
              </IconButton>
            </Tooltip>
              <Tooltip title="Editar" aria-label="edit">
                <IconButton aria-label="edit" onClick={() => onEditClick(term)}>
                  <Icon className="edit-icon">
                    edit
                  </Icon>
                </IconButton>
              </Tooltip>
              <Tooltip title="Eliminar" aria-label="delete">
                <IconButton aria-label="delete" onClick={() => onDeleteClick(term)}>
                  <Icon className="delete-icon">
                    delete
                  </Icon>
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      }
    }
  ];

  return (
    <div className="tfg-terms">
      <TFGTermDialog
        visible={showDialog}
        editing={editing}
        initial={initial}
        years={academicYears.data}
        onHide={onHideDialog}
        onSuccess={onDialogSuccess}
        selectedYear={selectedTerm}
      />
      <div className="upload-csv">
        <p className="disclaimer">
          Cargue un archivo con las cabeceras:&nbsp;
          <i>{CSVKeys.join(' | ')}</i>
          <br /><br />
          El campo type soporta los siguientes valores:<br />
          <b>AREA: </b>Solicitud de área temática<br />
          <b>DEFENSE: </b>Solicitud de defensa<br />
          <br />
          Puedes utilizar el botón inferior para copiar las cabeceras requeridas al portapapeles
        </p>
        <div className="upload-csv__actions">
          <Tooltip title="Copiar cabeceras al portapapeles" aria-label="copy">
            <IconButton aria-label="copy">
              <Icon className="copy-icon" onClick={onCopyClick}>
                content_copy
              </Icon>
            </IconButton>
          </Tooltip>
          <input
            type="file"
            onChange={onFilesChange}
            multiple={false}
            style={{ display: 'none' }}
            ref={fileUploadRef}
            accept=".csv"
          />
          <Button
            onClick={onUploadClick}
            variant="contained"
            color="primary"
          >
            Subir CSV
          </Button>
        </div>
      </div>
      <TFGAcademicYearSelector
        selectedTerm={selectedTerm}
        setSelectedTerm={setSelectedTerm}
        setTermStatus={setAcademicYears}
      />
      <div className="tfg-terms-actions">
        <Button
          variant="contained"
          color="primary"
          onClick={() => { setEditing(null); setShowDialog(true) }}
        >
          Crear nueva convocatoria
        </Button>
      </div>
      <MUIDataTable
        className="tfg-terms-table"
        title="Convocatorias"
        columns={columns}
        options={options}
        data={terms}
      />
    </div>
  )
}

export default TFGTerms