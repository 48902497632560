import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import React, { useState, useEffect, useCallback } from 'react'
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { dateInstanceToLocale } from '../../../Utils/Date';
import { useNotification } from '../../../Utils/hooks';
import { getEnrollmentTerms } from '../../../services/Enrollments/NewEnrollmentsService';
import NewEnrollmentsTermDialog from './NewEnrollmentsTermDialog/NewEnrollmentsTermDialog';

// const CSVKeys = ['type', 'name', 'start', 'end'];

const NewEnrollmentsTerms = () => {
  const [showDialog, setShowDialog] = useState(false);
  const [editing, setEditing] = useState(null);
  const [terms, setTerms] = useState([]);
  // const fileUploadRef = useRef(null);
  const notification = useNotification();

  const getTermsFn = useCallback(async () => {
    try {
      setApplicationLoading(true);
      const result = await getEnrollmentTerms();
      if (!result) throw new Error();
      setTerms(result);
    } catch {
      notification('error', 'Ha habido un error al obtener las convocatorias');
    } finally {
      setApplicationLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    getTermsFn();
  }, [getTermsFn]);

  // const onCopyClick = () => {
  //   copyToClipboard(CSVKeys.join('|'));
  //   notification('info', 'Las cabeceras se han copiado al portapapeles');
  // }

  // const onUploadClick = event => {
  //   event.preventDefault();

  //   if (fileUploadRef.current) {
  //     fileUploadRef.current.click();
  //   }
  // }

  // const onFilesChange = async event => {
  //   const files = event.target.files;

  //   if (files) {
  //     const file = files.item(0);
  //     if (file) {
  //       try {
  //         setApplicationLoading(true);
  //         await uploadTFGTermsCSV(file);
  //         notification('success', 'Las convocatorias se han creado correctamente');
  //         getTermsFn();
  //       } catch {
  //         notification('error', 'Ha habido un error al subir el CSV');
  //       } finally {
  //         setApplicationLoading(false);
  //       }
  //     }
  //   }
  // }

  const onEditClick = term => {
    setEditing(term);
    setShowDialog(true);
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setEditing(null), 250);
  }

  const onDialogSuccess = (isEditing, term) => {
    setTerms(prev => {
      const newTerms = [...prev];
      if (!isEditing) newTerms.push(term);
      else {
        const index = newTerms.findIndex(trm => trm.id === term.id);
        if (~index) newTerms[index] = term;
      }
      return newTerms;
    })
    onHideDialog();
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    print: false,
    download: false,
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'type',
      label: 'Tipo de convocatoria',
      options: {
        customBodyRender: type => type === "A" ? 'Extensión de matrícula' : 'Matrícula'
      }
    },
    // {
    //   name: 'academicYear',
    //   label: 'Año académico',
    // },
    {
      name: 'name',
      label: 'Nombre de la convocatoria',
    },
    {
      name: 'active',
      label: 'Estado',
      options: {
        customBodyRender: active => active ? 'Activada' : 'Desactivada'
      }
    },
    {
      name: 'startDate',
      label: 'Fecha inicio',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'endDate',
      label: 'Fecha fin',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        filter: false,
        print: false,
        customBodyRenderLite: index => {
          const term = terms[index];

          return (
            <div className="actions">
              <Tooltip title="Editar" aria-label="edit">
                <IconButton aria-label="edit" onClick={() => onEditClick(term)}>
                  <Icon className="edit-icon">
                    edit
                  </Icon>
                </IconButton>
              </Tooltip>
            </div>
          )
        }
      }
    }
  ];

  return (
    <div className="enrollment-terms">
      <NewEnrollmentsTermDialog
        visible={showDialog}
        editing={editing}
        onHide={onHideDialog}
        onSuccess={onDialogSuccess}
      />
      {/* <div className="upload-csv">
        <p className="disclaimer">
          Cargue un archivo con las cabeceras:&nbsp;
          <i>{CSVKeys.join(' | ')}</i>
          <br /><br />
          El campo type soporta los siguientes valores:<br />
          <b>AREA: </b>Solicitud de área temática<br />
          <b>DEFENSE: </b>Solicitud de defensa<br />
          <br />
          Puedes utilizar el botón inferior para copiar las cabeceras requeridas al portapapeles
        </p>
        <div className="upload-csv__actions">
          <Tooltip title="Copiar cabeceras al portapapeles" aria-label="copy">
            <IconButton aria-label="copy">
              <Icon className="copy-icon" onClick={onCopyClick}>
                content_copy
              </Icon>
            </IconButton>
          </Tooltip>
          <input
            type="file"
            onChange={onFilesChange}
            multiple={false}
            style={{ display: 'none' }}
            ref={fileUploadRef}
            accept=".csv"
          />
          <Button
            onClick={onUploadClick}
            variant="contained"
            color="primary"
          >
            Subir CSV
          </Button>
        </div>
      </div> */}
      <div className="enrollment-terms-actions">
        <Button
          variant="contained"
          color="primary"
          onClick={() => { setEditing(null); setShowDialog(true) }}
        >
          Crear nueva convocatoria
        </Button>
      </div>
      <MUIDataTable
        className="enrollment-terms-table"
        title="Convocatorias"
        columns={columns}
        options={options}
        data={terms}
      />
    </div>
  )
}

export default NewEnrollmentsTerms