import React from 'react'
import classNames from 'classnames';
import Badges from '../../../components/Badges';
import { renderInstanceTableActions, INSTANCE_STATUS } from '../Instances.utils';
import { dateInstanceToLocale } from '../../../Utils/Date';

export const renderTFGStatus = status => {
  const label = INSTANCE_STATUS[status] || 'Indefinido';

  const className = classNames(
    { blue: status === 'OPEN' },
    { yellow: status === 'IN_PROCESS' },
    { green: status === 'CLOSED' },
    { red: status === 'CANCELED' },
  );

  return <Badges data={[{ label, className }]} />
}

export const generateTemplatesTable = (templates, onEdit, onToggleActivation, onFiles, onDelete) => {
  const hasActions = !!onEdit || !!onToggleActivation || !!onDelete;

  const adminColumns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'typeName',
      label: 'Nombre',
    },
    {
      name: 'start',
      label: 'Fecha de inicio',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'end',
      label: 'Fecha de fin',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'termName',
      label: 'Convocatoria',
    },
    ...hasActions ? [
      {
        name: 'id',
        label: 'Acciones',
        options: {
          customBodyRenderLite: (id) => {
            const template = templates[id];
            return renderInstanceTableActions(template.id, template, onEdit, onToggleActivation, undefined, undefined, onFiles, undefined, onDelete)
          },
          sort: false,
          print: false,
          download: false,
          filter: false,
        }
      }
    ] : []
  ];

  return adminColumns;
}

export const generateStudentInstancesTable = (data, onCancel, onFiles) => {
  const studentColumns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'templateType',
      label: 'Nombre'
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRender: status => renderTFGStatus(status)
      }
    },
    {
      name: 'instructorText',
      label: 'Mensaje de la respuesta',
      options: {
        customBodyRender: message => message || '-'
      }
    },
    {
      name: 'created',
      label: 'Fecha de creación',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: index => {
          const instance = data[index];
          // Remove cancel action / button if status does not allow it
          const _onCancel = instance?.status === "OPEN" ? onCancel : undefined;
          return renderInstanceTableActions(instance.id, instance, undefined, undefined, _onCancel, undefined, onFiles);
        },
        sort: false,
        print: false,
        download: false,
      }
    }
  ];

  return studentColumns;
}

export const generateInstructorInstancesTable = (data, secr, onFiles, onClose, onCopy, onDelete, onReopen) => {
  const instructorColumns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    {
      name: 'userExternalId',
      label: 'Alumno',
      options: {
        customBodyRender: id => id || 'Externo',
      }
    },
    {
      name: 'templateType',
      label: 'Nombre de la instancia',
    },
    {
      name: 'givenName',
      label: 'Nombre del alumno',
    },
    {
      name: 'surname1',
      label: 'Apellidos del alumno',
    },
    {
      name: 'dni',
      label: 'DNI del alumno',
      options: {
        customBodyRender: dni => dni?.toUpperCase ? dni?.toUpperCase() : dni
      }
    },
    {
      name: 'created',
      label: 'Fecha de creación',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRender: status => renderTFGStatus(status)
      }
    },
    {
      name: 'userText',
      label: 'Mensaje de la petición',
      options: {
        // print: false,
        customBodyRender: message => message || '-'
      }
    },
    {
      name: 'instructorText',
      label: 'Mensaje de la respuesta',
      options: {
        // print: false,
        customBodyRender: message => message || '-'
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: index => {
          const instance = data[index];
          // Remove close action / button if status does not allow it
          const _onClose = (instance?.status === "OPEN" && secr) ? onClose : undefined;
          // Coord only, when instance is closed
          const _onReopen = (instance?.status === "CLOSED" && !secr) ? onReopen : undefined;
          return renderInstanceTableActions(instance.id, instance, undefined, undefined, undefined, _onClose, onFiles, onCopy, undefined, _onReopen);
        },
        sort: false,
        print: false,
        download: false,
      }
    }
  ];

  return instructorColumns;
}