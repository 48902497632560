import React from 'react';
import classNames from "classnames";
import Badges from "../../components/Badges/Badges";

const TFG_REQUEST_STATUS = {
  OPEN: 'Solicitada',
  ACCEPTED: 'Aceptada',
  REJECTED: 'Rechazada',
  CANCELED: 'Cancelada',
}

export const renderTFGStatus = status => {
  const label = TFG_REQUEST_STATUS[status] || 'Indefinido';
  const className = classNames(
    { blue: status === 'OPEN' },
    { green: status === 'ACCEPTED' },
    { red: ['REJECTED', 'CANCELED'].includes(status) },
  );

  return <Badges data={[{ label, className }]} />
}

export const renderInstanceOption = option => {
  const { value, instance, degree, term } = option;

  return (
    <div className="instance-option" key={value}>
      <span className="info">{`${term} | ${degree}`}</span>
      <h5 className="name">{instance}</h5>
    </div>
  )
}

export const tfgRequestStatusOptions = [
  { label: 'Solicitada', value: 'OPEN' },
  { label: 'Aceptada', value: 'ACCEPTED' },
  { label: 'Rechazada', value: 'REJECTED' },
  { label: 'Cancelada', value: 'CANCELED' },
]