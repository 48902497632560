import React from 'react'
import classNames from 'classnames';
import CropOriginalIcon from '@material-ui/icons/CropOriginal';
import StarIcon from '@material-ui/icons/Star';
import './ElementCard.scss';

const ElementCard = ({ className, image, icon, alt, title, onClick, active, favorite }) => {
  const CardClass = classNames('element-card', { clickable: !!onClick }, { active }, className);
  const StarClass = classNames('star-icon', 'active');

  return (
    <div className={CardClass} onClick={active ? undefined : onClick}>
      { !!image
          ? <img className="element-card--image" src={image} alt={alt || 'card image'} />
          : !!icon
            ? icon
            : <CropOriginalIcon className="element-card--image placeholder" />
      }
      <h4 className="element-card--title">{title}</h4>
      { favorite && (
        <div className="element-card--favorite">
          <StarIcon className={StarClass} />
        </div>
      )}
    </div>
  )
}

export default ElementCard
