import React, {useContext, useState} from "react";
import {Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, Paper, Typography} from "@material-ui/core";
import SnackBarContext from "../../Contexts/SnackBarContext";

const DocumentalMyDocumentsList = ({list}) =>{

    const alertContext = useContext(SnackBarContext)
    const [loading,setLoading] = useState(false)

    const fetchFile = async (id,filename) => {
        setLoading(true)
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        await fetch(`/documents-api/${id}/file`,{ headers: headers })
            .then(r => r.blob())
            .then((blob)=>{
                // 2. Create blob link to download
                const url = window.URL.createObjectURL(new Blob([blob]));
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', filename);
                // 3. Append to html page
                document.body.appendChild(link);
                // 4. Force download
                link.click();
                // 5. Clean up and remove the link
                link.parentNode.removeChild(link);
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
            });
        setLoading(false)
    }

    function renderStatus(status,reason){
        switch (status){
            case "APPROVED":
                return <Button variant={"contained"} style={{background:"#1de9b6"}}>Aprobado</Button>
            case "REJECTED":
                return <Button variant={"contained"} style={{background:"#ff4569"}}>Rechazado por: {reason}</Button>
            case "PENDING":
                return <Button variant={"contained"} style={{background:"#ffc400"}}>Pendiente</Button>
        }
    }

    return (
        <Paper variant={"elevation"} style={{margin:25,padding:25}}>
            <Grid container
                  spacing={2}
                  direction={"row"}>
                {list.map((value,index)=>{
                    return(
                        <Grid item md={6} key={index}>
                            <Card raised>
                                <CardHeader title={"Documento "+value.id} subheader={value.documentType}/>
                                <CardContent>
                                    {renderStatus(value.status,value.reason)}
                                    <Divider/>
                                    <Typography variant={"caption"}>Creado el {value.created}</Typography>
                                    <Divider/>
                                    <Typography variant={"caption"}>Modificado el {value.modified}</Typography>
                                </CardContent>
                                <CardActions>
                                    <Button
                                        variant={"contained"}
                                        color={"primary"}
                                        onClick={()=>fetchFile(value.id,value.documentFilename)}>
                                        Descargar
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    )
                })}
            </Grid>
        </Paper>
    )
}

export default DocumentalMyDocumentsList