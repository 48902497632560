import {Button, CircularProgress, IconButton, Input, Modal, Paper, TextField, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, {useContext, useState} from "react";
import {Alert} from "@material-ui/lab";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import CloseIcon from '@material-ui/icons/Close';
import {getExamsInstructionsFile, getExamsQuestionsFile} from "../../../services/Exams/ExamsService";

const ExamsInstructorModal = ({modal,handleClose,examSelected}) => {

    const [loading,setLoading] = useState(false)
    const [instructionFiles,setInstructionFiles] = useState()
    const [questionFiles,setQuestionFiles] = useState()
    const [examIncorrectReason, setExamIncorrectReason] = useState("")
    const [instructionsUploaded,setInstructionsUploaded] = useState(false)
    const [questionsUploaded,setQuestionsUploaded] = useState(false)
    const [incorrectError, setIncorrectError] = useState('');

    const alertContext = useContext(SnackBarContext)

    const postInstructions = async (files) => {
        if (files !== null) {
            setLoading(true);
            let formData = new FormData()
            files.forEach(file => formData.append("files", file))
            let sessionId = sessionStorage.getItem("sessionId");
            let headers = new Headers({
                'Authorization': 'Bearer ' + sessionId,
            });
            await fetch(`/exams-api/exams/${examSelected.id}/instructions`,
                {
                    headers: headers,
                    method: "POST",
                    body: formData
                })
                .then(r => r.json())
                .then(exam => {
                    alertContext.setMessage(`El Examen ${exam.id} ha sido actualizado`)
                    alertContext.setOpen(true)
                    setInstructionsUploaded(true)
                    setInstructionFiles(null)
                    if (questionsUploaded) {
                        setInstructionsUploaded(false)
                        setQuestionsUploaded(false)
                        handleCloseInternal()
                    }
                })
                .catch(reason => {
                    console.log(reason);
                });
            setLoading(false)
        } else {
            alertContext.setMessage(`No se ha seleccionado ningun archivo`);
        }
    }

    const postQuestions = async (files) => {
        if(files===null){
            alertContext.setMessage(`No se ha seleccionado ningun archivo`);
            return
        }
        setLoading(true);
        let formData=new FormData()
        files.forEach(file => formData.append("files",file))
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
        });
        await fetch(`/exams-api/exams/${examSelected.id}/questions`,
            {
                headers: headers,
                method: "POST",
                body: formData
            })
            .then(r => r.json())
            .then(exam => {
                alertContext.setMessage(`El Examen ${exam.id} ha sido actualizado`)
                alertContext.setOpen(true)
                setQuestionsUploaded(true)
                if(instructionsUploaded){
                    setInstructionsUploaded(false)
                    setQuestionsUploaded(false)
                    handleCloseInternal()
                }
            })
            .catch(reason => {
                console.log(reason);
            });
        setLoading(false)
    }

    const handleIncorrectExam = (id, newStatus) => {
        if (!examIncorrectReason) {
            setIncorrectError("Por favor, introduce una razón");
            return;
        };
        setExamReviewIncorrect(id, newStatus);
    }

    const handleCloseInternal = () => {
        setInstructionsUploaded(false)
        setQuestionsUploaded(false)
        handleClose();
    }

    const setExamReviewIncorrect = async (id, newStatus) =>{
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        })
        await fetch(`/exams-api/exams/${id}/status`,{
            headers: headers,
            method: "POST",
            body: JSON.stringify({
                id:id,
                status:newStatus,
                reason:examIncorrectReason
            })
        })
            .then(r => r.json())
            .then(() =>{
                alertContext.setMessage(`Estado del examen actualizado`);
                alertContext.setOpen(true)
                handleClose()
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
            });
    }

    const setExamReviewCorrect = async (id) =>{
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type':'application/json'
        })
        await fetch(`/exams-api/exams/${id}/status`,{
            headers: headers,
            method: "POST",
            body: JSON.stringify({
                id:id,
                status:'REVISED_CORRECT'
            })
        })
            .then(r => r.json())
            .then(response =>{
                alertContext.setMessage(`Estado del examen actualizado`);
                alertContext.setOpen(true)
                handleClose()
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
            });
    }

    const fetchInstructions = async (id,filename) => {
        setLoading(true)
        await getExamsInstructionsFile(id,filename)
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
                console.log(reason);
            });
        setLoading(false)
    }

    const fetchQuestions = async (id,filename) => {
        setLoading(true)
        await getExamsQuestionsFile(id,filename)
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
                console.log(reason);
            });
        setLoading(false)
    }

    const onChangeExamIncorrectReason = (e) =>{
        if (e.target.value && incorrectError) setIncorrectError('');
        setExamIncorrectReason(e.target.value)
    }

    function representExamState(state){
        console.log(state)
        switch (state){
            case "NOT_UPLOADED":
                return (
                    <Grid container justify={"center"} spacing={2} style={{padding:25}}>
                        <Grid item md={11}>
                            <Typography variant={"h6"} color={"primary"} >
                                Subir el fichero de instrucciones y el fichero de preguntas si fuera necesario
                            </Typography>
                        </Grid>
                        <Grid item md={1}>
                            <IconButton>
                                <CloseIcon onClick={handleClose} />
                            </IconButton>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} component={"label"} color={"secondary"} fullWidth>
                                {instructionFiles ? instructionFiles[0].name : "Instrucciones del examen"}
                                <Input type={"file"}
                                       style={{display:"none"}}
                                       inputProps={{ multiple: true }}
                                       onChange={(event)=> setInstructionFiles(event.target.files)} />
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            { loading
                                ? <CircularProgress/>
                                : <Button variant={"contained"}
                                          color={"primary"}
                                          disabled={instructionsUploaded}
                                          onClick={()=>postInstructions(Array.from(instructionFiles))}>
                                    Subir
                                </Button>
                            }
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} component={"label"} color={"secondary"} fullWidth>
                                {questionFiles ? questionFiles[0].name : "Preguntas del examen"}
                                <Input type={"file"}
                                       style={{display:"none"}}
                                       inputProps={{ multiple: true }}
                                       onChange={(event)=> setQuestionFiles(event.target.files)}/>
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            { loading
                                ? <CircularProgress/>
                                : <Button variant={"contained"}
                                          color={"primary"}
                                          disabled={questionsUploaded}
                                          onClick={()=>postQuestions(Array.from(questionFiles))}>
                                    Subir
                                </Button>
                            }
                        </Grid>
                    </Grid>
                )
            case "UPLOAD_WITHOUT_FILES":
            case "UPLOADED":
                return (
                    <Grid container justify={"center"} spacing={2} style={{padding:25}}>
                        <Grid item md={12}>
                            <Alert severity="info">Los archivos se estan procensado por soporte... espere</Alert>
                        </Grid>
                        <Grid item md={6}>
                            { examSelected.instructionsFileName != null
                                ? <Button variant={"contained"}
                                          onClick={() => fetchInstructions(examSelected.id, "Instrucciones_" + examSelected.option)}
                                          fullWidth>
                                    Descargar Instrucciones
                                </Button>
                                : <Alert severity={"warning"}>Este examen no tiene fichero de instrucciones</Alert>
                            }

                        </Grid>
                        <Grid item md={6}>
                            { examSelected.questionsFileName != null
                                ? <Button variant={"contained"}
                                          onClick={()=>fetchQuestions(examSelected.id, "Preguntas_" + examSelected.option)}
                                          fullWidth>
                                    Descargar Preguntas
                                </Button>
                                : <Alert severity={"warning"}>Este examen no tiene fichero de preguntas</Alert>
                            }

                        </Grid>
                        <Grid item md={6}>
                            <Typography variant={"subtitle1"} align={"center"}>En caso de error, marcar el examen como incorrecto e indicar un motivo:</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <TextField placeholder={"Mensaje para soporte"} onChange={onChangeExamIncorrectReason} fullWidth error={!!incorrectError} helperText={incorrectError} />
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} color={"secondary"} onClick={() => handleIncorrectExam(examSelected.id, "UPLOAD_INCORRECT")} fullWidth>Marcar como incorrecto y enviar</Button>
                        </Grid>
                    </Grid>
                )
            case "UPLOAD_INCORRECT":
                return (
                    <Grid container justify={"center"} spacing={2} style={{padding:25}}>
                        <Grid item md={12}>
                            <Alert severity="error">Los archivos no se han subido correctamente, intentelo de nuevo</Alert>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} component={"label"} color={"secondary"} fullWidth>
                                Instrucciones del examen
                                <Input type={"file"}
                                       style={{display:"none"}}
                                       inputProps={{ multiple: true }}
                                       onChange={(event)=> setInstructionFiles(event.target.files)} />
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            { loading
                                ? <CircularProgress/>
                                : <Button variant={"contained"}
                                          color={"primary"}
                                          disabled={instructionsUploaded}
                                          onClick={()=>postInstructions(Array.from(instructionFiles))}>
                                    Subir
                                </Button>
                            }
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} component={"label"} color={"secondary"} fullWidth>
                                Preguntas del examen
                                <Input type={"file"}
                                       style={{display:"none"}}
                                       inputProps={{ multiple: true }}
                                       onChange={(event)=> setQuestionFiles(event.target.files)}/>
                            </Button>
                        </Grid>
                        <Grid item md={6}>
                            { loading
                                ? <CircularProgress/>
                                : <Button variant={"contained"}
                                          color={"primary"}
                                          disabled={questionsUploaded}
                                          onClick={()=>postQuestions(Array.from(questionFiles))}>
                                    Subir
                                </Button>
                            }
                        </Grid>
                    </Grid>
                )
            case "UPLOAD_CORRECT":
                return (
                    <Alert severity="success">Los archivos se han subido correctamente, espere mientras soporte configura el examen</Alert>
                )
            case "REVISION_PENDING":
                return (
                    <Grid container justify={"center"} spacing={2} style={{padding:25}}>
                        <Grid item md={12}>
                            <Alert severity="info">El examen esta pendiente de revision por parte del profesor, confirme si la configuracion es correcta</Alert>
                        </Grid>
                        <Grid item md={6}>
                            <Typography variant={"subtitle1"} align={"center"}>En caso de error, marcar el examen como incorrecto e indicar un motivo:</Typography>
                        </Grid>
                        <Grid item md={6}>
                            <TextField placeholder={"Mensaje para soporte"} onChange={onChangeExamIncorrectReason} fullWidth error={!!incorrectError} helperText={incorrectError} />
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} color={"secondary"} onClick={() => handleIncorrectExam(examSelected.id, "REVISED_INCORRECT")} fullWidth>Marcar como incorrecto y enviar</Button>
                        </Grid>
                        <Grid item md={6}>
                            <Button variant={"contained"} color={"primary"} onClick={() => setExamReviewCorrect(examSelected.id)} fullWidth>Confirmar examen correcto</Button>
                        </Grid>
                    </Grid>
                )
            case "REVISED_INCORRECT":
                return (
                    <Alert severity="error">El examen esta marcado como incorrecto por el profesor</Alert>
                )
            case "REVISED_CORRECT":
                return (
                    <Alert severity="success">El examen esta correctamente configurado</Alert>
                )
            default:
                return (
                    <Alert severity="error">El estado del examen {examSelected.id} no es valido</Alert>
                )
        }
    }

    return (
        <Modal open={modal}
               onClose={handleCloseInternal}
               closeAfterTransition
               style={{margin:25,display: "flex",alignItems:"center",justifyContent:"center"}}>
            <Paper variant={"outlined"}>
                {representExamState(examSelected.state)}
            </Paper>
        </Modal>
    )
}

export default ExamsInstructorModal
