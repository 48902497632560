import {useEffect, useState} from "react";
import {getCourses, getCoursesOfUser, getOrganizations} from "../../services/Exams/CourseService";

const CourseExamsDisplayerLogic = (coordinator,userId) => {

    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const [organizations, setOrganizations] = useState([])

    const fetchCourses = async () => {
        setLoading(true)
        let courses
        if(coordinator){
            courses = await getCoursesOfUser(userId)
        }else{
            courses = await getCourses()
        }
        setCourses(courses)
        setLoading(false)
    }

    const fetchOrganizations = async () => {
        setLoading(true)
        let organizations = await getOrganizations()
        setOrganizations(organizations)
        setLoading(false)
    }

    useEffect(() => {
        fetchOrganizations()
        fetchCourses()
    }, [])

    return {courses,organizations,loading}
}

export default CourseExamsDisplayerLogic