import Grid from "@material-ui/core/Grid";
import {
    LinearProgress
} from "@material-ui/core";
import React from "react";
import ExamsCourseCard from "../../views/Exams/Student/ExamsCourseCard";
import ExamSelectorLogic from "./ExamSelectorLogic";

const ExamSelector = ({courseId,organizations,userSelected}) => {

    const {exams,loading,choice,postChoiceAction,deleteChoiceAction} = ExamSelectorLogic(courseId,userSelected)

    return (
        <Grid container spacing={3} justify={"center"}>
            {loading === true
                ? <LinearProgress/>
                : exams.map((value, index) => {
                    return (
                        <Grid item key={index} md={3}>
                            <ExamsCourseCard
                                userId={userSelected}
                                exam={value}
                                organizations={organizations}
                                choice={choice}
                                deleteChoice={deleteChoiceAction}
                                postChoice={postChoiceAction}
                            />
                        </Grid>
                    )
                })
            }
        </Grid>
    )
}

export default ExamSelector
