import React, {useState} from "react";
import {CardContent, CardHeader, IconButton, Typography} from "@material-ui/core";
import Select from "@material-ui/core/Select";
import CardActions from "@material-ui/core/CardActions";
import DoneIcon from "@material-ui/icons/Done";
import Card from "@material-ui/core/Card";
import Button from "@material-ui/core/Button";
import LinearProgress from "@material-ui/core/LinearProgress";

const AssignationComponent = ({assignation,templatesList}) => {

    const [loading,setLoading] = useState(false)
    const [template,setTemplate] = useState(assignation.templateId)

    const handleOnChange = (event) => {
        setTemplate(event.target.value)
    }

    const updateAssignation = () => {
        setLoading(true);
        const assignationDTO = JSON.stringify({id: assignation.id, roleId: assignation.roleId, templateId: template})
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type': 'application/json'
        });
        fetch(`/notifications-api/assignations/${assignation.id}`,
            {
                headers: headers,
                method: "PATCH",
                body: assignationDTO
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setLoading(false);
    }

    return (
        <Card variant={"outlined"}>
            <CardHeader
                title={assignation.roleId}
            />
            <CardContent>
                <Typography color={"primary"}>
                    Selecciona una plantilla para los mensajes a este rol institucional
                </Typography>
                <Select id={assignation.roleId+"_select"} variant={"filled"} value={template} onChange={handleOnChange}>
                    {templatesList}
                </Select>
            </CardContent>
            <CardActions>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={<DoneIcon/>}
                        style={{marginLeft:"auto"}}
                        onClick={() => {updateAssignation()}}>
                    Confirmar
                </Button>
            </CardActions>
            { loading === true && <LinearProgress/>}
        </Card>
    )
}

export default AssignationComponent