import React from "react";
import {
    Card,
    CardContent,
    CardHeader, FormControlLabel,
    Grid,
    makeStyles, Radio,
    RadioGroup,
    Typography
} from "@material-ui/core";

const EnrollmentFormSEPIOptionData = ({selected,setSelected = null}) => {

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles()

    const handleChange = (event) => {
        if(setSelected){
            setSelected(event.target.value);
        }else {
            console.warn("Read-only Component")
        }
    };

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"CESIÓN DE DATOS PERSONALES AL SERVICIO DE ATENCIÓN AL ESTUDIANTE (SAE) DE LA UCAV"}
            />
            <CardContent>
                <RadioGroup name={"dataSEPIOption"} value={selected} onChange={handleChange}>
                    <Grid container direction={"column"}>
                        <Grid item>
                            <Typography variant={"caption"} align={"center"}>
                                Por tales motivos, el/la alumno/a firmante, (marque la opción que elige)
                            </Typography>
                        </Grid>
                        <Grid item md={12}>
                            <Grid container spacing={2} justifyContent={"center"} alignItems={"center"}>
                                <Grid item>
                                    <FormControlLabel value={"true"} control={<Radio />} label={"AUTORIZA"}/>
                                </Grid>
                                <Grid item>
                                    <FormControlLabel value={"false"} control={<Radio/>} label={"NO AUTORIZA"}/>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </RadioGroup>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormSEPIOptionData