import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    LinearProgress,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import ExamsInstructorFileManager from "./ExamsInstructorFileManager";
import ExamsInstructorModal from "./ExamsInstructorModal";

const ExamsInstructorView = () => {

    const [loading,setLoading] = useState(false)
    const [courses,setCourses] = useState([])
    const [expanded,setExpanded] = useState("")
    const [modal,setModal] = useState(false)
    const [examSelected,setExamSelected] = useState({})

    const handleOpenModal = (exam) => {
        setModal(true)
        setExamSelected(exam)
    }

    const handleCloseModal = () => {
        setModal(false)
        setExamSelected({})
        fetchCourses()
    }

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchCourses = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const courses = await fetch(`/exams-api/courses`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setCourses(courses)
        setLoading(false);
    }

    useEffect(()=> {
        fetchCourses()
    },[])

    return (
        <Grid container
              direction={"column"}
              spacing={3}
              style={{padding:"25px"}}>
            <Grid item>
                <Typography variant={"h4"} color={"primary"}>Tus Cursos:</Typography>
            </Grid>
            { loading === true
                ? <Grid item><LinearProgress/></Grid>
                : courses.map((value, index) => {
                    return (
                        <Grid item key={index}>
                            <Accordion expanded={expanded === `${index}`} onChange={handleChange(`${index}`)}>
                                <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                    <Grid container spacing={3}>
                                        <Grid item>
                                            <Typography variant={"caption"} color={"primary"}>Id: {value.courseId}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant={"body1"}>{value.courseName}</Typography>
                                        </Grid>
                                    </Grid>
                                </AccordionSummary>
                                <AccordionDetails>
                                    <ExamsInstructorFileManager courseId={value.courseId} openModal={handleOpenModal}/>
                                </AccordionDetails>
                            </Accordion>
                        </Grid>
                    )
                })
            }
            <ExamsInstructorModal modal={modal} handleClose={handleCloseModal} examSelected={examSelected}/>
        </Grid>
    )
}

export default ExamsInstructorView