import React, {useEffect, useState} from "react";
import Box from "@material-ui/core/Box";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AssignationsMain from "./AssignationsMain";
import TemplatesMain from "./TemplatesMain";
import LinearProgress from "@material-ui/core/LinearProgress";

const NotificationsMain = () => {

    const [tabValue, setTabValue] = useState(0);
    const [loading,setLoading] = useState(false)
    const [assignations,setAssignations] = useState([])
    const [templates,setTemplates] = useState([])

    const fetchAssignations = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/notifications-api/assignations`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        "id": 1,
                        "type": 0,
                        "roleId": "STUDENT",
                        "templateId": 1
                    },
                    {
                        "id": 2,
                        "type": 1,
                        "roleId": "Faculty",
                        "templateId": 2
                    },
                    {
                        "id": 3,
                        "type": 2,
                        "roleId": "COORD",
                        "templateId": 3
                    }
                ];
            });
        setAssignations(response)
        setLoading(false);
    }

    const fetchTemplates = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const response= await fetch(`/notifications-api/templates`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        "id": 1,
                        "type": 0,
                        "reference": "default",
                        "title": "asunto",
                        "text": "<html/>"
                    },
                    {
                        "id": 2,
                        "type": 1,
                        "reference": "custom",
                        "title": "asunto_custom",
                        "text": "<html/>_custom"
                    },
                ];
            });
        setTemplates(response)
        setLoading(false);
    }

    useEffect(() => {
        fetchAssignations()
        fetchTemplates()
    }, [])

    const handleTabChange = (event, newValue) => {
        console.log(`Going to tab ${newValue}`);
        setTabValue(newValue);
    };

    function TabPanel(props) {
        return(
            <div role="tabpanel"
                 hidden={tabValue !== props.index}
                 id={`tabpanel-${props.index}`}
                 aria-labelledby={`full-width-tab-${props.index}`}>
                { tabValue === props.index && (
                    <Box>
                        {props.component}
                    </Box>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <AppBar position={"static"}>
                <Tabs variant={"fullWidth"} value={tabValue} onChange={handleTabChange}>
                    <Tab label={"Asignaciones"} />
                    <Tab label={"Plantillas"} />
                </Tabs>
            </AppBar>
            <TabPanel index={0} component={<AssignationsMain templates={templates} assignations={assignations}/>}/>
            <TabPanel index={1} component={<TemplatesMain templates={templates}/>}/>
            { loading === true && <LinearProgress/> }
        </React.Fragment>
    )
}

export default NotificationsMain