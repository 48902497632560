import React from 'react';
import { Controller } from 'react-hook-form';
import Checkbox from '../../../Checkbox';

const InputCheckbox = ({
  className, control, name, label, description,rules,
  disabled, placeholder, onChange, inputProps, ...props }
) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      defaultValue={false}
      render={({
          field: { onChange, value },
          // fieldState: { error },
        }) => <div className={className}>
          <Checkbox
            name={name}
            label={label}
            description={description}
            checked={value}
            onChange={onChange}
            {...inputProps}
          />
        </div>
      }
      {...props}
    />
  )
}

export default InputCheckbox
