export const getGrades = async () => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/grades`
    return await fetch(endpoint, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving enrollments")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const getEnrollmentTerms = async () => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/terms`
    let url = new URL(endpoint,document.location)
    return await fetch(url, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving terms")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}

export const getCoursesOfGrades = async (gradeId, termId, available) => {
    let sessionId = sessionStorage.getItem("sessionId")
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    let endpoint = `/enrollments-api/grades/${gradeId}/${termId}/courses`
    let url = new URL(endpoint,document.location)
    if(available){
        url.searchParams.append("available",available)
    }
    return await fetch(url, {headers: headers})
        .then(r => {
            if(r.ok) return r.json()
            else if (r.status===404) return []
            else{
                throw new Error("Error retrieving enrollments")
            }
        })
        .catch(reason => {
            console.log(reason);
        })
}