import Grid from "@material-ui/core/Grid";
import FormControl from "@material-ui/core/FormControl";
import {CircularProgress, InputLabel, Select} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Button from "@material-ui/core/Button";
import React, {useEffect, useState} from "react";

const TitulationSelectionPanel = ({titulation,setTitulation,setActiveStep}) =>{

    const [titulations,setTitulations] = useState([])
    const [loading,setLoading] = useState(false)

    const fetchTitulations = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const titulations= await fetch("/interactions-api/titulations",{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        id: "CourseId1",
                        name: "Titulation1"
                    },
                    {
                        id: "CourseId2",
                        name: "Titulation2"
                    }
                ];
            });
        setTitulations(titulations);
        setLoading(false);
    }

    useEffect(() => {
        fetchTitulations(titulations)
    },[])

    return(
        <Grid container
            spacing={3}
            alignItems={"center"}
            justify={"space-around"}
            style={{padding:"10px"}}>
            { loading === true &&
                <React.Fragment>
                    <CircularProgress />
                </React.Fragment>
            }
            { loading === false &&
                <React.Fragment>
                    <Grid item lg={12}>
                        <FormControl variant={"standard"} fullWidth>
                            <InputLabel id={"TitulationSelectLabel"}>
                                Titulacion
                            </InputLabel>
                            <Select labelId={"TitulationSelectLabel"}
                                    id={"TitulationSelect"}
                                    value={titulation}
                                    onChange={(event => {setTitulation(event.target.value)})}>
                                <MenuItem>Sin Titulacion</MenuItem>
                                { titulations.map(value => {
                                    return (
                                        <MenuItem key={value.courseId} value={value.courseId}>{value.name}</MenuItem>
                                    )
                                })}
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item lg>
                        <Button variant={"contained"}
                                color={"primary"}
                                fullWidth
                                onClick={()=>{setActiveStep(1)}}>
                            Seleccionar
                        </Button>
                    </Grid>
                </React.Fragment>
            }
        </Grid>
    )
}

export default TitulationSelectionPanel