import React, {useContext} from "react";
import IconButton from "@material-ui/core/IconButton";
import {Close} from "@material-ui/icons";
import {Snackbar} from "@material-ui/core";
import SnackBarContext from "./SnackBarContext";

const AlertMessage = (props) => {
    const alertContext=useContext(SnackBarContext)
    return(
        <Snackbar open={alertContext.open}
                  autoHideDuration={5000}
                  message={alertContext.message}
                  onClose={() => alertContext.setOpen(false)}
                  action={
                      <React.Fragment>
                          <IconButton size="small"
                                      aria-label="close"
                                      color="inherit"
                                      onClick={() => alertContext.setOpen(false)}>
                              <Close fontSize="small" />
                          </IconButton>
                      </React.Fragment>
                  }
        />
    )
}

export default AlertMessage