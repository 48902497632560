import React, { useState, useEffect, useRef, useCallback } from 'react';
import { CircularProgress, Button, Icon, Tooltip, IconButton } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import InstancesTable from './InstancesTable/InstancesTable';
import {
  deleteTemplate,
  getTemplates, uploadTemplatesCSV,
} from '../../services/Instances/InstancesService';
import { copyToClipboard } from '../../Utils/utils';
import { useNotification } from '../../Utils/hooks';
import { setApplicationLoading } from '../../components/ApplicationLoading';
import InstanceFormDialog from './InstanceFormDialog';
import { generateTemplatesTable } from './InstancesTable/InstancesTable.utils';
import './Instances.scss';

const CSVKeys = ['name', 'start', 'end', 'termId'];

const InstancesAdmin = ({ secr }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const fileUploadRef = useRef(null);
  const notification = useNotification();
  const confirm = useConfirm();

  const getInstancesFn = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getTemplates();
      if (!result) throw new Error();
      setData(result);
    } catch {
      notification('error', 'Ha habido un error al cargar las plantillas');
    } finally {
      setLoading(false);
    }
  }, [notification]);

  useEffect(() => {
    getInstancesFn();
  }, [getInstancesFn]);

  const onUploadClick = event => {
    event.preventDefault();

    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  }

  const onCopyClick = () => {
    copyToClipboard(CSVKeys.join(','));
    notification('info', 'Las cabeceras se han copiado al portapapeles');
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);
      if (file) {
        try {
          setApplicationLoading(true);
          await uploadTemplatesCSV(file);
          notification('success', 'Las plantillas se han creado correctamente');
          getInstancesFn();
        } catch {
          notification('error', 'Ha habido un error al subir el CSV');
        } finally {
          setApplicationLoading(false);
        }
      }
    }
  }

  const onEdit = id => {
    const instance = data.find(ins => ins.id === id);
  
    if (instance) {
      setSelectedTemplate(instance);
      setShowForm(true);
    }
  }

  const onEditDialogClose = () => {
    setShowForm(false);
    setTimeout(() => setSelectedTemplate(null), 250);
  }

  const onSuccess = () => {
    onEditDialogClose();
    getInstancesFn();
  }

  const onDelete = async (e, instance) => {
    e.stopPropagation();

    confirm({
      title: 'Eliminar instancia',
      description: `Atención, esta acción eliminará la instancia ${instance.typeName}, ¿estás seguro de que deseas realizar esta acción?`,
    })
      .then(async () => {
        try {
          if (!instance) return;
          setApplicationLoading(true);
          await deleteTemplate(instance.id);
          setData(prev => prev.filter(ins => ins.id !== instance.id))
        } catch {
          notification('error', 'Ha habido un error al eliminar la instancia');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const columns = generateTemplatesTable(data, onEdit, undefined /* Remove this param */, undefined, onDelete);

  return (
    <div className="instances">
      <InstanceFormDialog
        visible={showForm}
        instance={selectedTemplate}
        onHide={onEditDialogClose}
        onSuccess={onSuccess}
        secr={secr}
      />
      { !secr && (
        <div className="upload-csv">
          <p className="disclaimer">
            Cargue un archivo con las cabeceras:&nbsp;
            <i>{CSVKeys.join(', ')}</i>
            <br /><br />
            Puedes utilizar el botón inferior para copiar las cabeceras requeridas al portapapeles
          </p>
          <div className="upload-csv__actions">
            <Tooltip title="Copiar cabeceras al portapapeles" aria-label="copy">
              <IconButton aria-label="copy">
                <Icon className="copy-icon" onClick={onCopyClick}>
                  content_copy
                </Icon>
              </IconButton>
            </Tooltip>
            <input
              type="file"
              onChange={onFilesChange}
              multiple={false}
              style={{ display: 'none' }}
              ref={fileUploadRef}
              accept=".csv"
            />
            <Button
              onClick={onUploadClick}
              variant="contained"
              color="primary"
            >
              Subir CSV
            </Button>
          </div>
        </div>
      )}
      <div className="template-actions">
        <Button
          onClick={() => setShowForm(true)}
          variant="contained"
          color="primary"
        >
          Crear nueva instancia
        </Button>
      </div>
      { loading
        ? (
          <div className="spinner-container">
            <CircularProgress size={32} />
          </div>
        ) : (
          <InstancesTable
            data={data}
            columns={columns}
          />
        )}
    </div>
  )
}

export default InstancesAdmin;
