import React, {useEffect, useState} from "react";
import {CardContent, CardHeader, FormControl, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import DoneIcon from "@material-ui/icons/Done";
import Card from "@material-ui/core/Card";
import CircularProgress from "@material-ui/core/CircularProgress";

const AddAssignationComponent = ({assignationType,assignationText,templatesList}) => {

    const [loading,setLoading] = useState(false)
    const [roles,setRoles] = useState([])
    const [role,setRole] = useState()
    const [template,setTemplate] = useState()

    const fetchRoles = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type': 'application/json'
        });
        let response= await fetch(`/notifications-api/roles`, { headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    { id: "1_1", roleId: "Test", name: "Name"}
                ]
            });
        console.log(response)
        setRoles(response)
        setLoading(false);
    }

    const createAssignation = () => {
        setLoading(true);
        const assignationDTO = JSON.stringify({id: 0,type: assignationType, roleId: role.roleId, templateId: template})
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-Type': 'application/json'
        });
        fetch(`/notifications-api/assignations`,
            {
                headers: headers,
                method: "POST",
                body: assignationDTO
            })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setLoading(false);
        window.location.reload(false);
    }

    useEffect(() => {
        fetchRoles()
    },[])

    const handleOnChange = (event) => {
        setTemplate(event.target.value)
    }

    const handleOnChangeRole = (event) => {
        setRole(event.target.value)
    }

    return (
        <Card raised>
            <CardHeader title={`Nueva Asignacion de Plantilla para ${assignationText}`} />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography color={"primary"} variant={"h5"}>
                            Selecciona un rol y la plantilla para enviar correos automaticos de {assignationText} al rol de ese usuario
                        </Typography>
                    </Grid>
                    <Grid item sm={6}>
                        <InputLabel required variant={"filled"}>Selecciona un rol</InputLabel>
                    </Grid>
                    <Grid item sm={6}>
                        <FormControl fullWidth variant={"outlined"}>
                            {loading === true
                                ?
                                <CircularProgress />
                                :
                                <Select value={role} onChange={handleOnChangeRole} >
                                    { roles.map(value => {
                                        return <MenuItem value={value}>{value.name}</MenuItem>
                                    })}
                                </Select>
                            }

                        </FormControl>
                    </Grid>
                    <Grid item sm={6}>
                        <InputLabel required variant={"filled"}>Selecciona una plantilla</InputLabel>
                    </Grid>
                    <Grid item sm={6}>
                        <FormControl fullWidth variant={"outlined"}>
                            <Select value={template} onChange={handleOnChange}>
                                {templatesList}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>
            </CardContent>
            <CardActions>
                <Button variant={"contained"}
                        color={"primary"}
                        startIcon={<DoneIcon/>}
                        style={{marginLeft:"auto"}} onClick={() => createAssignation()}>
                    Nueva Asignacion
                </Button>
            </CardActions>
        </Card>
    )
}

export default AddAssignationComponent