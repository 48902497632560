import React, {useEffect, useState} from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    LinearProgress,
    Typography
} from "@material-ui/core";
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Grid from "@material-ui/core/Grid";
import ExamsStudentSelector from "./ExamsStudentSelector";

const ExamsStudentView = () => {

    const [loading, setLoading] = useState(false)
    const [courses, setCourses] = useState([])
    const [organizations, setOrganizations] = useState([])
    const [expanded, setExpanded] = useState("")

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded(isExpanded ? panel : false);
    };

    const fetchCourses = async () => {
        setLoading(true);
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const courses = await fetch(`/exams-api/courses`, {headers: headers})
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setCourses(courses)
        setLoading(false);
    }

    const fetchOrganizations = async () => {
        setLoading(true);
        let sessionId = sessionStorage.getItem("sessionId");
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId
        });
        const organizations = await fetch(`/exams-api/courses/organizations`, {headers: headers})
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
            });
        setOrganizations(organizations)
        setLoading(false);
    }

    useEffect(() => {
        fetchOrganizations()
        fetchCourses()
    }, [])

    return (
        <div
            style={{padding:"20px", backgroundColor:"transparent"}}>
            <Grid container
                  elevation={3}
                  direction={"column"}
                  spacing={3}>
                <Grid item>
                    <Typography variant={"h4"} color={"primary"}>Tus Cursos:</Typography>
                </Grid>
                {loading === true
                    ? <Grid item><LinearProgress/></Grid>
                    : courses.map((value, index) => {
                        return (
                            <Grid item key={index}>
                                <Accordion expanded={expanded === `${index}`} onChange={handleChange(`${index}`)}>
                                    <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
                                        <Grid container spacing={3}>
                                            <Typography variant={"body1"}>{value.courseName}</Typography>
                                        </Grid>
                                    </AccordionSummary>
                                    <AccordionDetails>
                                        <ExamsStudentSelector organizations={organizations} courseId={value.courseId}/>
                                    </AccordionDetails>
                                </Accordion>
                            </Grid>
                        )
                    })
                }

            </Grid>
        </div>
    )
}

export default ExamsStudentView
