import { TABLE_ACTIONS } from './tableReducer.actions';

// export interface IReducerAction {
//   type: TABLE_ACTIONS;
//   payload?: any;
// };

// export interface ITablePagination {
//   page: number,
//   // count: number,
//   // Server expected values
//   // page: number,
//   size: number,
//   sortOrder: {
//     name?: string,
//     direction?: 'asc' | 'desc',
//   },
// }

// export interface ITable {
//   data: any[],
//   loading: boolean,
//   pagination: ITablePagination,
//   error?: string,
//   total?: number,
// }

// export const initialState: ITable = {
export const initialState = {
  data: [],
  loading: false,
  pagination: {
    page: 0,
    size: 10,
  },
  filters: {},
  sortOrder: {
    name: '',
    direction: 'desc'
  },
};

// export const reducer: React.Reducer<ITable, IReducerAction> = (state: ITable, action: IReducerAction) => {
export const reducer = (state, action) => {
  switch (action.type) {
    case TABLE_ACTIONS.UPDATE_PAGINATION:
      const pagination = action.payload;
      return {
        ...state,
        pagination: { ...state.pagination, ...pagination },
      };

    case TABLE_ACTIONS.SEARCH_IN_PROGRESS:
      return {
        ...state,
        loading: true,
      };

    case TABLE_ACTIONS.SEARCH_SUCCESS:
      const { data, total } = action.payload;
      return {
        ...state,
        data,
        total,
        loading: false,
      };

    case TABLE_ACTIONS.SEARCH_ERROR:
      return {
        ...initialState,
        error: action.payload,
        loading: false,
        data: [],
      };

    case TABLE_ACTIONS.RESET_SEARCH:
      return {
        ...initialState,
        loading: false,
      };

    

    case TABLE_ACTIONS.UPDATE_ELEMENT:
      const { id, newElement } = action.payload;
      const index = state.data.findIndex(element => element.id === id);
      const newData = [...state.data];
      newData[index] = newElement;
  
      return {
        ...state,
        data: newData,
      };

    case TABLE_ACTIONS.ADD_ELEMENT:
      return {
        ...state,
        data: [...state.data, action.payload],
      };

    case TABLE_ACTIONS.DELETE_ELEMENT:
      const newElements = state.data.filter(element => element.id !== action.payload);
  
      return {
        ...state,
        data: newElements,
      };

    case TABLE_ACTIONS.UPDATE_SORTING:
      const { name, direction } = action.payload;
      
      return {
        ...state,
        sortOrder: {
          name,
          direction,
        },
        pagination: {
          ...state.pagination,
          page: 0
        }
      };

    case TABLE_ACTIONS.UPDATE_FILTERS:
      const keys = Object.keys(action.payload);
      const filteredKeys = keys.filter(key => action.payload[key])

      const newFilters = filteredKeys.reduce((acc, key) => {
        acc[key] = action.payload[key];
        return acc;
      }, {});
  
      return {
        ...state,
        filters: newFilters,
        pagination: {
          ...state.pagination,
          page: 0
        }
      }

    default:
      return state;
  }
};
