import React, { useState, useEffect, useReducer, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import { useConfirm } from 'material-ui-confirm';
import classNames from 'classnames';
import TFGEditRequest from '../../TFGEditRequest/TFGEditRequest';
import InputText from '../../../../components/Inputs/InputText';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import {
  reducer, initialState, updatePaginationTableAction, searchInProgressTableAction, searchErrorTableAction,
  searchSuccessTableAction, changeOrderingTableAction, updateElementTableAction,
} from '../../../../reducers/tableReducer';
import { MUIDatatableLabels } from '../../../../Utils/Table.utils';
import { useNotification } from '../../../../Utils/hooks';
import { acceptTFGRequest, cancelTFGRequest, downloadTFGPDF, getTFGRequests, rejectTFGRequest } from '../../../../services/TFG/TFGService';
import { renderTFGStatus } from '../../TFG.utils';
import { dateInstanceToLocale } from '../../../../Utils/Date';
import { downloadFromRawData } from '../../../../Utils/utils';
import { generateRequestsTableActions } from './TFGRequestsTable.utils';
import TFGRequestsFilters from '../TFGRequestsFilters/TFGRequestsFilters';

// type ReducerType = React.Reducer<ITable, IReducerAction>;

const TFGRequestsTable = ({ instructor, secr, coordinator, student, onRowClick, onRequestsChange }) => {
  const [tableReducer, dispatch] = useReducer(reducer, initialState);
  const [rejectRequest, setRejectRequest] = useState('');
  const [rejectReason, setRejectReason] = useState('');
  const [showRejectDialog, setShowRejectDialog] = useState(false);
  const [editing, setEditing] = useState(null);
  const [showEditDialog, setShowEditDialog] = useState(false);
  const notification = useNotification();
  // const mountedRef = useRef(false);
  const confirm = useConfirm();
  const history = useHistory();

  useEffect(() => {
    async function retrieveRequests() {
      try {
        dispatch(searchInProgressTableAction())
        // setApplicationLoading(true);
        let params = null;
        if (coordinator) {
          params = { filter: 'GESTION' };
        } else if (instructor) {
          params = { filter: 'DIRECTOR' };
        }
        const result = await getTFGRequests(params, tableReducer.pagination, tableReducer.sortOrder, tableReducer.filters);
        if (!result) throw new Error();
        dispatch(searchSuccessTableAction({ data: result.content, total: result.totalElements }))
        onRequestsChange && onRequestsChange(result.content);
      } catch (err) {
        if (err?.response?.status === 403) {
          notification('error', 'No tienes acceso a la gestión de TFGs');
          const token = sessionStorage?.getItem('sessionId');
          history.push(`/${token}`);
          return;
        }
        if (!student) notification('error', 'Ha habido un error al obtener las solicitudes');
        dispatch(searchErrorTableAction('Ha habido un error al obtener las solicitudes'))
      } finally {
        // setApplicationLoading(false);
      }
    }

    // if (mountedRef.current || student) retrieveRequests();
    retrieveRequests();
  }, [coordinator, history, instructor, notification, onRequestsChange, tableReducer.pagination, tableReducer.sortOrder, tableReducer.filters, student]);

  // useEffect(() => {
  //   mountedRef.current = true;
  //   return () => mountedRef.current = false;
  // }, []);

  const onAccept = id => {
    confirm({
      title: 'Aceptar solicitud',
      description: 'Atención, esta acción aceptará la solicitud seleccionada, ¿estás seguro de que deseas realizar esta acción?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          const result = await acceptTFGRequest(id);
          dispatch(updateElementTableAction(id, result));
        } catch {
          notification('error', 'Ha habido un error al aceptar la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }
  
  const onCancel = id => {
    confirm({
      title: 'Cancelar solicitud',
      description: 'Atención, esta acción cancelará la solicitud seleccionada, ¿estás seguro de que deseas realizar esta acción?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          const result = await cancelTFGRequest(id);
          dispatch(updateElementTableAction(id, result));
        } catch {
          notification('error', 'Ha habido un error al cancelar la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onDownload = async id => {
    try {
      setApplicationLoading(true);
      const { file, name } = await downloadTFGPDF(id);
      downloadFromRawData(file, name);
    } catch {
      notification('error', 'Ha habido un error al descargar el fichero');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onRejectClick = id => {
    setRejectRequest(id);
    setTimeout(() => setShowRejectDialog(true), 250);
  }

  const onHideReject = () => {
    setShowRejectDialog(false);
    setTimeout(() => { setRejectRequest(''); setRejectReason(''); }, 250);
  }

  const onReject = async () => {
    try {
      const id = rejectRequest;
      setApplicationLoading(true);
      const result = await rejectTFGRequest(id, rejectReason);
      dispatch(updateElementTableAction(id, result));
      onHideReject();
    } catch {
      notification('error', 'Ha habido un error al rechazar la solicitud');
    } finally {
      setApplicationLoading(false);
    }
  }

  const onEditClick = request => {
    setEditing(request);
    setShowEditDialog(true);
  }

  const onHideEditDialog = () => {
    setShowEditDialog(false);
    setTimeout(() => setEditing(null), 250);
  }

  const onEditSuccess = (request) => {
    dispatch(updateElementTableAction(request.id, request));
    onHideEditDialog();
  }

  const onTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        dispatch(updatePaginationTableAction({ page: tableState.page }))
        break;

      case 'changeRowsPerPage':
        dispatch(updatePaginationTableAction({ size: tableState.rowsPerPage, page: 0 }))
        break;

      case 'sort':
        dispatch(changeOrderingTableAction(tableState.sortOrder))
        break;

      default:
        break;
    }
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    onRowClick,
    rowHover: !!onRowClick,
    textLabels: {
      ...MUIDatatableLabels,
      body: {
        ...MUIDatatableLabels.body,
        noMatch: 'No se han encontrado solicitudes en curso'
      }
    },
    print: false,
    download: true,
    onTableChange,
    rowsPerPage: tableReducer.pagination.size,
    rowsPerPageOptions: [10, 25],
    serverSide: true,
    page: tableReducer.pagination.page,
    count: tableReducer.total,
    sortOrder: tableReducer.sortOrder,
    sort: false,
    filter: false
  };

  const columns = [
    {
      name: 'id',
      options: {
        display: 'excluded',
        filter: false,
      }
    },
    ...(instructor || coordinator || secr) ? [
      {
        name: 'userName',
        label: 'Alumno',
        options: {
          sort: false,
        }
      },
      {
        name: 'dni',
        label: 'DNI',
        options: {
          sort: false,
        }
      },
      {
        name: 'email',
        label: 'Email',
        options: {
          display: false,
          sort: false,
        }
      },
      // TODO: Remove comment once implemented
      // {
      //   name: 'studentPhone',
      //   label: 'Teléfono',
      //   options: {
      //     display: false,
      //   }
      // },
    ] : [],
    {
      name: 'degreeName',
      label: 'Titulación',
      options: {
        sort: false,
      }
    },
    {
      name: 'provisionalTitle',
      label: 'Título provisional',
      options: {
        sort: false,
      }
    },
    {
      name: 'areaName',
      label: 'Área temática',
      options: {
        sort: false,
      }
    },
    {
      name: 'instructorName',
      label: 'Profesor',
      options: {
        sort: false,
      }
    },
    ...student ? [
      {
        name: 'priority',
        label: 'Preferencia',
        options: {
          customBodyRender: pref => {
            const priorities = ['-', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H'];
            return `Opción ${priorities[pref ?? 0]}`;
          }
        }
      }
    ] : [],
    {
      name: 'created',
      label: 'Fecha de solicitud',
      options: {
        customBodyRender: date => dateInstanceToLocale(date)
      }
    },
    {
      name: 'status',
      label: 'Estado',
      options: {
        customBodyRender: status => renderTFGStatus(status)
      }
    },
    {
      name: 'id',
      label: 'Acciones',
      options: {
        customBodyRenderLite: index => {
          const request = tableReducer.data[index];
          return generateRequestsTableActions(student, instructor, coordinator, secr, request, onDownload, onAccept, onRejectClick, onCancel, onEditClick);
        },
        download: false,
        sort: false,
      }
    }
  ]

  const TableClass = classNames('tfg-requests-table', { clickable: !!onRowClick });

  return (
    <div className="tfg-requests-table-container">
      <TFGEditRequest
        visible={showEditDialog}
        editing={editing}
        onHide={() => setShowEditDialog(false)}
        onSuccess={onEditSuccess}
      />
      <Dialog
        className="reject-request-dialog"
        open={showRejectDialog}
        onClose={onHideReject}
        maxWidth="sm"
        fullWidth
      >
        <DialogTitle>Rechazar petición</DialogTitle>
        <DialogContent>
          <div className="reject-dialog">
            <p>Atención, esta acción rechazará la solicitud seleccionada, puedes adjuntar una razón para el alumno en el campo inferior</p>
            <InputText
              name="reject-reason"
              label="Razón"
              value={rejectReason}
              onChange={e => setRejectReason(e.target.value)}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="secondary" onClick={onHideReject}>
            Cancelar
          </Button>
          <Button variant="contained" color="primary" onClick={onReject} >
            Rechazar solicitud
          </Button>
        </DialogActions>
      </Dialog>
      { !student && <TFGRequestsFilters dispatch={dispatch} /> }
      {
        tableReducer.loading
          ? (
            <div className="table-progress-container">
              <CircularProgress size={24} />
            </div>
          ) : (
            <MUIDataTable
              className={TableClass}
              title="Solicitudes realizadas"
              columns={columns}
              options={options}
              data={tableReducer.data}
            />
          )
      }
    </div>
  )
}

export default TFGRequestsTable
