import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import InputText from '../../../../components/Inputs/RHK/InputText';
import InputAutoCompleteValue from '../../../../components/Inputs/RHK/InputAutoCompleteValue';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import { useNotification } from '../../../../Utils/hooks';
import { required } from '../../../../Utils/utils';
import { createTFGSubject, getTFGAreaDegrees, getTFGTitulationManagers, updateTFGSubject } from '../../../../services/TFG/TFGService';

const defaultValues = {
  name: "",
  assignedManagerIds: [],
  assignedDegreeIds: [],
}

const TFGSubjectDialog = ({ visible, onHide, subject, onSuccess, selectedTerm }) => {
  const { control, handleSubmit, reset, setValue, watch } = useForm({ defaultValues });
  const [titulations, setTitulations] = useState([]);
  const [instructors, setInstructors] = useState([]);
  const [loadingTitulations, setLoadingTitulations] = useState(false);
  const [loadingInstructors, setLoadingInstructors] = useState(false);
  const notification = useNotification();
  const isEdit = !!subject;
  const service = isEdit ? updateTFGSubject : createTFGSubject;

  const assignedDegreeIds = watch('assignedDegreeIds');

  useEffect(() => {
    if (!visible) {
      reset(defaultValues);
    }
  }, [reset, visible]);

  // TODO: Revisar la edición
  useEffect(() => {
    reset(subject || defaultValues);
  }, [subject, reset]);

  useEffect(() => {
    // const existingInstructors = [];

    if (subject) {
      // subject.assignedManagerIds.forEach(instructorId => {
      //   const exists = instructors.some(t => t.value === instructorId)
      //   if (exists) existingInstructors.push(instructorId);
      // })
      // setValue('assignedManagerIds', existingInstructors);
      // TODO: Check, this is not working (retrieveDirectors is not called)
      setValue('assignedManagerIds', subject.assignedManagers.map(inst => inst.externalId));
      setValue('assignedDegreeIds', subject.assignedDegrees.map(titul => titul.externalId));
    }
  }, [setValue, subject]);

  // useEffect(() => {
  //   // If titulation changes, reset instructors
  //   if (!subject) {
  //     setValue('assignedManagerIds', [])
  //   }
  // }, [assignedDegreeIds, setValue, subject])

  useEffect(() => {
    async function retrieveTitulations(term) {
      try {
        setLoadingTitulations(true);
        const instructors = await getTFGAreaDegrees(term).then(
          res => res.map(item => ({ value: item.externalId, label: item.name }))
        );
        if (!instructors) throw new Error();
        setTitulations(instructors);
      } catch {
        // Notification
      } finally {
        setLoadingTitulations(false);
      }
    }

    if (selectedTerm) retrieveTitulations(selectedTerm);
  }, [selectedTerm]);

  useEffect(() => {
    async function retrieveDirectors(titulations) {
      try {
        setLoadingInstructors(true);
        const ids = titulations.join(',');
        const instructors = await getTFGTitulationManagers(ids).then(
          res => res.map(item => ({ label: item.name, value: item.externalId }))
        );
        if (!instructors) throw new Error();
        setInstructors(instructors);
        // if (subject?.instructor) setValue('instructor', subject.instructor)
      } catch {
        // Notification
      } finally {
        setLoadingInstructors(false);
      }
    }

    !!assignedDegreeIds?.length && retrieveDirectors(assignedDegreeIds);
  }, [assignedDegreeIds]);

  const onSubmit = async data => {
    try {
      setApplicationLoading(true);
      const result = await service({ ...data, academicYearId: selectedTerm });
      onSuccess(isEdit, result);
      reset(defaultValues);
      if (isEdit) {
        notification('success', 'El área temática se ha modificado correctamente');
      } else {
        notification('success', 'El área temática se ha creado correctamente');
      }
    } catch {
      notification('error', `Ha habido un error al ${isEdit ? 'actualizar' : 'crear'} el área temática`);
    } finally {
      setApplicationLoading(false);
    }
  }

  const dialogTitle = `${subject ? 'Editar' : 'Crear'} área temática`;

  return (
    // TODO: Disable backdrop close
    <Dialog
      className="subject-form-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputText
                name="name"
                control={control}
                label="Nombre"
                rules={required}
                autoComplete="off"
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                className="multiple"
                name="assignedDegreeIds"
                control={control}
                options={titulations}
                label="Titulaciones"
                loading={loadingTitulations}
                rules={required}
                multiple
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                className="multiple"
                name="assignedManagerIds"
                control={control}
                options={instructors}
                label="Directores"
                disabled={!assignedDegreeIds.length}
                loading={loadingInstructors}
                rules={required}
                multiple
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default TFGSubjectDialog
