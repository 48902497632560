import React, { useCallback, useEffect, useReducer, useRef } from 'react'
import { Button, Icon, IconButton, Tooltip } from '@material-ui/core';
import MUIDataTable from 'mui-datatables';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import {
  reducer, initialState, updatePaginationTableAction, searchInProgressTableAction,
  searchErrorTableAction, searchSuccessTableAction,
} from '../../../reducers/tableReducer';
import { getTFGPriorityGrades, uploadTFGPriorityGrades } from '../../../services/TFG/TFGService';
import { copyToClipboard } from '../../../Utils/utils';
import { useNotification } from '../../../Utils/hooks';
import { MUIDatatableLabels } from '../../../Utils/Table.utils';

const CSVKeys = ['userId', 'degreeId', 'priority'];

const TFGGrades = () => {
  const [gradesReducer, dispatch] = useReducer(reducer, initialState);
  const fileUploadRef = useRef(null);
  const notification = useNotification();

  const getGradesFn = useCallback(async () => {
    try {
      setApplicationLoading(true);
      dispatch(searchInProgressTableAction());
      const result = await getTFGPriorityGrades(gradesReducer.pagination);
      if (!result) throw new Error();
      dispatch(searchSuccessTableAction({ data: result.content, total: result.totalElements }))
    } catch {
      notification('error', 'Ha habido un error al obtener las notas');
      dispatch(searchErrorTableAction('Ha habido un error al obtener las notas'))
    } finally {
      setApplicationLoading(false);
    }
  }, [gradesReducer.pagination, notification]);

  useEffect(() => {
    getGradesFn();
  }, [getGradesFn]);

  const onUploadClick = event => {
    event.preventDefault();

    if (fileUploadRef.current) {
      fileUploadRef.current.click();
    }
  }

  const onCopyClick = () => {
    copyToClipboard(CSVKeys.join(','));
    notification('info', 'Las cabeceras se han copiado al portapapeles');
  }

  const onFilesChange = async event => {
    const files = event.target.files;

    if (files) {
      const file = files.item(0);

      if (file) {
        try {
          setApplicationLoading(true);
          await uploadTFGPriorityGrades(file);
          notification('success', 'Las notas se han almacenado correctamente');
          getGradesFn();
        } catch {
          notification('error', 'Ha habido un error al subir el CSV');
        } finally {
          setApplicationLoading(false);
        }
      }
    }
  }

  const onTableChange = (action, tableState) => {
    switch (action) {
      case 'changePage':
        dispatch(updatePaginationTableAction({ page: tableState.page }))
        break;

      case 'changeRowsPerPage':
        dispatch(updatePaginationTableAction({ size: tableState.rowsPerPage, page: 0 }))
        break;

      default:
        break;
    }
  }

  const options = {
    selectableRows: 'none',
    selectableRowsOnClick: false,
    textLabels: {
      ...MUIDatatableLabels,
      body: {
        ...MUIDatatableLabels.body,
        noMatch: 'No se han encontrado notas de prioridad en el sistema'
      }
    },
    sort: false,
    filter: false,
    search: false,
    print: false,
    download: false,
    onTableChange,
    rowsPerPage: gradesReducer.pagination.size,
    rowsPerPageOptions: [10, 25],
    serverSide: true,
    page: gradesReducer.pagination.page,
    count: gradesReducer.total,
    sortOrder: gradesReducer.sortOrder,
  };

  const columns = [
    {
      name: 'userId',
      label: 'ID Usuario',
    },
    {
      name: 'degreeId',
      label: 'ID Titulación',
    },
    {
      name: 'priority',
      label: 'Nota',
    },
  ]

  return (
    <div className="upload-grades">
      <div className="upload-csv">
        <p className="disclaimer">
          Cargue un archivo con las cabeceras:&nbsp;
          <i>{CSVKeys.join(', ')}</i>
          <br /><br />
          Puedes utilizar el botón inferior para copiar las cabeceras requeridas al portapapeles
        </p>
        <div className="upload-csv__actions">
          <Tooltip title="Copiar cabeceras al portapapeles" aria-label="copy">
            <IconButton aria-label="copy">
              <Icon className="copy-icon" onClick={onCopyClick}>
                content_copy
              </Icon>
            </IconButton>
          </Tooltip>
          <input
            type="file"
            onChange={onFilesChange}
            multiple={false}
            style={{ display: 'none' }}
            ref={fileUploadRef}
            accept=".csv"
          />
          <Button
            onClick={onUploadClick}
            variant="contained"
            color="primary"
          >
            Subir CSV
          </Button>
        </div>
      </div>
      <MUIDataTable
        className="tfg-priority-grades"
        title="Notas de prioridad"
        columns={columns}
        options={options}
        data={gradesReducer.data}
      />
    </div>
  )
}

export default TFGGrades