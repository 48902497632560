import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools'
import { ConfirmProvider } from "material-ui-confirm";
import { createTheme, ThemeProvider } from "@material-ui/core/styles";
import ApplicationLoading from './components/ApplicationLoading/ApplicationLoading';
import { AlertProvider } from "./Contexts/SnackBarContext";
import { SnackbarProvider } from "notistack";
import DashBoardContainer from "./views/Dashboard/DashboardContainer";
import { confirmOptions, snackbarOptions } from "./Providers.utils";
import { RoleProvider } from "./Contexts/RoleProvider";
import './App.css';
import './styles/base.scss';

let ucavilaTheme = createTheme({
    palette: {
        primary: { main: '#003F62' }, //Ucavila Blue
        secondary: { main: '#86AFD9' }, //Ucavila Red
        error: { main: '#AD0022' },
        background: { default: '#FFF', paper: '#eee'}
    },
});

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            // staleTime: 300000,
            // retryDelay: 1000,
            refetchOnMount: false,
            refetchOnWindowFocus: false,
            retry: false,
        }
    }
});

function App() {
    return (
        <ThemeProvider theme={ucavilaTheme}>
            <SnackbarProvider {...snackbarOptions}>
                <QueryClientProvider client={queryClient}>
                    <ReactQueryDevtools initialIsOpen={false} />
                    <ConfirmProvider defaultOptions={confirmOptions}>
                        <AlertProvider>
                            <RoleProvider>
                                <DashBoardContainer/>
                            </RoleProvider>
                            <ApplicationLoading />
                        </AlertProvider>
                    </ConfirmProvider>
                </QueryClientProvider>
            </SnackbarProvider>
        </ThemeProvider>
    );
}

export default App;
