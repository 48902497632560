import React from 'react'
import classNames from 'classnames'
import WarningIcon from '@material-ui/icons/Warning'
import InfoIcon from '@material-ui/icons/Info'
import ErrorIcon from '@material-ui/icons/Error'
import './Disclaimer.scss'

const Disclaimer = ({ className, title, type = 'warning', description }) => {
  const DisclaimerClass = classNames('disclaimer-box', type, className);

  const DisclaimerIcon = () => {
    if (type === 'info') return <InfoIcon />;
    if (type === 'error') return <ErrorIcon />;
    return <WarningIcon />;
  }

  return (
    <div className={DisclaimerClass}>
      <div className="disclaimer__icon">
        {DisclaimerIcon()}
      </div>
      <div className="disclaimer__info">
        {!!title && <h4 className="title">{title}</h4>}
        {!!description && <p className="description">{description}</p>}
      </div>
    </div>
  )
}

export default Disclaimer;
