import {
    Card,
    CardContent,
    CardHeader,
    FormControlLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    TextField
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import {validIban} from "../../../Utils/utils";
import InputSelect from "../../Inputs/InputSelect/InputSelect";

const EnrollmentFormDebtData = (
    {
        debtDNI,
        setDebtDNI,
        debtOption,
        setDebtOption,
        debtName,
        setDebtName,
        debtAddress,
        setDebtAddress,
        debtCity,
        setDebtCity,
        debtZipCode,
        setDebtZipCode,
        debtState,
        setDebtState,
        debtCountry,
        setDebtCountry,
        debtIBAN,
        setDebtIBAN,
        debtBIC,
        setDebtBIC,
        setDebtValidations,
        readOnly
    }) => {

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }))
    const classes = useStyles()

    const [debtDocType,setDebtDocType] = useState("ES")

    useEffect(()=>{
        let validDNI = debtDocType === 'ES' ? (!dniValidation(debtDNI) || !nieValidation(debtDNI) || !cifValidation(debtDNI)) : true
        let validName = !textValidation(debtName)
        let validAddress = !textValidation(debtAddress)
        let validCity = !textValidation(debtCity)
        let validZipCode = !zipCodeValidation(debtZipCode)
        let validState = !textValidation(debtState)
        let validCountry = !textValidation(debtCountry)

        const base = validDNI && validName && validAddress && validCity &&
        validZipCode && validState && validCountry;

        if (debtOption === "BUSINESS") {
            if (setDebtValidations) setDebtValidations(base);
            return;
        };

        let validIban = !IBANCodeValidation(debtIBAN)
        let validBic = !BICCodeValidation(debtBIC)
        let result = base && validIban && validBic;
        if (setDebtValidations) setDebtValidations(result);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[debtDocType, debtOption,debtDNI,debtName,debtAddress,debtCity,debtZipCode,debtState,debtCountry,debtIBAN,debtBIC])

    const handleChangeDebtDNI = (event) => {
        if(setDebtDNI) setDebtDNI(event.target.value)
    }

    const handleChangeDebtOption = (event) => {
        if(setDebtOption) setDebtOption(event.target.value)
    }

    const handleChangeDebtName = (event) => {
        if(setDebtName) setDebtName(event.target.value)
    }

    const handleChangeDebtAddress = (event) => {
        if(setDebtAddress) setDebtAddress(event.target.value)
    }

    const handleChangeDebtCity = (event) => {
        if(setDebtCity) setDebtCity(event.target.value)
    }

    const handleChangeDebtZipCode = (event) => {
        if(setDebtZipCode) setDebtZipCode(event.target.value)
    }

    const handleChangeDebtState = (event) => {
        if(setDebtState) setDebtState(event.target.value)
    }

    const handleChangeDebtCountry = (event) => {
        if(setDebtCountry) setDebtCountry(event.target.value)
    }

    const handleChangeDebtIBAN = (event) => {
        if(setDebtIBAN) setDebtIBAN(event.target.value)
    }

    const handleChangeDebtBIC = (event) => {
        if(setDebtBIC) setDebtBIC(event.target.value)
    }

    const dniValidation = (dni) => {
        return !/^\d{8}[a-zA-Z]$/.test(dni)
    }

    const nieValidation = (nie) => {
        return !/^[XYZ]\d{7,8}[A-Z]$/.test(nie)
    }

    const cifValidation = (cif) => {
        return !/^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/.test(cif)
    }

    const textValidation = (text) => {
        return text === ""
    }

    const zipCodeValidation = (text) => {
        return !/^\d{5}$/.test(text)
    }

    const IBANCodeValidation = (text) => {
        return !validIban(text)
    }

    const BICCodeValidation = (text) => {
        return !(text != null && text.length >= 8)
    }

    const debtDNIValidation = debtDocType === 'ES'
        ? dniValidation(debtDNI) && nieValidation(debtDNI) && cifValidation(debtDNI)
        : undefined;

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"DATOS DEL DEUDOR"}
                subheader={"Todos los campos han de cumplimentarse obligatoriamente"}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        <RadioGroup name={"debtOption"} row value={debtOption} onChange={handleChangeDebtOption}>
                            <FormControlLabel value={"INDIVIDUAL"} control={<Radio />} label={"Particular"}/>
                            <FormControlLabel value={"BUSINESS"} control={<Radio />} label={"Empresa"}/>
                        </RadioGroup>
                    </Grid>
                    { !readOnly && (
                        <Grid item md={6}>
                        <InputSelect
                            name="debtDocType"
                            value={debtDocType} 
                            onChange={event => setDebtDocType(event.target.value)}
                            options={[
                                { label: 'Documento español (DNI, NIE, CIF)', value: 'ES' },
                                { label: 'Documento extranjero (PAS, CI, CIF Extranjero)', value: 'EXT' },
                            ]}
                            label={"Tipo de documento"}
                        />
                    </Grid>
                    ) }
                    <Grid item md={6}>
                        <TextField
                            error={readOnly ? undefined : debtDNIValidation}
                            label={"DNI/CIF del deudor/es (titular/es de la cuenta de cargo)"}
                            placeholder={"DNI"}
                            variant={"filled"}
                            fullWidth
                            value={debtDNI}
                            onChange={handleChangeDebtDNI}
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={textValidation(debtName)}
                            label={"Nombre del deudor/es (titular/es de la cuenta de cargo)"}
                            placeholder={"Nombre"}
                            variant={"filled"}
                            fullWidth
                            value={debtName}
                            onChange={handleChangeDebtName}
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={textValidation(debtAddress)}
                            label={"Dirección del deudor"}
                            placeholder={"C/ Calle, 1"}
                            variant={"filled"}
                            fullWidth
                            value={debtAddress}
                            onChange={handleChangeDebtAddress}
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={textValidation(debtCity)}
                            label={"Población"}
                            placeholder={"Madrid"}
                            variant={"filled"}
                            fullWidth
                            value={debtCity}
                            onChange={handleChangeDebtCity}
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={zipCodeValidation(debtZipCode)}
                            label={"Código postal"}
                            placeholder={"00000"}
                            variant={"filled"}
                            value={debtZipCode}
                            onChange={handleChangeDebtZipCode}
                            fullWidth
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={textValidation(debtState)}
                            label={"Provincia"}
                            placeholder={"Madrid"}
                            variant={"filled"}
                            fullWidth
                            value={debtState}
                            onChange={handleChangeDebtState}
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={textValidation(debtCountry)}
                            label={"País del deudor"}
                            placeholder={"Portugal"}
                            variant={"filled"}
                            fullWidth
                            value={debtCountry}
                            onChange={handleChangeDebtCountry}
                            required/>
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={debtOption === "INDIVIDUAL" && IBANCodeValidation(debtIBAN)}
                            label={"IBAN"}
                            placeholder={"12345678901234"}
                            helperText={"El código IBAN (Internacional Bank Account Number), es el número de identificación internacional para las cuentas bancarias, normalizado por el Comité Europeo de Normalización Básica a través de las normas ISO13616 y EBS204. Por favor, cumplimente las casillas empezando por la primera posición y detalle la cuenta IBAN en la que desea se giren los cargos bancarios."}
                            variant={"filled"}
                            fullWidth
                            value={debtIBAN}
                            onChange={handleChangeDebtIBAN}
                            required={debtOption === "INDIVIDUAL"}
                            // disabled={debtOption === "BUSINESS"}
                        />
                    </Grid>
                    <Grid item md={6}>
                        <TextField
                            error={debtOption === "INDIVIDUAL" && BICCodeValidation(debtBIC)}
                            label={"BIC"}
                            placeholder={"12345"}
                            helperText={"El código BIC completa la información proporcionada por el IBAN."}
                            variant={"filled"}
                            fullWidth
                            value={debtBIC}
                            onChange={handleChangeDebtBIC}
                            required={debtOption === "INDIVIDUAL"}
                            // disabled={debtOption === "BUSINESS"}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormDebtData