import React from "react";
import Stepper from "@material-ui/core/Stepper";
import {Paper, StepLabel, Typography} from "@material-ui/core";
import Step from "@material-ui/core/Step";
import TitulationSelectionPanel from "./TitulationSelectionPanel";
import CourseSelectionPanel from "./CourseSelectionPanel";
import UserSelectionPanel from "./UserSelectionPanel";
import SendInteractionPanel from "./SendInteractionPanel";

const NewInteractionPanelContainer = (props) => {

    const steps=["Selecciona Titulacion","Selecciona Asignatura","Selecciona Usuario","Crear Interaccion"]
    const [activeStep, setActiveStep] = React.useState(0);
    const [titulation,setTitulation] = React.useState("");
    const [user,setUser] = React.useState("");
    const [course,setCourse] = React.useState("");

    return (
        <React.Fragment>
            <Stepper activeStep={activeStep}>
                {steps.map((value, index) => {
                    return (
                        <Step key={value}>
                            <StepLabel>{value}</StepLabel>
                        </Step>
                    )
                })}
            </Stepper>
            <Paper elevation={3} style={{margin:"25px"}}>
                <Typography variant={"h6"}>{`Crear nueva interaccion ${user}`}</Typography>
                { activeStep === 0 &&
                <TitulationSelectionPanel
                    setActiveStep={setActiveStep}
                    titulation={titulation}
                    setTitulation={setTitulation} />
                }
                { activeStep === 1 &&
                <CourseSelectionPanel setActiveStep={setActiveStep}
                                      course={course}
                                      setCourse={setCourse} />
                }
                { activeStep === 2 &&
                <UserSelectionPanel setActiveStep={setActiveStep}
                                    course={course}
                                    user={user}
                                    setUser={setUser} />
                }
                { activeStep === 3 &&
                <SendInteractionPanel setActiveStep={setActiveStep}
                                      titulation={titulation}
                                      course={course}
                                      user={user}
                                      handleTabChange={props.handleTabChange}/>
                }
            </Paper>
        </React.Fragment>
    )

}

export default NewInteractionPanelContainer;