import {Button, CircularProgress, Input, Modal, Paper, TextField, Typography} from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import React, {useContext, useState} from "react";
import {Alert} from "@material-ui/lab";
import SnackBarContext from "../../../Contexts/SnackBarContext";

const ExamManagerModal = ({modal, handleClose, handleOpen, fetchExams, examSelected}) => {

    const [loading, setLoading] = useState(false)
    const alertContext = useContext(SnackBarContext)
    const acceptWithoutFiles = async (id) => {
        let sessionId = sessionStorage.getItem("sessionId")
        let headers = new Headers({
            'Authorization': 'Bearer ' + sessionId,
            'Content-Type': 'application/json'
        })
        await fetch(`/exams-api/exams/${examSelected.id}/uploadwithoutfiles`, {
            headers: headers,
            method: "POST",
        })
            .then(r => r.json())
            .then(response => {
                handleClose()
                fetchExams()
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                alertContext.setOpen(true)
            });
    }
    return (
        <Modal open={modal}
               onClose={handleClose}
               closeAfterTransition
               style={{margin: 25, display: "flex", alignItems: "center", justifyContent: "center"}}>
            <Paper variant={"outlined"}>
                <Grid container justify={"center"} spacing={2} style={{padding: 25}}>
                    <Grid item md={12}>
                        <Typography variant={"h6"} color={"primary"}>
                            Al realizar esta acción el examen se aceptará sin datos cargados
                        </Typography>
                    </Grid>
                </Grid>
                <Grid container alignItems={"center"} spacing={2} style={{padding: 25}}>
                    <Grid item md={6} style={{textAlign: "center"}}>
                        <Button variant={"contained"} onClick={acceptWithoutFiles}> Aceptar </Button>
                    </Grid>
                    <Grid item md={6} style={{textAlign: "center"}} alignContent={"center"}>
                        <Button variant={"contained"} onClick={() => handleClose()}> Cancelar </Button>
                    </Grid>
                </Grid>
            </Paper>
        </Modal>
    )
}

export default ExamManagerModal
