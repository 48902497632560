import React, {useContext, useEffect, useState} from "react";
import {CardContent, CardHeader, Grid, InputLabel, Paper, Select, TextField} from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import Card from "@material-ui/core/Card";
import CardActions from "@material-ui/core/CardActions";
import Button from "@material-ui/core/Button";
import SnackBarContext from "../../../Contexts/SnackBarContext";
import LinearProgress from "@material-ui/core/LinearProgress";

const StudentRecordNewInteractionPanel = ({userSelected,fetchInteractions}) => {

    const alertContext=useContext(SnackBarContext)
    const [loading,setLoading] = useState(false)
    const [courses,setCourses] = useState([])
    const [course,setCourse] = React.useState("")
    const [comment,setComment]=useState("")

    function sendNewInteraction(){
        if(comment===""){
            alertContext.setMessage("Es necesario un comentario para generar una interaccion nueva")
            alertContext.setOpen(true)
        }else{
            let interaction = {
                "issuer" : sessionStorage.getItem("sessionId"),
                "receiver" : userSelected,
                "dni" : userSelected,
                "course" : course,
                "interactionText" : comment
            }
            console.log(interaction);
            fetchPostInteraction(JSON.stringify(interaction))
        }
    }

    const fetchPostInteraction = async (interaction) => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId,
            'Content-type': 'application/json'
        });
        await fetch("/interactions-api",{
            headers: headers,
            method: "POST",
            body: interaction })
            .then(r => r.json())
            .then(response =>{
                alertContext.setMessage(`Interaccion ${response.id} creada`);
                alertContext.setOpen(true);
                fetchInteractions()
            })
            .catch(reason => {
                alertContext.setMessage(`Ocurrio un error: ${reason}`);
                console.log(reason);
            });
        setLoading(false);
    }

    const fetchCourses = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const courses= await fetch(`/interactions-api/${userSelected}/courses`,{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        courseId: "CourseId1",
                        name: "Asignatura1"
                    },
                    {
                        courseId: "CourseId2",
                        name: "Asignatura2"
                    }
                ];
            });
        setCourses(courses);
        setLoading(false);
    }

    useEffect(() =>{
        fetchCourses(courses)
    },[])

    return (
        <React.Fragment>
            <Paper elevation={3} style={{margin:"25px"}}>
                <Card>
                    <CardHeader title={"Crear nueva interaccion"} subheader={`Usuario ${userSelected}`}/>
                    <CardContent>
                        <Grid container
                              spacing={2}
                              justify={"space-evenly"} direction={"column"}>
                            <Grid item>
                                { loading === true
                                    ? <LinearProgress/>
                                    : <React.Fragment>
                                        <InputLabel id={"CourseSelectLabel"}>
                                            Asignatura
                                        </InputLabel>
                                        <Select labelId={"CourseSelectLabel"}
                                                id={"CourseSelect"}
                                                variant={"filled"}
                                                value={course}
                                                style={{minWidth:"130px"}}
                                                onChange={(event => {setCourse(event.target.value)})}>
                                            { courses.map(value => {
                                                return (
                                                    <MenuItem key={value.courseId} value={value.courseId}>{value.name}</MenuItem>
                                                )
                                            })}
                                        </Select>
                                    </React.Fragment>
                                }
                            </Grid>
                            <Grid item>
                                <TextField label={"Comentario"}
                                           required
                                           onChange={(e) => setComment(e.target.value)}
                                           variant={"filled"}
                                           multiline
                                           placeholder={"Comentario de la interaccion"}/>
                            </Grid>
                        </Grid>
                    </CardContent>
                    <CardActions>
                        <Button variant={"contained"}
                                color={"primary"}
                                onClick={() => { sendNewInteraction() }}>
                            Crear Interaccion
                        </Button>
                    </CardActions>
                </Card>
            </Paper>
        </React.Fragment>
    )

}

export default StudentRecordNewInteractionPanel;