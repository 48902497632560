import React, { useEffect } from 'react'
import { useQuery } from 'react-query';
import InputSelect from '../../../components/Inputs/InputSelect';
import { getTFGAcademicYears } from '../../../services/TFG/TFGService';
import { useNotification } from '../../../Utils/hooks';

const defaultTerms = [];

const TFGAcademicYearSelector = ({ selectedTerm, setSelectedTerm, setTermStatus }) => {
  const notification = useNotification();

  const { data: terms = defaultTerms, isLoading: loadingTerms } = useQuery(
    'terms',
    getTFGAcademicYears,
    {
      select: res => res.map(term => ({ ...term, value: term.id, label: term.name })),
      onError: () => notification('error', 'Ha habido un error al obtener los años académicos'),
    }
  );

  useEffect(() => {
    if (setTermStatus) setTermStatus({ loading: loadingTerms, data: terms });
  }, [loadingTerms, setTermStatus, terms])

  useEffect(() => {
    if (terms.length) {
      const activeTerm = terms.find(term => term.active);
      if (activeTerm) setSelectedTerm(activeTerm.id);
    }
  }, [setSelectedTerm, terms])

  return (
    <div className="term-selector">
      <InputSelect
        name="termId"
        label="Año académico"
        options={terms}
        value={selectedTerm} 
        onChange={e => setSelectedTerm(e.target.value)}
        disabled={loadingTerms}
      />
    </div>
  )
}

export default TFGAcademicYearSelector