import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import React from "react";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
import InteractionListContainer from "./InteractionsListPanel/InteractionsListContainer";
import NewInteractionContainer from "./NewInteractionPanel/NewInteractionContainer";

const InteractionsMain = (props) => {

    const [value, setValue] = React.useState(0);

    const handleTabChange = (event, newValue) => {
        console.log(`Going to tab ${newValue}`);
        setValue(newValue);
    };

    function TabPanel(props) {
        return(
            <div role="tabpanel"
                 hidden={value !== props.index}
                 id={`full-width-tabpanel-${props.index}`}
                 aria-labelledby={`full-width-tab-${props.index}`}>
                { value === props.index && (
                    <Box>
                        {props.component}
                    </Box>
                )}
            </div>
        )
    }

    return (
        <React.Fragment>
            <AppBar position={"static"}>
                <Tabs variant={"fullWidth"} value={value} onChange={handleTabChange}>
                    <Tab label="Interacciones" />
                    <Tab label="Nueva Interaccion" />
                </Tabs>
            </AppBar>
            <TabPanel index={0} component={InteractionListContainer}/>
            <TabPanel index={1} component={ <NewInteractionContainer handleTabChange={handleTabChange} /> }/>
        </React.Fragment>
    )

}

export default InteractionsMain;