import React, {useEffect, useState} from "react";
import RoleContext from "./RoleContext";

class Session {
    user
    role
    constructor(json) {
        Object.assign(this,json)
    }
}

const RoleProvider = (props) => {
    const [role,setRole] = useState()

    const fetchRole = async () => {
        let sessionId=sessionStorage.getItem("sessionId")
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        })
        let json = await fetch(`/sessions/session/${sessionId}`,{ headers: headers })
            .then(response => {
                if(response.ok){
                    return response.json()
                }else {
                    throw new Error("Error recieving session...")
                }
            })
            .catch(reason => {
                console.error(reason);
            });
        if(typeof json === typeof {} ){
            // console.log("No error")
        }else {
            console.log("error")
            // json={ role: "ROLE_ADMIN" }
            // json={ role: "ROLE_STUDENT" }
            // json={ role: "ROLE_INSTRUCTOR" }
            // json={ role: "ROLE_COORD" }
            // json={ role: "ROLE_SECR" }
        }
        let session=new Session(json);
        setRole(session.role)
    }

    useEffect(() =>{
        fetchRole()
    }, [])

    return (
        <RoleContext.Provider value={{role}}>
            {props.children}
        </RoleContext.Provider>
    )
}

export { RoleProvider }