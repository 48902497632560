import React, { useState, useEffect } from 'react'
import { Dialog, DialogActions, DialogContent, DialogTitle, Button } from '@material-ui/core';
import InputText from '../../../../components/Inputs/InputText';
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import { useNotification } from '../../../../Utils/hooks';
import { acceptTFGDefenseRequest } from '../../../../services/TFG/TFGService';

const TFGDefenseAcceptDialog = ({ accept, visible, onHide, onAcceptSuccess }) => {
  const [location, setLocation] = useState('');
  const [error, setError] = useState('');
  const notification = useNotification();

  useEffect(() => {
    if (!visible) setLocation('');
  }, [visible]);

  const onAccept = async () => {
    try {
      if (!location) {
        setError('Debes introducir un lugar para la defensa');
        return;
      }
      setApplicationLoading(true);
      const result = await acceptTFGDefenseRequest(accept.requestId, accept.id, location);
      onAcceptSuccess(result);
    } catch {
      notification('error', 'Ha habido un error al aceptar la solicitud');
    } finally {
      setApplicationLoading(false);
    }
  }

  return (
    <Dialog
      className="accept-request-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Aceptar solicitud</DialogTitle>
      <DialogContent>
        <div className="accept-dialog">
          <p>Atención, esta acción aceptará la solicitud de defensa seleccionada, por favor introduce el lugar donde se realizará la defensa</p>
          <InputText
            name="location"
            label="Lugar"
            placeholder="Lugar donde se realizará la defensa"
            value={location}
            onChange={e => setLocation(e.target.value)}
            error={error}
          />
        </div>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={onAccept} >
          Aceptar solicitud
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default TFGDefenseAcceptDialog