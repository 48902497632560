import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Grid } from '@material-ui/core';
import InputText from '../../../components/Inputs/RHK/InputText';
import InputSelect from '../../../components/Inputs/RHK/InputSelect';
import { instanceStatusOptions } from '../Instances.utils';
import InputDate from '../../../components/Inputs/RHK/InputDate';
import { dateYesterday } from '../../../Utils/Date';
import InputCheckbox from '../../../components/Inputs/RHK/InputCheckbox/InputCheckbox';

const InstancesFilters = ({ onSearch }) => {
  const { control, handleSubmit } = useForm();

  const onSubmit = data => {
    onSearch(data);
  }

  useEffect(() => {
    onSearch({ createdFrom: dateYesterday() })
  }, [onSearch]);

  return (
    <div className="filters">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <h3>Filtros</h3>
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="id"
            label="ID de solicitud"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="userExternalId"
            label="ID del alumno"
            control={control}
          />
        </Grid>
        <Grid item md={4} xs={12}>
          <InputText
            name="instance"
            label="ID de instancia"
            control={control}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputText
            name="studentId"
            label="DNI del alumno"
            control={control}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputSelect
            name="status"
            label="Estado"
            control={control}
            options={instanceStatusOptions}
            inputProps={{ multiple: true }}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputDate
            name="createdFrom"
            label="Fecha de creación (desde)"
            control={control}
            defaultValue={dateYesterday()}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputDate
            name="createdTo"
            label="Fecha de creación (hasta)"
            control={control}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputDate
            name="closedFrom"
            label="Fecha de cierre (desde)"
            control={control}
          />
        </Grid>
        <Grid item md={6} xs={12}>
          <InputDate
            name="closedTo"
            label="Fecha de cierre (hasta)"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <InputCheckbox
            name="external"
            label="Mostrar las instancias de alumnos externos"
            control={control}
          />
        </Grid>
        <Grid item xs={12}>
          <div className="filters--actions">
            <Button
              onClick={handleSubmit(onSubmit)}
              variant="contained"
              color="primary"
            >
              Aplicar filtros
            </Button>
          </div>
        </Grid>
      </Grid>
    </div>
  )
}

export default InstancesFilters