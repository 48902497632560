import React, {useState} from "react";
import {
    FormControlLabel,
    LinearProgress, List,
    Paper,
    Radio,
    RadioGroup,
    TextField, Typography
} from "@material-ui/core";
import Button from "@material-ui/core/Button";
import FormControl from "@material-ui/core/FormControl";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import { getGeneralUsers } from "../../../services/General/General";

const StudentRecordUserSelectionContainer = (props) => {

    const [search,setSearch] = useState("")
    const [searchType,setSearchType] = useState("id")
    const [users,setUsers] = useState([])
    const [loading,setLoading] = useState(false)

    const fetchUsers = async () => {
        try {
            setLoading(true);
            const result = await getGeneralUsers(searchType, search);
            setUsers(result);
        } catch {
            //
        } finally {
            setLoading(false);
        }
    }

    return (
        <React.Fragment>
            <Paper elevation={3} style={{margin:"25px"}}>
                <Grid container spacing={2} justify={"flex-end"} style={{padding:"15px"}}>
                    <Grid item md={12}>
                        <FormControl component={"fieldset"} fullWidth>
                            <TextField label={"Buscar"}
                                       name={"searchBy"}
                                       onChange={event => { setSearch(event.target.value) }}
                                       InputProps={{
                                           endAdornment:
                                               <Button
                                                   variant={"contained"}
                                                   color={"primary"}
                                                   onClick={fetchUsers}>
                                                   Buscar
                                               </Button>
                                       }}/>
                            <RadioGroup row value={searchType} onChange={event => setSearchType(event.target.value)}>
                                <FormControlLabel value={"id"} control={<Radio color={"primary"}/> } label={"Por Id"}/>
                                <FormControlLabel value={"name"} control={<Radio color={"primary"}/> } label={"Por Nombre"}/>
                                <FormControlLabel value={"family"} control={<Radio color={"primary"}/> } label={"Por Apellidos"}/>
                                <FormControlLabel value={"email"} control={<Radio color={"primary"}/> } label={"Por Correo Electronico"}/>
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item md={12}>
                        <Typography variant={"caption"}>Seleccione al usuario</Typography>
                        { loading === true && <LinearProgress /> }
                        <List>
                            { users.map((value, index) => {
                                return (
                                    <ListItem button key={index}
                                              onClick={() => { props.setUserSelected(value.dni) }}>
                                        <ListItemText primary={`${value.name} ${value.surnames}`}
                                                      secondary={value.dni}/>
                                    </ListItem>
                                )
                            })}
                        </List>
                    </Grid>
                </Grid>
            </Paper>
        </React.Fragment>
    )
}

export default StudentRecordUserSelectionContainer