import React, { useEffect } from 'react'
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid } from '@material-ui/core'
import { setApplicationLoading } from '../../../../components/ApplicationLoading';
import InputSelect from '../../../../components/Inputs/RHK/InputSelect';
import InputText from '../../../../components/Inputs/RHK/InputText';
import InputDate from '../../../../components/Inputs/RHK/InputDate';
import { useNotification } from '../../../../Utils/hooks';
import { required } from '../../../../Utils/utils';
import { createEnrollmentTerm, updateEnrollmentTerm } from '../../../../services/Enrollments/NewEnrollmentsService';
import InputCheckbox from '../../../../components/Inputs/RHK/InputCheckbox/InputCheckbox';

const initialValues = { active: true, name: '', type: '', startDate: null, endDate: null };

const NewEnrollmentsTermDialog = ({ visible, editing, onHide, onSuccess }) => {
  const { control, handleSubmit, reset } = useForm();
  const notification = useNotification();

  useEffect(() => {
    reset(editing ?? initialValues);
  }, [editing, reset])

  const onSubmit = async data => {
    try {
      setApplicationLoading(true);
      const service = editing ? updateEnrollmentTerm : createEnrollmentTerm;
      const result = await service(data);
      if (!result) throw new Error();
      onSuccess(!!editing, result);
      reset(initialValues);
    } catch {
      notification('error', `Ha habido un error al ${editing ? 'actualizar' : 'crear'} la convocatoria`);
    } finally {
      setApplicationLoading(false);
    }
  }

  return (
    <Dialog
      className="enrollment-term-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
    >
      <DialogTitle>Convocatoria</DialogTitle>
      <DialogContent>
        <form className="enrollment-term-form" onSubmit={handleSubmit(onSubmit)}>
          <Grid container spacing={2} justifyContent='end'>
            <Grid item xs={12} className="checkbox-grid">
              <InputCheckbox
                name="active"
                control={control}
                label="Activa"
              />
            </Grid>
            <Grid item xs={12}>
              <InputSelect
                name="type"
                label="Tipo de convocatoria"
                control={control}
                rules={required}
                options={[
                  { label: 'Automatrícula', value: 'R' },
                  { label: 'Extensión de matrícula', value: 'A' },
                ]}
              />
            </Grid>
            {/* TODO: Add titulation selector (multiple) */}
            <Grid item xs={12}>
              <InputText
                name="name"
                label="Nombre de convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputDate
                name="startDate"
                // label="Fecha inicio inscripciones"
                label="Fecha de inicio"
                // helperText="Fecha a partir de la que los alumnos pueden solicitar instancias de TFG para esta convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
            <Grid item md={6} xs={12}>
              <InputDate
                name="endDate"
                // label="Fecha fin inscripciones"
                label="Fecha de fin"
                // helperText="Fecha hasta la que los alumnos pueden solicitar instancias de TFG para esta convocatoria"
                control={control}
                rules={required}
              />
            </Grid>
          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="secondary" onClick={onHide}>
          Cancelar
        </Button>
        <Button variant="contained" color="primary" onClick={handleSubmit(onSubmit)}>
          Guardar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default NewEnrollmentsTermDialog