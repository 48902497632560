import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import StudentRecordUserSelectionContainer from '../../StudentRecord/CoordUserSelection/StudentRecordUserSelectionContainer';
import InstancesTable from '../InstancesTable/InstancesTable';
import InstancesOnBehalfDialog from './InstancesOnBehalfDialog';
import { generateTemplatesTable } from '../InstancesTable/InstancesTable.utils';
import { getTemplates, getUserInstanceInfoById } from '../../../services/Instances/InstancesService';
import { useNotification } from '../../../Utils/hooks';
import { Button, CircularProgress, Icon, IconButton, Tooltip } from '@material-ui/core';

const InstancesOnBehalf = () => {
  const [data, setData] = useState([]);
  const [userInfo, setUserInfo] = useState(null);
  const [loading, setLoading] = useState(false);
  const [userSelected, setUserSelected] = useState("")
  const [showDialog, setShowDialog] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [external, setExternal] = useState(false);
  const notification = useNotification();

  useEffect(() => {
    const getInstancesFn = async () => {
      try {
        setLoading(true);
        const result = await getTemplates();
        if (!result) throw new Error();
        setData(result);
      } catch {
        notification('error', 'Ha habido un error al cargar las plantillas');
      } finally {
        setLoading(false);
      }
    }

    getInstancesFn();
  }, [notification]);

  useEffect(() => {
    const getUserInfo = async userSelected => {
      try {
        const result = await getUserInstanceInfoById(userSelected);
        if (!result) throw new Error();
        setUserInfo(result);
      } catch {
        notification('error', 'Ha habido un error al cargar la información del usuario');
      }
    }

    userSelected && getUserInfo(userSelected);
  }, [userSelected, notification]);

  const onInstanceClick = (rowData) => {
    const id = rowData[0];
    const instance = data.find(instance => instance.id === id);
    setSelectedInstance(instance);
    setShowDialog(true);
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setSelectedInstance(), 250);
  }

  const onNewRequest = () => {
    onRemoveUser();
    onHideDialog();
    // setTabValue(1);
  }

  const onRemoveUser = () => {
    setUserInfo(null);
    setUserSelected('');
  }

  const columns = generateTemplatesTable(data);

  return (
    <div className="instances on-behalf">
      <InstancesOnBehalfDialog
        visible={showDialog}
        instance={selectedInstance}
        user={userInfo}
        onHide={onHideDialog}
        onSuccess={onNewRequest}
        userSelected={userSelected}
        external={external}
      />
      <div className="external-toggle">
        <Button color="primary" onClick={() => setExternal(prev => !prev)}>
          { external && (<Icon>arrow_back_ios</Icon>) }
          {`${external ? 'Volver al buscador de alumnos' : 'Crear instancia de alumno externo'}`}
          { !external && (<Icon>arrow_forward_ios</Icon>) }
        </Button>
      </div>
      {
        !external && (
          !!userSelected ? (
            <p className="selected-user">
              <Tooltip title="Deshacer selección" aria-label="close">
                <IconButton aria-label="close" onClick={onRemoveUser}>
                  <Icon className="close-icon">
                    close
                  </Icon>
                </IconButton>
              </Tooltip>
              Usuario seleccionado: {userSelected}
            </p>
          ) : (
            <StudentRecordUserSelectionContainer
              userSelected={userSelected}
              setUserSelected={setUserSelected}
            />
          )
        )
      }
      { ((!!userSelected && !userInfo) || loading) && (
        <div className="loading-table-container">
          <CircularProgress size={24} />
        </div>
      ) }
      { ((!loading && !!userInfo) || (!loading && external)) && (
        <InstancesTable
          data={data}
          columns={columns}
          title="Instancias disponibles"
          onRowClick={onInstanceClick}
        />
      ) }
    </div>
  )
}

export default InstancesOnBehalf;