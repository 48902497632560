// import { ITablePagination } from "./tableReducer";

export const TABLE_ACTIONS = {
  UPDATE_PAGINATION: 'UPDATE_PAGINATION',
  // UPDATE_SORTING: 'UPDATE_SORTING',
  SEARCH_IN_PROGRESS: 'SEARCH_IN_PROGRESS',
  SEARCH_SUCCESS: 'SEARCH_SUCCESS',
  SEARCH_ERROR: 'SEARCH_ERROR',
  RESET_SEARCH: 'RESET_SEARCH',
  ADD_ELEMENT: 'ADD_ELEMENT',
  UPDATE_ELEMENT: 'UPDATE_ELEMENT',
  DELETE_ELEMENT: 'DELETE_ELEMENT',
  UPDATE_FILTERS: 'UPDATE_FILTERS',
  UPDATE_SORTING: 'UPDATE_SORTING',
}

export const updatePaginationTableAction = payload => ({
  type: TABLE_ACTIONS.UPDATE_PAGINATION,
  payload,
});

export const searchSuccessTableAction = payload => ({
  type: TABLE_ACTIONS.SEARCH_SUCCESS,
  payload,
});

export const searchInProgressTableAction = () => ({
  type: TABLE_ACTIONS.SEARCH_IN_PROGRESS,
});

export const searchErrorTableAction = payload => ({
  type: TABLE_ACTIONS.SEARCH_ERROR,
  payload,
});

export const resetSearchTableAction = () => ({
  type: TABLE_ACTIONS.RESET_SEARCH,
});

export const addElementTableAction = (newElement) => ({
  type: TABLE_ACTIONS.ADD_ELEMENT,
  payload: newElement,
});

export const updateElementTableAction = (id, newElement) => ({
  type: TABLE_ACTIONS.UPDATE_ELEMENT,
  payload: { id, newElement },
});

export const deleteElementTableAction = id => ({
  type: TABLE_ACTIONS.DELETE_ELEMENT,
  payload: id,
});

export const updateTableFilters = filters => ({
  type: TABLE_ACTIONS.UPDATE_FILTERS,
  payload: filters,
})

export const changeOrderingTableAction = sortOrder => ({
  type: TABLE_ACTIONS.UPDATE_SORTING,
  payload: sortOrder,
})