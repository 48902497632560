import React, {useContext} from "react";
import RoleContext from "../../Contexts/RoleContext";
import ErrorPage from "../Error/ErrorPage";
import TFGStudent from './TFGStudent';
import TFGInstructor from './TFGInstructor';
import TFGAdmin from "./TFGAdmin";
import TFGCoord from './TFGCoord';
import TFGSecr from "./TFGSecr";
import './TFG.scss';

const TFGMain = () => {
  const roleContext = useContext(RoleContext);

  function getRoleView() {
    switch (roleContext.role){
      case "ROLE_STUDENT":
        return <TFGStudent />
      case "ROLE_INSTRUCTOR":
        return <TFGInstructor />
      case "ROLE_ADMIN":
        return <TFGAdmin />
      case "ROLE_COORD":
        return <TFGCoord />
      case "ROLE_SECR":
        return <TFGSecr />
      default:
        return <ErrorPage message="No tienes permisos para ver esta pagina" />
    }
  };

  return getRoleView();
}

export default TFGMain;