import React, { useState, useEffect, useMemo } from "react";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import AppBar from "@material-ui/core/AppBar";
import TFGSubjects from "./TFGSubjects";
import TFGCourt from "./TFGCourt/TFGCourt";
import TFGRequestsTable from "./TFGRequests/TFGRequestsTable";
import { useNotification } from "../../Utils/hooks";
import { getTFGInstructorAccess } from "../../services/TFG/TFGService";
import { setApplicationLoading } from "../../components/ApplicationLoading";
import TFGDefense from "./TFGDefense/TFGDefense";
import TFGConfig from "./TFGConfig";

const TFGInstructor = () => {
  const [tab, setTab] = useState(0);
  const [access, setAccess] = useState({
    initialized: false,
    areas: false,
  });
  const notification = useNotification();

  const handleTabChange = (_e, newValue) => {
    setTab(newValue);
  };

  useEffect(() => {
    async function checkAccess() {
      try {
        setApplicationLoading(true);
        const result = await getTFGInstructorAccess();
        setAccess({ initialized: true, areas: result.areas });
      } catch {
        notification('error', 'Error al obtener los permisos del instructor');
      } finally {
        setApplicationLoading(false);
      }
    }

    checkAccess();
  }, [notification])

  const [tabs, components] = useMemo(() => {
    const tabs = [];
    const components = [];
  
    if (access.areas) {
      tabs.push('Director TFG/TFM');
      components.push(<TFGRequestsTable instructor />);
      tabs.push('Gestión TFG/TFM');
      components.push(<TFGRequestsTable instructor coordinator />);
      tabs.push('Director de defensas');
      components.push(<TFGDefense role="INSTRUCTOR" />);
      tabs.push('Gestión de defensas');
      components.push(<TFGDefense role="COORDINATOR" />);
      tabs.push('Áreas temáticas');
      components.push(<TFGSubjects admin />);
      tabs.push('Asignación docente');
      components.push(<TFGConfig />);
      tabs.push('Tribunales de defensa');
      components.push(<TFGCourt />);
    } else {
      tabs.push('Solicitudes TFG/TFM');
      components.push(<TFGRequestsTable instructor />);
      tabs.push('Solicitudes de defensa');
      components.push(<TFGDefense role="INSTRUCTOR" />);
    }
  
    return [tabs, components];
  }, [access.areas]);

  return (
    <>
      <AppBar position="static">
        <Tabs variant="fullWidth" value={tab} onChange={handleTabChange}>
          {tabs.map(tab => <Tab key={tab} label={tab} />)}
        </Tabs>
      </AppBar>
      <div className="tfg">
        {components[tab]}
      </div>
    </>
  )
}

export default TFGInstructor
