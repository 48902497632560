import {
    Card,
    CardContent,
    CardHeader, Checkbox,
    Grid,
    LinearProgress,
    makeStyles,
    MenuItem,
    TextField
} from "@material-ui/core";
import MUIDataTable from "mui-datatables";
import React, { useEffect, useState } from "react";
import {getCoursesOfGrades, getEnrollmentTerms, getGrades} from "../../../services/Enrollments/GradesService";
import {Alert} from "@material-ui/lab";

const EnrollmentFormCourseTable = (
    {
        gradeSelected,
        setGradeSelected = null,
        termSelected,
        setTermSelected = null,
        coursesSelected,
        setCoursesSelected = null,
        setCreditsValidation = null,
    }) => {

    const [loading, setLoading] = useState(false)
    const [terms, setTerms] = useState([])
    const [userGrades, setUserGrades] = useState([])
    const [gradeCourses, setGradeCourses] = useState([])

    /*const creditsSelected = useMemo(()=>{
        if(setCreditsValidation){
            getSelectedCredits(coursesSelected)
        }
    },[coursesSelected])*/

    let selectedRows
    if(!setCoursesSelected) {
        selectedRows=coursesSelected.map(element => coursesSelected.indexOf(element))
    }

    const fetchTerms = async () => {
        setLoading(true)
        let terms = await getEnrollmentTerms()
        setTerms(terms)
        setLoading(false)
    }

    const fetchUserGrades = async () => {
        setLoading(true)
        let userGrades = await getGrades()
        setUserGrades(userGrades)
        setLoading(false)
    }

    const fetchGradeCourses = async (gradeId, termId) => {
        setLoading(true)
        let gradeCourses
        if(!setCoursesSelected){
            gradeCourses = await getCoursesOfGrades(gradeId, termId)
            gradeCourses=gradeCourses.filter((gradeCourse) => coursesSelected.map(r => r.id).includes(gradeCourse.id))
        }else {
            gradeCourses= await getCoursesOfGrades(gradeId, termId, true)
        }

        setGradeCourses(gradeCourses)
        setLoading(false)
    }

    /*function getSelectedCredits(localCoursesSelected) {
        if(gradeCourses.length <= 0 && localCoursesSelected.length <= 0){
            return 0
        }
        let totalCredits = gradeCourses.filter((grade) => localCoursesSelected.includes(grade.courseId))
            .map((grade) => parseInt(grade.credits))
            .reduce((previousValue, currentValue) => { return previousValue + currentValue},0)

        if(totalCredits > 0 && totalCredits <= 72)
            setCreditsValidation(true)
        else
            setCreditsValidation(false)
        return totalCredits
    }*/

    // Retrieve terms and grades options
    useEffect(() => {
        fetchTerms();
        fetchUserGrades();
    }, []);

    // When both attributes are defined on mount, directly retrieve the subjects
    useEffect(()=>{
        if (termSelected && gradeSelected) fetchGradeCourses(gradeSelected, termSelected)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // On term update, update the value and check if both grade and term are set to request the subjects
    const handleTermChange = (event) => {
        if (setTermSelected) setTermSelected(event.target.value);
        if (gradeSelected) fetchGradeCourses(gradeSelected, event.target.value);
    }

    // On grade update, update the value and check if both grade and term are set to request the subjects
    const handleGradeChange = (event) => {
        if (setGradeSelected) setGradeSelected(event.target.value);
        if (termSelected) fetchGradeCourses(event.target.value, termSelected);
    }

    const CustomCheckbox = (props) => {
        if(props["data-description"] === "row-select"){
            //this is a data row
            let dataIndex=props["data-index"] || 0
            let course=gradeCourses[dataIndex]
            if(course.enrollments>=course.maxEnrollments){
                return <Alert severity={"warning"}>Asignatura sin plazas libres</Alert>
            }
        }
        if(!setCoursesSelected){
            return <Checkbox {...props} disabled={true}/>
        }
        return <Checkbox {...props} />
    };

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }))
    const classes = useStyles()

    const columns =[
        {
            label: "CODIGO ASIGNATURA",
            name: "courseId"
        },
        {
            label: "DENOMINACIÓN OFICIAL DE LA ASIGNATURA",
            name: "courseName"
        },
        {
            label: "CRÉDITOS",
            name: "credits"
        }
    ]

    let options
    if(!setCoursesSelected){
        options={
            selectableRows: 'none',
            selectableRowsOnClick: false,
            rowsSelected: selectedRows,
            print: false
        }
    }else {
        options={
            selectableRows: 'multiple',
            onRowSelectionChange: (currentRowsSelected,allRowsSelected,rowsSelected) => {
                if(setCoursesSelected){
                    let localCoursesSelected=rowsSelected.map( (element) => gradeCourses[element].courseId)
                    setCoursesSelected(localCoursesSelected)
                }
            },
            print: false,
            /*customTableBodyFooterRender: function(ops) {
                return (
                    <Grid container spacing={2} direction={"row"} justify={"flex-end"}>
                        <Grid item>
                            { creditsSelected > 72
                                ? <Chip style={{background: '#AD0022'}} label={`Total Creditos: ${creditsSelected} (Limite excedido)`} />
                                : <Chip label={`Total Creditos: ${creditsSelected}`} />
                            }
                        </Grid>
                    </Grid>
                )
            }*/
        }
    }

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"ASIGNATURAS DE SU MATRÍCULA"}
                subheader={"Por favor, indique todas las asignaturas que desea que se incluyan en su matrícula"}
            />
            <CardContent>
                <Grid container spacing={2}>
                    <Grid item md={12}>
                        { loading
                            ? <LinearProgress/>
                            : (
                                <Grid container spacing={2}>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Ciclo lectivo"
                                            value={termSelected}
                                            fullWidth
                                            onChange={handleTermChange}
                                            disabled={!setTermSelected}
                                            select>
                                            { terms.map((value, index) => <MenuItem key={index} value={value.externalId}>{value.name}</MenuItem> ) }
                                        </TextField>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Grado"
                                            value={gradeSelected}
                                            fullWidth
                                            onChange={handleGradeChange}
                                            disabled={!setGradeSelected}
                                            select>
                                            { userGrades.map((value, index) => <MenuItem key={index} value={value.externalId}>{value.gradeName}</MenuItem> ) }
                                        </TextField>
                                    </Grid>
                                </Grid>
                            )
                        }

                    </Grid>
                    <Grid item md={12}>
                        <MUIDataTable
                            title={`Asignaturas`}
                            columns={columns}
                            options={options}
                            data={gradeCourses}
                            components={{
                                Checkbox: CustomCheckbox
                            }}
                        />
                    </Grid>
                </Grid>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormCourseTable
