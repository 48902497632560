import React, { useState, useRef, useCallback } from 'react';
import { CircularProgress } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import { setApplicationLoading } from '../../components/ApplicationLoading';
import InstancesTable from './InstancesTable/InstancesTable';
import InstanceResponseDialog from './InstanceResponseDialog';
import { useNotification } from '../../Utils/hooks';
import { copyToClipboard } from '../../Utils/utils';
import { getInstances, closeInstance, reopenInstance } from '../../services/Instances/InstancesService';
import { generateInstructorInstancesTable } from './InstancesTable/InstancesTable.utils';
import InstanceFiles from './InstanceFiles/InstanceFiles';
import InstancesFilters from './InstancesFilters';
import './Instances.scss';

const InstancesInstructor = ({ secr }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [filesInstance, setFilesInstance] = useState(null);
  const [visibleFilesDialog, setShowFilesDialog] = useState(false);
  const notification = useNotification();
  const confirm = useConfirm();
  const prevFilters = useRef(undefined);

  const _getInstances = useCallback(async (filters) => {
    try {
      if (!filters) filters = prevFilters.current;
      setLoading(true);
      const result = await getInstances(filters);
      if (!result) throw new Error();
      setData(result);
      prevFilters.current = filters;
    } catch {
      notification('error', 'Ha habido un error al cargar las instancias');
    } finally {
      setLoading(false);
    }
  }, [notification]);

  const onClose = (e, id) => {
    e.stopPropagation();

    confirm({
      title: 'Cerrar solicitud',
      description: 'Atención, esta acción cerrará la solicitud seleccionada terminando así el proceso, ¿estás seguro de que deseas realizar esta acción?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          const result = await closeInstance(id);
          setData(prev => {
            const newInstances = [...prev];
            const index = newInstances.findIndex(ins => ins.id === result.id);
            if (~index) newInstances[index] = result;
            return newInstances;
          })
          // _getInstances();
        } catch {
          notification('error', 'Ha habido un error al cerrar la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onReopen = (e, id) => {
    e.stopPropagation();

    confirm({
      title: 'Reabrir solicitud',
      description: 'Atención, esta acción reabrirá la solicitud seleccionada, devolviéndola al estado Abierta, ¿estás seguro de que deseas realizar esta acción?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          const result = await reopenInstance(id);
          setData(prev => {
            const newInstances = [...prev];
            const index = newInstances.findIndex(ins => ins.id === result.id);
            if (~index) newInstances[index] = result;
            return newInstances;
          })
          // _getInstances();
        } catch {
          notification('error', 'Ha habido un error al reabrir la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }

  const onInstanceClick = (rowData) => {
    if (!secr) return;

    const id = rowData[0];
    const instance = data.find(instance => instance.id === id);
    setSelectedInstance(instance);
    setShowDialog(true);
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setSelectedInstance(), 250);
  }

  const onInstanceReply = (instance) => {
    setData(prev => {
      const newInstances = [...prev];
      const index = newInstances.findIndex(ins => ins.id === instance.id);
      if (~index) newInstances[index] = instance;
      return newInstances;
    })
    onHideDialog();
    // _getInstances();
  }

  const onFiles = (e, instance) => {
    e.stopPropagation();
    setFilesInstance(instance);
    setShowFilesDialog(true);
  }

  const onHideFilesDialog = () => {
    setShowFilesDialog(false);
    setTimeout(() => setFilesInstance(null), 250);
  }

  const onSuccessfulFileUpload = (id, fileName) => {
    setData(prev => {
      const newData = [...prev];
      const index = newData.findIndex(ins => ins.id === id);
      newData[index].requestFileName = fileName;
      return newData;
    })
  }

  const onCopyToClipboard = (e, instance) => {
    e.stopPropagation();
    const text = `${instance.givenName} ${instance.surname1} ${instance.userExternalId}`;
    copyToClipboard(text);
    notification('success', 'Se ha copiado la información del alumno al portapapeles');
  }

  const columns = generateInstructorInstancesTable(data, secr, onFiles, onClose, onCopyToClipboard, undefined, onReopen);

  return (
    <div className="instances student">
      <InstanceResponseDialog
        visible={showDialog}
        instance={selectedInstance}
        user={selectedInstance}
        onHide={onHideDialog}
        onSuccess={onInstanceReply}
        onSuccessfulFileUpload={onSuccessfulFileUpload}
      />
      <InstanceFiles
        visible={visibleFilesDialog}
        instance={filesInstance}
        onHide={onHideFilesDialog}
        onSuccess={onSuccessfulFileUpload}
        canUploadInstructor={secr}
      />
      <InstancesFilters onSearch={_getInstances} />
      {
        loading
          ? (
            <div className="spinner-container">
              <CircularProgress size={32} />
            </div>
          ) : (
            <InstancesTable
              data={data}
              columns={columns}
              title='Solicitudes en proceso'
              onRowClick={secr ? onInstanceClick : undefined}
              options={{ sortOrder: { name: 'created', direction: 'desc' }, print: true }}
            />
          )
      }
    </div>
  )
}

export default InstancesInstructor;
