import Http from '../../Utils/Http';
import { localDevCatch, generateFileFormData, cleanUpObject } from '../../Utils/utils';
import {
  mockedTemplates, mockedInstances, mockedInstancesUserInfo, mockedTerms, mockedDegrees, mockedCourses, mockedTemplateTypes,
} from './InstancesService.mock';

export const getTemplateTypes = async () => {
  const result = await Http.get(`/instances-api/types`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTemplateTypes));
  return result;
};

export const createTemplateType = async (name) => {
  const result = await Http.post(`/instances-api/types`, { name })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: 666, name }));
  return result;
};

export const updateTemplateType = async (id, name) => {
  const result = await Http.patch(`/instances-api/types/${id}`, { name })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: 666, name }));
  return result;
};

export const deleteTemplateType = async id => {
  const result = await Http.delete(`/instances-api/types/${id}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
};

export const getTemplates = async all => {
  const result = await Http.get(`/instances-api/templates`, { params: { all } })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTemplates));
  return result;
};

export const uploadTemplatesCSV = async file => {
  const formData = generateFileFormData(file);
  const result = await Http.post(`/instances-api/templates/csv`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
}

export const downloadTemplate = async id => {
  const result = await Http.get(`/instances-api/templates/${id}/download`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const activateTemplate = async id => {
  const result = await Http.put(`/instances-api/templates/${id}`, { active: true })
    .then(res => res.data)
    // .catch((err) => localDevCatch(err, true));
  return result;
}

export const deleteTemplate = async id => {
  const result = await Http.delete(`/instances-api/templates/${id}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const getTerms = async () => {
  const result = await Http.get(`/instances-api/data/terms`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedTerms));
  return result;
};

export const getDegrees = async () => {
  const result = await Http.get(`/instances-api/data/degrees`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedDegrees));
  return result;
};

export const getCourses = async termId => {
  const result = await Http.get(`/instances-api/data/courses/${termId}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedCourses));
  return result;
};

export const createTemplate = async template => {
  const result = await Http.post(`/instances-api/templates`, template)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, template));
  return result;
}

export const updateTemplate = async template => {
  const result = await Http.put(`/instances-api/templates/${template.id}`, template)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, template));
  return result;
}

export const getUserInstanceInfo = async () => {
  const result = await Http.get(`/instances-api/data/users/info`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedInstancesUserInfo));
  return result;
}

export const getUserInstanceInfoById = async externalId => {
  const result = await Http.get(`/instances-api/data/users/${externalId}`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedInstancesUserInfo));
  return result;
}

export const getInstances = async (filters) => {
  if (filters) cleanUpObject(filters);
  const result = await Http.get(`/instances-api/instances`, { params: filters })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, mockedInstances));
  return result;
}

export const postInstanceRequest = async (templateId, userText, userInfo) => {
  const result = await Http.post(`/instances-api/instances`, { templateId, userText, ...userInfo })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, { id: 666, templateId, userText }));
  return result;
}

export const uploadInstanceFile = async (id, file) => {
  const formData = generateFileFormData(file, 'files');
  const result = await Http.post(`/instances-api/instances/${id}/file`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
}

export const downloadInstanceFile = async instanceId => {
  const result = await Http.get(`/instances-api/instances/${instanceId}/file`, { responseType: 'blob' })
    .then(res => {
      const { data, headers } = res;
      const name = headers['content-disposition']
        .split('filename="')[1]
        .split('"')[0];
      return ({ file: data, name })
    })
    .catch(err => localDevCatch(err, new Blob(['<span>Test</span>'])));
  return result
}

export const uploadStudentInstanceFile = async (id, file) => {
  const formData = generateFileFormData(file, 'files');
  const result = await Http.post(`/instances-api/instances/${id}/studentFile`, formData)
    .then(res => res.data)
    .catch(err => localDevCatch(err, true))
  return result;
}

export const downloadStudentInstanceFile = async instanceId => {
  const result = await Http.get(`/instances-api/instances/${instanceId}/studentFile`, { responseType: 'blob' })
    .then(res => {
      const { data, headers } = res;
      const name = headers['content-disposition']
        .split('filename="')[1]
        .split('"')[0];
      return ({ file: data, name })
    })
    .catch(err => localDevCatch(err, new Blob(['<span>Test</span>'])));
  return result
}

export const replyInstance = async (id, instructorText) => {
  const result = await Http.patch(`/instances-api/instances/${id}`, { instructorText })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const reopenInstance = async id => {
  const result = await Http.patch(`/instances-api/instances/${id}`, { status: 'OPEN' })
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const closeInstance = async id => {
  const result = await Http.post(`/instances-api/instances/${id}/close`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}

export const cancelInstance = async id => {
  const result = await Http.post(`/instances-api/instances/${id}/cancel`)
    .then(res => res.data)
    .catch((err) => localDevCatch(err, true));
  return result;
}