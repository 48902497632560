import {Button, CircularProgress} from "@material-ui/core";
import React, {useState} from "react";
import {getExtendedRequestPDF} from "../../../services/ExtendedEnrollments/ExtendedRequestsService";
import { getRequestPDF } from "../../../services/Enrollments/RequestsService";

const ExtendedEnrollmentsFormDownloadPDF = ({ requestId, newService }) =>{

    const [loading,setLoading] = useState(false)

    const fetchPDFFile = async (requestId) => {
        setLoading(true)
        await newService ? getRequestPDF(requestId) : getExtendedRequestPDF(requestId)
        setLoading(false)
    }

    return (
        <React.Fragment>
            { loading
                ? <CircularProgress/>
                : <Button variant={"contained"} color={"primary"} fullWidth onClick={() => fetchPDFFile(requestId)}>
                    Descargar PDF
                </Button>
            }
        </React.Fragment>
    )
}

export default ExtendedEnrollmentsFormDownloadPDF