import {Card, CardContent, CardHeader, FormControlLabel, makeStyles, Radio, RadioGroup} from "@material-ui/core";
import React from "react";

const EnrollmentFormTimeOptionData = ({selected, setSelected = null, disabled = false}) => {

    const useStyles = makeStyles(() => ({
        header: {
            backgroundColor: "#003F62",
            color: "#fff"
        },
        subheader: {
            color: "#fff"
        }
    }));
    const classes = useStyles();

    const handleChange = (event) => {
        if(setSelected){
            setSelected(event.target.value);
        }else {
            console.warn("Read-only Component")
        }
    };

    return (
        <Card>
            <CardHeader
                classes={{
                    root: classes.header,
                    subheader: classes.subheader
                }}
                title={"DEDICACIÓN AL ESTUDIO"}
            />
            <CardContent>
                <RadioGroup name={"timeOption"} value={selected} onChange={handleChange}>
                    <FormControlLabel value={"FULL_TIME"} control={<Radio />} label={"Matrícula a tiempo completo"} disabled={disabled} />
                    <FormControlLabel value={"PART_TIME"} control={<Radio />} label={"Matrícula a tiempo parcial"} disabled={disabled} />
                </RadioGroup>
            </CardContent>
        </Card>
    )
}

export default EnrollmentFormTimeOptionData