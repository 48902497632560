
export const getCourses = async () => {
    let sessionId = sessionStorage.getItem("sessionId");
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    return await fetch(`/exams-api/courses`, {headers: headers})
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
            return [
                {
                    courseId: "Curso001",
                    courseName: "Curso de Javascript"
                },
                {
                    courseId: "Curso002",
                    courseName: "Curso de Inteligencia Artificial"
                },
            ];
        })
}

export const getCoursesOfUser = async (userId) => {
    let sessionId = sessionStorage.getItem("sessionId");
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    return await fetch(`/exams-api/courses/${userId}`, {headers: headers})
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
            return [
                {
                    courseId: "Curso001",
                    courseName: "Curso de Javascript"
                },
                {
                    courseId: "Curso002",
                    courseName: "Curso de Inteligencia Artificial"
                },
            ];
        })
}

export const getOrganizations = async () => {
    let sessionId = sessionStorage.getItem("sessionId");
    let headers = new Headers({
        'Authorization': 'Bearer ' + sessionId
    });
    return await fetch(`/exams-api/courses/organizations`, {headers: headers})
        .then(r => r.json())
        .catch(reason => {
            console.log(reason);
            return [
                {
                    "courseId": "cntr_exam_1",
                    "courseName": "EXAMEN ONLINE 1"
                },
                {
                    "courseId": "cntr_exam_2",
                    "courseName": "EXAMEN ONLINE 2 "
                },
                {
                    "courseId": "cntr_exam_99",
                    "courseName": "EXAMEN ONLINE"
                }
            ];
        });
}