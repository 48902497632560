import InteractionsList from "./InteractionsList";
import React, {useEffect, useState} from "react";
import {CircularProgress} from "@material-ui/core";

const InteractionListContainer = () => {

    const [data, setData] = useState([])
    const [loading,setLoading] = useState(false)

    const fetchInteractions = async () => {
        setLoading(true);
        let sessionId=sessionStorage.getItem("sessionId");
        let headers= new Headers({
            'Authorization':'Bearer '+sessionId
        });
        const interactions= await fetch("/interactions-api",{ headers: headers })
            .then(r => r.json())
            .catch(reason => {
                console.log(reason);
                return [
                    {
                        id: 1,
                        type: "auto",
                        created: "1/1/1",
                        issuer: "1234",
                        receiver: "4321",
                        dni: "0000000A",
                        titulation: null,
                        course: null,
                        interactionText: "asdf",
                        attatchments: [
                            "interaction.pdf",
                            "response.doc"
                        ]
                    },
                    {
                        id: 2,
                        type: "auto",
                        created: "1/1/1",
                        issuer: "1234",
                        receiver: "System",
                        dni: "0000000A",
                        titulation: null,
                        course: null,
                        interactionText: "asdf",
                        attatchments: [
                            "interaction.pdf",
                            "response.doc"
                        ]
                    }
                ];
            });
        setData(interactions)
        setLoading(false);
    }

    useEffect(() =>{
       fetchInteractions(data)
    },[]);

    return (
        <React.Fragment>
            { loading === true
                ? <CircularProgress />
                : <InteractionsList interactions={data}/>
            }
        </React.Fragment>
    )
}

export default InteractionListContainer;
