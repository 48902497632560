import React, {useContext} from "react";
import RoleContext from "../../Contexts/RoleContext";
import ExamsStudentView from "./Student/ExamsStudentView";
import ErrorPage from "../Error/ErrorPage";
import ExamsAdminView from "./Admin/ExamsAdminView";
import ExamsManagerView from "./Manager/ExamsManagerView";
import ExamsStudentMain from "./Student/ExamsStudentMain";
import ExamsInstructorMain from "./Instructor/ExamsInstructorMain";
import ExamsCoordView from "./Coordinator/ExamsCoordView";

const ExamsMain = () => {

    const roleContext=useContext(RoleContext)

    function getRoleView(){
        switch (roleContext.role){
            case "ROLE_STUDENT":
                return <ExamsStudentMain/>
            case "ROLE_INSTRUCTOR":
                return <ExamsInstructorMain/>
            case "ROLE_SECR":
                return <ExamsStudentView/>
            case "ROLE_COORD":
                return  <ExamsCoordView/>
            case "ROLE_ADMIN":
                return <ExamsAdminView/>
            case "ROLE_GES_EXAM":
                return <ExamsManagerView/>
            default:
                return <ErrorPage message={"No tienes permisos para ver esta pagina"}/>
        }
    }

    return (
        <React.Fragment>
            { getRoleView() }
        </React.Fragment>
    )
}

export default ExamsMain
