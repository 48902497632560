import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { CircularProgress, AppBar, Tabs, Tab } from '@material-ui/core';
import { useConfirm } from 'material-ui-confirm';
import InstanceFiles from './InstanceFiles/InstanceFiles';
import InstancesTable from './InstancesTable/InstancesTable';
import {
  getTemplates, getInstances, getUserInstanceInfo, cancelInstance,
} from '../../services/Instances/InstancesService';
import { useNotification } from '../../Utils/hooks';
import { setApplicationLoading } from '../../components/ApplicationLoading';
import InstanceRequestDialog from './InstanceRequestDialog/InstanceRequestDialog';
import { generateTemplatesTable, generateStudentInstancesTable } from './InstancesTable/InstancesTable.utils';
import './Instances.scss';

const InstancesStudent = () => {
  const [templates, setTemplates] = useState([]);
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(false);
  const [tabValue, setTabValue] = useState(0);
  const [userInfo, setUserInfo] = useState(null);
  const [showDialog, setShowDialog] = useState(false);
  const [selectedInstance, setSelectedInstance] = useState(null);
  const [filesInstance, setFilesInstance] = useState(null);
  const [visibleFilesDialog, setShowFilesDialog] = useState(false);
  const notification = useNotification();
  const confirm = useConfirm();
  const instancesView = tabValue === 1;

  const data = useMemo(() =>
    tabValue === 1 ? instances : templates
  , [tabValue, instances, templates]);

  const _getTemplates = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getTemplates();
      if (!result) throw new Error();
      setTemplates(result);
    } catch {
      notification('error', 'Ha habido un error al cargar las plantillas');
    } finally {
      setLoading(false);
    }
  }, [notification]);

  const _getInstances = useCallback(async () => {
    try {
      setLoading(true);
      const result = await getInstances();
      if (!result) throw new Error();
      setInstances(result);
    } catch {
      notification('error', 'Ha habido un error al cargar las instancias');
    } finally {
      setLoading(false);
    }
  }, [notification]);

  const getUserInfo = useCallback(async () => {
    try {
      const result = await getUserInstanceInfo();
      if (!result) throw new Error();
      setUserInfo(result);
    } catch {
      notification('error', 'Ha habido un error al cargar la información del usuario');
    }
  }, [notification]);

  const loadData = useCallback(() => {
    if (tabValue === 0) _getTemplates();
    if (tabValue === 1) _getInstances();
  }, [_getTemplates, _getInstances, tabValue]);

  useEffect(() => {
    getUserInfo();
  }, [getUserInfo]);

  useEffect(() => {
    loadData();
  }, [loadData]);

  const onCancel = useCallback(id => {
    confirm({
      title: 'Cancelar solicitud',
      description: 'Atención, esta acción cancelará la solicitud seleccionada, ¿estás seguro de que deseas realizar esta acción?',
    })
      .then(async () => {
        try {
          setApplicationLoading(true);
          await cancelInstance(id);
          getInstances();
        } catch {
          notification('error', 'Ha habido un error al cancelar la solicitud');
        } finally {
          setApplicationLoading(false);
        }
      })
      .catch(() => {})
  }, [confirm, notification])

  const onFiles = useCallback((e, instance) => {
    e.stopPropagation();
    setFilesInstance(instance);
    setShowFilesDialog(true);
  }, [])

  const columns = useMemo(() => {
    return instancesView
      ? generateStudentInstancesTable(data, onCancel, onFiles)
      : generateTemplatesTable(data, undefined, undefined, undefined)
  }, [data, instancesView, onCancel, onFiles])

  const handleTabChange = (_event, newValue) => {
    setTabValue(newValue);
  };

  const onUpdateStudentFile = (id, name) => {
    setInstances(prev => {
      const newData = [...prev];
      const index = newData.findIndex(ins => ins.id === id);
      newData[index].studentFileName = name;
      return newData;
    })
  }

  const onInstanceClick = (rowData) => {
    const id = rowData[0];
    const instance = data.find(instance => instance.id === id);
    setSelectedInstance(instance);
    setShowDialog(true);
  }

  const onHideDialog = () => {
    setShowDialog(false);
    setTimeout(() => setSelectedInstance(), 250);
  }

  const onNewRequest = () => {
    onHideDialog();
    setTabValue(1);
  }

  const onHideFilesDialog = () => {
    setShowFilesDialog(false);
    setTimeout(() => setFilesInstance(null), 250);
  }

  const tableOptions = instancesView
    ? { sortOrder: { name: 'templateType', direction: 'asc' } }
    : { sortOrder: { name: 'typeName', direction: 'asc' } }

  return (
    <div className="instances student">
      <>
        <InstanceRequestDialog
          visible={showDialog}
          instance={selectedInstance}
          user={userInfo}
          onHide={onHideDialog}
          onSuccess={onNewRequest}
        />
        <InstanceFiles
          visible={visibleFilesDialog}
          instance={filesInstance}
          onHide={onHideFilesDialog}
          onSuccess={onUpdateStudentFile}
          canUploadStudentFile
        />
        <AppBar position="static" className="instance-type">
          <Tabs variant="fullWidth" value={tabValue} onChange={handleTabChange}>
            <Tab label="Instancias Disponibles" />
            <Tab label="Instancias Realizadas" />
          </Tabs>
        </AppBar>
        {
          loading
            ? (
              <div className="spinner-container">
                <CircularProgress size={32} />
              </div>
            ) : (
              <InstancesTable
                options={tableOptions}
                data={data}
                title={instancesView ? 'Solicitudes realizadas' : undefined}
                columns={columns}
                onRowClick={!instancesView ? onInstanceClick : undefined}
              />
            )
        }
      </>
    </div>
  )
}

export default InstancesStudent;
