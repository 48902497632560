import React from 'react'
import { Tooltip, Icon, IconButton } from '@material-ui/core';

export const renderInstanceTableActions = (id, instance, onEdit, onToggleActivation, onCancel, onClose, onFiles, onCopy, onDelete, onReopen) => {
  // const canDisable = !instance?.active;
  const canActivate = instance.start && instance.end;

  const FilesIcon = (
    <Tooltip title="Ver ficheros" aria-label="view-files">
      <IconButton aria-label="files" onClick={e => onFiles(e, instance)}>
        <Icon className="download-icon">
          perm_media
        </Icon>
      </IconButton>
    </Tooltip>
  )

  const EditIcon = (
    <Tooltip title="Editar" aria-label="edit">
      <IconButton aria-label="edit" onClick={() => onEdit(id)}>
        <Icon className="edit-icon">
          edit
        </Icon>
      </IconButton>
    </Tooltip>
  )

  // Admin
  const ActivationIcon = (
    <Tooltip title={instance.active ? 'Desactivar' : 'Activar'} aria-label="delete">
      <IconButton
        aria-label="activate"
        onClick={() => onToggleActivation(id, instance.active)}
        disabled={instance.active ? false : !canActivate}
        // disabledLabel={instance.active ? undefined : 'Los datos de la instancia no están completos'}
      >
        <Icon className="delete-icon">
         {instance.active ? 'visibility_off' : 'visibility_on'}
        </Icon>
      </IconButton>
    </Tooltip>
  )

  // Student
  const CancelIcon = (
    <Tooltip title="Cancelar" aria-label="close">
      <IconButton aria-label="close" onClick={() => onCancel(id)}>
        <Icon className="close-icon">
          close
        </Icon>
      </IconButton>
    </Tooltip>
  )

  // Instructor / secretary
  const CloseIcon = (
    <Tooltip title="Cerrar" aria-label="close">
      <IconButton aria-label="close" onClick={e => onClose(e, id)}>
        <Icon className="close-icon">
          close
        </Icon>
      </IconButton>
    </Tooltip>
  )

  // Instructor / secretary
  const CopyIcon = (
    <Tooltip title="Copiar al portapapeles" aria-label="copy-to-clipboard">
      <IconButton aria-label="copy-to-clipboard" onClick={e => onCopy(e, instance)}>
        <Icon className="copy-icon">
          content_copy
        </Icon>
      </IconButton>
    </Tooltip>
  )

  // Secretary
  const DeleteIcon = (
    <Tooltip title="Eliminar" aria-label="delete">
      <IconButton aria-label="delete" onClick={e => onDelete(e, instance)}>
        <Icon className="delete-icon">
          delete
        </Icon>
      </IconButton>
    </Tooltip>
  )

  // Coord
  const ReopenIcon = (
    <Tooltip title="Reabrir" aria-label="reopen">
      <IconButton aria-label="reopen" onClick={e => onReopen(e, id)}>
        <Icon className="reopen-icon">
          lock_open
        </Icon>
      </IconButton>
    </Tooltip>
  )

  return <div className="instance-actions">
    { !!onFiles && FilesIcon }
    { !!onEdit && EditIcon }
    { !!onToggleActivation && ActivationIcon }
    { !!onCancel && CancelIcon }
    { !!onClose && CloseIcon }
    { !!onCopy && CopyIcon }
    { !!onDelete && DeleteIcon }
    { !!onReopen && ReopenIcon }
  </div>
}

export const INSTANCE_STATUS = {
  OPEN: 'Abierta',
  CLOSED: 'Cerrada',
  CANCELED: 'Cancelada',
  IN_PROCESS: 'En proceso',
}

export const instanceStatusOptions = [
  { label: INSTANCE_STATUS['OPEN'], value: 'OPEN' },
  { label: INSTANCE_STATUS['CLOSED'], value: 'CLOSED' },
  { label: INSTANCE_STATUS['CANCELED'], value: 'CANCELED' },
  { label: INSTANCE_STATUS['IN_PROCESS'], value: 'IN_PROCESS' },
] 