import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { Button, Dialog, DialogTitle, DialogContent, DialogActions, Grid } from '@material-ui/core';
import InputDate from '../../../components/Inputs/RHK/InputDate';
import InputAutoCompleteValue from '../../../components/Inputs/RHK/InputAutoCompleteValue';
import { setApplicationLoading } from '../../../components/ApplicationLoading';
import { createTemplate, updateTemplate, getTerms, getTemplateTypes } from '../../../services/Instances/InstancesService';
import { useNotification } from '../../../Utils/hooks';
import { required } from '../../../Utils/utils';

const defaultValues = {
  typeId: null,
  start: null,
  end: null,
  // modality: '',
  // receiver: '',
  termId: null,
}

const convertData = dataSet => dataSet?.map(
  item => ({ label: item.name, value: item.externalId })
);

const InstanceFormDialog = ({ secr, visible, onHide, instance, onSuccess }) => {
  const { control, handleSubmit, reset } = useForm();
  const [types, setTypes] = useState([]);
  const [terms, setTerms] = useState([]);
  const [loadingData, setLoadingData] = useState(false);
  const notification = useNotification();
  const isEdit = !!instance;
  const service = isEdit ? updateTemplate : createTemplate;

  useEffect(() => {
    reset(instance || defaultValues);
  }, [instance, reset]);

  useEffect(() => {
    async function dataRequest() {
      try {
        if (!secr) return;
        setLoadingData(true);
        const terms = await getTerms().then(convertData);
        const types = await getTemplateTypes().then(res => res.map(
          type => ({ label: type.name, value: type.id })
        ));
        if (!terms) throw new Error();
        setTerms(terms);
        setTypes(types);
      } catch {
        // Notification
      } finally {
        setLoadingData(false);
      }
    }

    dataRequest();
  }, [secr]);

  const onSubmit = async data => {
    try {
      // TODO: Clean-up properties depending on user role
      setApplicationLoading(true);
      const result = await service(data);
      onSuccess(isEdit, result);
      reset(defaultValues);
      if (isEdit) {
        notification('success', 'La plantilla se ha modificado correctamente');
      } else {
        notification('success', 'La plantilla se ha creado correctamente');
      }
    } catch {
      notification('error', 'Ha habido un error al actualizar la instancia');
    } finally {
      setApplicationLoading(false);
    }
  }

  const dialogTitle = `${instance ? 'Editar' : 'Crear'} plantilla`;

  if (loadingData) return null;

  return (
    <Dialog
      className="instance-form-dialog"
      open={visible}
      onClose={onHide}
      maxWidth="md"
      fullWidth
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogTitle>{dialogTitle}</DialogTitle>
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                name="typeId"
                control={control}
                options={types}
                label="Nombre / tipo"
                loading={loadingData}
                rules={required}
              />
            </Grid>
            <Grid item md={6} xs={12} className="input-date">
              <InputDate
                name="start"
                control={control}
                label="Fecha de inicio"
                rules={required}
              />
            </Grid>
            <Grid item md={6} xs={12} className="input-date">
              <InputDate
                name="end"
                control={control}
                label="Fecha de fin"
                rules={required}
              />
            </Grid>
            <Grid item xs={12}>
              <InputAutoCompleteValue
                name="termId"
                control={control}
                options={terms}
                label="Convocatoria"
                loading={loadingData}
                rules={required}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button variant="contained" color="primary" type="submit">
            Guardar
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

export default InstanceFormDialog
